import { Variable } from "@components/richTextEditor/variable/variable";
import { CompositeDecorator, ContentBlock, ContentState, EditorState, Modifier, SelectionState } from "draft-js";
import * as React from "react";

export const clearInlineStyle = (selection: SelectionState, state: EditorState): EditorState => {

    const styles = ["BOLD", "ITALIC", "UNDERLINE"];

    const contentState = state.getCurrentContent();
    const contentWithoutStyles = styles.reduce(
        (acc, style) =>
            Modifier.removeInlineStyle(acc, selection, style),
        contentState
    );

    const newEditorState = EditorState.push(
        state,
        contentWithoutStyles,
        "change-inline-style"
    );

    return newEditorState;
};

export const clearBlocks = (selection: SelectionState, state: EditorState): EditorState => {

    const contentState = state.getCurrentContent();
    let contentWithoutBlocks = contentState;
    let newEditorState = state;
    const blockMap = contentState.getBlockMap();

    blockMap.forEach(block => {
        if (block) {
            contentWithoutBlocks = Modifier.setBlockType(newEditorState.getCurrentContent(), selection, "unstyled");
        }
    });

    newEditorState = EditorState.push(
        newEditorState,
        contentWithoutBlocks,
        "change-block-type"
    );

    return newEditorState;
};

const findVariableEntities = (contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState): void => {
    contentBlock.findEntityRanges(
        character => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === "VARIABLE"
            );
        },
        callback
    );
};

const findLinkEntities = (contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState): void => {
    contentBlock.findEntityRanges(
        character => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === "LINK"
            );
        },
        callback
    );
};

export interface LinkProps {
    offsetKey: string;
    decoratedText: string;
    contentState: ContentState;
    entityKey: string;
}

const Link: React.FunctionComponent<LinkProps> = props => {
    const { url } = props.contentState.getEntity(props.entityKey).getData();
    return (
        <a href={url} >
            {props.children}
        </a>
    );
};

export const decorator = new CompositeDecorator([
    {
        strategy: findVariableEntities,
        component: Variable
    },
    {
        strategy: findLinkEntities,
        component: Link
    }
]);
