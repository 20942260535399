/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { InquiryToBeRescheduledMutation, InquiryToBeRescheduledMutationVariables } from "@models/graphql";

const defaultInquiryToBeRescheduledOptions: MutationHookOptions<InquiryToBeRescheduledMutation, InquiryToBeRescheduledMutationVariables> = {};

export const useInquiryToBeRescheduled = (options?: MutationHookOptions<InquiryToBeRescheduledMutation, InquiryToBeRescheduledMutationVariables>) => {
    const [mutation, { data, loading, error }] = useMutation<InquiryToBeRescheduledMutation, InquiryToBeRescheduledMutationVariables>(
        GraphQLDocuments.inquiryToBeRescheduled
    );

    const inquiryToBeRescheduled = async (variables: InquiryToBeRescheduledMutationVariables) => {
        try {
            const response = await mutation({
                ...defaultInquiryToBeRescheduledOptions,
                ...options,
                variables
            });

            return {
                error: undefined,
                response
            };
        } catch (err) {
            return {
                error: err,
                response: undefined
            };
        }
    };

    return {
        isLoading: loading,
        error,
        data,
        inquiryToBeRescheduled: inquiryToBeRescheduled
    };
};
