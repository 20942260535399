/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { CreateTruckMutation, CreateTruckMutationVariables } from "@models/graphql";

const defaultCreateTruckOptions: MutationHookOptions<CreateTruckMutation, CreateTruckMutationVariables> = {};

export const useCreateTruck = (options?: MutationHookOptions<CreateTruckMutation, CreateTruckMutationVariables>) => {
    const [mutation, { data, loading, error }] = useMutation<CreateTruckMutation, CreateTruckMutationVariables>(
        GraphQLDocuments.createTruck
    );

    const createTruck = async (variables: CreateTruckMutationVariables) => {
        try {
            const response = await mutation({
                ...defaultCreateTruckOptions,
                ...options,
                variables
            });

            return {
                error: response.errors,
                data: response.data
            };
        } catch (err) {
            return {
                error: err
            };
        }
    };

    return {
        isLoading: loading,
        error,
        data,
        createTruck
    };
};
