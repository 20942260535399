/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { GetCollectionPatternQuery, GetCollectionPatternQueryVariables } from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

export const useGetCollectionPattern = (options?: QueryHookOptions<GetCollectionPatternQuery, GetCollectionPatternQueryVariables>) => {
    const { data, error, loading, refetch } = useQuery<GetCollectionPatternQuery, GetCollectionPatternQueryVariables>(
        GraphQLDocuments.getCollectionPattern,
        options
    );

    return {
        collectionPattern: data && data.collectionPattern,
        collectionPatternLoading: loading,
        collectionPatternError: error,
        refetch
    };
};
