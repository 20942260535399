import * as classnames from "classnames";
import { Moment } from "moment";
import * as moment from "moment";
import * as React from "react";
import { FunctionComponent } from "react";

import { CalendarMonthStyle, CellStyle, CalendarHeader } from "./calendarMonthStyle";
import { useUnplannableDays } from "@components/patternOverviewTab/unplannableDaysProvider";
import { useEditCollectionPattern } from "@components/patternOverviewTab/patternEdittingProvider";
import { createSelectable } from "react-selectable-fast";

export interface CalendarMonthProps {
    month: Moment;
    validRange: [Moment, Moment];
}

export const CellComponent = (props: {
    isSelected: boolean;
    isSelecting: boolean;
    selectableRef: any;
    disabled: boolean;
    date: string;
    isActive: boolean;
    selected: boolean;
}): JSX.Element => {
    const { isSelected, disabled, isSelecting: selecting, date, isActive, selected } = props;

    return (
        <CellStyle {...props} className={classnames({ selected: isSelected || selected, disabled, selecting, isActive })} ref={props.selectableRef}>
            <div className="content">{moment(date).format("D")}</div>
        </CellStyle>
    );
};

export const Cell = createSelectable(CellComponent);

export const CalendarMonth: FunctionComponent<CalendarMonthProps> = ({ month, validRange }) => {
    const firstDay = month
        .clone()
        .startOf("M")
        .startOf("w");
    const { unplannableDays } = useUnplannableDays();
    const { isActive, isEditting, activeDates } = useEditCollectionPattern();

    const days = React.useMemo(() => {
        const dz: JSX.Element[] = [];

        for (let i = 0; i < 42; i += 1) {
            const date = firstDay.clone().add(i, "d");
            const disabled = !date.isSame(month, "M") || !date.isBetween(validRange[0], validRange[1], "d", "[]");

            let selected = false;

            if (unplannableDays && isEditting && !disabled) {
                selected = unplannableDays.some(day => date.isSame(day, "day"));
            }

            const isCurrentDayActive = isActive && activeDates && date.isBetween(activeDates.from, activeDates.to, "d");

            dz.push(<Cell key={i} selected={selected} disabled={disabled} date={date.toISOString()} isActive={isCurrentDayActive} />);
        }

        return dz;
    }, [month.toISOString(), validRange[0].toISOString(), validRange[1].toISOString()]);

    return (
        <CalendarMonthStyle>
            <h1>
                {month
                    .clone()
                    .startOf("month")
                    .format("MMMM YYYY")}
            </h1>

            <div className="days">
                <CalendarHeader>Ma</CalendarHeader>
                <CalendarHeader>Di</CalendarHeader>
                <CalendarHeader>Wo</CalendarHeader>
                <CalendarHeader>Do</CalendarHeader>
                <CalendarHeader>Vr</CalendarHeader>
                <CalendarHeader>Za</CalendarHeader>
                <CalendarHeader>Zo</CalendarHeader>
                {days}
            </div>
        </CalendarMonthStyle>
    );
};
