import * as React from "react";
import { FunctionComponent } from "react";
import { TruckReschedulerStyle, FreeTruckItem } from "./truckReschedulerStyle";
import { useGetFreeTrucks } from "@graphql/hocs/hooks/useGetFreeTrucks";
import { Select, Button, message } from "antd";
import { useFormatMessage } from "@utils/intlHook";
import { FormattedMessage } from "react-intl";
import { useMoveTruckSchedule } from "@graphql/hocs/hooks/useMoveTruckSchedule";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { parseError } from "@utils/parseError";
import { useHistory } from "react-router";
import * as moment from "moment";
import { DATE_FORMAT } from "@utils/consts";

export interface TruckReschedulerProps {
    scheduleId: string;
    truckId: string;
    date: string;
}

export const TruckRescheduler: FunctionComponent<TruckReschedulerProps> = ({ date, truckId, scheduleId }) => {
    const formatMessage = useFormatMessage();
    const history = useHistory();
    const [selectedTruckId, setSelectedTruckId] = React.useState<string>();

    const { freeTrucks } = useGetFreeTrucks({
        variables: {
            date
        }
    });

    const { moveTruckSchedule, isLoading } = useMoveTruckSchedule({
        refetchQueries: [{
            query: GraphQLDocuments.getTruckSchedule,
            variables: {
                date,
                truckId
            }
        },
        {
            query: GraphQLDocuments.getCollectionRounds,
            variables: {
                from: moment(date).startOf("week").format(DATE_FORMAT),
                to: moment(date).endOf("week").format(DATE_FORMAT)
            }
        }],
        awaitRefetchQueries: true
    });

    const handleSubmit = async (): Promise<void> => {
        if (selectedTruckId) {
            const { response, error } = await moveTruckSchedule({
                id: scheduleId,
                truckId: selectedTruckId
            });

            if (error) {
                message.error(parseError(error, formatMessage));
            }

            if (response && response.data) {
                history.push(`/planning/${response.data.moveTruckSchedule.truck.id}/${response.data.moveTruckSchedule.date}`);
            }
        }
    };

    return (
        <TruckReschedulerStyle>
            <Select value={selectedTruckId} onChange={setSelectedTruckId} placeholder={formatMessage({ id: "chooseATruck" })}>
                {freeTrucks.map(truck => <Select.Option value={truck.id} key={truck.id} ><FreeTruckItem>{truck.name} <em>- {truck.type.name}</em></FreeTruckItem></Select.Option>)}
            </Select>
            <Button loading={isLoading} onClick={handleSubmit} disabled={!selectedTruckId}>
                <FormattedMessage id="moveScheduleToTruck" />
            </Button>
        </TruckReschedulerStyle>
    );
};

