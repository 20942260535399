import styled from "styled-components";
import { WhitePage } from "@style/whitePage";
import { COLORS } from "@style/colors";
import { SpinnerStyle } from "@components/spinner/spinnerStyle";

export const InquiryDetailPageStyle = styled(WhitePage)`
    background: ${COLORS.background};
    flex: 1;

    ${SpinnerStyle} {
        height: 100%;
    }

    .container {
        display: flex;
        height: calc(100% - 80px);

        .main {
            flex: 3;

            .ant-alert {
                margin: 30px 30px 0;
            }

            & > ${SpinnerStyle} {
                height: 600px;
            }
        }
    }
`;
