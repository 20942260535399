import { COLORS } from "@style/colors";
import { InnerContainer } from "@style/innerContainer";
import styled from "styled-components";

export const HeaderStyle = styled.div`

    padding-top: 25px;
    background-color: white;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.10);

    ${InnerContainer} {
        display: flex;
    }

    nav {
        padding-top: 10px;
        a {
            color: ${COLORS.text};
            transition: 0.3s;
            padding-right: 25px;
            &.active, &:hover {
                em {
                opacity: 1;
                }
            }
            

            em {
                opacity: 0.3;
                font-style:normal;
            }

  
        }
    }

    .ant-breadcrumb {
        padding: 30px 0;

        & > span:last-child a, a:hover {
            opacity: 1;
            color: ${COLORS.text};
        }

        a {
            color: ${COLORS.text};
            transition: 0.3s;
            opacity: 0.3;
        }
    }


    aside {
        padding-top: 10px;
        margin-left: auto;

        a {
            margin-left: 15px;
            color: ${COLORS.text};
            opacity: 0.3;
            transition: 0.3s;

            &:hover {
                opacity: 1;
            }
        }

    }


    img {
        display: block;
        height: 50px;
        margin-right: 65px;
    }
`;
