import styled from "styled-components";

export const CollectionGroupTabStyle = styled.div`

    .editBtns {
        display: flex;
        justify-content: flex-end;
        margin-top: -15px;
        margin-bottom: 5px;

        .icon {
            cursor: pointer;
            padding: 5px;
            opacity: 0.5;
            transition: .2s;

            &:hover {
                opacity: 1;
            }
        }

    }
`;
