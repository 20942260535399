/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ApolloError } from "apollo-client";
import { useState } from "react";
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";

import { CreateIncidentMutation, CreateIncidentMutationVariables } from "@models/graphql";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";

const defaultCreateIncidentOptions: MutationHookOptions<CreateIncidentMutation, CreateIncidentMutationVariables> = {

};

export const useCreateIncident = (options?: MutationHookOptions<CreateIncidentMutation, CreateIncidentMutationVariables>) => {
    const [hookState, setHookState] = useState<{
        isLoading: boolean;
        data?: CreateIncidentMutation;
        error?: ApolloError;
    }>({
        isLoading: false
    });

    const [mutation] = useMutation<CreateIncidentMutation, CreateIncidentMutationVariables>(GraphQLDocuments.createIncident);

    const createIncident = async (variables: CreateIncidentMutationVariables) => {
        setHookState({
            isLoading: true
        });

        try {
            const response = await mutation({
                ...defaultCreateIncidentOptions,
                ...options,
                variables
            });

            setHookState({
                data: response.data,
                isLoading: false
            });

            return response;
        } catch (error) {
            setHookState({
                error,
                isLoading: false
            });
        }
    };

    return {
        isLoading: hookState.isLoading,
        error: hookState.error,
        data: hookState.data,
        createIncident
    };
};
