import * as React from "react";
import { FunctionComponent } from "react";
import { StepStyle } from "./stepStyle";
import * as classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { Icon } from "@style/icon";
import { useRouter } from "@utils/routerHook";

export interface StepProps {
    number: number;
    label: string;
    done?: boolean;
    link: string;
}

export const Step: FunctionComponent<StepProps> = ({ number, done, label, link }) => {
    const { history, location } = useRouter();

    const current = location.pathname + location.search === link;

    const handleClick = (): void => {
        history.push(link);
    };

    return (
        <StepStyle onClick={handleClick} className={classnames({ done, current })}>
            <div className="number">
                {done ? <Icon type="checkmark" /> : number}
            </div>
            <FormattedMessage id={label} />
        </StepStyle>
    );
};
