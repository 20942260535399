/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ErrorMessage } from "@components/errorMessage/errorMessage";
import { PERMISSIONS } from "@components/permission/permission";
import { usePermissions } from "@components/permission/permissionProvider";
import { CapacityTooltip } from "@components/planningWeekOverview/collectionRoundBlock/collectionRoundBlockStyle";
import { useGetFreeDeliveryDays } from "@graphql/hocs/hooks/useGetFreeDeliveryDays";
import { useGetFreePickupDays } from "@graphql/hocs/hooks/useGetFreePickupDays";
import { useGetMunicipalities } from "@graphql/hocs/hooks/useGetMunicipalities";
import { useUpdateInquiryCollectionDay } from "@graphql/hocs/hooks/useUpdateInquiryCollectionDay";
import {
    CollectionDay,
    CollectionDayProductInput,
    DeliveryMethod,
    InquiryDetailFragment,
    InquiryStatusType
} from "@models/graphql";
import { COLORS } from "@style/colors";
import { Icon } from "@style/icon";
import { ApolloUtils } from "@utils/apolloUtils";
import { useFormatMessage } from "@utils/intlHook";
import { Alert, Form, Modal, Progress, Radio, Select, Spin, Tooltip } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { OptionProps } from "antd/lib/select";
import * as moment from "moment";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";

import { ChangeCollectionRoundModalStyle } from "./changeCollectionRoundModalStyle";

export interface ChangeCollectionRoundModalProps extends FormComponentProps {
    inquiry?: InquiryDetailFragment;
    visible: boolean;
    closeCallback(): void;
}

export const ChangeCollectionRoundModalComponent: FunctionComponent<ChangeCollectionRoundModalProps> = ({
    inquiry,
    closeCallback,
    visible,
    form
}) => {
    const { hasPermissions } = usePermissions();

    const formatMessage = useFormatMessage();
    const pickupDayId = form.getFieldValue("pickupDayId");
    const { updateInquiryCollectionDay, error, isLoading } = useUpdateInquiryCollectionDay({
        refetchQueries: ["getFreePickupDays", "getFreeDeliveryDays", "getCollectionRounds"],
        awaitRefetchQueries: true,
        update(cache) {
            ApolloUtils.deleteTypenamesFromCache(cache, ["CollectionDayResult", "CollectionDay", "CollectionRound"]);
        }
    });

    const municipalityId = inquiry ? inquiry.pickupAddress.municipality!.id : "";

    const { municipalities, municipalitiesLoading } = useGetMunicipalities();
    const municipalityIds = form.getFieldValue("municipalityIds") || [municipalityId];

    const {
        freePickupDays,
        freePickupDaysCount,
        freePickupDaysLoading,
        freePickupDaysError,
        fetchMoreFreePickupDays
    } = useGetFreePickupDays({
        variables: {
            municipalityId: municipalityId,
            municipalityIds,
            products: inquiry
                ? inquiry!.products.map<CollectionDayProductInput>((prod) => ({ amount: prod.amount, productId: prod.product.id }))
                : [],
            paging: {
                limit: 5,
                offset: 0
            }
        },
        skip: !inquiry || !visible
    });

    const {
        freeDeliveryDays,
        freeDeliveryDaysCount,
        freeDeliveryDaysError,
        freeDeliveryDaysLoading,
        fetchMoreFreeDeliveryDays
    } = useGetFreeDeliveryDays({
        variables: {
            pickupDayId: pickupDayId,
            municipalityId: municipalityId,
            municipalityIds,
            products: inquiry
                ? inquiry!.products.map<CollectionDayProductInput>((prod) => ({ amount: prod.amount, productId: prod.product.id }))
                : [],
            paging: {
                limit: 5,
                offset: 0
            }
        },
        skip: !inquiry || !visible || !pickupDayId
    });

    const needsDelivery = React.useMemo(() => {
        if (!inquiry) {
            return false;
        }

        // If the inquiry has already been deliverd, no new delivery should be made
        if (inquiry.statusHistory.find((s) => s.type === InquiryStatusType.Delivered)) {
            return false;
        }

        return inquiry.products.some((prod) => prod.product.deliveryMethod === DeliveryMethod.Delivery);
    }, [inquiry]);

    const fetchMoreDeliveryDays = (): void => {
        fetchMoreFreeDeliveryDays(freeDeliveryDays.length);
    };

    const fetchMorePickupDays = (): void => {
        fetchMoreFreePickupDays(freePickupDays.length);
    };
    const handleClose = (): void => {
        form.resetFields();
        closeCallback();
    };

    const onSubmit = (): void => {
        if (!inquiry) {
            return;
        }

        form.validateFields(async (errors, values) => {
            if (errors) {
                return;
            }

            const { error: updateError } = await updateInquiryCollectionDay({
                deliveryDayId: values.deliveryDayId,
                inquiryId: inquiry.id,
                pickupDayId: values.pickupDayId
            });

            if (!updateError) {
                handleClose();
            }
        });
    };

    const filterOptions = (searchVal: string, el: React.ReactElement<OptionProps>): boolean =>
        (el.props.children as string).toLowerCase().includes(searchVal.toLowerCase());

    const renderDayItem = (day: CollectionDay): JSX.Element | null => {
        if (
            day.cargo === null ||
            day.cargo === undefined ||
            day.maxCargo === null ||
            day.maxCargo === undefined ||
            day.addresses === null ||
            day.addresses === undefined ||
            day.maxAddresses === null ||
            day.maxAddresses === undefined
        ) {
            return null;
        }

        let progress = 0;

        if (day.cargo / day.maxCargo > day.addresses / day.maxAddresses) {
            progress = (day.cargo / day.maxCargo) * 100;
        } else {
            progress = (day.addresses / day.maxAddresses) * 100;
        }

        return (
            <Radio key={day.date} value={day.id} style={{ width: "100%", marginRight: "1em", marginBottom: 0 }}>
                <em>
                    {moment(day.date).format("dddd DD MMM YYYY")} — <FormattedMessage id={`daypart.${day.dayPart}`} /> — {day.group?.name}
                </em>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Progress
                        strokeColor={progress < 100 ? COLORS.primaryTransparant(0.8) : progress > 100 ? COLORS.error : COLORS.orange}
                        percent={progress}
                        showInfo={false}
                        strokeWidth={4}
                        style={{ marginRight: 10, flex: 1 }}
                    />
                    <Tooltip
                        placement="leftTop"
                        overlayClassName="capacity-tooltip"
                        title={
                            <CapacityTooltip>
                                <div className="item">
                                    {day.cargo} / {day.maxCargo} {formatMessage({ id: "effectiveCargos" })}
                                </div>
                                <div className="item">
                                    {day.addresses} / {day.maxAddresses} {formatMessage({ id: "adresses" })}
                                    <div className="sub">{day.cargoPickup} {formatMessage({ id: "pickups" })}</div>
                                    <div className="sub">{day.cargoDelivery} {formatMessage({ id: "deliveries" })}</div>
                                </div>
                            </CapacityTooltip>
                        }
                    >
                        <Icon type="info" />
                    </Tooltip>
                </div>
            </Radio>
        );
    };

    return (
        <Modal
            title={formatMessage({ id: "changeCollectionRoundModal.title" })}
            onCancel={handleClose}
            onOk={onSubmit}
            visible={visible}
            cancelText={formatMessage({ id: "cancel" })}
            okText={formatMessage({ id: "save" })}
            okButtonProps={{
                loading: isLoading
            }}
        >
            <ChangeCollectionRoundModalStyle>
                {hasPermissions([PERMISSIONS.planning.changeGroup]) && (
                    <Form.Item label={<FormattedMessage id="collectionGroups.municipalities" />}>
                        {form.getFieldDecorator("municipalityIds", {
                            initialValue: [municipalityId],
                            rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }]
                        })(
                            <Select
                                loading={municipalitiesLoading}
                                filterOption={filterOptions}
                                mode="multiple"
                                placeholder={formatMessage({ id: "patternDetail.groups" })}
                                style={{ width: "100%" }}
                            >
                                {municipalities.map((item) => (
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                )}

                <Form.Item required={false} label={<FormattedMessage id="changeCollectionRoundModal.nextFreePickup" />}>
                    <Spin spinning={freePickupDaysLoading}>
                        <ErrorMessage error={freePickupDaysError} />
                        {form.getFieldDecorator("pickupDayId", {
                            rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }]
                        })(<Radio.Group>{freePickupDays.map(renderDayItem)}</Radio.Group>)}
                        {!freePickupDays.length && !freePickupDaysLoading && (
                            <Alert type="info" message={formatMessage({ id: "noFreePickupDays" })} />
                        )}
                        {freePickupDays.length < freePickupDaysCount && (
                            <span onClick={fetchMorePickupDays}>
                                <FormattedMessage tagName="a" id="loadMore" />
                            </span>
                        )}
                    </Spin>
                </Form.Item>

                {needsDelivery && (
                    <Form.Item required={false} label={<FormattedMessage id="changeCollectionRoundModal.nextFreeDelivery" />}>
                        <Spin spinning={freeDeliveryDaysLoading}>
                            <ErrorMessage error={freeDeliveryDaysError} />
                            {!pickupDayId && <Alert type="info" message={formatMessage({ id: "selectDeliveryDayFirst" })} />}
                            {form.getFieldDecorator("deliveryDayId", {
                                rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }]
                            })(<Radio.Group>{freeDeliveryDays.map(renderDayItem)}</Radio.Group>)}
                            {!freeDeliveryDays.length && pickupDayId && !freeDeliveryDaysLoading && (
                                <Alert type="info" message={formatMessage({ id: "noFreeDeliveryDays" })} />
                            )}
                            {freeDeliveryDays.length < freeDeliveryDaysCount && (
                                <span onClick={fetchMoreDeliveryDays}>
                                    <FormattedMessage tagName="a" id="loadMore" />
                                </span>
                            )}
                        </Spin>
                    </Form.Item>
                )}

                <ErrorMessage error={error} />
            </ChangeCollectionRoundModalStyle>
        </Modal>
    );
};

export const ChangeCollectionRoundModal = Form.create<ChangeCollectionRoundModalProps>()(ChangeCollectionRoundModalComponent);
