import { Error } from "@components/error/error";
import { PERMISSIONS } from "@components/permission/permission";
import { usePermissions } from "@components/permission/permissionProvider";
import { GetInquiryDetailQuery } from "@models/graphql";
import { Placeholder } from "@style/placeholder";
import { useFormatMessage } from "@utils/intlHook";
import { parseAddressToString } from "@utils/parseAddressToString";
import { parseError } from "@utils/parseError";
import { CargoPerProductPartial, getRemainingProductsFromInquiry } from "@utils/productUtils";
import { useUpdateQuery } from "@utils/routerHook";
import { Avatar, Button, Modal } from "antd";
import { ApolloError } from "apollo-client";
import * as moment from "moment";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";

import { InquiryInformationSidebarStyle } from "./inquiryInformationSidebarStyle";

export interface InquiryInformationSidebarProps {
    inquiry: GetInquiryDetailQuery["inquiry"] | undefined;
    inquiryError: ApolloError | undefined;
    loading: boolean;
}

interface SidebarBlockProps {
    title?: string;
    titleComponent?: React.ReactNode;
}

export const InquiryInformationSidebar: FunctionComponent<InquiryInformationSidebarProps> = ({ inquiry, loading, inquiryError }) => {
    const formatMessage = useFormatMessage();
    const permissions = usePermissions();
    const updateQuery = useUpdateQuery({ replace: true });

    const [remainingProducts, setRemainingProducts] = React.useState<CargoPerProductPartial[]>([]);

    React.useEffect(() => {
        if(inquiry) {
            setRemainingProducts(getRemainingProductsFromInquiry(inquiry));
        }
    }, [inquiry]);

    const SidebarBlock: FunctionComponent<SidebarBlockProps> = (sidebarProps) => (
        <section>
            <h1 className="sectionHeader">
                {sidebarProps.title && <FormattedMessage id={sidebarProps.title} />}
                {sidebarProps.titleComponent}
            </h1>
            <div className="sidebarBlockBody">{sidebarProps.children}</div>
        </section>
    );
    if (loading) {
        return (
            <InquiryInformationSidebarStyle>
                <SidebarBlock title="inquiryDetail.info">
                    <Placeholder />
                </SidebarBlock>
                <SidebarBlock title="inquiryDetail.applicant">
                    <div className="iconBlock">
                        <Avatar icon="tag" /> <Placeholder />
                    </div>
                    <div className="iconBlock">
                        <Avatar icon="user" /> <Placeholder />
                    </div>
                    <div className="iconBlock">
                        <Avatar icon="mobile" /> <Placeholder />
                    </div>
                    <div className="iconBlock">
                        <Avatar icon="mail" /> <Placeholder />
                    </div>
                    <div className="iconBlock">
                        <Avatar icon="home" /> <Placeholder />
                    </div>
                </SidebarBlock>

                <SidebarBlock title="inquiryDetail.pickupAddress">
                    <Placeholder />
                </SidebarBlock>

                <SidebarBlock title="inquiryDetail.products">
                    <Placeholder />
                    <Placeholder />
                    <Placeholder />
                </SidebarBlock>

                <SidebarBlock title="inquiryDetail.checklist">
                    <Placeholder />
                    <Placeholder />
                    <Placeholder />
                </SidebarBlock>
            </InquiryInformationSidebarStyle>
        );
    }

    if (!inquiry) {
        return <Error originalError={inquiryError} errorMessage={parseError(inquiryError, formatMessage)} />;
    }

    return (
        <InquiryInformationSidebarStyle>
            <SidebarBlock title="inquiryDetail.info">
                {inquiry.deliveryDate && (
                    <div className="date">
                        <em>
                            <FormattedMessage id="inquiryDetail.deliveryDate" />:
                        </em>
                        {moment(inquiry.deliveryDate).format("ddd DD MMM YYYY")}
                    </div>
                )}
                {inquiry.pickupDate && (
                    <div className="date">
                        <em>
                            <FormattedMessage id="inquiryDetail.pickupDate" />:
                        </em>
                        {moment(inquiry.pickupDate).format("ddd DD MMM YYYY")}
                    </div>
                )}

                <div className="date">
                    <em>
                        <FormattedMessage id="inquiryDetail.manual" />:
                    </em>
                    {inquiry.paymentNumber ? <FormattedMessage tagName="small" id="no" /> : <FormattedMessage id="yes" />}
                </div>

                <div className="date">
                    <em>
                        <FormattedMessage id="inquiryDetail.payment" />:
                    </em>
                    {inquiry.cardsPayment ? <FormattedMessage tagName="small" id="yes" /> : <FormattedMessage id="no" />}
                </div>
            </SidebarBlock>
            <SidebarBlock title="inquiryDetail.applicant">
                <div className="iconBlock">
                    <Avatar icon="tag" /> <span>{inquiry.applicant.id}</span>
                </div>
                <div className="iconBlock">
                    <Avatar icon="user" /> <span>{inquiry.applicant.name}</span>
                </div>
                <div className="iconBlock">
                    <Avatar icon="mobile" />{" "}
                    <span>
                        {inquiry.applicant.phone ? inquiry.applicant.phone : <FormattedMessage tagName="strong" id="notAvailable" />}
                    </span>
                </div>
                <div className="iconBlock">
                    <Avatar icon="mail" />{" "}
                    <span>
                        {inquiry.applicant.email ? inquiry.applicant.email : <FormattedMessage tagName="strong" id="notAvailable" />}
                    </span>
                </div>
                <div className="iconBlock">
                    <Avatar icon="home" /> <span>{parseAddressToString(inquiry.applicant.address)}</span>
                </div>
            </SidebarBlock>

            <SidebarBlock
                title="inquiryDetail.pickupAddress"
                titleComponent={
                    permissions.hasPermissions([PERMISSIONS.inquiries.updatePickupAddress]) && (
                        <Button shape="circle" icon="edit" onClick={() => updateQuery({ updateInquiry: true })} />
                    )
                }
            >
                <p>{parseAddressToString(inquiry.pickupAddress.address)}</p>
                {inquiry.checklist && inquiry.checklist.deliveryLocationDescription && (
                    <p className="extraInfo">{inquiry.checklist.deliveryLocationDescription}</p>
                )}
                {inquiry.media && (
                    <div className="images">
                        {inquiry.media.map((item) => (
                            <img
                                alt="image"
                                onClick={() =>
                                    Modal.info({
                                        okText: formatMessage({ id: "close" }),
                                        width: 900,
                                        icon: null,
                                        content: <img style={{ width: "100%" }} alt="image" src={item.url} />
                                    })
                                }
                                src={item.url}
                                key={item.id}
                            ></img>
                        ))}
                    </div>
                )}
            </SidebarBlock>

            <SidebarBlock title="inquiryDetail.products">
                <strong>
                    <FormattedMessage id="inquiryDetail.originalProducts" />:
                </strong>
                {inquiry.products.map((product, i) => (
                    <p key={i}>
                        {product.product.name}
                        <span className="right">x {product.amount}</span>
                    </p>
                ))}
                {remainingProducts.length > 0 && (
                    <>
                        <strong>
                            <FormattedMessage id="inquiryDetail.remainingProducts" />:
                        </strong>
                        {remainingProducts.map((product, i) => (
                            <p key={i}>
                                {product.product.name}
                                <span className="right">x {product.effectiveCargo}</span>
                            </p>
                        ))}
                    </>
                )}
            </SidebarBlock>

            <SidebarBlock title="inquiryDetail.checklist">
                <p>
                    <FormattedMessage id="createInquiryPage.publicProperty" />
                    <span className="right">
                        <FormattedMessage id={inquiry.checklist.publicDomain ? "yes" : "no"} />
                    </span>
                </p>
            </SidebarBlock>
        </InquiryInformationSidebarStyle>
    );
};
