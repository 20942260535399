import { createContext, FunctionComponent, useContext, useState } from "react";
import * as React from "react";
import * as uuid from "uuid";

interface CollectionRoundContext {
    collectionRounds: string[][];
    addCollectionRound(dayIndex: number): void;
    removeCollectionRound(dayIndex: number, roundId: string): void;
    setCollectionRounds(val: string[][]): void;
}

const CollectionRoundContext = createContext<CollectionRoundContext>({} as any);

export const CollectionRoundProvider: FunctionComponent = ({ children }) => {
    const [collectionRounds, setCollectionRounds] = useState<string[][]>([]);
    const addCollectionRound = (dayIndex: number): void => {
        setCollectionRounds(state => {
            const newState = [...state];

            if (!newState[dayIndex]) {
                newState[dayIndex] = [uuid()];
            } else {
                newState[dayIndex] = [...newState[dayIndex], uuid()];
            }

            return newState;
        });
    };

    const removeCollectionRound = (dayIndex: number, roundId: string): void => {
        setCollectionRounds(state => {
            const newState = [...state];

            if (newState[dayIndex]) {
                newState[dayIndex] = newState[dayIndex].filter(r => r !== roundId);
            }

            return newState;
        });
    };

    const context: CollectionRoundContext = {
        collectionRounds,
        addCollectionRound,
        removeCollectionRound,
        setCollectionRounds
    };

    return <CollectionRoundContext.Provider value={context}>{children}</CollectionRoundContext.Provider>;
};

export const useFormCollectionRound = (): CollectionRoundContext => useContext(CollectionRoundContext);
