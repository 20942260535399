/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { CreateProductMutation, CreateProductMutationVariables } from "@models/graphql";

const defaultCreateProductOptions: MutationHookOptions<CreateProductMutation, CreateProductMutationVariables> = {};

export const useCreateProduct = (options?: MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) => {
    const [mutation, { data, loading, error }] = useMutation<CreateProductMutation, CreateProductMutationVariables>(
        GraphQLDocuments.createProduct
    );

    const createProduct = async (variables: CreateProductMutationVariables) => {
        try {
            const response = await mutation({
                ...defaultCreateProductOptions,
                ...options,
                variables
            });

            return {
                data: response.data,
                error: response.errors
            };
        } catch (err) {
            return {
                error: err
            };
        }
    };

    return {
        isLoading: loading,
        error,
        data,
        createProduct
    };
};
