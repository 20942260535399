/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { DeleteNotificationConfigurationMutation, DeleteNotificationConfigurationMutationVariables } from "@models/graphql";

const defaultDeleteNotificationConfigurationOptions: MutationHookOptions<
    DeleteNotificationConfigurationMutation,
    DeleteNotificationConfigurationMutationVariables
> = {};

export const useDeleteNotificationConfiguration = (
    options?: MutationHookOptions<DeleteNotificationConfigurationMutation, DeleteNotificationConfigurationMutationVariables>
) => {
    const [mutation, { data, loading, error }] = useMutation<
        DeleteNotificationConfigurationMutation,
        DeleteNotificationConfigurationMutationVariables
    >(GraphQLDocuments.deleteNotificationConfiguration);

    const deleteNotificationConfiguration = async (
        variables: DeleteNotificationConfigurationMutationVariables,
        opts?: MutationHookOptions<DeleteNotificationConfigurationMutation, DeleteNotificationConfigurationMutationVariables>
    ) => {
        try {
            const response = await mutation({
                ...defaultDeleteNotificationConfigurationOptions,
                ...options,
                ...opts,
                variables
            });

            return {
                error: response.errors,
                data: response.data
            };
        } catch (err) {
            return {
                error: err
            };
        }
    };

    return {
        isLoading: loading,
        error,
        data,
        deleteNotificationConfiguration
    };
};
