import styled from "styled-components";
import { COLORS } from "@style/colors";

interface VariableStyleProps {
    invalid: boolean;
}

export const VariableStyle = styled.span`
    color: ${(props: VariableStyleProps) => props.invalid ? "white" : COLORS.primary};
    background: ${(props: VariableStyleProps) => props.invalid ? COLORS.error : COLORS.lightGrey};
    padding: 3px;
    border-radius: 2px;

    
`;
