import "@assets/icons/app.font";
import { Error } from "@components/error/error";
import { Layout } from "@components/layout/layout";
import { PERMISSIONS } from "@components/permission/permission";
import { usePermissions } from "@components/permission/permissionProvider";
import { PlanningProvider } from "@components/planningProvider/planningProvider";
import { PrivateRoute } from "@components/privateRoute/privateRoute";
import { AssetsPage } from "@pages/assetsPage/assetsPage";
import { AssetTypePage } from "@pages/assetTypePage/assetTypePage";
import { CollectionCalendarPage } from "@pages/collectionCalendarPage/collectionCalendarPage";
import { CollectorsPage } from "@pages/collectorsPage/collectorsPage";
import { CreateInquiryPage } from "@pages/createInquiryPage/createInquiryPage";
import { IncidentOverviewPage } from "@pages/incidentOverviewPage/incidentOverviewPage";
import { IncidentReasonPage } from "@pages/incidentReasonPage/incidentReasonPage";
import { IncidentReportsPage } from "@pages/incidentReportsPage/incidentReportsPage";
import { InquiriesReportsPage } from "@pages/inquiriesReportsPage/inquiriesReportsPage";
import { InquiryDetailPage } from "@pages/inquiryDetailPage/inquiryDetailPage";
import { InquiryOverviewPage } from "@pages/inquiryOverviewPage/inquiryOverviewPage";
import { LoadingPage } from "@pages/loadingPage/loadingPage";
import { LogisticReportsPage } from "@pages/logisticReportsPage/logisticReportsPage";
import { ManagementPage } from "@pages/managementPage/managementPage";
import { NotificationsPage } from "@pages/notificationsPage/notificationsPage";
import { PatternDetailPage } from "@pages/patternDetailPage/patternDetailPage";
import { PlanningPage } from "@pages/planningPage/planningPage";
import { ProcessorsPage } from "@pages/processorsPage/processorsPage";
import { ProductsPage } from "@pages/productsPage/productsPage";
import { ReportsPage } from "@pages/reportsPage/reportsPage";
import { TruckSchedulePage } from "@pages/truckSchedulePage/truckSchedulePage";
import { TrucksPage } from "@pages/trucksPage/trucksPage";
import { UploadWeightDataPage } from "@pages/uploadWeightDataPage/uploadWeightDataPage";
import { GlobalStyle } from "@style/global";
import * as moment from "moment";
import * as React from "react";
import { hot } from "react-hot-loader";
import { Redirect, Switch } from "react-router";

moment.locale("nl");
// Spin.setDefaultIndicator(<Icon type="loading" />);

export interface AppProps {}

const AppComponent: React.FunctionComponent<AppProps> = () => {
    const { permissionsLoaded, permissionsError } = usePermissions();

    if (!permissionsLoaded) {
        return <LoadingPage />;
    }

    if (permissionsError) {
        return (
            <div style={{ marginTop: 100 }}>
                <Error originalError={permissionsError} errorMessage="Oeps, er is een probleem met het ophalen van je rechten." />
            </div>
        );
    }

    return (
        <Layout>
            <GlobalStyle />
            <Switch>
                <Redirect from="/" to="/inquiries" exact />
                <PrivateRoute requiredPermissions={[PERMISSIONS.inquiries.read]} exact path="/inquiries" component={InquiryOverviewPage} />
                <PrivateRoute requiredPermissions={[PERMISSIONS.inquiries.read]} exact path="/incidents" component={IncidentOverviewPage} />
                <PrivateRoute
                    requiredPermissions={[PERMISSIONS.inquiries.create]}
                    exact
                    path="/inquiries/create"
                    component={CreateInquiryPage}
                />
                <PrivateRoute
                    requiredPermissions={[PERMISSIONS.inquiries.read]}
                    exact
                    path="/inquiries/:inquiryId"
                    component={InquiryDetailPage}
                />
                <PrivateRoute requiredPermissions={[PERMISSIONS.assets.read]} exact path="/assets" component={AssetsPage} />
                <PrivateRoute requiredPermissions={[PERMISSIONS.frontend.management]} exact path="/management" component={ManagementPage} />
                <PrivateRoute
                    requiredPermissions={[PERMISSIONS.incidentReasons.read]}
                    exact
                    path="/management/incidents"
                    component={IncidentReasonPage}
                />
                <PrivateRoute
                    requiredPermissions={[PERMISSIONS.collectionPatterns.read]}
                    exact
                    path="/management/calendar"
                    component={CollectionCalendarPage}
                />
                <PrivateRoute
                    requiredPermissions={[PERMISSIONS.products.read]}
                    exact
                    path="/management/products"
                    component={ProductsPage}
                />
                <PrivateRoute requiredPermissions={[PERMISSIONS.trucks.read]} exact path="/management/trucks" component={TrucksPage} />
                <PrivateRoute
                    requiredPermissions={[PERMISSIONS.processors.read]}
                    exact
                    path="/management/processors"
                    component={ProcessorsPage}
                />
                <PrivateRoute
                    requiredPermissions={[PERMISSIONS.assetTypes.read]}
                    exact
                    path="/management/inventory"
                    component={AssetTypePage}
                />
                <PrivateRoute
                    requiredPermissions={[PERMISSIONS.collectors.read]}
                    exact
                    path="/management/collectors"
                    component={CollectorsPage}
                />
                <PrivateRoute
                    requiredPermissions={[PERMISSIONS.reports.csvUpload]}
                    exact
                    path="/management/weight-data"
                    component={UploadWeightDataPage}
                />
                <PrivateRoute
                    requiredPermissions={[PERMISSIONS.notificationConfigurations.read]}
                    exact
                    path="/management/notifications"
                    component={NotificationsPage}
                />
                <PrivateRoute
                    requiredPermissions={[PERMISSIONS.frontend.planning]}
                    path="/management/calendar/pattern/:page?"
                    component={PatternDetailPage}
                />
                <PrivateRoute requiredPermissions={[PERMISSIONS.reports.read]} exact path="/reports" component={ReportsPage} />
                <PrivateRoute
                    requiredPermissions={[PERMISSIONS.reports.read]}
                    exact
                    path="/reports/incidents"
                    component={IncidentReportsPage}
                />
                <PrivateRoute
                    requiredPermissions={[PERMISSIONS.reports.read]}
                    exact
                    path="/reports/inquiries"
                    component={InquiriesReportsPage}
                />
                <PrivateRoute
                    requiredPermissions={[PERMISSIONS.reports.read]}
                    exact
                    path="/reports/logistics"
                    component={LogisticReportsPage}
                />
                <PrivateRoute
                    requiredPermissions={[PERMISSIONS.inquiries.read, PERMISSIONS.collectionRounds.read, PERMISSIONS.trucks.read]}
                    path="/planning/:truckId/:date"
                    component={TruckSchedulePage}
                />
                <PlanningProvider>
                    <PrivateRoute requiredPermissions={[PERMISSIONS.frontend.planning]} exact path="/planning" component={PlanningPage} />
                </PlanningProvider>
            </Switch>
        </Layout>
    );
};

export const App = hot(module)(AppComponent);
