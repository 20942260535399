import styled from "styled-components";

export const PlanningSideBarStyle = styled.div`
    width: 375px;
    background-color: #fff;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.15);
    display: flex;
    flex-direction: column;
    z-index: 1;
    height: calc(100vh - 145px);
    flex-shrink: 0;
`;
