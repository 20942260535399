/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { DeleteCollectorMutation, DeleteCollectorMutationVariables } from "@models/graphql";

const defaultDeleteCollectorOptions: MutationHookOptions<DeleteCollectorMutation, DeleteCollectorMutationVariables> = {};

export const useDeleteCollector = (options?: MutationHookOptions<DeleteCollectorMutation, DeleteCollectorMutationVariables>) => {
    const [mutation, { data, loading, error }] = useMutation<DeleteCollectorMutation, DeleteCollectorMutationVariables>(
        GraphQLDocuments.deleteCollector
    );

    const deleteCollector = async (
        variables: DeleteCollectorMutationVariables,
        opts?: MutationHookOptions<DeleteCollectorMutation, DeleteCollectorMutationVariables>
    ) => {
        try {
            const response = await mutation({
                ...defaultDeleteCollectorOptions,
                ...options,
                ...opts,
                variables
            });

            return {
                error: response.errors,
                data: response.data
            };
        } catch (err) {
            return {
                error: err
            };
        }
    };

    return {
        isLoading: loading,
        error,
        data,
        deleteCollector
    };
};
