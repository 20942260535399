/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { DeleteIncidentReasonMutation, DeleteIncidentReasonMutationVariables } from "@models/graphql";

const defaultDeleteIncidentReasonOptions: MutationHookOptions<DeleteIncidentReasonMutation, DeleteIncidentReasonMutationVariables> = {};

export const useDeleteIncidentReason = (
    options?: MutationHookOptions<DeleteIncidentReasonMutation, DeleteIncidentReasonMutationVariables>
) => {
    const [mutation, { data, loading, error }] = useMutation<DeleteIncidentReasonMutation, DeleteIncidentReasonMutationVariables>(
        GraphQLDocuments.deleteIncidentReason
    );

    const deleteIncidentReason = async (
        variables: DeleteIncidentReasonMutationVariables,
        opts?: MutationHookOptions<DeleteIncidentReasonMutation, DeleteIncidentReasonMutationVariables>
    ) => {
        const response = await mutation({
            ...defaultDeleteIncidentReasonOptions,
            ...options,
            ...opts,
            variables
        });

        return response;
    };

    return {
        isLoading: loading,
        error,
        data,
        deleteIncidentReason
    };
};
