import { MutationFetchResult } from "@apollo/react-common";
import { ErrorMessage } from "@components/errorMessage/errorMessage";
import { PageHeader } from "@components/pageHeader/pageHeader";
import { Permission, PERMISSIONS } from "@components/permission/permission";
import { ProcessorModal } from "@components/processorModal/processorModal";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { useDeleteProcessor } from "@graphql/hocs/hooks/useDeleteProcessor";
import { useGetProcessors } from "@graphql/hocs/hooks/useGetProcessors";
import { DeleteProcessorMutation, GetProcessorsQuery, ProcessorFragment } from "@models/graphql";
import { Icon } from "@style/icon";
import { InnerContainer } from "@style/innerContainer";
import { useFormatMessage } from "@utils/intlHook";
import { parseAddressToString } from "@utils/parseAddressToString";
import { parseError } from "@utils/parseError";
import { Button, Icon as AntIcon, message, Popconfirm, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { ProcessorsPageStyle } from "./processorsPageStyle";

export interface ProcessorsPageProps {}

export const ProcessorsPage: FunctionComponent<ProcessorsPageProps> = ({}) => {
    const [deleteId, setDeleteId] = React.useState<string | null>(null);
    const formatMessage = useFormatMessage();
    const [modalVisible, setModalVisible] = React.useState(false);
    const [editItem, setEditItem] = React.useState<ProcessorFragment | null>(null);
    const {
        processors,
        processorsError,
        processorsLoading
    } = useGetProcessors();

    const { deleteProcessor, isLoading: deleteLoading } = useDeleteProcessor();

    const columns: ColumnProps<ProcessorFragment>[] = [
        {
            title: <FormattedMessage id="processorPage.name" />,
            dataIndex: "name",
            key: "name"
        },
        {
            title: <FormattedMessage id="processorPage.address" />,
            key: "address",
            render(_, r) {
                return parseAddressToString(r.address.address);
            }
        },
        {
            title: <FormattedMessage id="processorPage.products" />,
            dataIndex: "products",
            render(_, r) {
                return r.products.map(p => p.name).join(", ");
            }
        },
        {
            width: 80,
            render(_, record) {
                const handleDelete = async (): Promise<void> => {
                    try {
                        setDeleteId(record.id);
                        const { error } = await deleteProcessor(
                            { id: record.id },
                            {
                                update(
                                    cache,
                                    response: MutationFetchResult<
                                        DeleteProcessorMutation
                                    >
                                ) {
                                    const processorData = cache.readQuery<
                                        GetProcessorsQuery
                                    >({
                                        query: GraphQLDocuments.getProcessors
                                    });

                                    if (
                                        processorData &&
                                        response.data &&
                                        response.data.deleteProcessor
                                    ) {
                                        processorData.processors.items = processorData.processors.items.filter(p => p.id !== record.id);
                                        processorData.processors.count -= 1;

                                        cache.writeQuery<GetProcessorsQuery>({
                                            query:
                                                GraphQLDocuments.getProcessors,
                                            data: processorData
                                        });
                                    }
                                }
                            }
                        );

                        if (error) {
                            message.error(parseError(error, formatMessage));
                        } else {
                            message.success(formatMessage({
                                id: "processorTable.deleteSuccessful"
                            }));
                        }
                        setDeleteId(null);
                    } catch (error) {
                        message.error(parseError(error, formatMessage));
                    }
                };

                return (
                    <span className="buttons">
                        <Permission requiredPermissions={[PERMISSIONS.processors.update]}>
                            <Icon
                                onClick={() => {
                                    setEditItem(record);
                                    setModalVisible(true);
                                }}
                                type="pencil"
                            />
                        </Permission>

                        <Permission requiredPermissions={[PERMISSIONS.processors.delete]}>
                            {record.id === deleteId && deleteLoading ? 
                                <AntIcon type="loading" />
                                : 
                                <Popconfirm
                                    onConfirm={handleDelete}
                                    placement="left"
                                    title={formatMessage({
                                        id: "processorPage.confirmDelete"
                                    })}
                                >
                                    <Icon type="trash"></Icon>
                                </Popconfirm>
                            }
                        </Permission>
                    </span>
                );
            }
        }
    ];

    const handleCloseModal = (): void => {
        setModalVisible(false);
        setEditItem(null);
    };

    const handleCreateProcessor = (): void => {
        setEditItem(null);
        setModalVisible(true);
    };

    return (
        <ProcessorsPageStyle>
            <PageHeader title="processorPage.title">
                <Permission requiredPermissions={[PERMISSIONS.processors.create]}>
                    <Button onClick={handleCreateProcessor} type="primary">
                        <FormattedMessage id="processorPage.newProcessor" />
                    </Button>
                </Permission>
            </PageHeader>
            <ProcessorModal
                editItem={editItem}
                visible={modalVisible}
                closeCallback={handleCloseModal}
            />
            <InnerContainer>
                <ErrorMessage error={processorsError} />
                <Table
                    loading={processorsLoading}
                    dataSource={processors}
                    columns={columns}
                    rowKey="id"
                />
            </InnerContainer>
        </ProcessorsPageStyle>
    );
};
