import styled from "styled-components";

export const UpdateInquiryPickupAddressModalStyle = styled.div`
    .ant-radio-group {
        display: block;
    }

    .ant-alert {
        margin-top: 15px;
    }

    .ant-radio-wrapper {
        margin-bottom: 15px;
    }
`;
