import styled from "styled-components";
import { COLORS } from "@style/colors";
import { DraftStyleMap } from "draft-js";

export const ColorPickerStyle = styled.div`
    span {
        display: block;
        width: 15px;
        height: 15px;
        background: red;
        border-radius: 4px;
        cursor: pointer;
    }
`;

export const COLOR_MAP: DraftStyleMap = {
    primary: {
        color: COLORS.primary
    },
    secondary: {
        color: COLORS.secondary
    },
    error: {
        color: COLORS.error
    },
    warning: {
        color: COLORS.orange
    },
    grey: {
        color: COLORS.grey
    }
};

export const PopupContent = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    span {
        display: block;
        cursor: pointer;
        width: 15px;
        height: 15px;
        border-radius: 4px;
        border: 1px solid transparent;



        &.primary {
            background-color: ${COLOR_MAP.primary.color};
        }

        &.error {
            background-color: ${COLOR_MAP.error.color};
        }

        &.secondary {
            background-color: ${COLOR_MAP.secondary.color};
        }

        &.warning {
            background-color: ${COLOR_MAP.warning.color};
        }

        &.grey {
            background-color: ${COLOR_MAP.grey.color};
        }

        &.active {
            box-shadow: 0 0 2px 0 rgba(0,0,0,0.40);
            border-color: rgba(0,0,0,0.3);
        }

    }
`;
