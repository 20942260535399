/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { GetCollectionPatternsQuery, GetCollectionPatternsQueryVariables } from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

export const useGetCollectionPatterns = (options?: QueryHookOptions<GetCollectionPatternsQuery, GetCollectionPatternsQueryVariables>) => {
    const { data, error, loading, refetch } = useQuery<GetCollectionPatternsQuery, GetCollectionPatternsQueryVariables>(
        GraphQLDocuments.getCollectionPatterns,
        options
    );

    return {
        collectionPatterns: data && data.collectionPatterns ? data.collectionPatterns.items : [],
        collectionPatternsLoading: loading,
        collectionPatternsError: error,
        refetch
    };
};
