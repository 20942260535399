import styled from "styled-components";
import { COLORS } from "@style/colors";

export const CalendarMonthStyle = styled.div`
    h1 {
        text-transform: capitalize;
        margin-bottom: 25px;
    }

    .days {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-gap: 10px;
    }
`;

export const CalendarHeader = styled.div`
    text-align: center;
    opacity: 0.4;
    margin-bottom: 10px;
`;

export const CellStyle = styled.div`
    padding-top: 100%;
    background-color: ${COLORS.lightGrey};
    color: ${COLORS.text};
    border-radius: 4px;
    transition: 0.2s;
    border: 2px solid transparent;
    position: relative;

    .content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &:not(.selecting):not(.disabled):not(.isActive):hover {
        cursor: pointer;
        background-color: ${COLORS.secondaryTransparant(0.5)};
        color: white;

        &.selected {
            border-color: ${COLORS.secondary};
        }
    }

    &.selecting {
        border-color: ${COLORS.secondary};
    }

    &:not(.disabled).selected {
        background-color: ${COLORS.secondary};
        color: white;
    }

    &.disabled {
        opacity: 0.2;
        cursor: initial;
    }
`;
