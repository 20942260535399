import { GoogleMap, Polyline } from "@react-google-maps/api";
import * as React from "react";
import { FunctionComponent } from "react";

import { COLORS } from "@style/colors";
import { TruckTaskFragment } from "@models/graphql";
import { MapTask } from "@components/tasksMapView/mapTask/mapTask";
import { MAP_STYLING } from "@components/tasksMapView/mapStyling";

import { TasksMapViewStyle } from "./tasksMapViewStyle";

export interface TasksMapViewProps {
    truckScheduleTasks: TruckTaskFragment[];
    activeTaskId: string | null;
    isUnsaved: boolean;
}

export const TasksMapView: FunctionComponent<TasksMapViewProps> = ({ truckScheduleTasks, isUnsaved, activeTaskId }) => {
    const [map, setMap] = React.useState<google.maps.Map | null>(null);
    const [boundsDefined, setBoundsDefined] = React.useState(false);

    React.useEffect(() => {
        if (map) {
            const bounds = new google.maps.LatLngBounds();

            truckScheduleTasks.forEach(truckTask => {
                if (truckTask.task) {
                    return bounds.extend(truckTask.task.inquiry.pickupAddress.coordinates);
                }

                if (truckTask.processor) {
                    return bounds.extend(truckTask.processor.address.coordinates);
                }

                if (truckTask.depot) {
                    return bounds.extend(truckTask.depot.address.coordinates);
                }
            });

            map.fitBounds(bounds);
            map.panToBounds(bounds);
            setBoundsDefined(true);
        }
    }, [truckScheduleTasks, map, boundsDefined]);

    return (
        <TasksMapViewStyle>
            <GoogleMap
                onLoad={setMap}
                id="map"
                center={
                    !boundsDefined
                        ? {
                            lat: 50.969344,
                            lng: 5.420591
                        }
                        : undefined
                }
                zoom={10}
                options={{
                    styles: MAP_STYLING as any,
                    disableDefaultUI: true,
                    scrollwheel: true
                }}
            >
                {truckScheduleTasks.map(truckTask => {
                    if (truckTask.task) {
                        return (
                            <MapTask
                                opacity={activeTaskId ? activeTaskId === truckTask.id ? 1 : 0.1 : 1}
                                key={truckTask.id}
                                ap={truckTask.task.inquiry.applicant.name
                                    .split(" ")
                                    .map(v => v.charAt(0))
                                    .join("")
                                    .toUpperCase()}
                                position={truckTask.task.inquiry.pickupAddress.coordinates}
                            />
                        );
                    }

                    if (truckTask.processor) {
                        return (
                            <MapTask
                                opacity={activeTaskId ? activeTaskId === truckTask.id ? 1 : 0.1 : 1}
                                key={truckTask.id}
                                ap={""}
                                position={truckTask.processor.address.coordinates}
                                processor
                            />
                        );
                    }

                    if (truckTask.depot) {
                        return (
                            <MapTask
                                opacity={activeTaskId ? activeTaskId === truckTask.id ? 1 : 0.1 : 1}
                                key={truckTask.id}
                                ap={""}
                                position={truckTask.depot.address.coordinates}
                                start
                            />
                        );
                    }
                })}
                {!isUnsaved &&
                    truckScheduleTasks.map((truckTask, index) => 
                        <Polyline
                            key={index}
                            path={google.maps.geometry.encoding.decodePath(truckTask.polyline)}
                            options={{
                                strokeColor: COLORS.secondary,
                                strokeWeight: 3,
                                strokeOpacity: activeTaskId ? activeTaskId === truckTask.id ? 1 : 0.05 : 0.6
                            }}
                        />)}
            </GoogleMap>
        </TasksMapViewStyle>
    );
};
