/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { InquiryProcessedMutation, InquiryProcessedMutationVariables } from "@models/graphql";

const defaultInquiryProcessedOptions: MutationHookOptions<InquiryProcessedMutation, InquiryProcessedMutationVariables> = {};

export const useInquiryProcessed = (options?: MutationHookOptions<InquiryProcessedMutation, InquiryProcessedMutationVariables>) => {
    const [mutation, { data, loading, error }] = useMutation<InquiryProcessedMutation, InquiryProcessedMutationVariables>(
        GraphQLDocuments.inquiryProcessed
    );

    const inquiryProcessed = async (variables: InquiryProcessedMutationVariables) => {
        try {
            const response = await mutation({
                ...defaultInquiryProcessedOptions,
                ...options,
                variables
            });

            return {
                error: undefined,
                response
            };
        } catch (err) {
            return {
                error: err,
                response: undefined
            };
        }
    };

    return {
        isLoading: loading,
        error,
        data,
        inquiryProcessed: inquiryProcessed
    };
};
