import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";

import { useGetUniqueProductCombinations } from "@components/patternOverviewTab/patternFormProvider";
import { useFormCollectionGroups } from "@components/patternOverviewTab/collectionGroupsProvider";
import { CapacityTableColumn } from "@components/patternCapacity/capacityTable/capacityTableColumn/capacityTableColumn";

import { CapacityTableStyle, FirstColumn, ProductBlock, TruckBlock } from "./capacityTableStyle";
import { Spin } from "antd";
import { useEditCollectionPattern } from "@components/patternOverviewTab/patternEdittingProvider";

export interface CapacityTableProps {

}

export const CapacityTable: FunctionComponent<CapacityTableProps> = () => {
    const { collectionGroups } = useFormCollectionGroups();
    const combinations = useGetUniqueProductCombinations();

    const { editLoading } = useEditCollectionPattern();

    const tableRef = React.useRef<HTMLDivElement>(null);
    const columnsRef = React.useRef<HTMLDivElement>(null);

    const handleShadow = (): void => {
        const tableEl = tableRef.current;
        const columnsEl = columnsRef.current;

        if (!tableEl || !columnsEl) {
            return;
        }

        if (columnsEl.scrollLeft + columnsEl.clientWidth < columnsEl.scrollWidth && columnsEl.clientWidth !== 0) {
            tableEl.classList.add("rightShadow");
        } else {

            tableEl.classList.remove("rightShadow");
        }

        if (columnsEl.scrollLeft > 0) {
            tableEl.classList.add("leftShadow");
        } else {
            tableEl.classList.remove("leftShadow");

        }
    };

    const handleScroll = (): void => {
        handleShadow();
    };

    React.useEffect(() => {
        handleShadow();
    });

    return (
        <Spin spinning={editLoading}>

            <CapacityTableStyle ref={tableRef}>
                <FirstColumn>
                    <h1>
                        <FormattedMessage id="patternForm.capacityTable" />
                    </h1>
                    <TruckBlock>
                        <h4>
                            <FormattedMessage id="patternForm.addressesPerGroup" />
                        </h4>
                        <FormattedMessage tagName="em" id="patternForm.addressesPerHalfDay" />
                        <FormattedMessage tagName="em" id="patternForm.addressesPerFullDay" />
                    </TruckBlock>
                    {combinations.map(comb => 
                        <ProductBlock key={comb.products.map(prod => prod.id).join(",")}>
                            <h2><span>{comb.products.map(prod => prod.name).join(", ")}</span></h2>
                            <FormattedMessage tagName="em" id="patternForm.processor" />
                            {comb.combinations.map(c =>
                                <TruckBlock key={c.truckType.id}>
                                    <h3>
                                        <span>

                                            {c.truckType.name}
                                        </span>
                                    </h3>

                                    <FormattedMessage tagName="em" id="patternForm.capacityPerTruck" />
                                    <FormattedMessage tagName="em" id="patternForm.capacityPerHalfDay" />
                                    <FormattedMessage tagName="em" id="patternForm.capacityPerFullDay" />
                                </TruckBlock>)}
                        </ ProductBlock>)}
                </FirstColumn>
                <div onScroll={handleScroll} ref={columnsRef} className={"columns"}>

                    {collectionGroups.map(group => <CapacityTableColumn group={group} key={group.id} />)}
                </div>
            </CapacityTableStyle>
        </Spin>
    );
};
