import { FunctionComponent, ReactNode } from "react";
import { usePermissions } from "./permissionProvider";

export const PERMISSIONS = {
    pickupDays: {
        read: "pickupDays.read",
        extendedInfo: "pickupDays.extendedInfo"
    },
    deliveryDays: {
        read: "deliveryDays.read",
        extendedInfo: "deliveryDays.extendedInfo"
    },
    collectionRounds: {
        read: "collectionRounds.read",
        canOverbook: "collectionRounds.canOverbook"
    },
    collectionPatterns: {
        read: "collectionPatterns.read",
        create: "collectionPatterns.create",
        update: "collectionPatterns.update",
        delete: "collectionPatterns.delete"
    },
    collectors: {
        read: "collectors.read",
        create: "collectors.create",
        update: "collectors.update",
        delete: "collectors.delete"
    },
    inquiries: {
        read: "inquiries.read",
        create: "inquiries.create",
        update: "inquiries.update",
        updatePickupAddress: "inquiries.updatePickupAddress",
        checkIfAllowed: "inquiries.checkIfAllowed",
        cancel: "inquiries.cancel",
        changePayment: "inquiries.changePayment"
    },
    incidents: {
        read: "incidents.read",
        create: "incidents.create",
        update: "incidents.update",
        delete: "incidents.delete",
        makePayment: "incidents.makePayment"
    },
    incidentReasons: {
        read: "incidentReasons.read",
        create: "incidentReasons.create",
        update: "incidentReasons.update",
        delete: "incidentReasons.delete"
    },
    users: {
        read: "users.read"
    },
    processors: {
        read: "processor.read",
        create: "processor.create",
        update: "processor.update",
        delete: "processor.delete"
    },
    products: {
        read: "products.read",
        create: "products.create",
        update: "products.update"
    },
    trucks: {
        read: "trucks.read",
        create: "trucks.create",
        update: "trucks.update",
        delete: "trucks.delete"
    },
    trucksTypes: {
        read: "trucksTypes.read",
        create: "trucksTypes.create",
        update: "trucksTypes.update",
        delete: "trucksTypes.delete"
    },
    truckSchedule: {
        read: "truckSchedule.read",
        update: "truckSchedule.update"
    },
    planning: {
        scheduleInquiry: "planning.scheduleInquiry",
        addTruckToCollectionRound: "planning.addTruckToCollectionRound",
        createStandaloneCollectionRound: "planning.createStandaloneCollectionRound",
        changeGroup: "planning.changeGroup"
    },
    municipalities: {
        read: "municipalities.read"
    },
    assetTypes: {
        read: "assetTypes.read",
        create: "assetTypes.create",
        update: "assetTypes.update",
        delete: "assetTypes.delete"
    },
    assets: {
        read: "assets.read",
        create: "assets.create",
        update: "assets.update",
        delete: "assets.delete"
    },
    reports: {
        read: "reports.read",
        csvUpload: "reports.csvUpload"
    },
    notificationConfigurations: {
        read: "notificationConfigurations.read",
        create: "notificationConfigurations.create",
        update: "notificationConfigurations.update",
        delete: "notificationConfigurations.delete"
    },
    frontend: {
        planning: "frontend.planning",
        management: "frontend.management"
    }
};

export interface PermissionProps {
    requiredPermissions: string[];
    fallback?: ReactNode;
}

export const Permission: FunctionComponent<PermissionProps> = ({ children, requiredPermissions, fallback }) => {
    const { permissions } = usePermissions();

    if (!requiredPermissions.every(p => permissions.has(p))) {
        if (fallback) {
            return fallback;
        }

        return null;
    }

    return children as any;
};
