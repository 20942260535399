import { InputNumber } from "antd";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";

import { Icon } from "@style/icon";
import { ProductFragment, DeliveryMethod } from "@models/graphql";
import { useGetProductNames } from "@graphql/hocs/hooks/useGetProductNames";
import { useForm, useGetFormValues } from "@components/patternOverviewTab/patternFormProvider";
import { CapacityTable } from "@components/patternCapacity/capacityTable/capacityTable";

import { PatternCapacityStyle, ToBeDeliveredBox } from "./patternCapacityStyle";
import { useEditCollectionPattern } from "@components/patternOverviewTab/patternEdittingProvider";

export interface PatternCapacityProps {
    className?: string;
}

export const PatternCapacity: FunctionComponent<PatternCapacityProps> = ({ className }) => {
    const values = useGetFormValues();
    const { getFieldDecorator } = useForm();
    const { products } = useGetProductNames({ variables: { filter: { accessory: false } } });
    const productIds: Set<string> = new Set();
    const productsToBeDelivered: ProductFragment[] = [];

    const { editPatternItem } = useEditCollectionPattern();

    if (values.days) {
        values.days.forEach(day => {
            if (day.collectionRound) {
                Object.values(day.collectionRound).forEach(round => {
                    if (round.productIds) {
                        round.productIds.forEach(pId => productIds.add(pId));
                    }
                });
            }
        });
    }

    productIds.forEach(pId => {
        const match = products.find(product => product.id === pId && product.deliveryMethod === DeliveryMethod.Delivery);
        if (match) {
            productsToBeDelivered.push(match);
        }
    });

    const ToBeDelivered = (): null | JSX.Element => {
        if (!productsToBeDelivered.length) {
            return null;
        }

        return (
            <ToBeDeliveredBox>
                <Icon type="info" />
                <main>
                    <FormattedMessage tagName="h1" id="patternForm.toBeDeliveredTitle" />
                    <FormattedMessage tagName="p" id="patternForm.toBeDeliveredInfo" />
                    <div className="products">
                        {productsToBeDelivered.map(prod =>
                            <div key={prod.id} className="item">
                                <span>{prod.name}</span>
                                {getFieldDecorator(`fixedProductCycles[${prod.id}]`, { initialValue: editPatternItem && editPatternItem.fixedProductCycles && editPatternItem.fixedProductCycles[prod.id] ? editPatternItem.fixedProductCycles[prod.id] : 1 })(<InputNumber min={1} precision={0} />)}
                            </div>)}
                    </div>
                </main>
            </ToBeDeliveredBox>
        );
    };

    return (
        <PatternCapacityStyle className={className}>
            <ToBeDelivered />
            <CapacityTable />
        </PatternCapacityStyle>
    );
};
