import { Icon as AntIcon } from "antd";
import { FunctionComponent } from "react";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { parseError } from "@utils/parseError";
import { useFormatMessage } from "@utils/intlHook";
import { Icon } from "@style/icon";
import { InquiryOverviewFragment, InquiryStatusType, Responsibility } from "@models/graphql";
import { useGetInquiriesOverview } from "@graphql/hocs/hooks/useGetInquiriesOverview";
import { SubList } from "@components/planningSideBar/toBeRescheduledList/subList/subList";

import { EmptyList, LoadingList, ToBeRescheduledListStyle } from "./toBeRescheduledListStyle";

export interface ToBeRescheduledListProps {
    hideInquiries: string[];
}

export const ToBeRescheduledList: FunctionComponent<ToBeRescheduledListProps> = ({ hideInquiries }) => {
    const formatMessage = useFormatMessage();

    const { inquiries, inquiriesLoading, inquiriesError } = useGetInquiriesOverview({
        variables: {
            filter: {
                statusses: [InquiryStatusType.ToBeRescheduled]
            }
        }
    });

    const [civilianResponsibility, pickupServiceResponsibility, other] = React.useMemo(() => {
        const civRes: InquiryOverviewFragment[] = [];
        const pickupRes: InquiryOverviewFragment[] = [];
        const othe: InquiryOverviewFragment[] = [];

        if (inquiries && inquiries.length) {
            inquiries.forEach(inq => {
                if (hideInquiries.some(i => i === inq.id)) {
                    return;
                }

                const history = inq.statusHistory.find(st => st.type === InquiryStatusType.Incident);

                if (history && history.incident) {
                    if (history.incident.reason.responsibility === Responsibility.Civilian) {
                        civRes.push(inq);
                    } else {
                        pickupRes.push(inq);
                    }
                } else {
                    othe.push(inq);
                }
            });
        }

        return [civRes, pickupRes, othe];
    }, [inquiries, hideInquiries]);

    if (inquiriesLoading) {
        return (
            <LoadingList>
                <AntIcon type="loading" />
            </LoadingList>
        );
    }

    if (inquiriesError) {
        return (
            <EmptyList className="error">
                <Icon type="error" />
                <span>{parseError(inquiriesError, formatMessage)}</span>

                <div className="errorMessage">{inquiriesError.message}</div>
            </EmptyList>
        );
    }

    if (!civilianResponsibility.length && !pickupServiceResponsibility.length) {
        return (
            <EmptyList>
                <Icon type="empty-box" />
                <FormattedMessage id="toBeRescheduled.empty" />
            </EmptyList>
        );
    }

    return (
        <ToBeRescheduledListStyle>
            <SubList
                title={<FormattedMessage id={`responsibility.${Responsibility.Civilian}`} />}
                inquiries={civilianResponsibility}
                id={Responsibility.Civilian}
            />
            <SubList
                title={<FormattedMessage id={`responsibility.${Responsibility.PickupService}`} />}
                inquiries={pickupServiceResponsibility}
                id={Responsibility.PickupService}
            />
            <SubList title={<FormattedMessage id="other" />} inquiries={other} id={"OTHER"} />
        </ToBeRescheduledListStyle>
    );
};
