/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { GetFreeTrucksQuery, GetFreeTrucksQueryVariables } from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

export const useGetFreeTrucks = (options?: QueryHookOptions<GetFreeTrucksQuery, GetFreeTrucksQueryVariables>) => {
    const { data, error, loading } = useQuery<GetFreeTrucksQuery, GetFreeTrucksQueryVariables>(GraphQLDocuments.getFreeTrucks, options);

    return {
        freeTrucks: data && data.freeTrucks ? data.freeTrucks : [],
        freeTrucksLoading: loading,
        freeTrucksError: error
    };
};
