import styled from "styled-components";
import { COLORS } from "@style/colors";

export const GeneralPatternFormStyle = styled.div`
    .ant-form {
        display: flex;

        .ant-form-item {
            margin-right: 15px;
            padding-right: 15px;
            position: relative;

            .icon.pencil {
                opacity: 0.5;
            }

            .ant-input-number {
                width: 130px;
                .ant-input-number-handler-wrap {
                    opacity: 1;
                }
            }
        }

        .ant-form-item-label {
            line-height: initial;
            label {
                font-size: 12px;
            }
        }

        .ant-form-item-control {
            &:not(.has-error) {
                .ant-input, .ant-input-number {
                    border-color: ${COLORS.textTransparent(0.1)};
                }

            }
        }
    }
`;
