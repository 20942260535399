import { EmptyBlockPlaceHolder, HeaderPlaceHolder, InputContainer, ProductBlock, TruckBlock } from "@components/patternCapacity/capacityTable/capacityTableStyle";
import { useEditCollectionPattern } from "@components/patternOverviewTab/patternEdittingProvider";
import { useForm, useGetUniqueProductCombinations } from "@components/patternOverviewTab/patternFormProvider";
import { useGetProcessors } from "@graphql/hocs/hooks/useGetProcessors";
import { CollectionGroupInput } from "@models/graphql";
import { useFormatMessage } from "@utils/intlHook";
import { InputNumber, Select } from "antd";
import * as classnames from "classnames";
import * as React from "react";
import { FunctionComponent } from "react";
import { CapacityTableColumnStyle } from "./capacityTableColumnStyle";

export interface CapacityTableColumnProps {
    group: CollectionGroupInput;
}

export const CapacityTableColumn: FunctionComponent<CapacityTableColumnProps> = ({ group }) => {
    const formatMessage = useFormatMessage();
    const { processors, processorsLoading } = useGetProcessors();
    const combinations = useGetUniqueProductCombinations();
    const { getFieldDecorator, getFieldError } = useForm();
    const { editPatternItem } = useEditCollectionPattern();

    return (
        <CapacityTableColumnStyle>
            <h1>{group.name}</h1>
            <TruckBlock>
                <HeaderPlaceHolder />
                <InputContainer className={classnames({ "has-error": getFieldError(`groups[${group.id}].addressesPerHalfDay`) })}>
                    {getFieldDecorator(`groups[${group.id}].addressesPerHalfDay`, {
                        rules: [{ required: true }],
                        initialValue:
                            editPatternItem && editPatternItem.groups && editPatternItem.groups[group.id]
                                ? editPatternItem.groups[group.id].addressesPerHalfDay
                                : undefined
                    })(<InputNumber min={1} precision={0} placeholder={formatMessage({ id: "patternForm.addressesPerHalfDay" })} />)}
                </InputContainer>
                <InputContainer className={classnames({ "has-error": getFieldError(`groups[${group.id}].addressesPerFullDay`) })}>
                    {getFieldDecorator(`groups[${group.id}].addressesPerFullDay`, {
                        rules: [{ required: true }],
                        initialValue:
                            editPatternItem && editPatternItem.groups && editPatternItem.groups[group.id]
                                ? editPatternItem.groups[group.id].addressesPerFullDay
                                : undefined
                    })(<InputNumber min={1} precision={0} placeholder={formatMessage({ id: "patternForm.addressesPerFullDay" })} />)}
                </InputContainer>
            </TruckBlock>

            {combinations.map(comb => {
                const prodKey = comb.products.map(prod => prod.id).join(",");
                const hasGroupMatches = comb.combinations.some(c => c.groups.some(g => g.groupId === group.id));
                return (
                    <ProductBlock key={prodKey}>
                        <HeaderPlaceHolder />
                        {hasGroupMatches ? <InputContainer>
                            {getFieldDecorator(`processors[${prodKey}][${group.id}].processorId`, {
                                rules: [{ required: true }],
                                initialValue:
                                    editPatternItem &&
                                        editPatternItem.processors &&
                                        editPatternItem.processors[prodKey] &&
                                        editPatternItem.processors[prodKey][group.id]
                                        ? editPatternItem.processors[prodKey][group.id].processorId
                                        : undefined
                            })(<Select loading={processorsLoading} placeholder={formatMessage({ id: "patternForm.processor" })}>
                                {processors.map(p =>
                                    <Select.Option key={p.id}>{p.name}</Select.Option>)}
                            </Select>)}
                        </InputContainer> : <span className="placeholder" />}

                        {comb.combinations.map((c, index) => {
                            const groupMatch = c.groups.find(gr => gr.groupId === group.id);

                            if (groupMatch) {
                                let initVal: {
                                    capacity: number;
                                    halfDay?: number;
                                    fullDay?: number;
                                } | null = null;

                                if (
                                    editPatternItem &&
                                    editPatternItem.config &&
                                    editPatternItem.config[prodKey] &&
                                    editPatternItem.config[prodKey][c.truckType.id] &&
                                    editPatternItem.config[prodKey][c.truckType.id][group.id]
                                ) {
                                    initVal = editPatternItem.config[prodKey][c.truckType.id][group.id];
                                }

                                return (

                                    <React.Fragment key={index}>
                                        <TruckBlock key={c.truckType.id}>
                                            <HeaderPlaceHolder />

                                            <InputContainer
                                                className={classnames({ "has-error": getFieldError(`config[${comb.products.map(prod => prod.id).join(",")}][${c.truckType.id}][${group.id}].capacity`) })}
                                            >
                                                {getFieldDecorator(`config[${prodKey}][${c.truckType.id}][${group.id}].capacity`, {
                                                    rules: [{ required: true }],
                                                    initialValue: initVal ? initVal.capacity : undefined
                                                })(<InputNumber
                                                    min={1}
                                                    precision={0}
                                                    placeholder={formatMessage({ id: "patternForm.capacityPerTruck" })}
                                                />)}
                                            </InputContainer>

                                            <InputContainer
                                                className={classnames({
                                                    "has-error": getFieldError(`config[${prodKey}][${c.truckType.id}][${group.id}].halfDay`)
                                                })}
                                            >
                                                {groupMatch.halfDay ?
                                                    getFieldDecorator(`config[${prodKey}][${c.truckType.id}][${group.id}].halfDay`, {
                                                        rules: [{ required: true }],
                                                        initialValue: initVal ? initVal.halfDay : undefined
                                                    })(<InputNumber
                                                        min={0}
                                                        precision={0}
                                                        placeholder={formatMessage({ id: "patternForm.capacityPerHalfDay" })}
                                                    />)
                                                    :
                                                    <span className="placeholder" />
                                                }
                                            </InputContainer>
                                            <InputContainer
                                                className={classnames({
                                                    "has-error": getFieldError(`config[${prodKey}][${c.truckType.id}][${group.id}].fullDay`)
                                                })}
                                            >
                                                {groupMatch.fullDay ?
                                                    getFieldDecorator(`config[${prodKey}][${c.truckType.id}][${group.id}].fullDay`, {
                                                        rules: [{ required: true }],
                                                        initialValue: initVal ? initVal.fullDay : undefined
                                                    })(<InputNumber
                                                        min={0}
                                                        precision={0}
                                                        placeholder={formatMessage({ id: "patternForm.capacityPerFullDay" })}
                                                    />)
                                                    :
                                                    <span className="placeholder" />
                                                }
                                            </InputContainer>
                                        </TruckBlock>
                                    </ React.Fragment>
                                );
                            }

                            return <EmptyBlockPlaceHolder key={c.truckType.id} />;
                        })}
                    </ProductBlock>
                );
            })}
        </CapacityTableColumnStyle>
    );
};
