import { DocumentNode } from "graphql";

export class GraphQLDocuments {
    public static getProductNames: DocumentNode = require("@graphql/documents/queries/getProductNames.graphql");
    public static getInquiriesOverview: DocumentNode = require("@graphql/documents/queries/getInquiriesOverview.graphql");
    public static getInquiryDetail: DocumentNode = require("@graphql/documents/queries/getInquiryDetail.graphql");
    public static getFreePickupDays: DocumentNode = require("@graphql/documents/queries/getFreePickupDays.graphql");
    public static getFreeDeliveryDays: DocumentNode = require("@graphql/documents/queries/getFreeDeliveryDays.graphql");
    public static getApplicants: DocumentNode = require("@graphql/documents/queries/getApplicants.graphql");
    public static getIncidentReasons: DocumentNode = require("@graphql/documents/queries/getIncidentReasons.graphql");
    public static getTruckTypes: DocumentNode = require("@graphql/documents/queries/getTruckTypes.graphql");
    public static getCollectionPattern: DocumentNode = require("@graphql/documents/queries/getCollectionPattern.graphql");
    public static getCollectionPatterns: DocumentNode = require("@graphql/documents/queries/getCollectionPatterns.graphql");
    public static getCollectionRounds: DocumentNode = require("@graphql/documents/queries/getCollectionRounds.graphql");
    public static getTrucks: DocumentNode = require("@graphql/documents/queries/getTrucks.graphql");
    public static getMunicipalities: DocumentNode = require("@graphql/documents/queries/getMunicipalities.graphql");
    public static getProduct: DocumentNode = require("@graphql/documents/queries/getProduct.graphql");
    public static getCardsProducts: DocumentNode = require("@graphql/documents/queries/getCardsProducts.graphql");
    public static getProcessors: DocumentNode = require("@graphql/documents/queries/getProcessors.graphql");
    public static getStreets: DocumentNode = require("@graphql/documents/queries/getStreets.graphql");
    public static getCollectors: DocumentNode = require("@graphql/documents/queries/getCollectors.graphql");
    public static getTruckSchedule: DocumentNode = require("@graphql/documents/queries/getTruckSchedule.graphql");
    public static getAssetTypes: DocumentNode = require("@graphql/documents/queries/getAssetTypes.graphql");
    public static getNotificationConfigurations: DocumentNode = require("@graphql/documents/queries/getNotificationConfigurations.graphql");
    public static getAssets: DocumentNode = require("@graphql/documents/queries/getAssets.graphql");
    public static getInquiriesCount: DocumentNode = require("@graphql/documents/queries/getInquiriesCount.graphql");
    public static getProfile: DocumentNode = require("@graphql/documents/queries/getProfile.graphql");
    public static getIncidentReports: DocumentNode = require("@graphql/documents/queries/getIncidentReports.graphql");
    public static getOrderReports: DocumentNode = require("@graphql/documents/queries/getOrderReports.graphql");
    public static getLogisticReports: DocumentNode = require("@graphql/documents/queries/getLogisticReports.graphql");
    public static collectionRoundsCsv: DocumentNode = require("@graphql/documents/queries/collectionRoundsCsv.graphql");
    public static getFreeTrucks: DocumentNode = require("@graphql/documents/queries/getFreeTrucks.graphql");

    public static getOrderReportsCSV: DocumentNode = require("@graphql/documents/mutations/getOrderReportsCSV.graphql");
    public static getLogisticReportsCSV: DocumentNode = require("@graphql/documents/mutations/getLogisticReportsCSV.graphql");
    public static getIncidentReportCsv: DocumentNode = require("@graphql/documents/mutations/getIncidentReportCsv.graphql");
    public static createInquiry: DocumentNode = require("@graphql/documents/mutations/createInquiry.graphql");
    public static cancelInquiry: DocumentNode = require("@graphql/documents/mutations/cancelInquiry.graphql");
    public static inquiryOnhold: DocumentNode = require("@graphql/documents/mutations/inquiryOnHold.graphql");
    public static inquiryProcessed: DocumentNode = require("@graphql/documents/mutations/inquiryProcessed.graphql");
    public static inquiryToBeRescheduled: DocumentNode = require("@graphql/documents/mutations/inquiryToBeRescheduled.graphql");
    public static addIncidentComment: DocumentNode = require("@graphql/documents/mutations/addIncidentComment.graphql");
    public static addIncidentFeedback: DocumentNode = require("@graphql/documents/mutations/addIncidentFeedback.graphql");
    public static deleteIncidentComment: DocumentNode = require("@graphql/documents/mutations/deleteIncidentComment.graphql");
    public static updateIncidentComment: DocumentNode = require("@graphql/documents/mutations/updateIncidentComment.graphql");
    public static closeIncident: DocumentNode = require("@graphql/documents/mutations/closeIncident.graphql");
    public static createIncident: DocumentNode = require("@graphql/documents/mutations/createIncident.graphql");
    public static createIncidentReason: DocumentNode = require("@graphql/documents/mutations/createIncidentReason.graphql");
    public static updateIncidentReason: DocumentNode = require("@graphql/documents/mutations/updateIncidentReason.graphql");
    public static deleteIncidentReason: DocumentNode = require("@graphql/documents/mutations/deleteIncidentReason.graphql");
    public static paymentRequest: DocumentNode = require("@graphql/documents/mutations/paymentRequest.graphql");
    public static createCollectionPattern: DocumentNode = require("@graphql/documents/mutations/createCollectionPattern.graphql");
    public static updateCollectionPattern: DocumentNode = require("@graphql/documents/mutations/updateCollectionPattern.graphql");
    public static deleteCollectionPattern: DocumentNode = require("@graphql/documents/mutations/deleteCollectionPattern.graphql");
    public static activateCollectionPattern: DocumentNode = require("@graphql/documents/mutations/activateCollectionPattern.graphql");
    public static deactivateCollectionPattern: DocumentNode = require("@graphql/documents/mutations/deactivateCollectionPattern.graphql");
    public static updateInquiryCollectionDay: DocumentNode = require("@graphql/documents/mutations/updateInquiryCollectionDay.graphql");
    public static updateInquiry: DocumentNode = require("@graphql/documents/mutations/updateInquiry.graphql");
    public static addTruckToCollectionRound: DocumentNode = require("@graphql/documents/mutations/addTruckToCollectionRound.graphql");
    public static updateProduct: DocumentNode = require("@graphql/documents/mutations/updateProduct.graphql");
    public static createProduct: DocumentNode = require("@graphql/documents/mutations/createProduct.graphql");
    public static createStandAloneCollectionRound: DocumentNode = require("@graphql/documents/mutations/createStandaloneCollectionRound.graphql");
    public static updateTruck: DocumentNode = require("@graphql/documents/mutations/updateTruck.graphql");
    public static createTruck: DocumentNode = require("@graphql/documents/mutations/createTruck.graphql");
    public static deleteTruck: DocumentNode = require("@graphql/documents/mutations/deleteTruck.graphql");
    public static updateAssetType: DocumentNode = require("@graphql/documents/mutations/updateAssetType.graphql");
    public static createAssetType: DocumentNode = require("@graphql/documents/mutations/createAssetType.graphql");
    public static deleteAssetType: DocumentNode = require("@graphql/documents/mutations/deleteAssetType.graphql");
    public static updateAsset: DocumentNode = require("@graphql/documents/mutations/updateAsset.graphql");
    public static createAsset: DocumentNode = require("@graphql/documents/mutations/createAsset.graphql");
    public static deleteAsset: DocumentNode = require("@graphql/documents/mutations/deleteAsset.graphql");
    public static updateCollector: DocumentNode = require("@graphql/documents/mutations/updateCollector.graphql");
    public static createCollector: DocumentNode = require("@graphql/documents/mutations/createCollector.graphql");
    public static deleteCollector: DocumentNode = require("@graphql/documents/mutations/deleteCollector.graphql");
    public static deleteTruckType: DocumentNode = require("@graphql/documents/mutations/deleteTruckType.graphql");
    public static createTruckType: DocumentNode = require("@graphql/documents/mutations/createTruckType.graphql");
    public static updateTruckType: DocumentNode = require("@graphql/documents/mutations/updateTruckType.graphql");
    public static createProcessor: DocumentNode = require("@graphql/documents/mutations/createProcessor.graphql");
    public static updateProcessor: DocumentNode = require("@graphql/documents/mutations/updateProcessor.graphql");
    public static deleteProcessor: DocumentNode = require("@graphql/documents/mutations/deleteProcessor.graphql");
    public static createNotificationConfiguration: DocumentNode = require("@graphql/documents/mutations/createNotificationConfiguration.graphql");
    public static updateNotificationConfiguration: DocumentNode = require("@graphql/documents/mutations/updateNotificationConfiguration.graphql");
    public static deleteNotificationConfiguration: DocumentNode = require("@graphql/documents/mutations/deleteNotificationConfiguration.graphql");
    public static fixedTruckScheduleOrder: DocumentNode = require("@graphql/documents/mutations/fixedTruckScheduleOrder.graphql");
    public static resetSchedule: DocumentNode = require("@graphql/documents/mutations/resetSchedule.graphql");
    public static updateInquiryTaskCollectionDay: DocumentNode = require("@graphql/documents/mutations/updateInquiryTaskCollectionDay.graphql");
    public static getTruckScheduleCsv: DocumentNode = require("@graphql/documents/mutations/getTruckScheduleCsv.graphql");
    public static uploadWeightData: DocumentNode = require("@graphql/documents/mutations/uploadWeightData.graphql");
    public static inquiryDetail: DocumentNode = require("@graphql/documents/fragments/inquiryDetail.graphql");
    public static inquiryOverview: DocumentNode = require("@graphql/documents/fragments/inquiryOverview.graphql");
    public static inquiryDetailIncident: DocumentNode = require("@graphql/documents/fragments/inquiryDetailIncident.graphql");
    public static collectionRound: DocumentNode = require("@graphql/documents/fragments/collectionRound.graphql");
    public static moveTruckSchedule: DocumentNode = require("@graphql/documents/mutations/moveTruckSchedule.graphql");

    public static uploadMedia: DocumentNode = require("@graphql/documents/mutations/uploadMedia.graphql");
}
