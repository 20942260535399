import { Action } from "@globalState/actions/action";
import { GlobalState, initialState } from "@globalState/initialState";
import { createContext, Dispatch, useContext } from "react";

export const StateContext = createContext(initialState);
export const DispatchContext = createContext((() => 0) as Dispatch<Action>);

export const useDispatch = <R = any>(action: R): R => {
    const dispatch = useContext(DispatchContext);

    return ((data: any) => {
        dispatch((action as any)(data));
    }) as any;
};

export const useGlobalState = <K extends keyof GlobalState>(property: K): GlobalState[K] => {
    const state = useContext(StateContext);

    return state[property];
};
