import { usePermissions } from "@components/permission/permissionProvider";
import { Button } from "antd";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { Route, RouteProps } from "react-router";
import { Link } from "react-router-dom";
import { PrivateRouteStyle } from "./privateRouteStyle";

export interface PrivateRouteProps extends RouteProps {
    requiredPermissions?: string[];
}

export const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({ requiredPermissions, ...rest }) => {

    const { permissions } = usePermissions();

    if (!requiredPermissions || requiredPermissions.every(p => permissions.has(p))) {
        return <Route
            {...rest}
        />;
    }

    return (
        <PrivateRouteStyle>
            <h1>403</h1>
            <FormattedMessage tagName="h2" id="forbidden.title" />
            <FormattedMessage tagName="p" id="forbidden.info" />
            <Link to="/">
                <Button type="primary">
                    <FormattedMessage id="forbidden.button" />
                </Button>
            </Link>
        </PrivateRouteStyle>
    );
};
