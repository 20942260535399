import styled from "styled-components";

export const UploaderStyle = styled.div`
    .ant-upload-drag {
        background: #fbfbfb;
        border: 1px dashed #d9d9d9;
        border-radius: 4px;
        margin-bottom: 20px;
    }

    .ant-upload-drag-container {
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .ant-btn {
            width: 150px;
            margin-top: 20px;
        }
    }

    .ant-upload-list-picture-card {
        float: none;
        display: flex;
        flex-wrap: wrap;
    }

    .ant-upload-list-picture-card .ant-upload-list-item {
        float: none;
    }
`;
