/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { GetTrucksQuery, GetTrucksQueryVariables } from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

export const useGetTrucks = (options?: QueryHookOptions<GetTrucksQuery, GetTrucksQueryVariables>) => {
    const { data, error, loading } = useQuery<GetTrucksQuery, GetTrucksQueryVariables>(GraphQLDocuments.getTrucks, options);

    return {
        trucks: data && data.trucks ? data.trucks.items : [],
        trucksLoading: loading,
        trucksError: error
    };
};
