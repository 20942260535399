/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { GetTruckScheduleQuery, GetTruckScheduleQueryVariables } from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

export const useGetTruckSchedule = (options?: QueryHookOptions<GetTruckScheduleQuery, GetTruckScheduleQueryVariables>) => {
    const { data, error, loading } = useQuery<GetTruckScheduleQuery, GetTruckScheduleQueryVariables>(GraphQLDocuments.getTruckSchedule, options);

    return {
        truckSchedule: data && data.truckSchedule,
        truckScheduleLoading: loading,
        truckScheduleError: error
    };
};
