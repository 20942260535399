/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { GetProfileQuery, GetProfileQueryVariables } from "@models/graphql";

export const useGetProfile = (options?: QueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) => {
    const { data, error, loading } = useQuery<GetProfileQuery, GetProfileQueryVariables>(GraphQLDocuments.getProfile, options);

    return {
        profile: data && data.profile,
        profileLoading: loading,
        profileError: error
    };
};
