import * as React from "react";
import { FunctionComponent } from "react";
import { MapTaskStyle } from "./mapTaskStyle";
import { OverlayView } from "@react-google-maps/api";

export interface MapTaskProps {
    position: {
        lat: number;
        lng: number;
    };
    ap: string;
    processor?: boolean;
    start?: boolean;
    opacity: number;
}

export const MapTask: FunctionComponent<MapTaskProps> = ({ position, ap, processor, start, opacity }) => 
    <OverlayView mapPaneName={OverlayView.MARKER_LAYER} position={new google.maps.LatLng(position.lat, position.lng)}>
        <MapTaskStyle style={{ opacity }} processor={processor || start}>
            {processor ? <img src={require("@assets/images/processor.svg").default} /> : start ? <img src={require("@assets/images/home.svg").default} /> : ap}
        </MapTaskStyle>
    </OverlayView>
    ;
