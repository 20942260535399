import { PageHeader } from "@components/pageHeader/pageHeader";
import { PERMISSIONS } from "@components/permission/permission";
import { usePermissions } from "@components/permission/permissionProvider";
import { TrucksTable } from "@components/trucksTable/trucksTable";
import { TruckTypesTable } from "@components/truckTypesTable/truckTypesTable";
import { InnerContainer } from "@style/innerContainer";
import { Tabs } from "antd";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { TrucksPageStyle } from "./trucksPageStyle";

export interface TrucksPageProps {

}

export const TrucksPage: FunctionComponent<TrucksPageProps> = ({ }) => {

    const { hasPermissions } = usePermissions();
    return (
        <TrucksPageStyle>
            <PageHeader title="settingsPage.trucksTitle">

            </PageHeader>
            <InnerContainer>
                <Tabs>
                    <Tabs.TabPane disabled={!hasPermissions([PERMISSIONS.trucks.read])} key="trucks" tab={<FormattedMessage id="trucksPage.trucks" />}>
                        <TrucksTable />
                    </Tabs.TabPane>
                    <Tabs.TabPane disabled={!hasPermissions([PERMISSIONS.trucksTypes.read])} key="truckTypes" tab={<FormattedMessage id="trucksPage.truckTypes" />}>
                        <TruckTypesTable />
                    </Tabs.TabPane>
                </Tabs>
            </InnerContainer>
        </TrucksPageStyle>
    );
};

