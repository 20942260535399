import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";

import { CollectionRoundBlock } from "@components/patternOverviewTab/collectionRoundBlock/collectionRoundBlock";

import { PatternDayBlockStyle } from "./patternDayBlockStyle";
import { useFormCollectionRound } from "@components/patternOverviewTab/collectionRoundProvider";

export interface PatternDayBlockProps {
    dayIndex: number;
}

export const PatternDayBlock: FunctionComponent<PatternDayBlockProps> = ({ dayIndex }) => {
    const { addCollectionRound, collectionRounds } = useFormCollectionRound();

    const renderCollectionRounds = (): null | JSX.Element[] => {
        if (!collectionRounds[dayIndex]) {
            return null;
        }

        return collectionRounds[dayIndex].map(roundId =>
            <CollectionRoundBlock
                roundId={roundId}
                dayIndex={dayIndex}
                key={roundId}
            />);

    };

    const handleAddCollectionRound = (): void => {
        addCollectionRound(dayIndex);
    };

    return (
        <PatternDayBlockStyle>
            <header>
                <span>
                    <FormattedMessage id="day" /> {dayIndex + 1}
                </span>
                <span className="add" onClick={handleAddCollectionRound}>
                    + <FormattedMessage id="patternForm.addCollectionRound" />
                </span>
            </header>

            <main>
                {renderCollectionRounds()}
            </main>

        </PatternDayBlockStyle>
    );
};
