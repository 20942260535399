import { Form, Input, Icon as AntIcon } from "antd";
import * as moment from "moment";
import * as React from "react";
import { FunctionComponent } from "react";

import { useFormatMessage } from "@utils/intlHook";
import { Icon } from "@style/icon";
import { GetInquiryDetailQuery, GetInquiryDetailQueryVariables } from "@models/graphql";
import { useDeleteIncidentComment } from "@graphql/hocs/hooks/useDeleteIncidentComment";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";

import { CommentStyle } from "./commentStyle";
import { useUpdateIncidentComment } from "@graphql/hocs/hooks/useUpdateIncidentComment";

export interface CommentProps {
    isMe: boolean;
    comment: NonNullable<GetInquiryDetailQuery["inquiry"]["statusHistory"][0]["incident"]>["comments"][0];
    incidentId: string;
    inquiryId: string;
    reRenderCallback(): void; // BECAUSE FUCK APOLLO
}

export const Comment: FunctionComponent<CommentProps> = ({ comment, isMe, inquiryId, incidentId, reRenderCallback }) => {
    const formatMessage = useFormatMessage();
    const [isEditting, setIsEditting] = React.useState(false);
    const [editValue, setEditValue] = React.useState(comment.message);

    const { updateIncidentComment, isLoading: updateLoading } = useUpdateIncidentComment({});

    const { deleteIncidentComment, isLoading: deleteLoading } = useDeleteIncidentComment({
        update(cache, response) {
            const cacheData = cache.readQuery<GetInquiryDetailQuery, GetInquiryDetailQueryVariables>({
                query: GraphQLDocuments.getInquiryDetail,
                variables: { id: inquiryId }
            });

            if (!cacheData || !response || !response.data || !response.data.deleteIncidentComment.succeeded) {
                return;
            }

            const statusHistoryIndex = cacheData.inquiry.statusHistory.findIndex(history =>
                history.incident && history.incident.id === incidentId ? true : false);

            if (statusHistoryIndex === -1) {
                return;
            }

            const statusHistory = cacheData.inquiry.statusHistory[statusHistoryIndex];

            if (!statusHistory.incident) {
                return;
            }

            statusHistory.incident.comments = statusHistory.incident.comments.filter(c => c.id !== comment.id);
            cacheData.inquiry.statusHistory[statusHistoryIndex] = statusHistory;

            cache.writeQuery<GetInquiryDetailQuery, GetInquiryDetailQueryVariables>({
                query: GraphQLDocuments.getInquiryDetail,
                variables: { id: inquiryId },
                data: { inquiry: JSON.parse(JSON.stringify(cacheData.inquiry)) }
            });

            reRenderCallback();
        }
    });

    const editComment = (): void => {
        setIsEditting(!isEditting);
    };

    const deleteComment = (): void => {
        deleteIncidentComment({ commentId: comment.id });
    };

    const saveEdit = async (event: React.FormEvent): Promise<void> => {
        event.preventDefault();

        await updateIncidentComment({
            comment: editValue,
            commentId: comment.id
        });

        setIsEditting(false);
    };

    return (
        <CommentStyle>
            <h2>
                {isMe ? formatMessage({ id: "you" }) : `${comment.user.firstname} ${comment.user.lastname}`}
                <span className="commentDate">{moment(comment.timestamp).fromNow()}</span>
            </h2>
            <main>
                {isEditting ? 
                    <Form onSubmit={saveEdit}>
                        <Input
                            disabled={updateLoading}
                            suffix={updateLoading && <AntIcon type="loading" />}
                            value={editValue}
                            onChange={e => setEditValue(e.target.value)}
                        />{" "}
                    </Form>
                    : 
                    comment.message?.split("\n").map(text => <p>{text}</p>)
                }
            </main>
            {isMe && 
                <div className="editBtns">
                    <Icon type="pencil" className="editIcon" onClick={editComment}></Icon>
                    {deleteLoading ? <AntIcon type="loading" /> : <Icon type="trash" className="editIcon" onClick={deleteComment}></Icon>}
                </div>
            }
        </CommentStyle>
    );
};
