/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { GetAssetTypesQuery, GetAssetTypesQueryVariables } from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

export const useGetAssetTypes = (options?: QueryHookOptions<GetAssetTypesQuery, GetAssetTypesQueryVariables>) => {
    const { data, error, loading } = useQuery<GetAssetTypesQuery, GetAssetTypesQueryVariables>(GraphQLDocuments.getAssetTypes, options);

    return {
        assetTypes: data && data.assetTypes ? data.assetTypes.items : [],
        assetTypesLoading: loading,
        assetTypesError: error
    };
};
