/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as moment from "moment";
import * as uuid from "uuid";

import { isEqual } from "@utils/isEqualArray";
import { DATE_FORMAT } from "@utils/consts";
import {
    CollectionGroupInput,
    CollectionPatternConfigInput,
    CollectionPatternFragment,
    CollectionPatternInput,
    CollectionPatternProductConfigInput,
    CollectionPatternTruckConfigInput,
    DayPart,
    FixedProductCycleInput,
    PatternDayInput,
    CollectionPatternRoundInput
} from "@models/graphql";
import { PatternFormValues } from "@components/patternOverviewTab/patternFormProvider";

export const parseCollectionPatternToForm = (collectionPattern: CollectionPatternFragment): {
    fields: PatternFormValues;
    collRounds: string[][];
} => {

    const groups: Pick<PatternFormValues, "groups">["groups"] = {};
    const config: Pick<PatternFormValues, "config">["config"] = {};
    const days: Pick<PatternFormValues, "days">["days"] = [];
    const processors: Pick<PatternFormValues, "processors">["processors"] = {};
    const collRounds: string[][] = [];
    const fixedProductCycles: { [index: string]: number } = {};

    collectionPattern.configs.forEach(cfg => {
        groups[cfg.group.id] = {
            addressesPerFullDay: cfg.maxAddressesPerFullDay as (number | undefined),
            addressesPerHalfDay: cfg.maxAddressesPerHalfDay as (number | undefined)
        };

        cfg.collectionPatternProductConfigs.forEach(prdCfg => {
            const prdIds = prdCfg.products.map(prd => prd.id).join(",");

            if (!processors[prdIds]) {
                processors[prdIds] = {};
            }

            if (!processors[prdIds][cfg.group.id]) {
                processors[prdIds][cfg.group.id] = {
                    processorId: prdCfg.processor.id
                };
            }

            prdCfg.truckConfigs.forEach(truckCfg => {
                if (!config[prdIds]) {
                    config[prdIds] = {};
                }

                if (!config[prdIds][truckCfg.truckType.id]) {
                    config[prdIds][truckCfg.truckType.id] = {};
                }

                config[prdIds][truckCfg.truckType.id][cfg.group.id] = {
                    capacity: truckCfg.maxItemsPerCargo,
                    fullDay: truckCfg.cargoPerDayFullDay as (number | undefined),
                    halfDay: truckCfg.cargoPerDayHalfDay as (number | undefined)
                };

            });
        });

    });

    collectionPattern.fixedProductCycles.forEach(cycle => {
        fixedProductCycles[cycle.product.id] = cycle.fixedCyclesBetweenPickupAndDelivery;
    });

    collectionPattern.days.forEach((day, dayIndex) => {
        const collectionRound: {
            [index: string]: {
                dayPart?: DayPart;
                groupId?: string;
                productIds?: string[];
                truckTypeId?: string;
            };
        } = {};
        collRounds.push([]);

        day.collectionRound.forEach(col => {
            const id = uuid();
            collRounds[dayIndex].push(id);
            collectionRound[id] = {
                dayPart: col.dayPart,
                groupId: col.group.id,
                productIds: col.products.map(prod => prod.id),
                truckTypeId: col.truckType.id
            };
        });

        days.push({
            collectionRound
        });
    });

    const fields: PatternFormValues = {
        from: moment(collectionPattern.from),
        to: moment(collectionPattern.to),
        name: collectionPattern.name,
        dayCount: collectionPattern.dayCount,
        groups,
        days,
        config,
        processors,
        fixedProductCycles
    };

    return {
        fields,
        collRounds
    };

};

// tslint:disable: no-non-null-assertion
export const parseFormToCollectionPatternInput = (vals: PatternFormValues, unplannableDays: string[], collectionGroups: CollectionGroupInput[]): CollectionPatternInput => {
    const productConfigs: {
        groupId: string;
        productIds: string[];
        config: CollectionPatternTruckConfigInput[];
        processorId: string;
    }[] = [];

    Object.entries(vals.config!).forEach(([productIds, truckTypes]) => {
        Object.entries(truckTypes).forEach(([truckTypeId, groups]) => {
            Object.entries(groups).forEach(([gId, config]) => {
                const match = productConfigs.find(prodCfg => isEqual(prodCfg.productIds, productIds.split(",")) && prodCfg.groupId === gId);
                let processorId = "";

                if (vals.processors && vals.processors[productIds] && vals.processors[productIds][gId]) {
                    processorId = vals.processors[productIds][gId].processorId;
                }

                if (match) {
                    match.config.push({
                        maxItemsPerCargo: config.capacity,
                        truckTypeId,
                        cargoPerDayFullDay: config.fullDay,
                        cargoPerDayHalfDay: config.halfDay
                    });
                } else {
                    productConfigs.push({
                        productIds: productIds.split(","),
                        config: [{
                            maxItemsPerCargo: config.capacity,
                            truckTypeId,
                            cargoPerDayFullDay: config.fullDay,
                            cargoPerDayHalfDay: config.halfDay
                        }],
                        groupId: gId,
                        processorId: processorId
                    });
                }

            });
        });
    });

    const daysInput: PatternDayInput[] = [];

    for (let i = 0; i < vals.dayCount!; i += 1) {
        const day = vals.days![i];

        daysInput.push({

            dayIndex: i,
            collectionRound: !day ? [] : Object.values(day.collectionRound!).map<CollectionPatternRoundInput>(round => ({
                dayPart: round.dayPart!,
                groupId: round.groupId!,
                productIds: round.productIds!,
                truckTypeId: round.truckTypeId!
            }))
        });
    }

    return {
        from: vals.from.format(DATE_FORMAT),
        to: vals.to.format(DATE_FORMAT),
        dayCount: vals.dayCount!,
        name: vals.name!,
        notPlannableDays: unplannableDays.filter(date => moment(date).isBetween(vals.from, vals.to, "d", "[]")).map(date => moment(date).format(DATE_FORMAT)),
        groups: collectionGroups,
        days: daysInput,
        configs: Object.entries(vals.groups!).map<CollectionPatternConfigInput>(([groupId, group]) => ({
            groupId,
            maxAddressesPerFullDay: group.addressesPerFullDay,
            maxAddressesPerHalfDay: group.addressesPerHalfDay,
            collectionPatternProductConfigs: productConfigs.filter(config => config.groupId === groupId).map<CollectionPatternProductConfigInput>(config => ({
                productIds: config.productIds,
                truckConfigs: config.config,
                processorId: config.processorId
            }))
        })),
        fixedProductCycles: vals.fixedProductCycles ? Object.entries(vals.fixedProductCycles).map<FixedProductCycleInput>(([productId, fixedCyclesBetweenPickupAndDelivery]) => ({
            productId,
            fixedCyclesBetweenPickupAndDelivery
        })) : []
    };
};
