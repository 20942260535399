import styled from "styled-components";
import { keyframes } from "styled-components";
import { COLORS } from "@style/colors";

export const bounce = keyframes`
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
`;

export const MutationLoadingStyle = styled.div`
    width: 34px;
    height: 34px;
    background: white;
    border-radius: 100%;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.25);
    display: flex;
    align-items: center;
    justify-content: center;

    .bounce {
        width: 6px;
        height: 6px;
        flex-shrink: 0;
        background-color: ${COLORS.primary};

        border-radius: 100%;
        display: block;
        animation: ${bounce} 1.4s infinite ease-in-out both;
    }

    .b1 {
        animation-delay: -0.32s;
    }

    .b2 {
        animation-delay: -0.16s;
    }

    .icon {
        font-size: 12px;
        color: ${COLORS.primary};
    }

    .cross {
        color: ${COLORS.error};
    }

    &.appear {
        transform: scale(0);
    }

    &.appear-active {
        transform: scale(1);
        transition: transform 300ms ease-in;
    }

    &.exit {
        transform: scale(1);
    }

    &.exit-active {
        transform: scale(0);
        transition: transform 300ms ease-in;
    }

`;
