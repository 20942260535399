import { DatePicker, Select, Table, Button, message, Tag } from "antd";
import { ColumnProps } from "antd/lib/table";
import * as moment from "moment";
import { FunctionComponent } from "react";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { useFormatMessage } from "@utils/intlHook";
import { DATE_FORMAT } from "@utils/consts";
import { InnerContainer } from "@style/innerContainer";
import { GetIncidentReportsQuery } from "@models/graphql";
import { useGetProductNames } from "@graphql/hocs/hooks/useGetProductNames";
import { useGetIncidentReports } from "@graphql/hocs/hooks/useGetIncidentReports";

import { Moment } from "moment";
import { IncidentReportsPageStyle } from "./incidentReportsPageStyle";
import { ErrorMessage } from "@components/errorMessage/errorMessage";
import { CsvDownloadModal } from "@components/planningPageHeader/csvDownloadModal/csvDownloadModal";
import { useGetIncidentReportCsv } from "@graphql/hocs/hooks/useGetIncidentReportCsv";
import { parseError } from "@utils/parseError";

export interface IncidentReportsPageProps {

}

export const IncidentReportsPage: FunctionComponent<IncidentReportsPageProps> = (props: IncidentReportsPageProps) => {
    const [selectedProductIds, setSelectedProductIds] = React.useState<string[]>([]);
    const [csvUrl, setCsvUrl] = React.useState<string>("");
    const [csvModalVisible, setcCvModalVisible] = React.useState(false);
    const [[from, to], setDateRange] = React.useState<[Moment, Moment]>([moment().startOf("month"), moment().subtract(1, "d")]);
    const formatMessage = useFormatMessage();
    const { incidentReports, incidentReportsError, incidentReportsLoading } = useGetIncidentReports({
        variables: {
            from: from.format(DATE_FORMAT),
            to: to.format(DATE_FORMAT),
            filter: {
                productIds: selectedProductIds
            }
        }
    });
    const { getIncidentReportCsv, isLoading } = useGetIncidentReportCsv();
    const { products } = useGetProductNames();

    const columns: ColumnProps<GetIncidentReportsQuery["incidentReports"][0]>[] = [
        {
            key: "date",
            title: formatMessage({ id: "incidentReportsPage.date" }),
            width: 100,
            render(_, record) {
                return moment(record.date, DATE_FORMAT).format("DD/MM/YYYY");
            }
        },
        {
            key: "product",
            title: formatMessage({ id: "incidentReportsPage.product" }),
            render(_, record) {
                return record.inquiry.products.map(product => <div className="product-item" key={product.product.id}>{product.amount} <em> x {product.product.name}</em></div>);

            }
        },
        {
            key: "municipality",
            title: formatMessage({ id: "incidentReportsPage.municipality" }),
            render(_, record) {
                return record.inquiry.pickupAddress.municipality ? record.inquiry.pickupAddress.municipality.name : "";
            }
        },
        {
            key: "closed",
            title: formatMessage({ id: "incidentReportsPage.closed" }),
            width: 100,
            render(_, record) {
                return record.closed ? <FormattedMessage id="yes" /> : <FormattedMessage tagName="small" id="no" />;

            }
        },
        {
            key: "incidentReason",
            title: formatMessage({ id: "incidentReportsPage.incidentReason" }),
            render(_, record) {
                return record.incidentReason.name;
            }
        },
        {
            key: "newDate",
            width: 200,
            title: formatMessage({ id: "incidentReportsPage.newDate" }),
            render(_, record) {
                return record.date ? moment(record.date).format("DD/MM/YYYY") : "";
            }
        },
        {
            key: "currentStatus",
            title: formatMessage({ id: "incidentReportsPage.inquiryStatus" }),
            render(_, record) {
                return <FormattedMessage id={`inquiryStatusType.${record.inquiry.currentStatus}`} />;
            }
        },
        {
            key: "orderNumber",
            title: formatMessage({ id: "incidentReportsPage.orderNumber" }),
            render: (_, record) => <Tag>{record.inquiry.orderNumber}</Tag>,
            fixed: "right"
        }
    ];

    const handleProductChange = (val: string[]): void => {
        setSelectedProductIds(val);
    };

    const handleDateRangeChange = (val: [Moment, Moment]): void => {
        setDateRange(val);
    };

    const handleExport = async (): Promise<void> => {
        const { data, error } = await getIncidentReportCsv({
            from: from.format(DATE_FORMAT),
            to: to.format(DATE_FORMAT),
            filter: {
                productIds: selectedProductIds
            }
        });

        if (data) {
            setcCvModalVisible(true);
            setCsvUrl(data.incidentReportsCSV.url);
        }

        if (error) {
            message.error(parseError(error, formatMessage));
        }

    };

    return (
        <IncidentReportsPageStyle>
            <CsvDownloadModal
                fileName={`Incident Reports ${from.format("DD-MM-YYYY")} - ${to.format("DD-MM-YYYY")}`}
                url={csvUrl}
                visible={csvModalVisible}
                closeCallback={() => setcCvModalVisible(false)}
            />
            <InnerContainer>
                <FormattedMessage id="incidentReportsPage.title" tagName="h1" />
                <div className="filterBar">
                    <span>
                        <Select onChange={handleProductChange} placeholder={formatMessage({ id: "products" })} value={selectedProductIds} mode="multiple">
                            {products.map(p => <Select.Option key={p.id} value={p.id}>{p.name}</Select.Option>)}
                        </Select>
                        <DatePicker.RangePicker allowClear={false} disabledDate={cur => !cur || cur.isSameOrAfter(moment(), "d")} onChange={handleDateRangeChange} value={[from, to]} />
                    </span>
                    <Button onClick={handleExport} loading={isLoading}>
                        <FormattedMessage id="incidentReportsPage.export" />
                    </Button>
                </div>
                <ErrorMessage error={incidentReportsError} />
                <Table
                    rowKey={record => `${record.date}-${record.inquiry.id}`}
                    loading={incidentReportsLoading}
                    columns={columns}
                    dataSource={incidentReports}
                />
            </InnerContainer>
        </IncidentReportsPageStyle>
    );
};
