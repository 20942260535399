import * as React from "react";
import { FunctionComponent } from "react";
import { CollectionGroupModalStyle } from "./collectionGroupModalStyle";
import { Modal, Form, Input, Select } from "antd";
import { CollectionGroupInput } from "@models/graphql";
import { FormComponentProps } from "antd/lib/form";
import { useFormatMessage } from "@utils/intlHook";
import { FormattedMessage } from "react-intl";
import * as uuid from "uuid";
import { useFormCollectionGroups } from "@components/patternOverviewTab/collectionGroupsProvider";
import { useGetMunicipalities } from "@graphql/hocs/hooks/useGetMunicipalities";
import { OptionProps } from "antd/lib/select";

export interface CollectionGroupModalProps extends FormComponentProps {
    visible: boolean;
    editGroup?: CollectionGroupInput | null;
    closeCallback(): void;
}

export const CollectionGroupModalComponent: FunctionComponent<CollectionGroupModalProps> = ({ visible, closeCallback, editGroup, form }) => {
    const formatMessage = useFormatMessage();
    const { addCollectionGroup, updateCollectionGroup } = useFormCollectionGroups();

    const { municipalities } = useGetMunicipalities();

    const handleClose = (): void => {
        closeCallback();
        form.resetFields();
    };

    const handleSubmit = (): void => {
        form.validateFields((errors, values) => {
            if (!errors) {
                if (!editGroup) {
                    addCollectionGroup({
                        id: uuid(),
                        municipalityIds: values.municipalityIds,
                        name: values.name
                    });
                } else {
                    updateCollectionGroup({
                        id: editGroup.id,
                        municipalityIds: values.municipalityIds,
                        name: values.name
                    });
                }

                handleClose();
            }
        });
    };

    const filterOptions = (searchVal: string, el: React.ReactElement<OptionProps>): boolean => (el.props.children as string).toLowerCase().includes(searchVal.toLowerCase());

    return (
        <Modal visible={visible} onCancel={handleClose} onOk={handleSubmit}>
            <CollectionGroupModalStyle>
                <Form>
                    <Form.Item label={<FormattedMessage id="collectionGroups.title" />}>
                        {form.getFieldDecorator("name", {
                            initialValue: editGroup ? editGroup.name : undefined,
                            rules: [
                                { required: true, message: formatMessage({ id: "requiredField" }) }
                            ]
                        })(<Input placeholder={formatMessage({ id: "collectionGroups.title" })} />)}
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="collectionGroups.municipalities" />}>
                        {form.getFieldDecorator("municipalityIds", {
                            initialValue: editGroup ? editGroup.municipalityIds : undefined,
                            rules: [
                                { required: true, message: formatMessage({ id: "requiredField" }) }
                            ]
                        })(<Select filterOption={filterOptions} mode="multiple" placeholder={formatMessage({ id: "collectionGroups.municipalities" })} >
                            {municipalities.map(m => <Select.Option key={m.id} value={m.id}>{m.name}</Select.Option>)}
                        </Select>)}
                    </Form.Item>
                </Form>
            </CollectionGroupModalStyle>
        </Modal>
    );
};

export const CollectionGroupModal = Form.create<CollectionGroupModalProps>()(CollectionGroupModalComponent);
