import styled from "styled-components";
import { WhitePage } from "@style/whitePage";

export const IncidentReportsPageStyle = styled(WhitePage)`
    h1 {
        font-size: 18px;
        margin: 15px 0 30px;
        font-weight: 600;
    }

    .filterBar {
        display: flex;
        margin-bottom: 30px;
        justify-content: space-between;

        .ant-select {
            width: 200px;
            margin-right: 15px;
        }
    }

    .product-item {
        font-size: 12px;
        
        &:not(:last-of-type) {
            margin-bottom: 5px;
        }

        em {
            font-style: normal;
            opacity: 0.6;
        }
    }



    small {
        font-size: 14px;
        opacity: 0.4;
    }
`;
