import { Button, Select, DatePicker, message, Tag, Form } from "antd";
import Table, { ColumnProps } from "antd/lib/table";
import * as moment from "moment";
import { Moment } from "moment";
import { FunctionComponent } from "react";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { useFormatMessage } from "@utils/intlHook";
import { DATE_FORMAT } from "@utils/consts";
import { InnerContainer } from "@style/innerContainer";
import { useGetProductNames } from "@graphql/hocs/hooks/useGetProductNames";
import { CsvDownloadModal } from "@components/planningPageHeader/csvDownloadModal/csvDownloadModal";
import { ErrorMessage } from "@components/errorMessage/errorMessage";

import { InquiriesReportsPageStyle } from "./inquiriesReportsPageStyle";
import { useGetOrderReports } from "@graphql/hocs/hooks/useGetOrderReports";
import { GetOrderReportsQuery, InquiryStatusType } from "@models/graphql";
import { useGetOrderReportsCsv } from "@graphql/hocs/hooks/useGetOrderReportsCsv";
import { parseError } from "@utils/parseError";

export interface InquiriesReportsPageProps {}

export const InquiriesReportsPage: FunctionComponent<InquiriesReportsPageProps> = (props: InquiriesReportsPageProps) => {
    const [selectedProductIds, setSelectedProductIds] = React.useState<string[]>([]);
    const [selectedStatusses, setSelectedStatusses] = React.useState<InquiryStatusType[]>([InquiryStatusType.Processed]);

    const [csvUrl, setCsvUrl] = React.useState<string>("");
    const [csvModalVisible, setcCvModalVisible] = React.useState(false);
    const [[from, to], setDateRange] = React.useState<[Moment, Moment]>([moment().startOf("month"), moment().subtract(1, "d")]);
    const formatMessage = useFormatMessage();

    const { getOrderReportsCsv, isLoading } = useGetOrderReportsCsv();

    const { orderReports, orderReportsError, orderReportsLoading } = useGetOrderReports({
        variables: {
            from: from.format(DATE_FORMAT),
            to: to.format(DATE_FORMAT),
            filter: {
                productIds: selectedProductIds,
                statusses: selectedStatusses
            }
        }
    });

    const { products } = useGetProductNames();

    const columns: ColumnProps<GetOrderReportsQuery["orderReports"][0]>[] = [
        {
            key: "date",
            title: formatMessage({ id: "orderReportsPage.date" }),
            width: 110,
            render(_, record) {
                return moment(record.inquiry.createdAt, DATE_FORMAT).format("DD/MM/YYYY");
            }
        },
        {
            key: "placement",
            width: 110,
            title: formatMessage({ id: "orderReportsPage.placement" }),
            render(_, record) {
                const latestStatus = record.inquiry.statusHistory.find((h) => h.type === InquiryStatusType.Planned || h.type === InquiryStatusType.WaitingForPayment);

                if (latestStatus && latestStatus.deliveryDate) {
                    return moment(latestStatus.deliveryDate).format("DD/MM/YYYY");
                } else if (record.inquiry.deliveryDate) {
                    return moment(record.inquiry.deliveryDate).format("DD/MM/YYYY");
                }
            }
        },
        {
            key: "changedPlacement",
            width: 110,
            title: formatMessage({ id: "orderReportsPage.changedPlacement" }),
            render(_, record) {
                const differentStatus = record.inquiry.statusHistory.find(
                    (h) => h.type === (InquiryStatusType.Planned || h.type === InquiryStatusType.WaitingForPayment) && h.deliveryDate !== record.inquiry.deliveryDate
                );

                if (differentStatus && differentStatus.deliveryDate) {
                    return moment(differentStatus.deliveryDate).format("DD/MM/YYYY");
                }
            }
        },
        {
            key: "pickup",
            width: 110,
            title: formatMessage({ id: "orderReportsPage.pickup" }),
            render(_, record) {
                const latestStatus = record.inquiry.statusHistory.find((h) => h.type === InquiryStatusType.Planned || h.type === InquiryStatusType.WaitingForPayment);

                if (latestStatus && latestStatus.pickupDate) {
                    return moment(latestStatus.pickupDate).format("DD/MM/YYYY");
                } else if (record.inquiry.pickupDate) {
                    return moment(record.inquiry.pickupDate).format("DD/MM/YYYY");
                }
            }
        },
        {
            key: "changedPickup",
            width: 110,
            title: formatMessage({ id: "orderReportsPage.changedPickup" }),
            render(_, record) {
                const differentStatus = record.inquiry.statusHistory.find(
                    (h) => (h.type === InquiryStatusType.Planned || h.type === InquiryStatusType.WaitingForPayment) && h.pickupDate !== record.inquiry.pickupDate
                );

                if (differentStatus && differentStatus.pickupDate) {
                    return moment(differentStatus.pickupDate).format("DD/MM/YYYY");
                }
            }
        },
        {
            key: "orderType",
            title: formatMessage({ id: "orderReportsPage.orderType" }),
            // width: 100,
            render(_, record) {
                return record.inquiry.digibeet ? "Digibeet" : record.inquiry.paymentNumber ? "Online" : "Manueel";
            }
        },
        {
            key: "municipalityPickupAddress",
            title: formatMessage({ id: "orderReportsPage.municipalityPickupAddress" }),
            render(_, record) {
                return record.inquiry.pickupAddress.municipality ? record.inquiry.pickupAddress.municipality.name : "";
            }
        },
        {
            key: "municipalityApplicant",
            title: formatMessage({ id: "orderReportsPage.municipalityApplicant" }),
            render(_, record) {
                return record.inquiry.applicant.address ? record.inquiry.applicant.address.city : "";
            }
        },
        {
            key: "currentStatus",
            title: formatMessage({ id: "orderReportsPage.inquiryStatus" }),
            render(_, record) {
                return <FormattedMessage id={`inquiryStatusType.${record.inquiry.currentStatus}`} />;
            }
        },
        {
            key: "incident",
            title: formatMessage({ id: "orderReportsPage.incident" }),
            render(_, record) {
                return record.inquiry.statusHistory.filter((s) => s.type === InquiryStatusType.Incident).length === 0 ? (
                    <FormattedMessage tagName="small" id="no" />
                ) : (
                    <FormattedMessage id="yes" />
                );
            }
        },
        {
            key: "applicantId",
            title: formatMessage({ id: "orderReportsPage.applicantId" }),
            render(_, record) {
                return record.inquiry.applicant.id;
            }
        },
        {
            key: "applicantType",
            title: formatMessage({ id: "orderReportsPage.applicantType" }),
            render(_, record) {
                return <FormattedMessage id={`applicantType.${record.inquiry.applicant.type}`} />;
            }
        },
        {
            key: "product",
            title: formatMessage({ id: "orderReportsPage.product" }),
            render(_, record) {
                return record.inquiry.products.map((product) => (
                    <div className="product-item" key={product.product.id}>
                        {product.amount} <em> x {product.product.name}</em>
                    </div>
                ));
            }
        },
        {
            key: "orderNumber",
            title: formatMessage({ id: "orderReportsPage.orderNumber" }),
            render: (_, record) => <Tag>{record.inquiry.orderNumber}</Tag>,
            fixed: "right"
        }
    ];

    const handleStatusChange = (val: InquiryStatusType[]): void => {
        setSelectedStatusses(val);
    };

    const handleProductChange = (val: string[]): void => {
        setSelectedProductIds(val);
    };

    const handleDateRangeChange = (val: [Moment, Moment]): void => {
        setDateRange(val);
    };

    const handleExport = async (): Promise<void> => {
        const { data, error } = await getOrderReportsCsv({
            from: from.format(DATE_FORMAT),
            to: to.format(DATE_FORMAT),
            filter: {
                productIds: selectedProductIds,
                statusses: selectedStatusses
            }
        });

        if (data) {
            setcCvModalVisible(true);
            setCsvUrl(data.orderReportsCSV.url);
        }

        if (error) {
            message.error(parseError(error, formatMessage));
        }
    };
    return (
        <InquiriesReportsPageStyle>
            <CsvDownloadModal
                fileName={`Inquiry Reports ${from.format("DD-MM-YYYY")} - ${to.format("DD-MM-YYYY")}`}
                url={csvUrl}
                visible={csvModalVisible}
                closeCallback={() => setcCvModalVisible(false)}
            />
            <InnerContainer>
                <FormattedMessage id="orderReportsPage.title" tagName="h1" />
                <div className="filterBar">
                    <span>
                        <Select
                            onChange={handleStatusChange}
                            placeholder={formatMessage({ id: "status" })}
                            value={selectedStatusses}
                            mode="multiple"
                        >
                            {Object.keys(InquiryStatusType).map((key) => (
                                <Select.Option key={key} value={InquiryStatusType[key]}>
                                    <FormattedMessage id={`inquiryStatusType.${InquiryStatusType[key]}`} />
                                </Select.Option>
                            ))}
                        </Select>

                        <Select
                            onChange={handleProductChange}
                            placeholder={formatMessage({ id: "products" })}
                            value={selectedProductIds}
                            mode="multiple"
                        >
                            {products.map((p) => (
                                <Select.Option key={p.id} value={p.id}>
                                    {p.name}
                                </Select.Option>
                            ))}
                        </Select>

                        <Form.Item label={<FormattedMessage id="orderReportsPage.requestDate" />}>
                            <DatePicker.RangePicker
                                allowClear={false}
                                disabledDate={(cur) => !cur || cur.isSameOrAfter(moment(), "d")}
                                onChange={handleDateRangeChange}
                                value={[from, to]}
                            />
                        </Form.Item>
                    </span>
                    <Button onClick={handleExport} loading={isLoading}>
                        <FormattedMessage id="orderReportsPage.export" />
                    </Button>
                </div>
                <ErrorMessage error={orderReportsError} />
                <Table
                    rowKey="id"
                    loading={orderReportsLoading}
                    columns={columns}
                    dataSource={orderReports}
                    scroll={{ x: "max-content" }}
                />
            </InnerContainer>
        </InquiriesReportsPageStyle>
    );
};
