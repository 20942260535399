import styled from "styled-components";
import { InnerContainer } from "@style/innerContainer";
import { COLORS } from "@style/colors";

export const ReportsPageStyle = styled.div`


    & > ${InnerContainer} {
        margin-top: 30px;
    }

    h1 {
        margin: 20px auto;
        max-width: 1520px;
        padding: 20px 20px 0 20px;
        font-size: 20px;
        font-weight: bold;
    }

    a {
        background: #FFFFFF;
        box-shadow: 0 1px 1px 0 rgba(0,0,0,0.15);
        border-radius: 4px;
        margin-bottom: 30px;
        overflow: hidden;
        display: flex;
        width: 100%;
        height: 120px;
        align-items: center;
        padding: 25px;
        margin-right: 20px;
        transition: 0.3s;
        cursor: pointer;
        color: ${COLORS.text};
        font-weight: 600;

        &:hover {
            box-shadow: 0 3px 5px 0 rgba(0,0,0,0.15);
        }

        .icon {
            color: ${COLORS.primary};
            width: 80px;
            height: 80px;
            display: block;
            border-radius: 100%;
            background-color: #F8F8F8;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 22px;
            margin-right: 25px;
        }
    }

`;
