/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { CreateProcessorMutation, CreateProcessorMutationVariables } from "@models/graphql";

const defaultCreateProcessorOptions: MutationHookOptions<CreateProcessorMutation, CreateProcessorMutationVariables> = {};

export const useCreateProcessor = (options?: MutationHookOptions<CreateProcessorMutation, CreateProcessorMutationVariables>) => {
    const [mutation, { data, loading, error }] = useMutation<CreateProcessorMutation, CreateProcessorMutationVariables>(
        GraphQLDocuments.createProcessor
    );

    const createProcessor = async (variables: CreateProcessorMutationVariables) => {
        try {
            const response = await mutation({
                ...defaultCreateProcessorOptions,
                ...options,
                variables
            });

            return {
                error: response.errors,
                data: response.data
            };
        } catch (err) {
            return {
                error
            };
        }
    };

    return {
        isLoading: loading,
        error,
        data,
        createProcessor
    };
};
