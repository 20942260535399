/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { AddIncidentCommentMutation, AddIncidentCommentMutationVariables } from "@models/graphql";
import { useFormatMessage } from "@utils/intlHook";
import { parseError } from "@utils/parseError";

const defaultAddIncidentCommentOptions: MutationHookOptions<AddIncidentCommentMutation, AddIncidentCommentMutationVariables> = {};

export const useAddIncidentComment = (options?: MutationHookOptions<AddIncidentCommentMutation, AddIncidentCommentMutationVariables>) => {
    const [mutation, { data, loading, error }] = useMutation<AddIncidentCommentMutation, AddIncidentCommentMutationVariables>(
        GraphQLDocuments.addIncidentComment
    );
    const formatMessage = useFormatMessage();

    const addIncidentComment = async (variables: AddIncidentCommentMutationVariables) => {
        try {
            const response = await mutation({
                ...defaultAddIncidentCommentOptions,
                ...options,
                variables
            });

            return {
                error: undefined,
                response
            };
        } catch (err) {
            console.error(error);

            return {
                error,
                response: undefined
            };
        }
    };

    return {
        isLoading: loading,
        error: parseError(error, formatMessage),
        data,
        addIncidentComment: addIncidentComment
    };
};
