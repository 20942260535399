import { CollectionRoundBlock } from "@components/planningWeekOverview/collectionRoundBlock/collectionRoundBlock";
import { GetCollectionRoundsQuery } from "@models/graphql";
import "antd/es/collapse/style/index";
import { Moment } from "moment";
import * as React from "react";
import { FunctionComponent } from "react";
import { WeekBlockStyle } from "./weekBlockStyle";

export interface WeekBlockProps {
    date: Moment;
    collectionRounds: GetCollectionRoundsQuery["collectionRounds"];
}

export const WeekBlock: FunctionComponent<WeekBlockProps> = ({ date, collectionRounds }) =>
    <WeekBlockStyle>
        <main>
            {collectionRounds.map(round => <CollectionRoundBlock key={round.id} collectionRound={round} />)}
        </main>
    </WeekBlockStyle>
    ;
