/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import {
    GetNotificationConfigurationsQuery,
    GetNotificationConfigurationsQueryVariables
} from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

export const useGetNotificationConfigurations = (options?: QueryHookOptions<
        GetNotificationConfigurationsQuery,
        GetNotificationConfigurationsQueryVariables
    >) => {
    const { data, error, loading } = useQuery<
        GetNotificationConfigurationsQuery,
        GetNotificationConfigurationsQueryVariables
    >(GraphQLDocuments.getNotificationConfigurations, options);

    return {
        notificationConfigurations:
            data && data.notificationConfigurations.items
                ? data.notificationConfigurations.items
                : [],
        notificationConfigurationsLoading: loading,
        notificationConfigurationsError: error
    };
};
