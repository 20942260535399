import styled from "styled-components";
import { COLORS } from "@style/colors";

export const SearchBarStyle = styled.div`
    padding: 25px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${COLORS.textTransparent(0.1)};

    .icon {
        margin-right: 15px;
    }

    input {
        border: none;
        outline: none;
        font-size: 14px;
        font-weight: 200;
        flex: 1;

        &::placeholder {
            opacity: 0.4;
        }
    }
`;
