import { useApolloClient } from "@apollo/react-hooks";
import { message, Modal } from "antd";
import * as moment from "moment";
import * as React from "react";
import { FunctionComponent } from "react";
import { DragDropContext, DraggableLocation, DragStart, DropResult } from "react-beautiful-dnd";

import { useQueryParameters } from "@utils/useQueryParameters";
import { parseError } from "@utils/parseError";
import { useFormatMessage } from "@utils/intlHook";
import { DATE_FORMAT } from "@utils/consts";
import {
    CollectionRoundFragment,
    CollectionRoundTaskType,
    DeliveryMethod,
    GetCollectionRoundsQuery,
    GetCollectionRoundsQueryVariables,
    GetInquiriesOverviewQuery,
    GetInquiriesOverviewQueryVariables,
    InquiryOverviewFragment,
    InquiryStatusType,
    Responsibility
} from "@models/graphql";
import { useUpdateInquiryCollectionDay } from "@graphql/hocs/hooks/useUpdateInquiryCollectionDay";
import { useGetCollectionRounds } from "@graphql/hocs/hooks/useGetCollectionRounds";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { PlanningWeekOverview } from "@components/planningWeekOverview/planningWeekOverview";
import { PlanningSideBar } from "@components/planningSideBar/planningSideBar";
import { usePlanningContext } from "@components/planningProvider/planningProvider";
import { PlanningPageHeader } from "@components/planningPageHeader/planningPageHeader";
import { InquiryDeliveryModal } from "@components/inquiryDeliveryModal/inquiryDeliveryModal";
import { Error as ErrorMessage } from "@components/error/error";

import { PlanningPageStyle } from "./planningPageStyle";
import { ModalFuncProps } from "antd/lib/modal";
import { useUpdateInquiryTaskCollectionDay } from "@graphql/hocs/hooks/useUpdateInquiryTaskCollectionDay";
import { NormalizedCache } from "apollo-cache-inmemory";

export interface PlanningPageProps { }

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const planningParams = () =>
    useQueryParameters<{
        fromDate: string;
        toDate: string;
        search: string;
    }>("/planning{?fromDate,toDate,search}", {
        fromDate: moment()
            .startOf("w")
            .format(DATE_FORMAT),
        toDate: moment()
            .endOf("w")
            .format(DATE_FORMAT),
        search: ""
    });

export const PlanningPage: FunctionComponent<PlanningPageProps> = () => {
    const { parameters } = planningParams();
    const {
        inquiriesSaving,
        setInquiriesSaving,
        setInquiriesDone,
        inquiriesDone,
        setInquiriesError,
        inquiriesError,
        setDisabledCollectionRounds,
        setIsDragging
    } = usePlanningContext();
    const { updateInquiryCollectionDay } = useUpdateInquiryCollectionDay();
    const { updateInquiryTaskCollectionDay } = useUpdateInquiryTaskCollectionDay({
        awaitRefetchQueries: true,
        update(cache) {
            const data: NormalizedCache = (cache as any).data;

            Object.keys((data as any).data).forEach(key => key.match(/^TruckSchedule/) && data.delete(key));
            Object.keys((data as any).data).forEach(key => key.match(/^\$ROOT_QUERY\.TruckSchedule/) && data.delete(key));
        }
    });

    const formatMessage = useFormatMessage();
    const client = useApolloClient();
    const [forceRerender, setForceRerender] = React.useState(false);

    const from = parameters.fromDate;
    const to = parameters.toDate;

    const { collectionRounds, collectionRoundsLoading, collectionRoundsError } = useGetCollectionRounds({
        variables: {
            from,
            to
        }
    });

    const hideInquiries: string[] = [];
    collectionRounds.forEach(round => round.tasks.forEach(task => {
        if (task.inquiry.currentStatus !== InquiryStatusType.ToBeRescheduled) {
            hideInquiries.push(task.inquiry.id);
        }
    }));

    function addTaskToRound(
        collectionRoundsData: GetCollectionRoundsQuery,
        inquiryId: string,
        destination: DraggableLocation,
        taskType: CollectionRoundTaskType
    ): void {
        const inquiry = client.readFragment<InquiryOverviewFragment>({ fragment: GraphQLDocuments.inquiryOverview, id: `Inquiry:${inquiryId}` });

        if (!inquiry) {
            return;
        }

        if (
            destination.droppableId === Responsibility.Civilian ||
            destination.droppableId === Responsibility.PickupService ||
            destination.droppableId === "OTHER"
        ) {
            const data = client.readQuery<GetInquiriesOverviewQuery, GetInquiriesOverviewQueryVariables>({
                query: GraphQLDocuments.getInquiriesOverview,
                variables: {
                    filter: {
                        statusses: [InquiryStatusType.ToBeRescheduled]
                    }
                }
            });

            // Remove inquiry from the 'ToBeRescheduled' list
            if (data && data.inquiries) {
                data.inquiries.items.splice(destination.index, 0, inquiry);

                client.writeQuery<GetInquiriesOverviewQuery>({
                    data,
                    query: GraphQLDocuments.getInquiriesOverview,
                    variables: {
                        filter: {
                            statusses: [InquiryStatusType.ToBeRescheduled]
                        }
                    }
                });
            }

            return;
        }

        if (destination.droppableId === "SEARCH") {
            const data = client.readQuery<GetInquiriesOverviewQuery, GetInquiriesOverviewQueryVariables>({
                query: GraphQLDocuments.getInquiriesOverview,
                variables: {
                    filter: {
                        search: parameters.search
                    }
                }
            });

            if (data && data.inquiries) {
                data.inquiries.items.splice(destination.index, 0, inquiry);

                client.writeQuery<GetInquiriesOverviewQuery>({
                    data,
                    query: GraphQLDocuments.getInquiriesOverview,
                    variables: {
                        filter: {
                            search: parameters.search
                        }
                    }
                });
            }

            return;
        }

        const destinationIndex = collectionRoundsData.collectionRounds.findIndex(round => round.id === destination.droppableId);

        if (destinationIndex > -1) {
            collectionRoundsData.collectionRounds[destinationIndex].tasks.splice(destination.index, 0, {
                __typename: "CollectionRoundTask",
                cargo: taskType === CollectionRoundTaskType.Delivery ? 0 : inquiry.products.reduce((sum, obj) => sum + (obj.product.accessory ? 0 : obj.amount), 0),
                inquiry,
                type: taskType
            });
        }
    }

    const removeTaskFromRounds = (collectionRoundsData: GetCollectionRoundsQuery, inquiryId: string, taskType: CollectionRoundTaskType): void => {
        collectionRoundsData.collectionRounds = collectionRoundsData.collectionRounds.map(round => {
            round.tasks = round.tasks.filter(task => !(task.inquiry.id === inquiryId && task.type === taskType));

            return round;
        });
    };

    const resetTask = (
        inquiryId: string,
        source: DraggableLocation,
        collectionRoundsData: GetCollectionRoundsQuery,
        taskType: CollectionRoundTaskType
    ): void => {
        removeTaskFromRounds(collectionRoundsData, inquiryId, taskType);
        addTaskToRound(collectionRoundsData, inquiryId, source, taskType);
    };

    const handleSave = async (vals: {
        inquiryId: string;
        destinationId: string;
        source: DraggableLocation;
        collectionRoundsData: GetCollectionRoundsQuery;
        taskType?: CollectionRoundTaskType;
    }): Promise<void> => {

        const { inquiryId, destinationId, source, collectionRoundsData, taskType } = vals;
        const inquiry = client.readFragment<InquiryOverviewFragment>({ fragment: GraphQLDocuments.inquiryOverview, id: `Inquiry:${inquiryId}` });

        if (!inquiry) {
            return;
        }

        const hasDeliverables = inquiry.products.some(prod => prod.product.deliveryMethod === DeliveryMethod.Delivery);
        const isAlreadyDeliverd = inquiry.statusHistory.find(h => h.type === InquiryStatusType.Delivered);

        setInquiriesSaving([...inquiriesSaving, inquiryId]);

        // This flow is used when you drag an inquiry from the sidebar and it has deliverables
        if (!taskType && hasDeliverables && !isAlreadyDeliverd) {
            try {
                let deliveryDayId: string | null = null;
                let updater: ((newConfig: ModalFuncProps) => void) | null = null;
                const setDeliveryDayId = (id: string): void => {
                    deliveryDayId = id;

                    if (updater && id) {
                        updater({
                            okButtonProps: {
                                disabled: false
                            }
                        });
                    }
                };

                await new Promise((resolve, reject) => {
                    const collectionRound = client.readFragment<CollectionRoundFragment>({
                        fragment: GraphQLDocuments.collectionRound,
                        id: `CollectionRound:${destinationId}`,
                        fragmentName: "collectionRound"
                    });

                    if (!inquiry || !collectionRound) {
                        reject(new Error("Geen ophaling geselecteerd"));

                        return;
                    }

                    const modal = Modal.confirm({
                        content:
                            <InquiryDeliveryModal
                                pickupDayId={destinationId}
                                inquiry={inquiry}
                                pickupCollectionRound={collectionRound}
                                formatMessage={formatMessage}
                                client={client}
                                setDeliveryDayId={setDeliveryDayId}
                            />
                        ,
                        onOk() {
                            resolve(void 0);
                        },
                        onCancel() {
                            reject(new Error("Geen ophaling geselecteerd"));
                        },
                        okButtonProps: {
                            disabled: true
                        },
                        icon: null,
                        className: "inquiryDeliveryModal",
                        width: 585
                    });

                    updater = modal.update;
                });

                if (!destinationId) {
                    throw new Error("De leveringsdatum kon niet worden bepaald");
                }

                const { error } = await updateInquiryCollectionDay({
                    inquiryId,
                    pickupDayId: destinationId,
                    deliveryDayId
                });

                if (!error) {
                    setInquiriesDone([...inquiriesDone, inquiryId]);

                    // Add the delivery task to the planning page aswell.
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    addTaskToRound(collectionRoundsData, inquiryId, { droppableId: deliveryDayId!, index: 0 }, CollectionRoundTaskType.Delivery);

                    client.writeQuery<GetCollectionRoundsQuery, GetCollectionRoundsQueryVariables>({
                        data: collectionRoundsData,
                        query: GraphQLDocuments.getCollectionRounds,
                        variables: { from, to }
                    });
                } else {
                    setInquiriesError([...inquiriesError, inquiryId]);
                    message.error(parseError(error, formatMessage));

                    resetTask(inquiryId, source, collectionRoundsData, CollectionRoundTaskType.Pickup);
                }
            } catch (err) {
                message.error("Je moet een levermoment kiezen om deze aanvraag in te plannen.");

                resetTask(inquiryId, source, collectionRoundsData, CollectionRoundTaskType.Pickup);

                client.writeQuery<GetCollectionRoundsQuery, GetCollectionRoundsQueryVariables>({
                    data: collectionRoundsData,
                    query: GraphQLDocuments.getCollectionRounds,
                    variables: { from, to }
                });

                // For some reason apollo refused to update after thise write query, changing the state after this fixes it.
                // Hacky but it works.
                setForceRerender(!forceRerender);
            }
        }

        const { error } = await updateInquiryTaskCollectionDay({
            collectionDay: destinationId,
            inquiryId,
            type: taskType || CollectionRoundTaskType.Pickup
        });

        if (!error) {
            setInquiriesDone([...inquiriesDone, inquiryId]);
        } else {
            setInquiriesError([...inquiriesError, inquiryId]);
            message.error(parseError(error, formatMessage));

            resetTask(inquiryId, source, collectionRoundsData, CollectionRoundTaskType.Pickup);
        }

        setInquiriesSaving(inquiriesSaving.filter(id => id !== inquiryId));
    };

    const handleOnDragEnd = (result: DropResult): void => {
        setIsDragging(false);
        setDisabledCollectionRounds([]);
        const { destination, draggableId, source } = result;
        let inquiryId = draggableId;
        let taskType: CollectionRoundTaskType | undefined;

        if (source.droppableId === "SEARCH") {
            inquiryId = draggableId.replace("SEARCH-", "");
        }

        if (draggableId.match(/^(PICKUP|DELIVERY).*$/)) {
            inquiryId = draggableId.split("-")[1];
            taskType = draggableId.split("-")[0] as CollectionRoundTaskType;
        }

        if (destination) {
            const collectionRoundsData = client.readQuery<GetCollectionRoundsQuery, GetCollectionRoundsQueryVariables>({
                query: GraphQLDocuments.getCollectionRounds,
                variables: { from, to }
            });

            if (!collectionRoundsData || !collectionRoundsData.collectionRounds) {
                return;
            }

            // Handle to be rescheduled requests
            if (source.droppableId === Responsibility.Civilian || source.droppableId === Responsibility.PickupService) {
                const data = client.readQuery<GetInquiriesOverviewQuery, GetInquiriesOverviewQueryVariables>({
                    query: GraphQLDocuments.getInquiriesOverview,
                    variables: {
                        filter: {
                            statusses: [InquiryStatusType.ToBeRescheduled]
                        }
                    }
                });

                // Remove inquiry from the 'ToBeRescheduled' list
                if (data && data.inquiries) {
                    data.inquiries.items = data.inquiries.items.filter(inq => inq.id !== inquiryId);
                    data.inquiries.count -= 1;

                    client.writeQuery<GetInquiriesOverviewQuery>({
                        data,
                        query: GraphQLDocuments.getInquiriesOverview,
                        variables: {
                            filter: {
                                statusses: [InquiryStatusType.ToBeRescheduled]
                            }
                        }
                    });
                }
            } else if (source.droppableId === "SEARCH") {
                const data = client.readQuery<GetInquiriesOverviewQuery, GetInquiriesOverviewQueryVariables>({
                    query: GraphQLDocuments.getInquiriesOverview,
                    variables: {
                        filter: {
                            search: parameters.search
                        }
                    }
                });

                // Remove inquiry from search list
                if (data && data.inquiries) {
                    data.inquiries.items = data.inquiries.items.filter(f => f.id !== inquiryId);

                    client.writeQuery<GetInquiriesOverviewQuery>({
                        data,
                        query: GraphQLDocuments.getInquiriesOverview,
                        variables: {
                            filter: {
                                search: parameters.search
                            }
                        }
                    });
                }
            } else {
                // Remove the inquiry from the source collection round
                removeTaskFromRounds(collectionRoundsData, inquiryId, taskType || CollectionRoundTaskType.Pickup);
            }

            // Add the inquiry to the destination collection round
            addTaskToRound(collectionRoundsData, inquiryId, destination, taskType || CollectionRoundTaskType.Pickup);

            // Persist the collectionrounds to the Apollo Cache
            client.writeQuery<GetCollectionRoundsQuery, GetCollectionRoundsQueryVariables>({
                data: collectionRoundsData,
                query: GraphQLDocuments.getCollectionRounds,
                variables: { from, to }
            });

            if (destination.droppableId !== source.droppableId) {
                handleSave({
                    inquiryId,
                    destinationId: destination.droppableId,
                    source,
                    collectionRoundsData,
                    taskType
                });
            }
        }
    };

    const handleOnDragStart = (initial: DragStart): void => {
        setIsDragging(true);

        let inquiryId = initial.draggableId;

        if (initial.draggableId.match(/^(PICKUP|DELIVERY).*$/)) {
            inquiryId = initial.draggableId.split("-")[1];
        }

        const inquiry = client.readFragment<InquiryOverviewFragment>({
            fragment: GraphQLDocuments.inquiryOverview,
            id: `Inquiry:${inquiryId}`
        });
        const collectionRoundsData = client.readQuery<GetCollectionRoundsQuery, GetCollectionRoundsQueryVariables>({
            query: GraphQLDocuments.getCollectionRounds,
            variables: { from, to }
        });

        if (inquiry && collectionRoundsData) {
            setDisabledCollectionRounds(collectionRoundsData.collectionRounds
                .filter(round => !inquiry.products.every(prod => prod.product.accessory || !round.products || round.products.some(rProd => rProd.id === prod.product.id)))
                .map(round => round.id));
        }
    };

    return (
        <PlanningPageStyle>
            <PlanningPageHeader />
            <DragDropContext onDragStart={handleOnDragStart} onDragEnd={handleOnDragEnd}>
                <main>
                    <PlanningSideBar hideInquiries={hideInquiries} />
                    {collectionRoundsError ?
                        <ErrorMessage errorMessage={parseError(collectionRoundsError, formatMessage)} originalError={collectionRoundsError} />
                        :
                        <PlanningWeekOverview collectionRoundsLoading={collectionRoundsLoading} collectionRounds={collectionRounds} />
                    }
                </main>
            </DragDropContext>
        </PlanningPageStyle>
    );
};
