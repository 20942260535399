
import styled from "styled-components";
import { COLORS } from "@style/colors";

export const InquiryIncidentStyle = styled.span`
    header {
        margin: 15px 50px;
        padding: 15px;
        border-radius: 5px;
        background-color: ${ COLORS.errorLight};
        border: 1px solid ${ COLORS.error};
        display: flex;
        align-items: center;
        justify-content: space-between;


    }
    .flexContainer {
        padding: 5px 50px;
        display: flex;

        & > div {
            flex: 1;
        }
    }
`;
