import { QueryHookOptions, useQuery } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { GetInquiriesCountQuery, GetInquiriesCountQueryVariables } from "@models/graphql";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useGetInquiriesCount = (options?: QueryHookOptions<GetInquiriesCountQuery, GetInquiriesCountQueryVariables>) => {
    const { data, error, loading } = useQuery<GetInquiriesCountQuery, GetInquiriesCountQueryVariables>(GraphQLDocuments.getInquiriesCount, options);

    return {
        inquiriesCount: data && data.inquiries ? data.inquiries.count : 0,
        inquiriesCountLoading: loading,
        inquiriesCountError: error
    };
};
