import { COLORS } from "@style/colors";
import styled from "styled-components";

export const CapacityTableStyle = styled.div`
    display: flex;
    position: relative;
    margin-bottom: 50px;

    .columns {
        display: flex;
        overflow-x: auto;
        position: relative;
    }

    &.leftShadow {
        &::before {
            opacity: 1;
            width: 10px;
        }
    }

    &.rightShadow {
        &::after {
            opacity: 1;
            width: 10px;
        }
    }

    &::before {
        content: "";
        display: block;
        position: absolute;
        z-index: 1000;
        left: 330px;
        top: 0;
        bottom: 0;
        width: 0;
        opacity: 0;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.33) 0%, rgba(0, 0, 0, 0.33) 17%, rgba(0, 0, 0, 0) 100%);
        transition: 0.3s;
    }

    &::after {
        content: "";
        display: block;
        position: absolute;
        z-index: 1000;
        right: 0;
        top: 0;
        bottom: 0;
        width: 0;
        opacity: 0;
        background: linear-gradient(to left, rgba(0, 0, 0, 0.33) 0%, rgba(0, 0, 0, 0.33) 17%, rgba(0, 0, 0, 0) 100%);
        transition: 0.3s;
    }
`;

export const FirstColumn = styled.div`
    width: 330px;
    flex-shrink: 0;
    z-index: 2;

    em {
        height: 45px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        font-size: 12px;
    }

    h1,
    h2,
    h3,
    h4 {
        margin-bottom: 0;
        height: 45px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 20px;
        margin-bottom: 5px;
        font-size: 14px;
    }

    h2,
    h3,
    h4 {
        span {
            z-index: 1;
        }
        &::after {
            content: "";
            position: absolute;
            right: 0;
            left: 0;
            height: 45px;
            background-color: ${COLORS.lightGrey};
            border-radius: 4px;
        }
    }

    h1 {
        font-weight: 600;

        border-radius: 4px 4px 0 0;
    }

    h2 {
        background-color: ${COLORS.primary};
        color: white;
        padding: 0 20px;
        font-size: 12px;

        border-radius: 4px 0 0 4px;

        &::after {
            background-color: ${COLORS.primary};
        }
    }

    h3 {
        font-size: 12px;
    }

    h4 {
        color: white;

        &::after {
            background-color: ${COLORS.secondary};
        }
    }
`;

export const TruckBlock = styled.div`
    margin-bottom: 10px;
`;

export const InputContainer = styled.div`
    display: flex;
    height: 45px;
    width: 100%;
    align-items: center;
    padding: 5px;

    .placeholder {
        width: 100%;
        height: 100%;
        background: ${COLORS.lightGrey};
        border-radius: 4px;
    }

    .ant-input-number {
        width: 100%;
        height: 100%;
        .ant-input-number-handler-wrap {
            opacity: 1;
        }
    }

    .ant-select {
        width: 100%;
    }
`;

export const ProductBlock = styled.div`
    & > .placeholder {
        width: 100%;
        height: 32px;
        display: block;
        background: ${COLORS.lightGrey};
        border-radius: 4px;
        margin: 10px 0 8px 0;
    }
`;

export const HeaderPlaceHolder = styled.div`
    height: 45px;
    margin-bottom: 5px;
`;

interface EmptyBlockPlaceHolderProps {
    long: boolean;
}

export const EmptyBlockPlaceHolder = styled.div`
    height: ${(props: EmptyBlockPlaceHolderProps) => props.long ? "230px" : "185px"};
    position: relative;
    margin-bottom: 10px;

    &::before, &::after {
        content: "";
        border-radius: 4px;
        display: block;
        position: absolute;
        background: ${COLORS.lightGrey};
        top: ${(props: EmptyBlockPlaceHolderProps) => props.long ? "100px" : "55px"};
        left: 5px;
        right: 5px;
        bottom: 5px;
    }

    &::after {
        top: 5px;
        bottom: 195px;
        display: ${(props: EmptyBlockPlaceHolderProps) => props.long ? "block" : "none"};
    }
`;
