import { Form, InputNumber, Modal, Select } from "antd";
import { FormComponentProps } from "antd/lib/form";
import * as moment from "moment";
import { FunctionComponent } from "react";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { useFormatMessage } from "@utils/intlHook";
import { DATE_FORMAT } from "@utils/consts";
import { planningParams } from "@pages/planningPage/planningPage";
import { GetCollectionRoundsQuery, GetCollectionRoundsQueryVariables } from "@models/graphql";
import { useGetTrucks } from "@graphql/hocs/hooks/useGetTrucks";
import { useGetProcessors } from "@graphql/hocs/hooks/useGetProcessors";
import { usecreateStandAloneCollectionRound } from "@graphql/hocs/hooks/useCreateStandaloneCollectionRound";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { ErrorMessage } from "@components/errorMessage/errorMessage";

import { CreateStandaloneTruckModalStyle } from "./createStandaloneTruckModalStyle";

export interface CreateStandaloneTruckModalProps extends FormComponentProps {
    visible: boolean;
    date: moment.Moment;
    closeCallback(): void;
}

interface FormValues {
    processorId: string;
    truckId: string;
    itemsPerCargo: number;
}

export const CreateStandaloneTruckModalComponent: FunctionComponent<CreateStandaloneTruckModalProps> = ({ form, visible, date, closeCallback }) => {
    const { trucks } = useGetTrucks();
    const { processors } = useGetProcessors();
    const formatMessage = useFormatMessage();
    const { parameters } = planningParams();
    const from = parameters.fromDate;
    const to = parameters.toDate;

    const { createStandAloneCollectionRound, isLoading, error: createError } = usecreateStandAloneCollectionRound({
        update(cache, response) {
            let collectionRoundsData = cache.readQuery<GetCollectionRoundsQuery, GetCollectionRoundsQueryVariables>({
                query: GraphQLDocuments.getCollectionRounds,
                variables: { from, to }
            });

            if (collectionRoundsData && response.data) {
                collectionRoundsData = {
                    collectionRounds: [...collectionRoundsData.collectionRounds, response.data.createStandAloneCollectionRound]
                };
            }

            cache.writeQuery({
                query: GraphQLDocuments.getCollectionRounds,
                variables: { from, to },
                data: collectionRoundsData
            });
        }
    });

    const handleClose = (): void => {
        form.resetFields();
        closeCallback();
    };

    const handleSubmit = (): void => {
        form.validateFields(async (errors, values: FormValues) => {
            if (errors) {
                return;
            }

            const { error } = await createStandAloneCollectionRound({
                date: date.format(DATE_FORMAT),
                processorId: values.processorId,
                truckId: values.truckId,
                itemsPerCargo: values.itemsPerCargo
            });

            if (!error) {
                handleClose();
            }
        });
    };

    return (
        <Modal
            visible={visible}
            okButtonProps={{
                loading: isLoading
            }}
            title={formatMessage({ id: "collectionRound.extraTruck" })}
            onOk={handleSubmit}
            okText={formatMessage({ id: "save" })}
            cancelText={formatMessage({ id: "cancel" })}
            onCancel={handleClose}
        >
            <CreateStandaloneTruckModalStyle>
                <Form>
                    <Form.Item label={<FormattedMessage id="processor" />}>
                        {form.getFieldDecorator("processorId", {
                            rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }]
                        })(<Select placeholder={formatMessage({ id: "processor" })}>
                            {processors.map(processor =>
                                <Select.Option key={processor.id} value={processor.id}>
                                    {processor.name}
                                </Select.Option>)}
                        </Select>)}
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="truck" />}>
                        {form.getFieldDecorator("truckId", {
                            rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }]
                        })(<Select placeholder={formatMessage({ id: "truck" })}>
                            {trucks.map(truck =>
                                <Select.Option key={truck.id} value={truck.id}>
                                    {truck.name}
                                </Select.Option>)}
                        </Select>)}
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="itemsPerCargo" />}>
                        {form.getFieldDecorator("itemsPerCargo", {
                            rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }]
                        })(<InputNumber min={1} precision={0} placeholder={formatMessage({ id: "itemsPerCargo" })} />)}
                    </Form.Item>
                </Form>
                <ErrorMessage error={createError} />
            </CreateStandaloneTruckModalStyle>
        </Modal>
    );
};

export const CreateStandaloneTruckModal = Form.create<CreateStandaloneTruckModalProps>()(CreateStandaloneTruckModalComponent);
