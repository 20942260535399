/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { GetIncidentReasonsQueryVariables, GetIncidentReasonsQuery } from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";
import { useFormatMessage } from "@utils/intlHook";
import { parseError } from "@utils/parseError";

export const useGetIncidentReasons = (options?: QueryHookOptions<GetIncidentReasonsQuery, GetIncidentReasonsQueryVariables>) => {
    const { data, error, loading } = useQuery<GetIncidentReasonsQuery, GetIncidentReasonsQueryVariables>(GraphQLDocuments.getIncidentReasons, options);
    const formatMessage = useFormatMessage();

    return {
        incidentReasons: data && data.incidentReasons && data.incidentReasons.items ? data.incidentReasons.items : [],
        incidentReasonsLoading: loading,
        incidentReasonsError: parseError(error, formatMessage)
    };
};
