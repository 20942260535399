import * as environment from "environment";
import { UserManagerSettings } from "oidc-client";

/* eslint-disable camelcase */
export const oidcConfiguration = (): UserManagerSettings => ({
    client_id: "c957d201-4c3c-49d7-b633-d3dd9afdf47c",
    redirect_uri: `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ""
    }/authentication/callback`,
    response_type: "id_token token",
    post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ""
    }`,
    scope: "api://c957d201-4c3c-49d7-b633-d3dd9afdf47c/AccessAsDispatcher openid profile",
    authority: "/auth",
    silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ""
    }/authentication/silent_callback`,
    automaticSilentRenew: false,
    loadUserInfo: false,
    metadata: environment.oidc.metadata
});
