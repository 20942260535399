import styled from "styled-components";
import { COLORS } from "@style/colors";

export const PatternCapacityStyle = styled.div``;

export const ToBeDeliveredBox = styled.div`
    display: flex;
    background: rgba(3, 168, 183, 0.1);
    border: 1px solid #00a0f1;
    border-radius: 4px;
    width: 100%;
    padding: 25px;
    margin-bottom: 40px;

    p {
        color: ${COLORS.textTransparent(0.5)};
        margin-bottom: 25px;
    }

    h1 {
        margin-bottom: 15px;
        font-size: 16px;
    }

    .ant-input-number {
        width: 100%;
    }

    .ant-input-number-handler-wrap {
        opacity: 1;
    }

    .products {
        display: flex;
    }

    .item {
        display: flex;
        flex-direction: column;
        margin-right: 25px;
        width: 200px;

        & > span {
            font-size: 12px;
            margin-bottom: 5px;
        }
    }

    .icon {
        color: #00a0f1;
        font-size: 20px;
        margin-right: 25px;
    }
`;
