
import styled from "styled-components";
import { StepIconStyle } from "@pages/stepIcon/stepIconStyle";
import { COLORS } from "@style/colors";
import { ErrorMessageStyle } from "@components/errorMessage/errorMessageStyle";

export const InquiryIncidentFeedbackStyle = styled.div`
    flex: 1;

    section {
        background: #FFFFFF;
        box-shadow: 0 1px 1px 0 rgba(0,0,0,0.15);
        border-radius: 4px;
        margin-bottom: 30px;
        overflow: hidden;

        ${StepIconStyle} {
            margin-right: 15px;
        }

        main {
            padding: 25px;
            display: block;

            &.split {
                display: flex;
            }

            &.split > div {
                width: 50%;

                &:first-of-type {
                    margin-right: 25px;
                }
            }

            .ant-radio-wrapper {
                margin-bottom: 15px;
            }

            .ant-checkbox-wrapper {
                margin-bottom: 0 !important;
            }
        }

        h1 {
            display: block;
            width: 100%;
            height: 45px;
            display: flex;
            align-items: center;
            padding: 0 15px;
            background-color: ${COLORS.lightGrey};
            color: ${COLORS.text};
            transition: .3s;

            &.done {
                background-color: ${COLORS.primary};
                color: white;
            }
        }
    }

    ${ErrorMessageStyle} {
        margin: 0 25px 25px 25px;
    }

    .confirm {
        display: flex;
        .ant-btn {
            width: 50%;

            &:first-of-type {
                margin-right: 15px;
            }
        }
    }
`;
