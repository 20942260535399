import {
    CollectionRoundTaskCargoPerProduct,
    Incident,
    InquiryProduct,
    InquiryStatus,
    InquiryStatusType,
    Product
} from "@models/graphql";
import Maybe from "graphql/tsutils/Maybe";
import * as React from "react";
import * as moment from "moment";

type ProductPartial = Pick<Product, "name" | "id">

type InquiryProductPartial = Pick<InquiryProduct, "amount"> & {
    product: ProductPartial
}

export type CargoPerProductPartial = Pick<CollectionRoundTaskCargoPerProduct, "effectiveCargo" | "cargo"> & {
    product: ProductPartial
}

export const renderProducts = (inquiryProducts: InquiryProductPartial[], cargoPerProduct?: CargoPerProductPartial[] | null): JSX.Element => {
    if (cargoPerProduct && cargoPerProduct.length > 0) {
        return (
            <>
                {cargoPerProduct.map(prod =>
                    <div key={prod.product.id} className="product">
                        {prod.effectiveCargo} x {prod.product.name}
                    </div>
                )}
            </>
        );
    }

    return (
        <>
            {inquiryProducts.map(prod =>
                <div key={prod.product.id} className="product">
                    {prod.amount} x {prod.product.name}
                </div>
            )}
        </>
    );
};

type IncidentPartial = Pick<Incident, "payments">

type StatusHistoryPartial = Pick<InquiryStatus, "timestamp" | "type"> & {
    incident?: Maybe<IncidentPartial>;
}

interface InquiryWithStatusHistory {
    statusHistory: StatusHistoryPartial[];
    products: {
        product: {
            id: string;
            name: string;
        },
        amount: number;
    }[];
}

export const getRemainingProductsFromInquiry = (inquiry: InquiryWithStatusHistory): CargoPerProductPartial[] => {
    const remainingProducts: CargoPerProductPartial[] = [];

    const statusHistoryWithIncident = inquiry.statusHistory.filter(status => status.type === InquiryStatusType.Incident).sort((a, b) => moment(b.timestamp).isBefore(a.timestamp) ? -1 : 1);
    const mostRecentStatusHistorytWithPayment = statusHistoryWithIncident.find(status => status.incident && status.incident.payments[0]);

    if (mostRecentStatusHistorytWithPayment) {
        const payment = mostRecentStatusHistorytWithPayment.incident?.payments.sort((a, b) => moment(b.timestamp).isBefore(a.timestamp) ? -1 : 1)[0];

        if (payment && payment.products) {
            payment.products.forEach((paymentProduct) => {
                const originalProduct = inquiry.products.find(item => paymentProduct.productId === item.product.id);

                remainingProducts.push(
                    {
                        product: {
                            id: paymentProduct.productId,
                            name: originalProduct?.product.name || ""
                        },
                        cargo: originalProduct?.amount || 0,
                        effectiveCargo: paymentProduct.amount || 0
                    }
                );
            });

        }
    }

    return remainingProducts;
};
