import { createContext, FunctionComponent, useContext, useState } from "react";
import * as React from "react";

import { CollectionGroupInput } from "@models/graphql";

interface CollectionGroupsContext {
    collectionGroups: CollectionGroupInput[];

    addCollectionGroup(group: CollectionGroupInput): void;
    removeCollectionGroup(groupId: string): void;
    updateCollectionGroup(group: CollectionGroupInput): void;
    setCollectionGroups(val: CollectionGroupInput[]): void;
}

const CollectionGroupsContext = createContext<CollectionGroupsContext>({} as any);

export const CollectionGroupsProvider: FunctionComponent = ({ children }) => {
    const [collectionGroups, setCollectionGroups] = useState<CollectionGroupInput[]>([]);

    const addCollectionGroup = (group: CollectionGroupInput): void => {
        setCollectionGroups(state => [...state, group]);
    };

    const removeCollectionGroup = (groupId: string): void => {
        setCollectionGroups(state => state.filter(group => group.id !== groupId));
    };

    const updateCollectionGroup = (group: CollectionGroupInput): void => {
        setCollectionGroups(state => {
            const newState = [...state];
            const groupIndex = newState.findIndex(g => g.id === group.id);

            if (groupIndex > -1) {
                newState[groupIndex] = group;
            }

            return newState;
        });
    };

    const context: CollectionGroupsContext = {
        collectionGroups,
        addCollectionGroup,
        removeCollectionGroup,
        updateCollectionGroup,
        setCollectionGroups
    };

    return <CollectionGroupsContext.Provider value={context}>{children}</CollectionGroupsContext.Provider>;
};

export const useFormCollectionGroups = (): CollectionGroupsContext => useContext(CollectionGroupsContext);
