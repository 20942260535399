import styled from "styled-components";
import { COLORS } from "@style/colors";

export const ErrorMessageStyle = styled.div`
    border: 1px solid ${COLORS.error};
    background-color: ${COLORS.errorLight};
    padding: 10px 30px 10px 10px;
    border-radius: 4px;
    position: relative;
    color: ${COLORS.error};

    .icon {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;

        &.reset {
            right: 35px;
        }
    }
`;
