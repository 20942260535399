import styled from "styled-components";

import { COLORS } from "@style/colors";

export const ToBeRescheduledListStyle = styled.div`


`;

export const LoadingList = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .anticon {
        font-size: 50px;
        color: ${COLORS.primaryTransparant(0.5)};
    }
`;

export const EmptyList = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    & > .icon {
        width: 120px;
        height: 120px;
        background-color: ${COLORS.lightGrey};
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${COLORS.primary};
        font-size: 50px;
        margin-bottom: 45px;
    }

    span {
        opacity: 0.3;
        font-weight: 200;
        width: 200px;
        text-align: center;
        line-height: 150%;
    }

    &.error {
        .icon {
            color: ${COLORS.error};
        }

        span {
            color: ${COLORS.error};
            opacity: 0.8;
        }

        .errorMessage {
            font-family: monospace;
            opacity: 0.2;
            padding: 0 40px;
            margin-top: 40px;
            text-align: center;
            line-height: 150%;

            &:hover {
                opacity: 0.8;
            }
        }
    }
` ;
