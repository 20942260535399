import { Button, Popconfirm, Spin, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";

import { Icon } from "@style/icon";
import { CollectionGroupInput } from "@models/graphql";
import { useGetMunicipalities } from "@graphql/hocs/hooks/useGetMunicipalities";
import { useEditCollectionPattern } from "@components/patternOverviewTab/patternEdittingProvider";
import { useFormCollectionGroups } from "@components/patternOverviewTab/collectionGroupsProvider";
import { CollectionGroupModal } from "@components/collectionGroupTab/collectionGroupModal/collectionGroupModal";

import { CollectionGroupTabStyle } from "./collectionGroupTabStyle";

export interface CollectionGroupTabProps {
    className?: string;
}

export const CollectionGroupTab: FunctionComponent<CollectionGroupTabProps> = ({ className }) => {
    const { collectionGroups, removeCollectionGroup } = useFormCollectionGroups();
    const { municipalities } = useGetMunicipalities();
    const [modalVisibile, setModalVisible] = React.useState(false);
    const [editItem, setEditItem] = React.useState<CollectionGroupInput | null>(null);
    const { isActive } = useEditCollectionPattern();

    const { editLoading } = useEditCollectionPattern();

    const handleAddGroup = (): void => {
        setEditItem(null);
        setModalVisible(true);
    };

    const handleEditGroup = (group: CollectionGroupInput): void => {
        setEditItem(group);
        setModalVisible(true);
    };

    const handleDeleteGroup = (group: CollectionGroupInput): void => {
        removeCollectionGroup(group.id);
    };

    const columns: ColumnProps<CollectionGroupInput>[] = [
        {
            key: "name",
            dataIndex: "name",
            width: 300,
            title: <FormattedMessage id="collectionGroups.title" />
        },
        {
            key: "municipalities",
            title: <FormattedMessage id="collectionGroups.municipalities" />,
            render(_, record) {
                return record.municipalityIds.map(id => {
                    const match = municipalities.find(m => m.id === id);

                    if (match) {
                        return match.name;
                    }
                }).join(", ");

            }
        },
        {
            key: "buttons",
            width: 80,
            render(_, record) {
                if (isActive) {
                    return null;
                }

                return (
                    <div className="editBtns">
                        <Icon type="pencil" onClick={() => handleEditGroup(record)}></Icon>
                        <Popconfirm placement="left" onConfirm={() => handleDeleteGroup(record)} title={<FormattedMessage id="collectionGroups.deleteConfirm" />}>
                            <Icon type="trash" ></Icon>
                        </Popconfirm>
                    </div>
                );
            }
        }
    ];

    return (
        <Spin spinning={editLoading}>

            <CollectionGroupTabStyle className={className}>
                <header className="pageHeader">
                    <FormattedMessage id="patternDetail.groups" />
                    <Button type="ghost" onClick={handleAddGroup}>+ &nbsp;<FormattedMessage id="collectionGroups.add" /></Button>

                </header>
                <CollectionGroupModal closeCallback={() => setModalVisible(false)} visible={modalVisibile} editGroup={editItem} />
                <Table
                    columns={columns}
                    rowKey="id"
                    dataSource={collectionGroups}
                />
            </CollectionGroupTabStyle>
        </Spin>
    );
};
