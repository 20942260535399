/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { CollectionRoundsCsvMutation, CollectionRoundsCsvMutationVariables } from "@models/graphql";

const defaultCollectionRoundsCsvOptions: MutationHookOptions<CollectionRoundsCsvMutation, CollectionRoundsCsvMutationVariables> = {};

export const useCollectionRoundsCsv = (
    options?: MutationHookOptions<CollectionRoundsCsvMutation, CollectionRoundsCsvMutationVariables>
) => {
    const [mutation, { data, loading, error }] = useMutation<CollectionRoundsCsvMutation, CollectionRoundsCsvMutationVariables>(
        GraphQLDocuments.collectionRoundsCsv
    );

    const collectionRoundsCsv = async (variables: CollectionRoundsCsvMutationVariables) => {
        try {
            const response = await mutation({
                ...defaultCollectionRoundsCsvOptions,
                ...options,
                variables
            });

            return {
                error: response.errors,
                data: response.data
            };
        } catch (err) {
            return {
                error: err
            };
        }
    };

    return {
        isLoading: loading,
        error,
        data,
        collectionRoundsCsv
    };
};
