import styled from "styled-components";
import { InnerContainer } from "@style/innerContainer";
import { COLORS } from "@style/colors";

export const PageHeaderStyle = styled.div`
    background-color: white;
    height: 70px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    box-shadow: ${(props: { shadow?: boolean }) => props.shadow ? "0 1px 2px 0 rgba(0,0,0,0.10);" : "none"};

    h1 {
        margin: 0;
        padding: 0;
        font-size: 20px;
        font-weight: bold;
    }

   ${InnerContainer} {
        display: flex;
        justify-content: space-between;
        align-items: center;

        aside {
            .ant-btn {
                margin-left: 15px;
                width: 160px;
                height: 40px;
                font-weight: 200;
            }
        }

        & > a {
            font-size: 14px;
            color: ${COLORS.text};
            font-weight: 600;

            .icon {
                margin-right: 10px;
            }
        }
    }

`;
