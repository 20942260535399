import styled from "styled-components";
import { RichTextEditorStyle } from "@components/richTextEditor/richTextEditorStyle";
import { COLORS } from "@style/colors";

export const NotificationFormModalStyle = styled.div`
    .has-error {
        ${RichTextEditorStyle} {
            .ant-select-selection {
                border-color: #d9d9d9;
            }
            border-color: ${COLORS.error};
        }
    }

    .row {
        display: flex;

        .ant-input-number {
            width: 100%;
        }

        .ant-form-item {
            width: 100%;

            &:not(:last-of-type) {
                margin-right: 15px;
            }
        }
    }
`;
