/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { GetIncidentReportsQuery, GetIncidentReportsQueryVariables } from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

export const useGetIncidentReports = (options?: QueryHookOptions<GetIncidentReportsQuery, GetIncidentReportsQueryVariables>) => {
    const { data, error, loading } = useQuery<GetIncidentReportsQuery, GetIncidentReportsQueryVariables>(GraphQLDocuments.getIncidentReports, options);

    return {
        incidentReports: data && data.incidentReports ? data.incidentReports : [],
        incidentReportsLoading: loading,
        incidentReportsError: error
    };
};
