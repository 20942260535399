import { PatternTable } from "@components/patternTable/patternTable";
import { Permission, PERMISSIONS } from "@components/permission/permission";
import { InnerContainer } from "@style/innerContainer";
import { Button } from "antd";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { CollectionCalendarPageStyle } from "./collectionCalendarPageStyle";

export interface CollectionCalendarPageProps {

}

export const CollectionCalendarPage: FunctionComponent<CollectionCalendarPageProps> = () =>
    <CollectionCalendarPageStyle>
        <InnerContainer>
            <div className="topBar">
                <Permission requiredPermissions={[PERMISSIONS.collectionPatterns.create]}>

                    <Link to="/management/calendar/pattern">
                        <Button type="primary"><FormattedMessage id="patternForm.newPattern" /></Button>
                    </Link>
                </Permission>
            </div>

            <PatternTable />
        </InnerContainer>

    </CollectionCalendarPageStyle>
    ;
