export const IMAGES = {
    calendar: require("./calendar.svg").default,
    incidents: require("./incidents.svg").default,
    inventory: require("./inventory.svg").default,
    logo: require("./logo.svg").default,
    marker: require("./marker.svg").default,
    notifications: require("./notifications.svg").default,
    products: require("./products.svg").default,
    trucks: require("./trucks.svg").default,
    users: require("./users.svg").default,
    processors: require("./processors.svg").default,
    processor: require("./processor.svg").default,
    home: require("./home.svg").default,
    upload: require("./upload.svg").default
};
