import { Button, DatePicker, message } from "antd";
import * as moment from "moment";
import { FunctionComponent } from "react";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { parseError } from "@utils/parseError";
import { useFormatMessage } from "@utils/intlHook";
import { DATE_FORMAT } from "@utils/consts";
import { InnerContainer } from "@style/innerContainer";
import { Icon } from "@style/icon";
import { planningParams } from "@pages/planningPage/planningPage";
import { useCollectionRoundsCsv } from "@graphql/hocs/hooks/useCollectionRoundsCsv";
import { CsvDownloadModal } from "@components/planningPageHeader/csvDownloadModal/csvDownloadModal";

import { PlanningPageHeaderStyle } from "./planningPageHeaderStyle";
import { useDispatch } from "@globalState/useGlobalState";
import { updatePlanningDateAction } from "@globalState/actions/mainActions/updatePlanningDate";

export interface PlanningPageHeaderProps {

}

export const PlanningPageHeader: FunctionComponent<PlanningPageHeaderProps> = () => {
    const formatMessage = useFormatMessage();
    const { parameters, updateParameters } = planningParams();
    const updatePlanningDate = useDispatch(updatePlanningDateAction);
    const { collectionRoundsCsv, isLoading } = useCollectionRoundsCsv();
    const [csvUrl, setCsvUrl] = React.useState<string>("");
    const [csvModalVisible, setcCvModalVisible] = React.useState(false);

    const from = moment(parameters.fromDate, DATE_FORMAT);
    const to = moment(parameters.toDate, DATE_FORMAT);

    const nextWeek = (): void => {
        updateParameters({
            fromDate: from.clone().startOf("w").add(1, "w").format(DATE_FORMAT),
            toDate: from.clone().startOf("w").add(1, "w").endOf("w").format(DATE_FORMAT)
        });
    };

    const previousWeek = (): void => {
        updateParameters({
            fromDate: from.clone().startOf("w").subtract(1, "w").format(DATE_FORMAT),
            toDate: from.clone().startOf("w").subtract(1, "w").endOf("w").format(DATE_FORMAT)
        });
    };

    const handleDateChange = (date: [moment.Moment, moment.Moment]): void => {
        updateParameters({
            fromDate: date[0].format(DATE_FORMAT),
            toDate: date[1].format(DATE_FORMAT)
        });
    };

    React.useEffect(() => {
        updatePlanningDate({
            fromDate: parameters.fromDate,
            toDate: parameters.toDate
        });
    }, [parameters.fromDate, parameters.toDate]);

    const handleExport = async (): Promise<void> => {
        const { data, error } = await collectionRoundsCsv({
            from: from.format(DATE_FORMAT),
            to: to.format(DATE_FORMAT)
        });

        if (data) {
            setcCvModalVisible(true);
            setCsvUrl(data.collectionRoundsCSV.url);
        }

        if (error) {
            message.error(parseError(error, formatMessage));
        }

    };

    return (
        <PlanningPageHeaderStyle>
            <CsvDownloadModal
                fileName={`Planning ${from.format("DD-MM-YYYY")} - ${to.format("DD-MM-YYYY")}`}
                url={csvUrl}
                visible={csvModalVisible}
                closeCallback={() => setcCvModalVisible(false)}
            />
            <InnerContainer>
                <div className="left">
                    <div className="date">
                        <div className="datePicker">
                            <div className="value">
                                {from.format("DD MMM")} <FormattedMessage id="until" /> {to.format("DD MMM")}
                            </div>
                            <DatePicker.RangePicker allowClear={false} onChange={handleDateChange} format={"DD MMM"} value={[from, to]} />
                        </div>

                    </div>

                    <div className="buttons">
                        <Icon onClick={previousWeek} type="arrow-left" />
                        <Icon onClick={nextWeek} type="arrow-right" />
                    </div>

                </div>
                <aside>
                    <Button onClick={handleExport} loading={isLoading} type="primary">
                        <FormattedMessage id="exportCsv" />
                    </Button>
                </aside>
            </InnerContainer>
        </PlanningPageHeaderStyle>
    );
};
