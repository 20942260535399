import * as React from "react";

export interface IconProps extends React.HTMLAttributes<HTMLLIElement> {
    type:
    | "bell"
    | "calendar-alt"
    | "calendar"
    | "checkmark"
    | "house"
    | "mail"
    | "mobile"
    | "save"
    | "more"
    | "pencil"
    | "cross"
    | "search"
    | "tag"
    | "trash"
    | "info"
    | "truck"
    | "back-arrow"
    | "forward-arrow"
    | "empty-box"
    | "marker"
    | "list"
    | "location"
    | "error"
    | "incident"
    | "people"
    | "reset"
    | "qr"
    | "power"
    | "arrow-left"
    | "arrow-right"
    | "duplicate"
    | "user";
}

export const Icon: React.StatelessComponent<IconProps> = (props: IconProps) => {
    const { type, className } = props;

    return <i {...props} className={`icon ${type} ${className || ""}`} />;
};
