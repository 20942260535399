import styled from "styled-components";

import { ErrorStyle } from "@components/error/errorStyle";

export const PlanningPageStyle = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    & > main {
        display: flex;

        & > .ant-spin-nested-loading {
            width: calc(100vw - 375px);
            position: relative;
        }

        ${ErrorStyle} {
            height: calc(100vh - 145px);
            padding: 20px;
        }
    }
`;
