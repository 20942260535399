import styled from "styled-components";
import { IncidentReasonStyle } from "@pages/incidentReasonPage/incidentReasonStyle";

export const ProcessorsPageStyle = styled(IncidentReasonStyle)`
    .icon {
        margin-left: 10px;
        opacity: 0.5;
        cursor: pointer;

        &:hover {
            opacity: 1;
        }
    }

    .anticon {
        margin-left: 10px;
    }
`;
