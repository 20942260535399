/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { GetProductQuery, GetProductQueryVariables } from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

export const useGetProduct = (options?: QueryHookOptions<GetProductQuery, GetProductQueryVariables>) => {
    const { data, error, loading } = useQuery<GetProductQuery, GetProductQueryVariables>(GraphQLDocuments.getProduct, options);

    return {
        product: data && data.product,
        productLoading: loading,
        productError: error
    };
};
