import { IMAGES } from "@assets/images";
import { TruckTaskFragment } from "@models/graphql";
import { Icon } from "@style/icon";
import { parseAddressToString } from "@utils/parseAddressToString";
import * as classnames from "classnames";
import * as React from "react";
import { FunctionComponent } from "react";
import { Draggable } from "react-beautiful-dnd";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { Link } from "react-router-dom";
import { TruckScheduleItemStyle } from "./truckScheduleItemStyle";
import { InquiryStatus } from "@components/inquiryStatus/inquiryStatus";
import { renderProducts } from "@utils/productUtils";

export interface TruckScheduleItemProps {
    truckTask: TruckTaskFragment;
    dragIndex: number;
    setActiveTask(taskId: string | null): void;
}

export const TruckScheduleItem: FunctionComponent<TruckScheduleItemProps> = ({
    setActiveTask,
    truckTask: { task, processor, id, depot, distance },
    dragIndex
}) => {
    const renderContent = (): JSX.Element | null => {
        if (task) {

            return (
                <>
                    <div className="info">
                        <InquiryStatus withPopup status={task.inquiry.currentStatus} />
                        <div className="address">
                            <em>{task.inquiry.applicant.name}</em>({parseAddressToString(task.inquiry.pickupAddress.address)})
                        </div>
                        <div className="products">
                            {renderProducts(task.inquiry.products, task.cargoPerProduct)}
                        </div>
                        <div className="type">{task.type && <FormattedMessage id={`collectionRoundTaskType.${task.type}`} />} — <FormattedNumber value={distance} maximumFractionDigits={2} /> KM</div>
                    </div>
                    <div className="buttons">
                        <Link to={`/inquiries/${task.inquiry.id}`}>
                            <Icon type="forward-arrow" />
                        </Link>
                    </div>
                </>
            );
        }

        if (processor) {
            return (
                <>
                    <div className="taskType processor">
                        <img src={IMAGES.processor} />
                    </div>
                    <div className="address">
                        <em>{parseAddressToString(processor.address.address)}</em>
                    </div>
                    <div className="type">{processor.name} — <FormattedNumber value={distance} maximumFractionDigits={2} /> KM</div>
                </>
            );
        }

        if (depot) {
            return (
                <>
                    <div className="taskType depot">
                        <img src={IMAGES.home} />
                    </div>
                    <div className="address">
                        <em>{parseAddressToString(depot.address.address)}</em>
                    </div>
                    <div className="type">{depot.name} — <FormattedNumber value={distance} maximumFractionDigits={2} /> KM</div>
                </>
            );
        }

        return null;
    };

    const handleMouseEnter = (): void => {
        setActiveTask(id);
    };

    const handleMouseLeave = (): void => {
        setActiveTask(null);
    };

    return (
        <Draggable isDragDisabled={!!processor} draggableId={id} index={dragIndex}>
            {(provided, snapshot) =>
                <TruckScheduleItemStyle
                    className={classnames({ isDragging: snapshot.isDragging, processor: !!processor })}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    {renderContent()}
                </TruckScheduleItemStyle>
            }
        </Draggable>
    );
};
