import * as React from "react";
import { FunctionComponent } from "react";
import { InquiryStatusStyle } from "./inquiryStatusStyle";
import { InquiryStatusType } from "@models/graphql";
import { Tooltip } from "antd";
import { useFormatMessage } from "@utils/intlHook";

export interface InquiryStatusProps {
    status: InquiryStatusType;
    withPopup?: boolean;
}

export const InquiryStatus: FunctionComponent<InquiryStatusProps> = ({ status, withPopup }) => {

    const formatMessage = useFormatMessage();

    if (!withPopup) {
        return (
            <InquiryStatusStyle status={status} />
        );
    }

    return (
        <Tooltip title={formatMessage({ id: `inquiryStatusType.${status}` })}>
            <InquiryStatusStyle status={status} />
        </Tooltip>
    );
};
