import * as React from "react";
import { FunctionComponent } from "react";

import { Header } from "@components/header/header";

import { LayoutStyle } from "./layoutStyle";

export interface LayoutProps {

}

export const Layout: FunctionComponent<LayoutProps> = ({ children }) => 
    <LayoutStyle>
        <Header />
        {children}
    </LayoutStyle>
    ;
