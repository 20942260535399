import styled from "styled-components";
import { COLORS } from "@style/colors";

export const GoogleAutocompleteStyle = styled.div`
    position: relative;

    input {
        margin: 0;
        padding: 4px 11px;
        color: rgba(0, 0, 0, 0.65);
        border: 1px solid #d9d9d9;

        border-radius: 4px;
        outline: 0;
        position: relative;

        width: 100%;

        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0s;
        height: 32px;
        line-height: 1.5;
        background: transparent;
        border-width: 1px;
    }

    .autocomplete-dropdown-container {
        position: absolute;
        top: 35px;
        left: 0;
        right: 0;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        z-index: 5;
        background: white;
        border-radius: 4px;
        overflow: hidden;

        .active {
            background: ${COLORS.primaryTransparant(0.1)};
        }

        & > div {
            padding: 0 10px;
        }
    }
`;
