import { ApolloError } from "apollo-client/errors/ApolloError";
import { FormattedMessage, MessageValue } from "react-intl";
import { GraphQLError } from "graphql";

export const getDownstreamError = (error: GraphQLError): GraphQLError => {
    let err: GraphQLError = error;

    while (err.extensions && err.extensions.downstreamErrors && err.extensions.downstreamErrors[0]) {
        err = getDownstreamError(err.extensions.downstreamErrors[0]);
    }

    return err;
};

export const parseError = (incomingError: ApolloError | GraphQLError[] | undefined, formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor, values?: { [key: string]: MessageValue }) => string): string => {
    if (!incomingError) {
        return "";
    }

    let error: GraphQLError | ApolloError;

    if (Array.isArray(incomingError) && incomingError[0]) {
        error = incomingError[0];
    } else {
        error = incomingError as ApolloError;
    }

    const match = error.message.match(/errors\..*$/);

    if (match && match.length) {
        return formatMessage({ id: match[0].toLowerCase() });
    }

    let graphqlError: GraphQLError;
    if (error instanceof ApolloError && error.graphQLErrors && error.graphQLErrors[0]) {
        graphqlError = error.graphQLErrors[0];
    } else {
        graphqlError = error as GraphQLError;
    }

    if (graphqlError) {
        const err = getDownstreamError(graphqlError);

        const downstreamMatch = err.message.match(/errors\..*$/);

        if (downstreamMatch && downstreamMatch.length) {
            return formatMessage({ id: downstreamMatch[0].toLowerCase() });
        }
    }

    return formatMessage({ id: "error.unknown" });
};
