/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useEffect, useRef } from "react";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

import { GetInquiriesOverviewQuery, GetInquiriesOverviewQueryVariables } from "@models/graphql";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";

export const useGetInquiriesOverview = (options?: QueryHookOptions<GetInquiriesOverviewQuery, GetInquiriesOverviewQueryVariables>) => {
    const prevDataRef = useRef();
    const { data, error, loading } = useQuery<GetInquiriesOverviewQuery, GetInquiriesOverviewQueryVariables>(GraphQLDocuments.getInquiriesOverview, options);

    const hasData = data && Object.keys(data).length;
    const activeData = hasData ? data : prevDataRef.current;

    useEffect(() => {
        if (hasData) {
            prevDataRef.current = data as any;
        }
    });

    if (error) {
        console.error(error);
    }

    return {
        inquiries: activeData && activeData.inquiries ? activeData.inquiries.items : [],
        inquiriesCount: activeData && activeData ? activeData.inquiries.count : 0,
        inquiriesLoading: loading,
        inquiriesError: error
    };
};
