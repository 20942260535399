import { Form, Select } from "antd";
import { FunctionComponent } from "react";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { useFormatMessage } from "@utils/intlHook";
import { Icon } from "@style/icon";
import { DayPart } from "@models/graphql";
import { useGetTruckTypes } from "@graphql/hocs/hooks/useGetTruckTypes";
import { useGetProductNames } from "@graphql/hocs/hooks/useGetProductNames";
import { useForm } from "@components/patternOverviewTab/patternFormProvider";
import { useEditCollectionPattern } from "@components/patternOverviewTab/patternEdittingProvider";
import { useFormCollectionRound } from "@components/patternOverviewTab/collectionRoundProvider";
import { useFormCollectionGroups } from "@components/patternOverviewTab/collectionGroupsProvider";

import { CollectionRoundBlockStyle } from "./collectionRoundBlockStyle";

export interface CollectionRoundBlockProps {
    roundId: string;
    dayIndex: number;
}

export const CollectionRoundBlock: FunctionComponent<CollectionRoundBlockProps> = ({ roundId, dayIndex }) => {
    const formatMessage = useFormatMessage();
    const form = useForm();

    const { products } = useGetProductNames({ variables: { filter: { accessory: false } } });
    const { truckTypes } = useGetTruckTypes();
    const { removeCollectionRound } = useFormCollectionRound();
    const { collectionGroups } = useFormCollectionGroups();
    const { editPatternItem, isActive } = useEditCollectionPattern();

    const handleRemove = (): void => {
        removeCollectionRound(dayIndex, roundId);
    };

    const selectedGroup: string | undefined = form.getFieldValue(`days[${dayIndex}].collectionRound[${roundId}]groupId`);

    React.useEffect(() => {
        if (selectedGroup && !collectionGroups.some(group => group.id === selectedGroup)) {
            form.setFieldsValue({
                [`days[${dayIndex}].collectionRound[${roundId}]groupId`]: undefined
            });
        }
    });

    let initVal: {
        dayPart?: DayPart;
        groupId?: string;
        productIds?: string[];
        truckTypeId?: string;
    } | null = null;

    if (
        editPatternItem &&
        editPatternItem.days &&
        editPatternItem.days[dayIndex] &&
        editPatternItem.days[dayIndex].collectionRound &&
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        editPatternItem.days[dayIndex].collectionRound![roundId]
    ) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        initVal = editPatternItem.days[dayIndex].collectionRound![roundId];
    }

    return (
        <CollectionRoundBlockStyle>
            {!(isActive && !!initVal) && <Icon onClick={handleRemove} type="trash" />}
            <Form.Item label={<FormattedMessage id="patternForm.dayPart" />}>
                {form.getFieldDecorator(`days[${dayIndex}].collectionRound[${roundId}]dayPart`, {
                    initialValue: initVal ? initVal.dayPart : DayPart.Morning,
                    rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }]
                })(<Select disabled={isActive && !!initVal}>
                    <Select.Option value={DayPart.Morning}>
                        <FormattedMessage id={`daypart.${DayPart.Morning}`} />
                    </Select.Option>
                    <Select.Option value={DayPart.Afternoon}>
                        <FormattedMessage id={`daypart.${DayPart.Afternoon}`} />
                    </Select.Option>
                    <Select.Option value={DayPart.Fullday}>
                        <FormattedMessage id={`daypart.${DayPart.Fullday}`} />
                    </Select.Option>
                </Select>)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id="patternForm.group" />}>
                {form.getFieldDecorator(`days[${dayIndex}].collectionRound[${roundId}]groupId`, {
                    rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }],
                    initialValue: initVal ? initVal.groupId : undefined
                })(<Select disabled={isActive && !!initVal} placeholder={<FormattedMessage id="patternForm.group" />}>
                    {collectionGroups.map(group => 
                        <Select.Option key={group.id} value={group.id}>
                            {group.name}
                        </Select.Option>)}
                </Select>)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id="patternForm.products" />}>
                {form.getFieldDecorator(`days[${dayIndex}].collectionRound[${roundId}]productIds`, {
                    rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }],
                    initialValue: initVal ? initVal.productIds : undefined
                })(<Select disabled={isActive && !!initVal} mode="multiple" placeholder={<FormattedMessage id="patternForm.products" />}>
                    {products.map(p => 
                        <Select.Option key={p.id} value={p.id}>
                            {p.name}
                        </Select.Option>)}
                </Select>)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id="patternForm.truckType" />}>
                {form.getFieldDecorator(`days[${dayIndex}].collectionRound[${roundId}]truckTypeId`, {
                    rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }],
                    initialValue: initVal ? initVal.truckTypeId : undefined
                })(<Select disabled={isActive && !!initVal} placeholder={<FormattedMessage id="patternForm.truckType" />}>
                    {truckTypes.map(truck => 
                        <Select.Option key={truck.id} value={truck.id}>
                            {truck.name}
                        </Select.Option>)}
                </Select>)}
            </Form.Item>
        </CollectionRoundBlockStyle>
    );
};
