import { IncidentOverviewFilter } from "@components/incidentOverviewFilter/incidentOverviewFilter";
import { IncidentsTable } from "@components/incidentsTable/incidentsTable";
import { PageHeader } from "@components/pageHeader/pageHeader";
import { InnerContainer } from "@style/innerContainer";
import * as React from "react";
import { FunctionComponent } from "react";
import { IncidentOverviewPageStyle } from "./incidentOverviewPageStyle";

export interface IncidentOverviewPageProps {

}

export const IncidentOverviewPage: FunctionComponent<IncidentOverviewPageProps> = (props: IncidentOverviewPageProps) =>
    <IncidentOverviewPageStyle>
        <PageHeader title="incidentsOverview.title">
            {/* <Permission requiredPermissions={[PERMISSIONS.inquiries.create]}>
                <Link to="/inquiries/create">
                    <Button type="primary" ><FormattedMessage id="inquiryOverview.newInquiry" /></Button>
                </Link>
            </Permission> */}
        </PageHeader>

        <InnerContainer>

            <IncidentOverviewFilter />
            <IncidentsTable />
        </InnerContainer>
    </IncidentOverviewPageStyle>
    ;
