import styled from "styled-components";
import { ErrorMessageStyle } from "@components/errorMessage/errorMessageStyle";

export const ApplicantAutocompleteStyle = styled.div`
    position: relative;

    .anticon-spin {
        position: absolute;
        top: 11px;
        right: 10px;
        z-index: 5;
    }

    ${ErrorMessageStyle} {
        padding: 0 30px 0 10px;
        margin: 5px 0;
    }
`;
