import { ColorPicker } from "@components/richTextEditor/colorPicker/colorPicker";
import { COLOR_MAP } from "@components/richTextEditor/colorPicker/colorPickerStyle";
import { clearBlocks, clearInlineStyle, decorator } from "@components/richTextEditor/helpers";
import { StyleButton } from "@components/richTextEditor/styleButton/styleButton";
import { Form, Icon, Input, Modal, Select } from "antd";
import { ModalFuncProps } from "antd/lib/modal";
import { convertFromRaw, convertToRaw, DraftEditorCommand, DraftHandleValue, Editor, EditorState, Modifier, RawDraftContentState, RichUtils, SelectionState } from "draft-js";
import "draft-js/dist/Draft.css";
import { getEntityRange, handleNewLine } from "draftjs-utils";
import * as equal from "fast-deep-equal";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { Divider, RichTextEditorStyle } from "./richTextEditorStyle";

export interface RichTextEditorProps {
    isRich: boolean;
    variables: string[];
    onChange?(val: RawDraftContentState): void;
    value?: RawDraftContentState;
}

const INLINE_STYLES = [{ label: "B", style: "BOLD" }, { label: "I", style: "ITALIC" }, { label: "U", style: "UNDERLINE" }];

const BLOCK_STYLES = [{ label: "H1", style: "header-one" }, { label: "H2", style: "header-two" }, { label: "H3", style: "header-three" }];

export const RichTextEditorComponent: FunctionComponent<RichTextEditorProps> = ({ isRich, variables, onChange, value }, ref) => {
    const editorRef = React.useRef<Editor | null>(null);
    const [editorState, setEditorState] = React.useState(value ? EditorState.createWithContent(convertFromRaw(value), decorator) : EditorState.createEmpty(decorator));

    React.useEffect(() => {
        if (!value) {
            return setEditorState(EditorState.createEmpty(decorator));
        }

        if (!equal(value, convertToRaw(editorState.getCurrentContent()))) {
            try {
                const newState = EditorState.createWithContent(convertFromRaw(value), decorator);
                setEditorState(newState);
            } catch (err) {
                console.error(err);
            }
        }
    }, [value]);

    React.useEffect(() => {
        if (!isRich) {
            const currentContent = editorState.getCurrentContent();
            const firstBlock = currentContent.getBlockMap().first();
            const lastBlock = currentContent.getBlockMap().last();
            const firstBlockKey = firstBlock.getKey();
            const lastBlockKey = lastBlock.getKey();
            const lengthOfLastBlock = lastBlock.getLength();

            const selection = new SelectionState({
                anchorKey: firstBlockKey,
                anchorOffset: 0,
                focusKey: lastBlockKey,
                focusOffset: lengthOfLastBlock
            });

            const newEditorState = [clearBlocks, clearInlineStyle].reduce((acc, helper) => helper(selection, acc), editorState);

            setEditorState(newEditorState);
        }
    }, [isRich]);

    React.useEffect(() => {
        const contentState = editorState.getCurrentContent();
        const rawState = convertToRaw(contentState);
        rawState.entityMap = Object.values(rawState.entityMap).map(entity => {
            if (entity.type === "VARIABLE" && !variables.some(v => v === entity.data.variable)) {
                entity.data.invalid = true;
            } else if (entity.type === "VARIABLE") {
                entity.data.invalid = false;
            }

            return entity;
        }) as any;

        const newState = EditorState.push(editorState, convertFromRaw(rawState), "apply-entity");

        setEditorState(newState);
    }, [variables]);

    const handleKeyCommand = (command: DraftEditorCommand): DraftHandleValue => {
        const newState = RichUtils.handleKeyCommand(editorState, command);

        if (newState && isRich) {
            setEditorState(newState);
            return "handled";
        }
        return "not-handled";
    };

    const handleReturn = (event: React.KeyboardEvent): DraftHandleValue => {
        const newState = handleNewLine(editorState, event);

        if (newState) {
            setEditorState(newState);
            return "handled";
        }
        return "not-handled";
    };

    const currentBlockType = editorState
        .getCurrentContent()
        .getBlockForKey(editorState.getSelection().getStartKey())
        .getType();
    const currentInlineStyle = editorState.getCurrentInlineStyle();

    const toggleBlockType = (blockType: string): void => {
        setEditorState(RichUtils.toggleBlockType(editorState, blockType));
    };

    const toggleInlineStyle = (inlineStyle: string): void => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    };

    const toggleColor = (toggledColor: string): void => {
        const selection = editorState.getSelection();
        const nextContentState = Object.keys(COLOR_MAP).reduce((contentState, color) => Modifier.removeInlineStyle(contentState, selection, color), editorState.getCurrentContent());

        let nextEditorState = EditorState.push(editorState, nextContentState, "change-inline-style");

        const currentStyle = editorState.getCurrentInlineStyle();

        // Unset style override for current color.
        if (selection.isCollapsed()) {
            nextEditorState = currentStyle.reduce(
                (state, color) =>
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    RichUtils.toggleInlineStyle(state!, color!)
                , nextEditorState
            );
        }

        // If the color is being toggled on, apply it.
        if (!currentStyle.has(toggledColor)) {
            nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, toggledColor);
        }

        setEditorState(nextEditorState);
    };

    const handleFocus = (): void => {
        if (editorRef.current) {
            editorRef.current.focus();
        }
    };

    const handleAddEntity = (variable: string): void => {
        const entityKey = editorState
            .getCurrentContent()
            .createEntity("VARIABLE", "IMMUTABLE", {
                variable
            })
            .getLastCreatedEntityKey();

        const newContentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            `{{${variable}}}`,
            editorState.getCurrentInlineStyle(),
            entityKey
        );

        const newEditorState = EditorState.push(editorState, newContentState, "insert-characters");
        setEditorState(newEditorState);

        setTimeout(handleFocus, 0);
    };

    const handleUrl = async (): Promise<void> => {
        let link = "";

        const changeVal = (ev: React.ChangeEvent<HTMLInputElement>): void => {
            link = ev.target.value;
        };

        let m: {
            destroy: () => void;
            update: (newConfig: ModalFuncProps) => void;
        } | undefined;

        try {
            await new Promise((resolve, reject) => {
                m = Modal.confirm({
                    content: <Form>
                        <Form.Item style={{ margin: 0, padding: 0 }} label="Link">
                            <Input placeholder="http://www.limburg.net" onChange={changeVal} />
                            <em style={{ fontSize: 12 }}>Laat leeg om een link te verwijderen.</em>
                        </Form.Item>

                    </Form>,
                    onOk: resolve,
                    onCancel: reject,
                    icon: null
                });
            });
        } catch (err) {
            if (m) {
                m.destroy();
            }
            return;
        }

        if (m) {
            m.destroy();
        }

        const selection = editorState.getSelection();

        if (!link) {
            setEditorState(RichUtils.toggleLink(editorState, selection, null));

            return;
        }

        const content = editorState.getCurrentContent();
        const contentWithEntity = content.createEntity("LINK", "MUTABLE", { url: link });

        const newEditorState = EditorState.push(editorState, contentWithEntity, "apply-entity");
        const entityKey = contentWithEntity.getLastCreatedEntityKey();

        setEditorState(RichUtils.toggleLink(newEditorState, selection, entityKey));
    };

    const renderRichItems = (): JSX.Element | null => {
        if (!isRich) {
            return null;
        }

        return (
            <>
                {INLINE_STYLES.map(style =>
                    <StyleButton
                        key={style.label}
                        active={currentInlineStyle.has(style.style)}
                        label={style.label}
                        onToggle={toggleInlineStyle}
                        style={style.style}
                    />)}

                <StyleButton
                    active={false}
                    label={<Icon type="link" />}
                    onToggle={handleUrl}
                    style={""}
                />
                <Divider />
                {BLOCK_STYLES.map(style =>
                    <StyleButton
                        key={style.label}
                        active={currentBlockType === style.style}
                        label={style.label}
                        onToggle={toggleBlockType}
                        style={style.style}
                    />)}
                <Divider />
                <ColorPicker activeStyle={currentInlineStyle} onToggle={toggleColor} />
            </>
        );
    };

    const handleChange = (state: EditorState): void => {
        const contentState = state.getCurrentContent();
        const selection = state.getSelection();
        const startBlockKey = selection.getAnchorKey();
        const startBlock = contentState.getBlockForKey(startBlockKey);

        const endBlockKey = selection.getFocusKey();
        const endBlock = contentState.getBlockForKey(endBlockKey);

        const endEntity = endBlock.getEntityAt(selection.getEndOffset());
        const startEntity = startBlock.getEntityAt(selection.getStartOffset());

        const anchorKey = startBlockKey;
        let anchorOffset = selection.getAnchorOffset();
        const focusKey = endBlockKey;
        let focusOffset = selection.getFocusOffset();

        if (endEntity) {
            const endEntityRange = getEntityRange(state, endEntity);
            focusOffset = endEntityRange.end;
        }

        if (startEntity) {
            const startEntityRange = getEntityRange(state, startEntity);

            anchorOffset = startEntityRange.start;
        }

        if (startEntity && !endEntity && selection.getStartOffset() < selection.getEndOffset()) {
            const startEntityRange = getEntityRange(state, startEntity);

            focusOffset = startEntityRange.end;
        }

        if (endEntity || startEntity) {
            const newSelection = new SelectionState({
                anchorKey,
                anchorOffset,
                focusKey,
                focusOffset
            });
            setEditorState(EditorState.forceSelection(state, newSelection));
        } else {
            setEditorState(state);
        }

        if (onChange) {
            onChange(convertToRaw(contentState));
        }
    };

    return (
        <RichTextEditorStyle ref={ref}>
            <section className="toolBar">
                {renderRichItems()}

                <Select size="small" value={undefined} placeholder="Kies een variable om toe te voegen." onChange={handleAddEntity}>
                    {variables.map(v =>
                        <Select.Option key={v} value={v}>
                            <FormattedMessage id={`variable.${v}`} />
                        </Select.Option>)}
                </Select>
            </section>
            <section className="editor" onClick={handleFocus}>
                <Editor
                    ref={editorRef}
                    onChange={handleChange}
                    handleKeyCommand={handleKeyCommand}
                    editorState={editorState}
                    customStyleMap={COLOR_MAP}
                    handleReturn={handleReturn}
                />
            </section>
        </RichTextEditorStyle>
    );
};

export const RichTextEditor = React.forwardRef(RichTextEditorComponent);
