/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { AddIncidentFeedbackMutation, AddIncidentFeedbackMutationVariables } from "@models/graphql";

const defaultAddIncidentFeedbackOptions: MutationHookOptions<AddIncidentFeedbackMutation, AddIncidentFeedbackMutationVariables> = {};

export const useAddIncidentFeedback = (
    options?: MutationHookOptions<AddIncidentFeedbackMutation, AddIncidentFeedbackMutationVariables>
) => {
    const [mutation, { data, loading, error }] = useMutation<AddIncidentFeedbackMutation, AddIncidentFeedbackMutationVariables>(
        GraphQLDocuments.addIncidentFeedback
    );

    const addIncidentFeedback = async (variables: AddIncidentFeedbackMutationVariables) => {
        try {
            const response = await mutation({
                ...defaultAddIncidentFeedbackOptions,
                ...options,
                variables
            });

            return {
                error: undefined,
                response
            };
        } catch (err) {
            return {
                error: err,
                response: undefined
            };
        }
    };

    return {
        isLoading: loading,
        error,
        data,
        addIncidentFeedback: addIncidentFeedback
    };
};
