import styled, { keyframes } from "styled-components";
import { COLORS } from "@style/colors";

export const pulseAnimation = keyframes`
    0% {
        opacity: 1;
        transform: scale(1.2);
    }

    100% {
        opacity: 0.3;
        transform: scale(1);
    }
`;

export const LoadingPageStyle = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
        width: 200px;
        animation: ${pulseAnimation} 1.2s infinite alternate;
    }

    h1 {
        color: ${COLORS.primary};
        margin-top: 40px;
        font-weight: 200;
        font-size: 22px;
        opacity: 0.6;
    }
`;
