import { GetCollectionRoundsQuery } from "@models/graphql";
import { planningParams } from "@pages/planningPage/planningPage";
import { Collapse, Spin } from "antd";
import * as moment from "moment";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { CreateStandaloneTruckModal } from "./createStandaloneTruckModal/createStandaloneTruckModal";
import { PlanningWeekOverviewStyle } from "./planningWeekOverviewStyle";
import { WeekBlock } from "./weekBlock/weekBlock";
import { DATE_FORMAT } from "@utils/consts";
import useScrollInfo from "@utils/useScrollInfo";
import { useQuery, useUpdateQuery } from "@utils/routerHook";

export interface PlanningWeekOverviewProps {
    collectionRounds: GetCollectionRoundsQuery["collectionRounds"];
    collectionRoundsLoading: boolean;
}

export const PlanningWeekOverview: FunctionComponent<PlanningWeekOverviewProps> = ({ collectionRounds, collectionRoundsLoading }) => {
    const updateQuery = useUpdateQuery({ replace: true });
    const urlQuery = useQuery();
    const [scrollInfo, setRef, ref] = useScrollInfo();

    React.useEffect(() => {
        // Skip initial render
        if (
            scrollInfo.x.value === null ||
            scrollInfo.x.value === undefined ||
            scrollInfo.y.value === null ||
            scrollInfo.y.value === undefined
        ) {
            return;
        }

        updateQuery({ x: scrollInfo.x.value, y: scrollInfo.y.value });
    }, [scrollInfo]);

    React.useEffect(() => {
        const { x, y } = urlQuery;

        if (ref.current) {
            ref.current.scrollLeft = x;
            ref.current.scrollTop = y;
        }
    }, [collectionRoundsLoading]);

    const activeKeys = urlQuery["keys"] || [];

    const setActiveKeys = (keys: string[]): void => {
        updateQuery({ keys });
    };

    React.useEffect(() => {
        if (!urlQuery["keys"]) {
            setActiveKeys([moment().startOf("d").toISOString(), moment().add(1, "d").startOf("d").toISOString()]);
        }
    }, []);

    const { parameters } = planningParams();
    // const [activeKeys, setActiveKeys] = React.useState<string[]>([
    //     moment().startOf("d").toISOString(),
    //     moment().add(1, "d").startOf("d").toISOString()
    // ]);
    const [addTruckDate, setAddTruckDate] = React.useState<moment.Moment | undefined>();
    const startDate = moment(parameters.fromDate);

    const handleAddTruck = (e: React.MouseEvent, date: moment.Moment): void => {
        e.stopPropagation();
        setAddTruckDate(date);
    };

    const weekDates = React.useMemo(() => {
        const dates: moment.Moment[] = [];
        const daysBetween = Math.abs(startDate.diff(moment(parameters.toDate, DATE_FORMAT), "d"));

        for (let i = 0; i <= daysBetween; i += 1) {
            dates.push(startDate.clone().add(i, "d"));
        }

        return dates;
    }, [parameters.fromDate, parameters.toDate]);

    const handleCloseModal = (): void => {
        setAddTruckDate(undefined);
    };

    const handleCollapseChange = (keys: string[]): void => {
        setActiveKeys(keys);
    };

    return (
        <Spin spinning={collectionRoundsLoading}>
            <PlanningWeekOverviewStyle ref={setRef}>
                {addTruckDate && (
                    <CreateStandaloneTruckModal date={addTruckDate} visible={!!addTruckDate} closeCallback={handleCloseModal} />
                )}
                <Collapse onChange={handleCollapseChange} bordered={false} activeKey={activeKeys}>
                    {weekDates.map((date) => {
                        const rounds = collectionRounds.filter((round) => date.isSame(round.date, "d"));
                        return (
                            <Collapse.Panel
                                header={
                                    <header className="weekHeader">
                                        <div className="inset">
                                            <div className="date">{date.format("dddd DD MMMM")}</div>

                                            {date.isAfter(moment(), "d") && (
                                                <div onClick={(e) => handleAddTruck(e, date)} className="addTruck">
                                                    <FormattedMessage id="planning.addRound" />
                                                </div>
                                            )}
                                        </div>
                                    </header>
                                }
                                key={date.toISOString()}
                            >
                                <WeekBlock date={date} collectionRounds={rounds} />
                            </Collapse.Panel>
                        );
                    })}
                </Collapse>
            </PlanningWeekOverviewStyle>
        </Spin>
    );
};
