import { MutationLoading } from "@components/mutationLoading/mutationLoading";
import { usePlanningContext } from "@components/planningProvider/planningProvider";
import {
    CollectionRoundTaskType,
    GetCollectionRoundsQuery,
    InquiryOverviewFragment,
    InquiryStatusType
} from "@models/graphql";
import { Icon } from "@style/icon";
import { DATE_FORMAT } from "@utils/consts";
import { parseAddressToString } from "@utils/parseAddressToString";
import { getRemainingProductsFromInquiry, renderProducts } from "@utils/productUtils";
import * as classnames from "classnames";
import * as moment from "moment";
import { FunctionComponent } from "react";
import * as React from "react";
import { Draggable } from "react-beautiful-dnd";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { PlanningInquiryItemStyle } from "./planningInquiryItemStyle";

export interface PlanningInquiryItemProps {
    index: number;
    inquiry: InquiryOverviewFragment;
    type: CollectionRoundTaskType;
    showOriginalPickupDate?: boolean;
    sidebarItem: boolean;
    date?: string;
		cargoPerProduct?: GetCollectionRoundsQuery["collectionRounds"][0]["tasks"][0]["cargoPerProduct"];
}

export const PlanningInquiryItem: FunctionComponent<PlanningInquiryItemProps> = ({ index, inquiry, type, cargoPerProduct, sidebarItem, date }) => {
	 const [remainingProducts, setRemainingProducts] = React.useState(cargoPerProduct);

    React.useEffect(() => {
        if(!cargoPerProduct) {
            setRemainingProducts(getRemainingProductsFromInquiry(inquiry));
        }
    }, []);

    const { inquiriesSaving, inquiriesDone, setInquiriesDone, inquiriesError, setInquiriesError, isDragging } = usePlanningContext();

    const isSaving = inquiriesSaving.some(id => id === inquiry.id);
    const isDoneSaving = inquiriesDone.some(id => id === inquiry.id);
    const isError = inquiriesError.some(id => id === inquiry.id);

    const removeDone = (): void => {
        if (isDoneSaving) {
            setInquiriesDone(inquiriesDone.filter(id => id !== inquiry.id));
        }
    };

    const removeError = (): void => {
        if (isError) {
            setInquiriesError(inquiriesError.filter(id => id !== inquiry.id));
        }
    };

    React.useEffect(() => {
        if (isDoneSaving) {
            setTimeout(removeDone, 800);
        }

        if (inquiriesError) {
            setTimeout(removeError, 800);
        }
    }, [isDoneSaving, isSaving, inquiriesError]);

    const disabled = React.useMemo(() => {

        if (inquiry.currentStatus === InquiryStatusType.ToBeRescheduled) {
            return false;
        }

        if (date && moment(date, DATE_FORMAT).isBefore(moment(), "day")) {
            return true;
        }

        if (!date && type === CollectionRoundTaskType.Delivery && inquiry.deliveryDate && moment(inquiry.deliveryDate).isBefore(moment(), "day")) {
            return true;
        }

        if (!date && type === CollectionRoundTaskType.Pickup && inquiry.pickupDate && moment(inquiry.pickupDate).isBefore(moment(), "day")) {
            return true;
        }

        return false;
    }, [inquiry.pickupDate, inquiry.deliveryDate, type]);

    return (
        <Draggable isDragDisabled={disabled} draggableId={!sidebarItem ? `${type}-${inquiry.id}` : inquiry.id} index={index}>
            {(provided, snapshot) =>
                <PlanningInquiryItemStyle
                    className={classnames({ isDragging: snapshot.isDragging, disabled: disabled && isDragging })}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <MutationLoading done={isDoneSaving} isLoading={isSaving} error={isError} />

                    <div className="info">
                        <div className="address">
                            <em>{inquiry.applicant.name}</em>({parseAddressToString(inquiry.pickupAddress.address)})
                        </div>
                        <div className="products">
                            {renderProducts(inquiry.products, remainingProducts)}
                        </div>
                        {type && !sidebarItem && <div className="type">
                            <FormattedMessage id={`collectionRoundTaskType.${type}`} />{" "}
                        </div>
                        }
                    </div>
                    <div className="buttons">
                        <Link to={`/inquiries/${inquiry.id}`}>
                            <Icon type="forward-arrow" />
                        </Link>
                    </div>
                </PlanningInquiryItemStyle>
            }
        </Draggable>
    );
};
