import * as React from "react";
import { FunctionComponent } from "react";
import { AssetTypeFormModalStyle } from "./assetTypeFormModalStyle";
import { FormComponentProps } from "antd/lib/form";
import { AssetType, AssetTypeInput, GetAssetTypesQuery } from "@models/graphql";
import { useFormatMessage } from "@utils/intlHook";
import { useCreateAssetType } from "@graphql/hocs/hooks/useCreateAssetType";
import { useUpdateAssetType } from "@graphql/hocs/hooks/useUpdateAssetType";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { Modal, Form, Input } from "antd";
import { FormattedMessage } from "react-intl";
import { ErrorMessage } from "@components/errorMessage/errorMessage";

export interface AssetTypeFormModalProps extends FormComponentProps {
    visible: boolean;
    editItem: AssetType | null;
    closeCallback(): void;
}

export const AssetTypeFormModalComponent: FunctionComponent<AssetTypeFormModalProps> = ({ closeCallback, editItem, form, visible }) => {
    const formatMessage = useFormatMessage();
    const { createAssetType, isLoading: createLoading, error: createError } = useCreateAssetType({
        update(cache, response) {
            const collectorsData = cache.readQuery<GetAssetTypesQuery>({ query: GraphQLDocuments.getAssetTypes });

            if (!collectorsData || !response.data || !response.data.createAssetType) { return; }

            collectorsData.assetTypes.items.push(response.data.createAssetType);
            collectorsData.assetTypes.count += 1;

            cache.writeQuery({ query: GraphQLDocuments.getAssetTypes, data: collectorsData });
        }
    });
    const { updateAssetType, isLoading: updateLoading, error: updateError } = useUpdateAssetType();

    const handleClose = (): void => {
        closeCallback();
        form.resetFields();
    };

    const handleSubmit = (): void => {
        form.validateFields(async (errors, values) => {
            if (errors) {
                return;
            }

            const assetType: AssetTypeInput = {
                name: values.name
            };

            if (editItem) {
                const { error } = await updateAssetType({
                    id: editItem.id,
                    assetType
                });

                if (!error) {
                    handleClose();
                }
            } else {

                const { error } = await createAssetType({ assetType });

                if (!error) {
                    handleClose();
                }
            }
        });
    };
    return (
        <Modal
            visible={visible}
            okButtonProps={{
                loading: createLoading || updateLoading
            }}
            title={<FormattedMessage id="assetTypeForm.title" />}
            okText={<FormattedMessage id="save" />}
            cancelText={<FormattedMessage id="cancel" />}
            onCancel={handleClose}
            onOk={handleSubmit}
        >
            <AssetTypeFormModalStyle>
                <Form onSubmit={handleSubmit}>
                    <Form.Item label={<FormattedMessage id="assetTypeForm.name" />}>
                        {form.getFieldDecorator("name", {
                            rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }],
                            initialValue: editItem ? editItem.name : undefined
                        })(<Input placeholder={formatMessage({ id: "assetTypeForm.name" })} />)}
                    </Form.Item>

                    <ErrorMessage error={createError || updateError} />
                </Form>
            </AssetTypeFormModalStyle>
        </Modal>
    );
};

export const AssetTypeFormModal = Form.create<AssetTypeFormModalProps>()(AssetTypeFormModalComponent)
    ;
