import * as React from "react";
import { FunctionComponent } from "react";
import { InquiryIncidentStyle } from "./inquiryIncidentStyle";
import { GetInquiryDetailQuery } from "@models/graphql";
import { InquiryIncidentDetail } from "@components/inquiryIncidentDetail/inquiryIncidentDetail";
import { InquiryIncidentFeedback } from "@components/inquiryIncidentFeedback/inquiryIncidentFeedback";
import { InquiryInvoiceSender } from "@components/inquiryInvoiceSender/inquiryInvoiceSender";

export interface InquiryIncidentProps {
    incident: NonNullable<GetInquiryDetailQuery["inquiry"]["statusHistory"][0]["incident"]>;
    products: GetInquiryDetailQuery["inquiry"]["products"];
    timestamp: string;
    inquiryId: string;

    readonly?: boolean;
}

export const InquiryIncident: FunctionComponent<InquiryIncidentProps> = ({ incident, timestamp, inquiryId, products, readonly }) => 
    <InquiryIncidentStyle>
        <header>
            {incident.reason.name}
        </header>
        <div className="flexContainer">
            <InquiryIncidentDetail
                inquiryId={inquiryId}
                incident={incident}
                timestamp={timestamp}
                readonly={readonly}
            />
            <div>
                { !readonly && <InquiryInvoiceSender
                    incidentId={incident.id}
                    previousPayments={incident.payments}
                    products={products}
                    inquiryId={inquiryId}
                />}
                <InquiryIncidentFeedback
                    inquiryId={inquiryId}
                    incidentId={incident.id}
                    feedback={incident.feedback}
                    readonly={readonly}
                />
            </div>

        </div>
    </InquiryIncidentStyle>
    ;
