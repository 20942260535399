import * as React from "react";
import { createContext, FunctionComponent, useContext, useState } from "react";

interface PlanningContext {
    inquiriesSaving: string[];
    inquiriesDone: string[];
    inquiriesError: string[];
    disabledCollectionRounds: string[];
    isDragging: boolean;
    setInquiriesSaving(val: string[]): void;
    setInquiriesDone(val: string[]): void;
    setInquiriesError(val: string[]): void;
    setDisabledCollectionRounds(val: string[]): void;
    setIsDragging(val: boolean): void;
}

const PlanningContext = createContext<PlanningContext>({} as any);

export const PlanningProvider: FunctionComponent = ({ children }) => {
    const [inquiriesSaving, setInquiriesSaving] = useState<string[]>([]);
    const [inquiriesDone, setInquiriesDone] = useState<string[]>([]);
    const [inquiriesError, setInquiriesError] = useState<string[]>([]);
    const [isDragging, setIsDragging] = useState(false);

    const [disabledCollectionRounds, setDisabledCollectionRounds] = useState<string[]>([]);

    const context: PlanningContext = {
        inquiriesDone,
        setInquiriesDone,
        inquiriesSaving,
        setInquiriesSaving,
        inquiriesError,
        setInquiriesError,
        disabledCollectionRounds,
        setDisabledCollectionRounds,
        setIsDragging,
        isDragging
    };

    return <PlanningContext.Provider value={context}>{children}</PlanningContext.Provider>;
};

export const usePlanningContext = (): PlanningContext => useContext(PlanningContext);
