import styled from "styled-components";

export const AssetsTableStyle = styled.div`
    flex: 1;

    .buttons {
        display: flex;
        justify-content: flex-end;

        .icon {
            margin-left: 15px;
            opacity: 0.6;
            cursor: pointer;

            &:hover {
                opacity: 1;
            }
        }
    }
`;
