/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { UpdateIncidentCommentMutation, UpdateIncidentCommentMutationVariables } from "@models/graphql";
import { useFormatMessage } from "@utils/intlHook";
import { parseError } from "@utils/parseError";

const defaultUpdateIncidentCommentOptions: MutationHookOptions<UpdateIncidentCommentMutation, UpdateIncidentCommentMutationVariables> = {};

export const useUpdateIncidentComment = (
    options?: MutationHookOptions<UpdateIncidentCommentMutation, UpdateIncidentCommentMutationVariables>
) => {
    const formatMessage = useFormatMessage();
    const [mutation, { data, loading, error }] = useMutation<UpdateIncidentCommentMutation, UpdateIncidentCommentMutationVariables>(
        GraphQLDocuments.updateIncidentComment
    );

    const updateIncidentComment = async (variables: UpdateIncidentCommentMutationVariables) => {
        try {
            const response = await mutation({
                ...defaultUpdateIncidentCommentOptions,
                ...options,
                variables
            });

            return response;
        } catch (err) {
            return { error: err };
        }
    };

    return {
        isLoading: loading,
        error: parseError(error, formatMessage),
        data,
        updateIncidentComment
    };
};
