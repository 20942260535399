import { ApolloError } from "apollo-client";
import { createContext, FunctionComponent, useContext, useState } from "react";
import * as React from "react";

import { PatternFormValues } from "@components/patternOverviewTab/patternFormProvider";
import { Moment } from "moment";

interface PatternEdittingContext {
    isEditting: boolean;
    isActive: boolean;
    editPatternItem: PatternFormValues | null;
    editLoading: boolean;
    editError?: ApolloError;
    activeDates: {
        from: Moment;
        to: Moment;
    } | null;

    setEditItem(formValues: PatternFormValues): void;
    setIsActive(isActive: boolean): void;
    setEditItemState({ loading: boolean, error: ApolloError }): void;
    setActiveDates(val: { from: Moment; to: Moment } | null): void;
}

const PatternEdittingContext = createContext<PatternEdittingContext>({} as any);

export const PatternEdittingProvider: FunctionComponent = ({ children }) => {
    const [isEditting, setIsEditting] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [activeDates, setActiveDates] = useState<{ from: Moment; to: Moment } | null>(null);
    const [editPatternItem, setPatternEditItem] = useState<PatternFormValues | null>(null);
    const [editItemState, setEditItemState] = useState<{ loading: boolean; error?: ApolloError }>({ loading: false });

    const setEditItem = (pat: PatternFormValues): void => {
        setIsEditting(true);
        setPatternEditItem(pat);
    };

    const context: PatternEdittingContext = {
        isEditting,
        isActive,
        setIsActive,
        editPatternItem,
        setEditItem,
        setEditItemState,
        editError: editItemState.error,
        editLoading: editItemState.loading,
        activeDates,
        setActiveDates
    };

    return <PatternEdittingContext.Provider value={context}>{children}</PatternEdittingContext.Provider>;
};

export const useEditCollectionPattern = (): PatternEdittingContext => useContext(PatternEdittingContext);
