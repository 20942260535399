import * as React from "react";
import * as classnames from "classnames";
import { FunctionComponent } from "react";
import { Droppable } from "react-beautiful-dnd";

import { InquiryOverviewFragment, CollectionRoundTaskType } from "@models/graphql";

import { SubListStyle } from "./subListStyle";
import { PlanningInquiryItem } from "@components/planningInquiryItem/planningInquiryItem";

export interface SubListProps {
    inquiries: InquiryOverviewFragment[];
    id: string;
    title: string | React.ReactNode;
}

export const SubList: FunctionComponent<SubListProps> = ({ inquiries, id, title }) => {
    if (!inquiries.length) {
        return null;
    }

    return (
        <Droppable droppableId={id} isDropDisabled={true}>
            {(provided, snapshot) => 
                <SubListStyle className={classnames({ draggingOver: snapshot.isDraggingOver })}>
                    <header>
                        {title}
                        <span className="amount">{inquiries.length}</span>
                    </header>
                    <div className="items" ref={provided.innerRef} {...provided.droppableProps}>
                        {inquiries.map((inq, index) => 
                            <PlanningInquiryItem index={index} key={inq.id} inquiry={inq} type={CollectionRoundTaskType.Pickup} sidebarItem />)}
                        {provided.placeholder}
                    </div>
                </SubListStyle>
            }
        </Droppable>
    );
};
