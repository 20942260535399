import { MutationFetchResult } from "@apollo/react-common";
import { Checkbox, Form, Input, Modal } from "antd";
import { FormComponentProps } from "antd/lib/form";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";

import { useFormatMessage } from "@utils/intlHook";
import {
    CreateProcessorMutation,
    GetProcessorsQuery,
    InquiryLocation,
    ProcessorFragment,
    ProcessorInput
} from "@models/graphql";
import { useUpdateProcessor } from "@graphql/hocs/hooks/useUpdateProcessor";
import { useGetProductNames } from "@graphql/hocs/hooks/useGetProductNames";
import { useCreateProcessor } from "@graphql/hocs/hooks/useCreateProcessor";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { Spinner } from "@components/spinner/spinner";
import { MarkerMap } from "@components/markerMap/markerMap";
import { GoogleAutocomplete } from "@components/googleAutocomplete/googleAutocomplete";
import { ErrorMessage } from "@components/errorMessage/errorMessage";

import { ProcessorModalStyle } from "./processorModalStyle";

export interface ProcessorModalProps extends FormComponentProps {
    visible: boolean;
    editItem: ProcessorFragment | null;
    closeCallback(): void;
}

export const ProcessorModalComponent: FunctionComponent<ProcessorModalProps> = ({ visible, closeCallback, editItem, form }) => {
    const formatMessage = useFormatMessage();
    const { products, productsLoading } = useGetProductNames({ variables: { filter: { accessory: false } } });
    const { updateProcessor, error: updateError, isLoading: updateLoading } = useUpdateProcessor();
    const { createProcessor, error: createError, isLoading: createLoading } = useCreateProcessor({
        update(cache, response: MutationFetchResult<CreateProcessorMutation>) {
            const processorsData = cache.readQuery<GetProcessorsQuery>({ query: GraphQLDocuments.getProcessors });

            if (processorsData && response && response.data) {
                processorsData.processors.items = [...processorsData.processors.items, response.data.createProcessor];
                processorsData.processors.count += 1;
            }

            cache.writeQuery({ query: GraphQLDocuments.getProcessors, data: processorsData });
        }
    });

    const location: InquiryLocation | null = form.getFieldValue("address") || editItem && editItem.address;

    const handleClose = (): void => {
        closeCallback();
        form.resetFields();
    };

    const handleSubmit = (): void => {
        form.validateFields(async (errors, values) => {
            if (errors) {
                return;
            }

            delete values.address.__typename;
            delete values.address.address.__typename;
            delete values.address.coordinates.__typename;

            const processor: ProcessorInput = {
                address: values.address,
                name: values.name,
                productIds: values.productIds
            };

            if (editItem) {
                const { error } = await updateProcessor({
                    processor,
                    id: editItem.id
                });
                if (!error) {
                    handleClose();
                }

            } else {
                const { error } = await createProcessor({
                    processor
                });
                if (!error) {
                    handleClose();
                }
            }

        });
    };

    const handleMapChange = (address: InquiryLocation): void => {
        form.setFieldsValue({
            address
        });
    };

    return (
        <Modal
            visible={visible}
            okButtonProps={{
                loading: createLoading || updateLoading
            }}
            title="Verwerker"
            okText={<FormattedMessage id="save" />}
            cancelText={<FormattedMessage id="cancel" />}
            onCancel={handleClose}
            onOk={handleSubmit}
        >
            <ProcessorModalStyle>
                <Form onSubmit={handleSubmit}>
                    <Form.Item label={<FormattedMessage id="processorForm.name" />}>
                        {form.getFieldDecorator("name", {
                            rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }],
                            initialValue: editItem ? editItem.name : undefined
                        })(<Input placeholder={formatMessage({ id: "processorForm.name" })} />)}
                    </Form.Item>
                    <Form.Item label={formatMessage({ id: "processorForm.address" })}>
                        {form.getFieldDecorator("address", {
                            rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }],
                            initialValue: editItem ? editItem.address : undefined
                        })(<GoogleAutocomplete placeholder={formatMessage({ id: "processorForm.address" })} />)}
                    </Form.Item>
                    <MarkerMap position={location ? location.coordinates : null} onChange={handleMapChange} />
                    <Form.Item label={formatMessage({ id: "processorForm.products" })}>
                        {productsLoading && <Spinner />}
                        {form.getFieldDecorator("productIds", {
                            initialValue: editItem ? editItem.products.map(prod => prod.id) : [],
                            rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }]
                        })(<Checkbox.Group>
                            {products.map(product =>
                                <Checkbox key={product.id} value={product.id}>
                                    {product.name}
                                </Checkbox>)}
                        </Checkbox.Group>)}
                    </Form.Item>
                </Form>
                <ErrorMessage error={createError || updateError} />
            </ProcessorModalStyle>
        </Modal>
    );
};

export const ProcessorModal = Form.create<ProcessorModalProps>()(ProcessorModalComponent);
