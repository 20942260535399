import styled from "styled-components";
import { keyframes } from "styled-components";
import { COLORS } from "@style/colors";

export const GradientKeyframes = keyframes`
    0% {
        background-position: 0% 0;
    }

    100% {
        background-position: 100% 0;
    }
`;

export const Placeholder = styled.div`

    width: 100%;
    height: 85px;
    background-color: ${COLORS.lightGrey};
    border-radius: 2px;

    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${GradientKeyframes};
    animation-timing-function: linear;
    background: linear-gradient(to right, #eeeeee 15%, #e5e5e5 50%, #eeeeee 85%);
    background-size: 600% 600%;
`;
