import styled from "styled-components";
import { WhitePage } from "@style/whitePage";
import { ErrorMessageStyle } from "@components/errorMessage/errorMessageStyle";
import { COLORS } from "@style/colors";

export const UploadWeightDataPageStyle = styled(WhitePage)`
    .ant-form {
        margin-bottom: 30px;
    }
`;

export const Overlay = styled.div`
    position: relative;

    .cover {
        display: ${(props: { visible: boolean }) => props.visible ? "flex" : "none"};
        position: absolute;
        background-color: rgba(255,255,255, 0.85);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;

        align-items: center;
        justify-content: center;

        font-size: 22px;
        font-weight: 300;
    }
`;

export const ErrorIds = styled(ErrorMessageStyle)`
    h1 {
        font-size: 16px;
        color: ${COLORS.error};
        font-weight: bold;
    }
    margin-bottom: 15px;
`;

export const SuccesIds = styled(ErrorMessageStyle)`
    border-color:  ${COLORS.primary};
    background-color: ${COLORS.primaryTransparant(0.1)};
    color: ${COLORS.primary};

    h1 {
        font-size: 16px;
        color: ${COLORS.primary};
        font-weight: bold;
    }
    margin-bottom: 15px;
`;
