import { ApolloProvider } from "@apollo/react-hooks";
import { AuthenticationProvider, getUserManager, OidcSecure, oidcLog } from "@axa-fr/react-oidc-context";
import { GlobalStateProvider } from "@globalState/globalStateProvider";
import { useLoadScript } from "@react-google-maps/api";
import { ConfigProvider } from "antd";
import nlBE from "antd/es/locale/nl_BE";
import * as environment from "environment";
import * as OfflinePluginRuntime from "offline-plugin/runtime";
import * as React from "react";
import { render } from "react-dom";
import { addLocaleData, IntlProvider } from "react-intl";
import * as nl from "react-intl/locale-data/nl";
import { BrowserRouter } from "react-router-dom";

import { oidcConfiguration } from "@utils/oidcConfig";
import { InjectIntlContext } from "@utils/intlHook";
import { initErrorTracking } from "@utils/errorTracking";
import { LoadingPage } from "@pages/loadingPage/loadingPage";
import { client } from "@graphql/client";
import { PermissionProvider } from "@components/permission/permissionProvider";

import { App } from "./app";

import * as Sentry from "@sentry/react";
import { CustomEvents } from "@axa-fr/react-oidc-context/dist/oidcContext/AuthenticationContext.hooks";

initErrorTracking(history);

const ProfiledApp = Sentry.withProfiler(App);

OfflinePluginRuntime.install({
    onUpdateReady: () => {
        OfflinePluginRuntime.applyUpdate();
    },
    onUpdated: () => {
        window.location.reload();
    }
});

addLocaleData([...nl]);
const messages = require("./translations/nl.json");
const reactContainer = document.getElementById("app-container");

const libraries = ["places", "geometry"];

const authEvents: Partial<CustomEvents> = {
    onAccessTokenExpiring(callback) {
        const userManager = getUserManager();

        console.info("Access token expiring, silently renewing if possible...");

        userManager
            .signinSilent({ prompt: "none" })
            .then((user: Oidc.User) => {
                console.info("Access token renewed", user);

                userManager.storeUser(user);
            })
            .catch((error: Error) => {
                console.warn("An error occurred silently renewing", error);
                console.info("Redirecting user to sign-in...");

                userManager.getUser().then((currentUser) => {
                    userManager
                        // eslint-disable-next-line camelcase
                        .signinRedirect({ prompt: "login", login_hint: currentUser ? currentUser.profile.preferred_username : undefined })
                        .then((user) => {
                            console.info("Access token renewed by redirect");

                            userManager.storeUser(user);
                        });
                });
            });
    },
    onSilentRenewError(error) {
        console.warn("An error occurred silently renewing", error);
    }
};

const MainProviderComponent: React.FunctionComponent = () => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: environment.GM_KEY,
        libraries
    });

    if (!isLoaded) {
        return <LoadingPage />;
    }

    return (
        <Sentry.ErrorBoundary>
            <AuthenticationProvider
                callbackComponentOverride={LoadingPage}
                authenticating={LoadingPage}
                configuration={oidcConfiguration()}
                customEvents={authEvents as CustomEvents}
                loggerLevel={oidcLog.INFO}
            >
                <ConfigProvider locale={nlBE}>
                    <GlobalStateProvider>
                        <IntlProvider messages={messages} locale="nl-BE">
                            <InjectIntlContext>
                                <ApolloProvider client={client}>
                                    <BrowserRouter>
                                        <PermissionProvider>
                                            <OidcSecure>
                                                <ProfiledApp />
                                            </OidcSecure>
                                        </PermissionProvider>
                                    </BrowserRouter>
                                </ApolloProvider>
                            </InjectIntlContext>
                        </IntlProvider>
                    </GlobalStateProvider>
                </ConfigProvider>
            </AuthenticationProvider>
        </Sentry.ErrorBoundary>
    );
};

render(<MainProviderComponent />, reactContainer);
