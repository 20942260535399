/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { GetFreeDeliveryDaysQuery, GetFreeDeliveryDaysQueryVariables } from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

export const useGetFreeDeliveryDays = (options?: QueryHookOptions<GetFreeDeliveryDaysQuery, GetFreeDeliveryDaysQueryVariables>) => {
    const { data, error, loading, fetchMore } = useQuery<GetFreeDeliveryDaysQuery, GetFreeDeliveryDaysQueryVariables>(GraphQLDocuments.getFreeDeliveryDays, options);

    const fetch = (offset: number) => fetchMore({
        variables: {
            paging: {
                limit: 5,
                offset
            }
        },
        updateQuery: (prev: GetFreeDeliveryDaysQuery, { fetchMoreResult }): GetFreeDeliveryDaysQuery => {
            if (!fetchMoreResult) { return prev; }

            return {
                ...prev,
                freeDeliveryDays: {
                    ...prev.freeDeliveryDays,
                    count: fetchMoreResult.freeDeliveryDays.count,
                    items: [...prev.freeDeliveryDays.items, ...fetchMoreResult.freeDeliveryDays.items]
                }
            };
        }
    });

    return {
        freeDeliveryDays: data && data.freeDeliveryDays ? data.freeDeliveryDays.items : [],
        freeDeliveryDaysCount: data && data.freeDeliveryDays ? data.freeDeliveryDays.count : 0,
        freeDeliveryDaysLoading: loading,
        freeDeliveryDaysError: error,
        fetchMoreFreeDeliveryDays: fetch
    };
};
