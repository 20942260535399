import * as moment from "moment";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { SelectableGroup } from "react-selectable-fast";

import { CalendarMonth } from "@components/patternUnplannableDays/calendarMonth/calendarMonth";

import { PatternUnplannableDaysStyle } from "./patternUnplannableDaysStyle";
import { useGetFormValues } from "@components/patternOverviewTab/patternFormProvider";
import { useUnplannableDays } from "@components/patternOverviewTab/unplannableDaysProvider";
import { Spin } from "antd";
import { useEditCollectionPattern } from "@components/patternOverviewTab/patternEdittingProvider";

export interface PatternUnplannableDaysProps {
    className?: string;
}

export const PatternUnplannableDays: FunctionComponent<PatternUnplannableDaysProps> = ({ className }) => {
    const { from, to } = useGetFormValues();
    const { setUnplannableDays } = useUnplannableDays();
    const { editLoading } = useEditCollectionPattern();
    const selectGroup = React.useRef<SelectableGroup>(null);

    const fromIso = from ? from.toISOString() : "";
    const toIso = to ? to.toISOString() : "";

    // To fix an issue with the lib not clearing the "ignoreCache"
    React.useEffect(() => {
        if (selectGroup.current) {
            selectGroup.current.ignoreCheckCache = new Map();
        }
    }, [fromIso, toIso]);

    if (!from || !to && !editLoading) {
        return (
            <PatternUnplannableDaysStyle className={className}>
                <FormattedMessage id="patternForm.selectPeriodFirst" />
            </PatternUnplannableDaysStyle>
        );
    }
    const diff = moment(to)
        .startOf("month")
        .diff(moment(from).startOf("month"), "month");

    const renderMonths = (): JSX.Element[] => {
        const months: JSX.Element[] = [];

        for (let i = 0; i <= diff; i += 1) {
            months.push(<CalendarMonth key={i} validRange={[from, to]} month={moment(from).add(i, "month")} />);
        }

        return months;
    };
    const handleSelectionFinish = (selectedNodes: React.ReactElement<{ date: string }>[]): void => {
        const dates = selectedNodes.map(node => node.props.date);

        setUnplannableDays(dates);
    };

    return (
        <Spin spinning={editLoading}>
            <PatternUnplannableDaysStyle className={className}>
                <span className="info">
                    <FormattedMessage id="patternForm.unplannableLegend" />
                </span>
                <SelectableGroup
                    enableDeselect
                    className="selectable"
                    ignoreList={[".disabled", ".isActive"]}
                    onSelectionFinish={handleSelectionFinish}
                    ref={selectGroup}
                >
                    <div className="grid">{renderMonths()}</div>
                </SelectableGroup>
            </PatternUnplannableDaysStyle>
        </Spin>
    );
};
