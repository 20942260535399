import * as classnames from "classnames";
import * as React from "react";
import { FunctionComponent } from "react";
import { Redirect, Route, Switch } from "react-router";

import { InnerContainer } from "@style/innerContainer";
import { PatternUnplannableDays } from "@components/patternUnplannableDays/patternUnplannableDays";
import { UnplannableDaysProvider } from "@components/patternOverviewTab/unplannableDaysProvider";
import { PatternOverviewTab } from "@components/patternOverviewTab/patternOverviewTab";
import { PatternFormProvider } from "@components/patternOverviewTab/patternFormProvider";
import { PatternEdittingProvider } from "@components/patternOverviewTab/patternEdittingProvider";
import { CollectionRoundProvider } from "@components/patternOverviewTab/collectionRoundProvider";
import { CollectionGroupsProvider } from "@components/patternOverviewTab/collectionGroupsProvider";
import { PatternFormHeader } from "@components/patternFormHeader/patternFormHeader";
import { PatternCapacity } from "@components/patternCapacity/patternCapacity";
import { CollectionGroupTab } from "@components/collectionGroupTab/collectionGroupTab";

import { PatternDetailPageStyle } from "./patternDetailPageStyle";

export interface PatternDetailPageProps {

}

export const PatternDetailPage: FunctionComponent<PatternDetailPageProps> = ({ }) => 
    <PatternDetailPageStyle>
        <PatternFormProvider>
            <CollectionRoundProvider>
                <CollectionGroupsProvider>
                    <UnplannableDaysProvider>
                        <PatternEdittingProvider>
                            <PatternFormHeader />
                            <InnerContainer>
                                <Switch>
                                    <Redirect exact from="/management/calendar/pattern" to="/management/calendar/pattern/groups" />
                                    <Route exact path="/management/calendar/pattern/:page?">
                                        {a => <>
                                            <CollectionGroupTab className={classnames({ hidden: a.match && a.match.params.page !== "groups" })} />
                                            <PatternOverviewTab className={classnames({ hidden: a.match && a.match.params.page !== "pattern" })} />
                                            <PatternCapacity className={classnames({ hidden: a.match && a.match.params.page !== "capacity" })} />
                                            <PatternUnplannableDays className={classnames({ hidden: a.match && a.match.params.page !== "unplannable-days" })} />
                                        </>}
                                    </Route>
                                </Switch>
                            </InnerContainer>
                        </PatternEdittingProvider>
                    </UnplannableDaysProvider>
                </CollectionGroupsProvider>
            </CollectionRoundProvider>
        </PatternFormProvider>
    </PatternDetailPageStyle>
    ;
