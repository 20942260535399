/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { GetTruckTypesQuery, GetTruckTypesQueryVariables } from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

export const useGetTruckTypes = (options?: QueryHookOptions<GetTruckTypesQuery, GetTruckTypesQueryVariables>) => {
    const { data, error, loading } = useQuery<GetTruckTypesQuery, GetTruckTypesQueryVariables>(GraphQLDocuments.getTruckTypes, options);

    return {
        truckTypes: data && data.truckTypes ? data.truckTypes.items : [],
        truckTypesLoading: loading,
        truckTypesError: error
    };
};
