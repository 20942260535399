import { GoogleAutocomplete } from "@components/googleAutocomplete/googleAutocomplete";
import { MarkerMap } from "@components/markerMap/markerMap";
import { Uploader } from "@components/uploader/uploader";
import { useGetMunicipalities } from "@graphql/hocs/hooks/useGetMunicipalities";
import { useUploadMedia } from "@graphql/hocs/hooks/useUploadMedia";
import { InquiryDetailFragment, InquiryLocation, Municipality } from "@models/graphql";
import { useFormatMessage } from "@utils/intlHook";
import { Alert, Col, Form, Input, Row, Select } from "antd";
import { WrappedFormUtils } from "antd/lib/form/Form";
import { RcCustomRequestOptions, UploadFile } from "antd/lib/upload/interface";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export interface InquiryDeliveryLocationProps {
    form: WrappedFormUtils;
    inquiry?: InquiryDetailFragment;

    deliveryMunicipality?: Municipality | null;

    position?: null | { lat: number; lng: number };
    setPosition?: (position: null | { lat: number; lng: number }) => void;

    onDeliveryChange?: (value: InquiryLocation) => void;
}

export const InquiryDeliveryLocation: React.FunctionComponent<InquiryDeliveryLocationProps> = (props) => {
    const { inquiry, form, position, setPosition, deliveryMunicipality, onDeliveryChange } = props;
    const formatMessage = useFormatMessage();

    const [uploadMedia] = useUploadMedia();
    const { municipalities, municipalitiesLoading } = useGetMunicipalities();

    const deliveryLocation = form.getFieldValue("deliveryLocation");
    const deliveryMunicipalityId = form.getFieldValue("deliveryMunicipalityId");

    const handleImageRequest = async (options: RcCustomRequestOptions, base64?: string): Promise<void> => {
        if (!base64) {
            return;
        }

        let progress = 0;
        const interval = setInterval(() => {
            progress = Math.min(progress + 10, 99);
            options.onProgress({ percent: progress }, options.file);

            if (progress >= 99) {
                clearInterval(interval);
            }
        }, 1000);

        try {
            const result = await uploadMedia({
                variables: {
                    input: {
                        content: base64,
                        filename: options.filename,
                        type: options.file.type
                    }
                }
            });

            const body = result.data?.uploadMedia;

            if (body) {
                options.onSuccess(body, options.file);
            } else {
                throw new Error("Unknown upload error");
            }
        } catch (error) {
            console.error(error);

            options.onError(error);
        } finally {
            clearInterval(interval);
        }
    };

    const onImageRemove = (file: UploadFile): void => {
        const { response } = file;

        if (response) {
            // const mediaId = response.id;
            // TODO: remove media
        }
    };

    const maxArrayLengthRule = (rule: any, value: any, callback: any, source?: any, options?: any): void => {
        if (!value || (Array.isArray(value) && value.length <= rule.max)) {
            callback();
        } else {
            callback(rule.message);
        }
    };

    const onPositionChange = (loc: InquiryLocation): void => {
        setPosition && setPosition(loc.coordinates);

        form.setFieldsValue({
            deliveryLocation: loc
        });
    };

    const onDeliveryLocationChange = (loc: InquiryLocation): void => {
        // setPosition && setPosition(loc.coordinates);
        onDeliveryChange && onDeliveryChange(loc);
    };

    return (
        <>
            <Form.Item label={<FormattedMessage id="createInquiryPage.deliveryLocation" />}>
                {form.getFieldDecorator("deliveryLocation", {
                    rules: [{ required: true, message: <FormattedMessage id="requiredField" /> }],
                    initialValue: inquiry?.pickupAddress
                })(
                    <GoogleAutocomplete
                        placeholder={formatMessage({ id: "createInquiryPage.deliveryLocation" })}
                        onChange={onDeliveryLocationChange}
                    />
                )}
            </Form.Item>
            <Row gutter={10}>
                <Col span={12}>
                    <Form.Item label={<FormattedMessage id="createInquiryPage.addition" />} colon={false}>
                        {form.getFieldDecorator("addition", {
                            initialValue: inquiry?.pickupAddress.address.numberAddition
                        })(<Input placeholder={formatMessage({ id: "createInquiryPage.addition" })} />)}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={<FormattedMessage id="createInquiryPage.busNumber" />} colon={false}>
                        {form.getFieldDecorator("busNumber", {
                            initialValue: inquiry?.pickupAddress.address.busNumber
                        })(<Input placeholder={formatMessage({ id: "createInquiryPage.busNumber" })} />)}
                    </Form.Item>
                </Col>
            </Row>
            <MarkerMap position={position || null} onChange={onPositionChange} />
            <FormattedMessage tagName="i" id="deliveryMapInfo" />

            {!deliveryMunicipality && deliveryLocation && (
                <>
                    <Form.Item label={<FormattedMessage id="createInquiryPage.municipality" />} colon={false}>
                        {form.getFieldDecorator("deliveryMunicipalityId", {
                            rules: [{ required: true, message: <FormattedMessage id="requiredField" /> }],
                            initialValue: inquiry?.pickupAddress.municipality?.id
                        })(
                            <Select
                                loading={municipalitiesLoading}
                                placeholder={<FormattedMessage id="createInquiryPage.municipality" />}
                                showSearch
                                filterOption={(val, el) => (el.props.children as string).toLowerCase().includes(val.toLowerCase())}
                                style={{ width: "100%" }}
                            >
                                {municipalities.map((m) => (
                                    <Select.Option key={m.id} value={m.id}>
                                        {m.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                    {!deliveryMunicipalityId && (
                        <Alert type="info" message={<FormattedMessage id="createInquiryPage.noMatchingMunicipality" />} />
                    )}
                </>
            )}

            <Form.Item label={<FormattedMessage id="createInquiryPage.images" />}>
                {form.getFieldDecorator("media", {
                    rules: [
                        {
                            validator: maxArrayLengthRule,
                            max: 10,
                            message: <FormattedMessage id="createInquiryPage.mediaMax" />
                        }
                    ],
                    initialValue: inquiry?.media
                })(<Uploader customRequest={handleImageRequest} valueExtractor={(value) => value.url} max={10} onRemove={onImageRemove} />)}
            </Form.Item>
        </>
    );
};
