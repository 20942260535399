import styled from "styled-components";
import { COLORS } from "@style/colors";

export const CollectionRoundBlockStyle = styled.div`
    background: #FFFFFF;
    border: 1px solid #DAE5EB;
    border-radius: 4px;
    width: 365px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    transition: border-color 0.2s, opacity 0.2s;

    &.isDraggingOver {
        border-color: ${COLORS.primary};
    }

    &.disabled {
        opacity: 0.4;
    }

    & > main {
        padding: 15px 15px 5px 15px;
        flex: 1;
        display: flex;
        flex-direction: column;
        min-height: 50px;
    }

    & > header {
        padding: 15px 15px 0 15px;

        .top {
            display: flex;
            align-items: center;

            a {
                color: ${COLORS.text};
                border-left: 1px solid ${COLORS.textTransparent(0.2)};
                padding-left: 15px;

                &:hover {
                    color: ${COLORS.primary};
                }
            }

            .ant-select {
                width: 100%;

                .ant-select-selection__rendered {
                    margin-left: 0;
                }

                .ant-select-selection {
                    border: none;
                }
            }

            .ant-select-open, .ant-select-focused {
                .ant-select-selection {
                    box-shadow: none;
                }
            }
        }

        .info {
            font-weight: 200;
            font-size: 12px;
            margin-top: 10px;

            .ant-progress {
                margin-top: 10px;
            }

            .ant-progress-inner {
                background: #F5F5F5;
                box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.15);
            }


            .progress {
                display: flex;
                align-items: center;

                .icon {
                    margin-left: 15px;
                    font-size: 16px;
                    opacity: 0.4;
                    transition: opacity 0.2s;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }

        .roundInfo {
            display: flex;
            justify-content: space-between;
        }

        .products {
            margin-top: 15px;

            .prod {
                opacity: 0.6;
                &:not(:last-of-type){
                    margin-bottom: 10px;
                }
            }
        }
    }
`;

export const SelectOptionStyle = styled.span`
    em {
        font-weight: 600;
    }

    i {
        font-weight: 200;
        opacity: 0.6;
    }
`;

export const CapacityTooltip = styled.div`
    .item {
        min-width: 150px;
        padding: 10px;
        color: ${COLORS.text};

        &:first-of-type {
            border-bottom: 1px solid ${COLORS.textTransparent(0.1)};
        }

        .sub {
            padding-left: 1em;
            font-size: 0.8em;
        }
    }
`;
