/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { UpdateNotificationConfigurationMutation, UpdateNotificationConfigurationMutationVariables } from "@models/graphql";

const defaultUpdateNotificationConfigurationOptions: MutationHookOptions<
    UpdateNotificationConfigurationMutation,
    UpdateNotificationConfigurationMutationVariables
> = {};

export const useUpdateNotificationConfiguration = (options?: MutationHookOptions<UpdateNotificationConfigurationMutation, UpdateNotificationConfigurationMutationVariables>) => {
    const [mutation, { data, loading, error }] = useMutation<UpdateNotificationConfigurationMutation, UpdateNotificationConfigurationMutationVariables>(GraphQLDocuments.updateNotificationConfiguration);

    const updateNotificationConfiguration = async (variables: UpdateNotificationConfigurationMutationVariables) => {
        try {
            const response = await mutation({
                ...defaultUpdateNotificationConfigurationOptions,
                ...options,
                variables
            });

            return {
                error: response.errors,
                data: response.data
            };
        } catch (err) {
            return {
                error: err
            };
        }
    };

    return {
        isLoading: loading,
        error,
        data,
        updateNotificationConfiguration
    };
};
