import styled from "styled-components";
import { COLORS } from "@style/colors";

export const InquiryStatusHistoryStyle = styled.div`
    padding: 50px;
    background-color: ${COLORS.background};

    .title {
        font-size: 18px;
    }

    .subText {
        color: ${COLORS.textTransparent(0.3)};
    }

    .mainText {
        margin-left: 30px;
        flex: 1;
    }

    .mainText .sub {
        color: ${COLORS.textTransparent(0.6)};
        font-size: 0.9em;
    }

    .right {
        float: right;
    }

    .timelineBody {
        margin-left: 20px;
        padding: 20px 40px 20px 20px;
        background: white;
        border-radius: 5px;
        box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.01), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);

        display: flex;
    }

    .ant-timeline-item-head {
        background-color: ${COLORS.background};
        margin-top: 20px;
    }

    .ant-timeline-item-tail {
        margin-top: 20px;
    }

    .ant-timeline-item:last-child > .ant-timeline-item-tail{
        display: none;
    }

    .ant-collapse {
        margin-left: 20px;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        padding: 0;

        .timelineBody {
            margin-left: 0;
        }
    }

    .ant-collapse-content-box {
        background: none;

        > span > header {
            margin: 0;

            margin-top: 1em;
            margin-bottom: 1em;
        }

        .flexContainer {
            padding: 0;
        }
    }
`;
