import { Table, Icon } from "antd";
import { ColumnProps } from "antd/lib/table";
import * as moment from "moment";
import { FunctionComponent } from "react";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { useRouter } from "@utils/routerHook";
import { parseAddressToString } from "@utils/parseAddressToString";
import { InnerContainer } from "@style/innerContainer";
import { CollectionRoundTaskFragment, InquiryProductFragment, DayPart } from "@models/graphql";
import { useGetTruckSchedule } from "@graphql/hocs/hooks/useGetTruckSchedule";

import { TruckScheduleTableStyle } from "./truckScheduleTableStyle";
import { ErrorMessage } from "@components/errorMessage/errorMessage";
import { TruckRescheduler } from "@components/truckRescheduler/truckRescheduler";
import { InquiryStatus } from "@components/inquiryStatus/inquiryStatus";
import { DATE_FORMAT } from "@utils/consts";

export interface TruckScheduleTableProps { }

export const TruckScheduleTable: FunctionComponent<TruckScheduleTableProps> = ({ }) => {
    const {
        match: {
            params: { date, truckId }
        },
        history
    } = useRouter<{ truckId: string; date: string }>();

    const { truckSchedule, truckScheduleError, truckScheduleLoading } = useGetTruckSchedule({
        variables: {
            date,
            truckId
        }
    });

    const { productCombinations, afternoonTakss: afternoonTaks, fullDayTasks, morningTasks } = React.useMemo(() => {
        const prodCombs: InquiryProductFragment["product"][][] = [];
        const tFullDay: CollectionRoundTaskFragment[][] = [];
        const tAfternoon: CollectionRoundTaskFragment[][] = [];
        const tMorning: CollectionRoundTaskFragment[][] = [];

        if (truckSchedule) {
            truckSchedule.tasks.forEach(truckTask => {
                const { task } = truckTask;
                if (!task) {
                    return;
                }

                let index = prodCombs.findIndex(comb =>
                    comb.length === task.inquiry.products.length &&
                    comb.every(p => task.inquiry.products.some(prod => prod.product.id === p.id)));

                if (index <= -1) {
                    index = prodCombs.push(task.inquiry.products.map(p => p.product)) - 1;
                }

                if (truckTask.dayPart === DayPart.Afternoon) {
                    if (!tAfternoon[index]) {
                        tAfternoon[index] = [task];
                    } else {
                        tAfternoon[index].push(task);
                    }
                } else if (truckTask.dayPart === DayPart.Morning) {
                    if (!tMorning[index]) {
                        tMorning[index] = [task];
                    } else {
                        tMorning[index].push(task);
                    }
                } else {
                    if (!tFullDay[index]) {
                        tFullDay[index] = [task];
                    } else {
                        tFullDay[index].push(task);
                    }
                }
            });
        }
        return {
            productCombinations: prodCombs,
            fullDayTasks: tFullDay,
            morningTasks: tMorning,
            afternoonTakss: tAfternoon
        };
    }, [truckSchedule]);

    const renderProducts = (record: CollectionRoundTaskFragment): string => {
        if(record.cargoPerProduct) {
            return record.cargoPerProduct.map(prod => `${prod.effectiveCargo} x ${prod.product.name}`).join(", ");
        }

        return record.inquiry.products.map(prod => `${prod.amount} x ${prod.product.name}`).join(", ");
    };

    const columns: ColumnProps<CollectionRoundTaskFragment>[] = [
        {
            // title: "Contact",
            title: <FormattedMessage id="truckScheduleTable.status" />,
            key: "status",
            render(_, record) {
                return <InquiryStatus withPopup status={record.inquiry.currentStatus} />;
            }
        },
        {
            // title: "Contact",
            title: <FormattedMessage id="truckScheduleTable.name" />,
            key: "name",
            render(_, record) {
                return record.inquiry.applicant.name;
            }
        },
        {
            // title: "Ref",
            title: <FormattedMessage id="truckScheduleTable.ref" />,
            key: "ref",
            render(_, record) {
                return record.inquiry.orderNumber;
            }
        },
        {
            // title: "Ref",
            title: <FormattedMessage id="truckScheduleTable.type" />,
            key: "type",
            width: 100,
            render(_, record) {
                return <FormattedMessage id={`collectionRoundTaskType.${record.type}`} />;
            }
        },
        {
            // title: "GSM",
            title: <FormattedMessage id="truckScheduleTable.gsm" />,
            key: "gsm",
            render(_, record) {
                return record.inquiry.applicant.phone;
            }
        },
        {
            // title: "Artiek",
            title: <FormattedMessage id="truckScheduleTable.product" />,
            key: "product",
            render(_, record) {
                return renderProducts(record);
            }
        },
        {
            // title: "Openbaar domein",
            title: <FormattedMessage id="truckScheduleTable.public" />,
            key: "public",
            render(_, record) {
                if (record.inquiry.checklist.publicDomain) {
                    return <FormattedMessage tagName="em" id="yes" />;
                }
                return <FormattedMessage tagName="i" id="no" />;

            }
        },
        {
            title: <FormattedMessage id="truckScheduleTable.address" />,
            key: "address",
            render(_, record) {
                return parseAddressToString(record.inquiry.pickupAddress.address);
            }
        }
    ];

    const onRow = (task: CollectionRoundTaskFragment): { onClick(): void } => ({
        onClick: () => history.push(`/inquiries/${task.inquiry.id}`)
    });

    const renderTable = (tasks: CollectionRoundTaskFragment[][], dayPart: DayPart): JSX.Element | null => {
        if (!tasks.length) {
            return null;
        }

        return (
            <section>
                <h1>
                    {moment(date).format("dddd DD MMMM")} - <FormattedMessage id={`daypart.${dayPart}`} />
                </h1>
                {productCombinations.map((combo, index) => {
                    if (!tasks[index] || !tasks[index].length) {
                        return null;
                    }

                    return (
                        <div key={index}>
                            <h2>{combo.map(prod => prod.name).join(", ")}</h2>
                            <Table
                                dataSource={tasks[index]}
                                rowKey={(record, i) => i.toString()}
                                columns={columns}
                                pagination={false}
                                onRow={onRow}
                            />
                        </div>
                    );
                })}
            </section>
        );
    };

    return (
        <TruckScheduleTableStyle>
            <InnerContainer>
                {truckSchedule && moment().isSame(moment(truckSchedule.date, DATE_FORMAT), "d") && (morningTasks.length || afternoonTaks.length || fullDayTasks.length) ? <TruckRescheduler date={truckSchedule.date} truckId={truckSchedule.truck.id} scheduleId={truckSchedule.id} /> : null}
                <ErrorMessage error={truckScheduleError} />

                {truckScheduleLoading && <Icon className="loader" type="loading" />}
                {renderTable(morningTasks, DayPart.Morning)}
                {renderTable(afternoonTaks, DayPart.Afternoon)}
                {renderTable(fullDayTasks, DayPart.Fullday)}

                {!morningTasks.length && !afternoonTaks.length && !fullDayTasks.length && !truckScheduleLoading && !truckScheduleError ? <FormattedMessage id="truckScheduleTable.noTaks" /> : null}
            </InnerContainer>
        </TruckScheduleTableStyle>
    );
};
