import { useApolloClient } from "@apollo/react-hooks";
import { ErrorMessage } from "@components/errorMessage/errorMessage";
import { Permission, PERMISSIONS } from "@components/permission/permission";
import { useDeleteAsset } from "@graphql/hocs/hooks/useDeleteAsset";
import { useGetAssets } from "@graphql/hocs/hooks/useGetAssets";
import { useUpdateAsset } from "@graphql/hocs/hooks/useUpdateAsset";
import { Asset, SortOrder, Status } from "@models/graphql";
import { Icon } from "@style/icon";
import { useFormatMessage } from "@utils/intlHook";
import { parseAddressToString } from "@utils/parseAddressToString";
import { parseError } from "@utils/parseError";
import { useQueryParameters } from "@utils/useQueryParameters";
import { Icon as AntIcon, message, Modal, Popconfirm, Select, Table } from "antd";
import { ColumnProps, PaginationConfig, SorterResult } from "antd/lib/table";
import { NormalizedCache } from "apollo-cache-inmemory";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { QRCode } from "react-qr-svg";
import { AssetsTableStyle } from "./assetsTableStyle";

export interface AssetsTableProps {
    editCallback(item: Asset): void;
    deleteCallback(itemId: string | null): void;
    deleteId: string | null;
}

export interface AssetsTableFilter {
    assetTypeIds?: string[];
    statusses?: Status[];
    municipalityIds?: string[];
    page: number;
    pageSize: number;
    sortOrder: SortOrder;
    sortField: string;
    search?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const assetsTableFilter = () =>
    useQueryParameters<AssetsTableFilter>(
        "/assets{?assetTypeIds,statusses,municipalityIds,page,pageSize,search,sortOrder,sortField}",
        {
            page: 1,
            pageSize: 20,
            sortField: "lastUpdated",
            sortOrder: SortOrder.Desc
        },
        {
            assetTypeIds: "array",
            municipalityIds: "array",
            page: "number",
            pageSize: "number",
            statusses: "array"
        }
    );

export const AssetsTable: FunctionComponent<AssetsTableProps> = ({ deleteCallback, deleteId, editCallback }) => {
    const formatMessage = useFormatMessage();
    const [updateId, setUpdateId] = React.useState<string | null>(null);
    const client = useApolloClient();
    const { deleteAsset, isLoading: deleteLoading } = useDeleteAsset();
    const { parameters: filter, updateParameters: updateFilter } = assetsTableFilter();
    const { assets, assetsCount, assetsError, assetsLoading } = useGetAssets({
        variables: {
            filter: {
                search: filter.search,
                status: filter.statusses,
                assetTypeIds: filter.assetTypeIds
            },
            paging: {
                limit: filter.pageSize,
                offset: (filter.page - 1) * filter.pageSize
            }
        }
    });

    const { updateAsset } = useUpdateAsset();

    const handleTableChange = (pagination: PaginationConfig, filters: Record<keyof Asset, string[]>, sorter: SorterResult<Asset>): void => {
        const newFilter: Partial<AssetsTableFilter> = {};

        if (sorter) {
            if (!sorter.columnKey) {
                newFilter.sortOrder = undefined;
                newFilter.sortField = undefined;
            } else {
                newFilter.sortOrder = sorter.order === "ascend" ? SortOrder.Asc : SortOrder.Desc;
                newFilter.sortField = sorter.columnKey;
            }
        }

        if (pagination.current) {
            newFilter.page = pagination.current;
        }

        if (pagination.pageSize) {
            newFilter.pageSize = pagination.pageSize;
        }

        updateFilter(newFilter);
    };

    const columns: ColumnProps<Asset>[] = [
        {
            key: "name",
            title: formatMessage({ id: "assetsTable.name" }),
            dataIndex: "name"
        },
        {
            key: "assetType",
            title: formatMessage({ id: "assetsTable.assetType" }),
            render(_, record) {
                return record.type.name;
            }
        },
        {
            key: "qrCode",
            title: formatMessage({ id: "assetsTable.qrCode" }),
            render(_, record) {
                return record.tagCode;
            }
        },
        {
            key: "status",
            title: formatMessage({ id: "assetsTable.status" }),
            width: 200,
            render(_, record) {
                const handleChange = async (val: Status): Promise<void> => {
                    setUpdateId(record.id);
                    const { error } = await updateAsset(
                        {
                            asset: {
                                assetTypeId: record.type.id,
                                name: record.name,
                                status: val,
                                tagCode: record.tagCode
                            },
                            id: record.id
                        },
                        {
                            optimisticResponse: {
                                updateAsset: {
                                    ...(record as any),
                                    status: val
                                },
                                __typename: "Mutation"
                            }
                        }
                    );

                    if (error) {
                        message.error(parseError(error, formatMessage));
                    } else {
                        message.success(formatMessage({ id: "assetPage.updateSuccesful" }));
                    }

                    setUpdateId(null);
                };

                return (
                    <Permission fallback={<FormattedMessage id={`Status.${record.status}`} />} requiredPermissions={[PERMISSIONS.assets.update]}>
                        <Select
                            loading={record.id === updateId}
                            onChange={handleChange}
                            value={record.status}
                            style={{ width: 150 }}
                            placeholder={formatMessage({ id: "assetForm.status" })}
                        >
                            <Select.Option value={Status.InUse}>
                                <FormattedMessage id={`Status.${Status.InUse}`} />
                            </Select.Option>
                            <Select.Option value={Status.Unused}>
                                <FormattedMessage id={`Status.${Status.Unused}`} />
                            </Select.Option>
                            <Select.Option value={Status.Blocked}>
                                <FormattedMessage id={`Status.${Status.Blocked}`} />
                            </Select.Option>
                        </Select>
                    </Permission>
                );
            }
        },
        {
            key: "lastLocation",
            title: formatMessage({ id: "assetsTable.lastLocation" }),
            render(_, record) {
                return record.lastAddress ? parseAddressToString(record.lastAddress as any) : "";
            }
        },
        {
            key: "actions",
            title: formatMessage({ id: "assetsTable.actions" }),
            width: 80,
            render(_, record) {
                const handleDelete = async (): Promise<void> => {
                    try {
                        deleteCallback(record.id);
                        const { error } = await deleteAsset(
                            { id: record.id },
                            {
                                async update(cache) {
                                    const data: NormalizedCache = (cache as any).data;
                                    Object.keys((data as any).data).forEach(key => key.match(/^Asset/) && data.delete(key));
                                    Object.keys((data as any).data).forEach(key => key.match(/^\$ROOT_QUERY\.assets/) && data.delete(key));

                                    await client.reFetchObservableQueries();
                                }
                            }
                        );

                        if (error) {
                            message.error(parseError(error, formatMessage));
                        } else {
                            message.success(formatMessage({
                                id: "collectorsTable.deleteSuccessful"
                            }));
                        }
                        deleteCallback(null);
                    } catch (error) {
                        message.error(parseError(error, formatMessage));
                    }
                };
                return (
                    <div className="buttons">

                        <Icon
                            onClick={() =>
                                Modal.confirm({
                                    icon: null,
                                    okCancel: false,
                                    width: 415,
                                    content:
                                        <>
                                            {" "}
                                            <QRCode bgColor="#FFFFFF" fgColor="#000000" level="Q" style={{ width: 350 }} value={record.tagCode} />
                                        </>

                                })
                            }
                            type="qr"
                        />
                        <Permission requiredPermissions={[PERMISSIONS.assets.update]}>
                            <Icon type="pencil" onClick={() => editCallback(record)} />
                        </Permission>
                        <Permission requiredPermissions={[PERMISSIONS.assets.delete]}>
                            {record.id === deleteId && deleteLoading ?
                                <AntIcon type="loading" />
                                :
                                <Popconfirm
                                    onConfirm={handleDelete}
                                    placement="left"
                                    title={formatMessage({
                                        id: "collectorsPage.confirmDelete"
                                    })}
                                >
                                    <Icon type="trash"></Icon>
                                </Popconfirm>
                            }
                        </Permission>
                    </div>
                );
            }
        }
    ];

    return (
        <AssetsTableStyle>
            <ErrorMessage error={assetsError} />
            <Table
                columns={columns}
                rowKey="id"
                dataSource={assets}
                loading={assetsLoading}
                onChange={handleTableChange}
                pagination={{
                    hideOnSinglePage: false,
                    pageSize: filter.pageSize,
                    position: "both",
                    disabled: false,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    total: assetsCount,
                    current: filter.page
                }}
            />
        </AssetsTableStyle>
    );
};
