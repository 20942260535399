import * as React from "react";
import { FunctionComponent } from "react";
import { CSSTransition } from "react-transition-group";

import { Icon } from "@style/icon";

import { MutationLoadingStyle } from "./mutationLoadingStyle";

export interface MutationLoadingProps {
    isLoading: boolean;
    done?: boolean;
    error?: boolean;
}

export const MutationLoading: FunctionComponent<MutationLoadingProps> = ({ isLoading, done, error }) => <>
    <CSSTransition unmountOnExit appear in={isLoading} timeout={300} >
        <MutationLoadingStyle>
            <div className="bounce b1"></div>
            <div className="bounce b2"></div>
            <div className="bounce b3"></div>
        </MutationLoadingStyle>
    </CSSTransition>
    <CSSTransition unmountOnExit appear in={error} timeout={300} >
        <MutationLoadingStyle>
            <Icon type="cross" />
        </MutationLoadingStyle>
    </CSSTransition>
    <CSSTransition unmountOnExit appear in={done} timeout={300} >
        <MutationLoadingStyle>
            <Icon type="checkmark" />
        </MutationLoadingStyle>
    </CSSTransition>
</>;
