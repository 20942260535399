import { Button, Modal, Upload } from "antd";
import { RcFile, UploadChangeParam } from "antd/lib/upload";
import * as React from "react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { useFormatMessage } from "@utils/intlHook";

import { UploaderStyle } from "./uploaderStyle";
import { RcCustomRequestOptions, UploadFile } from "antd/lib/upload/interface";

export interface UploaderProps {
    value?: any[];
    onChange?: (value: any) => void;
    onRemove?: (file: UploadFile) => boolean | void | Promise<boolean | void>;
    valueExtractor?: (value: any) => string;

    imageHandler?(images: string[]): Promise<void> | void;

    readonly?: boolean;

    customRequest?: ((options: RcCustomRequestOptions, base64?: string) => void);

    max?: number;
}

export const Uploader: React.FunctionComponent<UploaderProps> = ({ imageHandler, value, valueExtractor, onChange, readonly, customRequest, onRemove, max }) => {
    const formatMessage = useFormatMessage();

    const [imagesAmount, setImagesAmount] = useState(0);
    const [previewImage, setPreviewImage] = useState<string | undefined>(undefined);

    React.useEffect(() => {
        if (value) {
            setImagesAmount(value.length);
        }
    }, [value]);

    const isAllowed = (): boolean => max === undefined || imagesAmount < max;

    const getBase64 = (img: RcFile): Promise<string> => new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            if (reader.result) {
                resolve(reader.result.toString());
            } else {
                reject(new Error("Error while reading image as Base64"));
            }
        };

        reader.onerror = reject;

        reader.readAsDataURL(img);
    });

    const processImages = async (options): Promise<void> => {
        if (isAllowed()) {
            const { onSuccess, file } = options;

            const imageUrl = await getBase64(file);

            if (customRequest) {
                customRequest(options, imageUrl);
            } else {
                onSuccess(imageUrl);
            }
        }
    };

    const handlePreview = (file: UploadFile): void => {
        if (file.url || file.response.url) {
            setPreviewImage(file.url || file.response.url);
        } else {
            setPreviewImage(file.response);
        }
    };

    const onUploadChange = (info: UploadChangeParam): void => {
        if (!info.event && isAllowed()) {
            if (!readonly) {
                setImagesAmount(info.fileList.length);

                if (imageHandler) {
                    imageHandler(info.fileList.map(file => file.url || file.response));
                }

                if (onChange) {
                    onChange(info.fileList.map(file => file.url || file.response));
                }
            }
        }
    };
    
    const onBeforeUpload = (file: RcFile, fileList: RcFile[]): boolean | Promise<void> => isAllowed();

    let infoNode: React.ReactNode = 
        <>
            { isAllowed() && <FormattedMessage id="incidentDetail.feedbackUploadInfo" /> }
            { !isAllowed() && <FormattedMessage id="createInquiryPage.mediaMax" /> }
            <Button type="primary" disabled={!isAllowed()}>
                <FormattedMessage id="incidentDetail.feedbackUploadButton" />
            </Button>
        </>;

    if (readonly) {
        let messageId = "incidentDetail.feedbackUploadInfoReadonly";

        if (!value || value.length === 0) {
            messageId = "incidentDetail.feedbackUploadInfoReadonlyNoPhotos";
        }

        infoNode = formatMessage({ id: messageId });
    }

    return (
        <UploaderStyle>
            <Upload.Dragger
                listType="picture-card"
                defaultFileList={value && value
                    .map(val => val && valueExtractor ? valueExtractor(val) : val)
                    .map((val, index) => ({
                        uid: String(index),
                        name: val,
                        url: val,
                        thumbUrl: val,
                        size: 50,
                        type: "jpg"
                    }))}
                accept=".jpg,.png"
                onPreview={handlePreview}
                customRequest={processImages}
                onChange={onUploadChange}
                onRemove={onRemove}
                showUploadList={{
                    showDownloadIcon: false,
                    showPreviewIcon: true,
                    showRemoveIcon: !!onRemove
                }}
                beforeUpload={onBeforeUpload}
                disabled={!isAllowed()}
            >
                {infoNode}
            </Upload.Dragger>
            <Modal width={600} onCancel={() => setPreviewImage(undefined)} footer={null} visible={!!previewImage}>
                <img style={{ width: "100%", marginTop: 35 }} alt="image" src={previewImage} />
            </Modal>
            <span style={{ clear: "both" }}></span>
        </UploaderStyle>
    );
};
