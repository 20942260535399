import * as React from "react";
import { FunctionComponent } from "react";

import { useGetFormValues, useForm } from "@components/patternOverviewTab/patternFormProvider";
import { PatternDayBlock } from "@components/patternOverviewTab/patternDayBlock/patternDayBlock";

import { PatternOverviewTabStyle } from "./patternOverviewTabStyle";
import { useFormatMessage } from "@utils/intlHook";
import { Form, InputNumber, Spin } from "antd";
import { FormattedMessage } from "react-intl";
import { useEditCollectionPattern } from "@components/patternOverviewTab/patternEdittingProvider";

export interface PatternOverviewTabProps {
    className?: string;
}

export const PatternOverviewTab: FunctionComponent<PatternOverviewTabProps> = ({ className }) => {
    const formatMessage = useFormatMessage();
    const { getFieldDecorator } = useForm();
    const { dayCount } = useGetFormValues();
    const { editPatternItem, isEditting, editLoading } = useEditCollectionPattern();

    const renderDays = (): JSX.Element[] => {
        const days: JSX.Element[] = [];

        for (let i = 0; i < (dayCount || 1); i += 1) {
            days.push(<PatternDayBlock key={i} dayIndex={i} />);
        }

        return days;
    };

    return (
        <Spin spinning={editLoading}>

            <PatternOverviewTabStyle className={className}>
                <header className="pageHeader">
                    <FormattedMessage id="patternDetail.pattern" />
                </header>
                <Form.Item label={formatMessage({ id: "patternForm.amountOfDaysPlaceholder" })}>
                    {getFieldDecorator("dayCount", {
                        initialValue: editPatternItem ? editPatternItem.dayCount : 1,
                        rules: [
                            { required: true, message: formatMessage({ id: "requiredField" }) }
                        ]
                    })(<InputNumber disabled={isEditting} min={1} placeholder={formatMessage({ id: "patternForm.amountOfDaysPlaceholder" })} />)}
                </Form.Item>
                {renderDays()}
            </PatternOverviewTabStyle>
        </Spin>
    );
};
