import * as React from "react";
import { FunctionComponent } from "react";

import { IMAGES } from "@assets/images";

import { LoadingPageStyle } from "./loadingPageStyle";

export interface LoadingPageProps {}

export const LoadingPage: FunctionComponent<LoadingPageProps> = ({}) => 
    <LoadingPageStyle>
        <img src={IMAGES.logo} alt="Logo" />

        <h1>Laden...</h1>
    </LoadingPageStyle>
    ;
