import styled from "styled-components";

export const MarkerStyle = styled.div`
    margin-left: -15px;
    margin-top: -42px;

    img {
        width: 30px;
        height: 42px;
    }
`;
