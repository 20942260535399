import * as React from "react";
import { FunctionComponent } from "react";
import { AssetsFilterStyle } from "./assetsFilterStyle";

import { Icon } from "@style/icon";
import { FormattedMessage } from "react-intl";
import { useGetAssetTypes } from "@graphql/hocs/hooks/useGetAssetTypes";
import { Spinner } from "@components/spinner/spinner";
import { Checkbox, Select, Button } from "antd";
import { assetsTableFilter } from "@components/assetsTable/assetsTable";
import { useFormatMessage } from "@utils/intlHook";
import { parseStringArray } from "@utils/useQueryParameters";
import { Status } from "@models/graphql";
import { useGetMunicipalities } from "@graphql/hocs/hooks/useGetMunicipalities";
import { SearchBox } from "@components/inquiryOverviewFilter/inquiryOverviewFilterStyle";

export interface AssetsFilterProps {}

export const AssetsFilter: FunctionComponent<AssetsFilterProps> = ({}) => {
    const formatMessage = useFormatMessage();
    const { parameters: filter, updateParameters: updateFilter } = assetsTableFilter();

    const { assetTypes, assetTypesLoading } = useGetAssetTypes();
    const { municipalities, municipalitiesLoading } = useGetMunicipalities();

    const handleSearch = (ev: React.ChangeEvent<HTMLInputElement>): void => {
        updateFilter({
            search: ev.target.value || undefined
        });
    };

    const handleAssetTypeChange = (value: string[]): void => {
        updateFilter({
            assetTypeIds: value
        });
    };

    const handleStatusChange = (value: string[]): void => {
        updateFilter({
            statusses: value as Status[]
        });
    };

    const handleMunicipalityChange = (value: string[]): void => {
        updateFilter({
            municipalityIds: value,
            page: 1
        });
    };

    const resetFilter = (): void => updateFilter({}, true);

    const notFiltered = React.useMemo(() => !filter.assetTypeIds && !filter.municipalityIds && !filter.search && !filter.statusses, [filter]);

    return (
        <AssetsFilterStyle>
            {!notFiltered && 
                <Button onClick={resetFilter}>
                    <Icon type="reset" /> Reset filter
                </Button>
            }
            <section>
                <SearchBox>
                    <Icon type="search" />
                    <input value={filter.search || ""} onChange={handleSearch} placeholder={formatMessage({ id: "assetsFilter.search" })} />
                </SearchBox>
            </section>
            <section>
                <FormattedMessage id="assetsFilter.assetTypes" tagName="h1" />
                {assetTypesLoading && <Spinner />}
                <Checkbox.Group onChange={handleAssetTypeChange} value={parseStringArray(filter.assetTypeIds)}>
                    {assetTypes.map(assetType => 
                        <Checkbox key={assetType.id} value={assetType.id}>
                            {assetType.name}
                        </Checkbox>)}
                </Checkbox.Group>
            </section>
            <section>
                <FormattedMessage id="assetsFilter.status" tagName="h1" />
                <Checkbox.Group value={parseStringArray(filter.statusses)} onChange={handleStatusChange}>
                    <Checkbox value={Status.Blocked}>
                        <FormattedMessage id={`Status.${Status.Blocked}`} />{" "}
                    </Checkbox>
                    <Checkbox value={Status.InUse}>
                        <FormattedMessage id={`Status.${Status.InUse}`} />{" "}
                    </Checkbox>
                    <Checkbox value={Status.Unused}>
                        <FormattedMessage id={`Status.${Status.Unused}`} />{" "}
                    </Checkbox>
                </Checkbox.Group>
            </section>
            <section>
                <FormattedMessage id="assetsFilter.municipalities" tagName="h1" />
                <Select
                    value={parseStringArray(filter.municipalityIds)}
                    onChange={handleMunicipalityChange}
                    loading={municipalitiesLoading}
                    filterOption={(val, el) => (el.props.children as string).toLowerCase().includes(val.toLowerCase())}
                    placeholder={formatMessage({ id: "assetsFilter.municipalities" })}
                    mode="multiple"
                >
                    {municipalities.map(m => 
                        <Select.Option key={m.id} value={m.id}>
                            {m.name}
                        </Select.Option>)}
                </Select>
            </section>
        </AssetsFilterStyle>
    );
};
