import * as React from "react";
import { FunctionComponent } from "react";
import { PlanningSideBarStyle } from "./planningSideBarStyle";
import { ToBeRescheduledList } from "@components/planningSideBar/toBeRescheduledList/toBeRescheduledList";
import { SearchBar } from "@components/planningSideBar/searchBar/searchBar";
import { planningParams } from "@pages/planningPage/planningPage";
import { InquirySearchResults } from "@components/planningSideBar/inquirySearchResults/inquirySearchResults";

export interface PlanningSideBarProps {
    hideInquiries: string[];
}

export const PlanningSideBar: FunctionComponent<PlanningSideBarProps> = ({ hideInquiries }) => {
    const { parameters } = planningParams();

    return (
        <PlanningSideBarStyle>
            <SearchBar />
            {parameters.search ? <InquirySearchResults hideInquiries={hideInquiries} /> : <ToBeRescheduledList hideInquiries={hideInquiries} />}
        </PlanningSideBarStyle>
    );
};
