import { Button, Col, Form, Icon, Row, Select } from "antd";
import { FormComponentProps } from "antd/lib/form";
import * as Papa from "papaparse";
import { FunctionComponent } from "react";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { useFormatMessage } from "@utils/intlHook";
import { InnerContainer } from "@style/innerContainer";
import { WeightInputItem } from "@models/graphql";
import { useUploadWeightData } from "@graphql/hocs/hooks/useUploadWeightData";
import { useGetProcessors } from "@graphql/hocs/hooks/useGetProcessors";
import { ErrorMessage } from "@components/errorMessage/errorMessage";

import { ErrorIds, Overlay, SuccesIds, UploadWeightDataPageStyle } from "./uploadWeightDataPageStyle";

export interface UploadWeightDataPageProps extends FormComponentProps {

}

interface FormValues {
    idColumn: string;
    processorId: string;
    weightColumn: string;
}

export const UploadWeightDataPageComponent: FunctionComponent<UploadWeightDataPageProps> = ({ form }) => {
    const formatMessage = useFormatMessage();
    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const [fields, setFields] = React.useState<string[] | null>(null);
    const [data, setData] = React.useState<any[] | null>(null);
    const [fileName, setFileName] = React.useState<string | null>(null);

    const { processors, processorsLoading } = useGetProcessors();
    const { data: uploadData, error, isLoading, uploadWeightData } = useUploadWeightData();

    const idColumnValue = form.getFieldValue("idColumn");

    const handleFile = (ev: React.ChangeEvent<HTMLInputElement>): void => {
        if (ev.target.files) {
            const file = ev.target.files.item(0);

            if (file) {
                Papa.parse(file, {
                    header: true,
                    skipEmptyLines: true,
                    complete(r) {
                        setFields(r.meta.fields);
                        setData(r.data);
                    }
                });

                setFileName(file.name);
            }
        }
    };

    const handleClick = (): void => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    const handleSubmit = (): void => {
        form.validateFields((errors, { idColumn, processorId, weightColumn }: FormValues) => {

            if (errors || !data) {
                return;
            }

            uploadWeightData({
                processorId,
                weightInput: data.map<WeightInputItem>(item => ({
                    id: item[idColumn],
                    weight: item[weightColumn]
                }))
            });
        });
    };

    return (
        <UploadWeightDataPageStyle>
            <InnerContainer>
                <Form>
                    <Row gutter={20}>
                        <Col span={24}>
                            <Form.Item label={formatMessage({ id: "uploadWeightData.processor" })}>
                                <input accept=".csv" style={{ display: "none" }} ref={inputRef} onChange={handleFile} type="file" />

                                <Button onClick={handleClick}>
                                    <Icon type="upload" /> <FormattedMessage id="uploadWeightData.uploadCsv" />
                                </Button>
                                <div className="fileName">
                                    {fileName}
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Overlay visible={!data}>
                        <div className="cover">
                            <FormattedMessage id="uploadWeightData.uploadFirst" />
                        </div>
                        <Row gutter={20}>
                            <Col span={24}>
                                <Form.Item label={formatMessage({ id: "uploadWeightData.processor" })}>
                                    {form.getFieldDecorator("processorId", {
                                        rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }]
                                    })(<Select loading={processorsLoading} placeholder={formatMessage({ id: "uploadWeightData.processor" })}>
                                        {processors.map(processor => <Select.Option key={processor.id} value={processor.id}>{processor.name}</Select.Option>)}
                                    </Select>)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={12}>
                                <Form.Item label={formatMessage({ id: "uploadWeightData.idColumn" })}>
                                    {form.getFieldDecorator("idColumn", {
                                        rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }]
                                    })(<Select placeholder={formatMessage({ id: "uploadWeightData.idColumn" })}>
                                        {fields && fields.map(field => <Select.Option key={field} value={field}>{field}</Select.Option>)}
                                    </Select>)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={formatMessage({ id: "uploadWeightData.weightColumn" })}>
                                    {form.getFieldDecorator("weightColumn", {
                                        rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }]
                                    })(<Select placeholder={formatMessage({ id: "uploadWeightData.weightColumn" })}>
                                        {fields && fields.map(field => <Select.Option key={field} value={field}>{field}</Select.Option>)}
                                    </Select>)}
                                </Form.Item>
                            </Col>
                        </Row>

                        {uploadData && !uploadData.uploadWeightData.succeeded && <ErrorIds>
                            <FormattedMessage tagName="h1" id="uploadWeightData.missingIds" />
                            {uploadData.uploadWeightData.errorIds.map(id => <div key={id}>{id}</div>)}
                        </ErrorIds>}

                        {uploadData && data && !uploadData.uploadWeightData.succeeded && <SuccesIds>
                            <FormattedMessage tagName="h1" id="uploadWeightData.foundIds" />
                            {data.filter(d => !uploadData.uploadWeightData.errorIds.some(e => e === d[idColumnValue])).map(id => <div key={id}>{id[idColumnValue]}</div>)}
                        </SuccesIds>}

                        <ErrorMessage error={error} />

                        <Button loading={isLoading} onClick={handleSubmit} type="primary">
                            <FormattedMessage id="save" />
                        </Button>
                    </Overlay>
                </Form>
            </InnerContainer>
        </UploadWeightDataPageStyle>
    );
};

export const UploadWeightDataPage = Form.create<UploadWeightDataPageProps>()(UploadWeightDataPageComponent);
