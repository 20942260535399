import styled from "styled-components";
import { COLORS } from "@style/colors";

export const StepStyle = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    &:not(:last-of-type) {
        margin-right: 40px;
    }

    .icon {
        font-size: 6px;
    }

    & > span {
        opacity: 0.25;
        transition: 0.2s;
    }

    .number {
        width: 18px;
        height: 18px;
        background: rgba(0,0,0,0.15);
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: white;
        margin-right: 10px;
        transition: 0.2s;
    }

    &.done, &.current {
        .number {
            background-color: ${COLORS.primary};
        }
    }

    &.done {
        & > span {
            opacity: 0.5;
        }
    }

    &.current {
        & > span {
            opacity: 1;
        }
    }

    &:hover {
        & > span {
            opacity: 1;
        }
    }
`;
