import styled from "styled-components";

import { COLORS } from "@style/colors";

export const CsvDownloadModalStyle = styled.div`
    header {
        display: flex;
        align-items: center;
        font-weight: bold;
        padding: 15px;
        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: ${COLORS.primary};
            border-radius: 100%;
            width: 20px;
            height: 20px;
            color: white;
            font-size: 6px;
            margin-right: 10px;
        }
    }

    input {
        position: absolute;
        left: -10000px;
    }

    main {
        background: #F8FAFB;
        padding: 15px;

        h1 {
            font-size: 14px;
        }

        a {
            color: ${COLORS.textTransparent(0.5)};
        }
    }

    footer {
        padding: 15px;
        display: flex;
        justify-content: flex-end;

        .ant-btn {
            margin-left: 15px;
        }
    }
`;
