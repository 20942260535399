import * as React from "react";
import { FunctionComponent } from "react";
import { VariableStyle } from "./variableStyle";
import { Tooltip } from "antd";
import { useFormatMessage } from "@utils/intlHook";
import { ContentState } from "draft-js";

export interface VariableProps {
    offsetKey: string;
    decoratedText: string;
    contentState: ContentState;
    entityKey: string;
}

export const Variable: FunctionComponent<VariableProps> = ({ children, offsetKey, contentState, entityKey }) => {

    const { variable, invalid } = contentState.getEntity(entityKey).getData();
    const formatMessage = useFormatMessage();

    return (
        <VariableStyle invalid={invalid} data-offset-key={offsetKey}>
            <Tooltip trigger="click" title={invalid ? formatMessage({ id: "variable.notAllowed" }) : formatMessage({ id: `variable.${variable}` })}>
                {children}
            </Tooltip>
        </VariableStyle>
    );
};
