import styled from "styled-components";
import { COLORS } from "@style/colors";

export const StepIconStyle = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s;

    &.done {
        background-color: #fff;
        color: ${COLORS.primary};
        font-size: 6px;
    }

    &.notdone {
        background-color: #D5D5D5;
        color: ${COLORS.text};
        font-size: 12px;
    }
`;
