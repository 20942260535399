import { ErrorMessage } from "@components/errorMessage/errorMessage";
import { PageHeader } from "@components/pageHeader/pageHeader";
import { Permission, PERMISSIONS } from "@components/permission/permission";
import { ProductDetailModal } from "@components/productDetailModal/productDetailModal";
import { useGetProductNames } from "@graphql/hocs/hooks/useGetProductNames";
import { ProductFragment } from "@models/graphql";
import { Icon } from "@style/icon";
import { InnerContainer } from "@style/innerContainer";
import { Button, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { ProductsPageStyle } from "./productsPageStyle";

export interface ProductsPageProps {

}

export const ProductsPage: FunctionComponent<ProductsPageProps> = () => {
    const { products, productsError, productsLoading } = useGetProductNames();
    const [editItemId, setEditItem] = React.useState<null | string>(null);
    const [modalVisibile, setModalVisible] = React.useState(false);

    const columns: ColumnProps<ProductFragment>[] = [
        {
            title: <FormattedMessage id="productsTable.name" />,
            dataIndex: "name",
            key: "name",
            render(_, record) {

                return <span className="name">
                    {record.name}
                </span>;
            }
        },
        {
            key: "buttons",
            width: 60,
            render(_, record) {
                return <span className="buttons">
                    <Permission requiredPermissions={[PERMISSIONS.products.update]}>
                        <Icon onClick={() => {
                            setEditItem(record.id);
                            setModalVisible(true);
                        }} type="pencil"
                        />
                    </Permission>
                </span>;
            }
        }
    ];

    const handleClose = (): void => {
        setModalVisible(false);
        setEditItem(null);
    };

    return (
        <ProductsPageStyle>
            <PageHeader title="productsPage.title">
                <Permission requiredPermissions={[PERMISSIONS.products.create]}>
                    <Button onClick={() => setModalVisible(true)} type="primary" ><FormattedMessage id="productsPage.addProduct" /></Button>
                </Permission>
            </PageHeader>

            <ProductDetailModal visible={modalVisibile} closeCallback={handleClose} editId={editItemId} />

            <InnerContainer>
                <ErrorMessage error={productsError} />
                <Table
                    rowKey="id"
                    dataSource={products}
                    loading={productsLoading}
                    columns={columns}
                />
            </InnerContainer>
        </ProductsPageStyle>
    );
};
