import { COLORS } from "@style/colors";
import styled from "styled-components";

export const PlanningWeekOverviewStyle = styled.div`
    flex: 1;
    z-index: 0;
    overflow: auto;
    height: calc(100vh - 145px);
    display: grid;

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        background-color: ${COLORS.lightGrey};
        padding: 0;
    }

    .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box{
        padding: 0;
    }

    .ant-collapse-borderless {
        background: transparent;
    }

    .weekHeader {
        margin-left: 15px;

        .inset {
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 25px;
            width: calc(100vw - 410px);
        }

        .date {
            color: ${COLORS.primary};
        }

        .addTruck {
            color: ${COLORS.primary};
            cursor: pointer;


            .anticon {
                margin-right: 10px;
            }

            &:hover:not(.loading) {
                opacity: 0.6;
            }
        }
    }
`;
