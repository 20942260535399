import { useReactOidc } from "@axa-fr/react-oidc-context";
import { Avatar, Button, Divider, Form, Input, Modal } from "antd";
import * as moment from "moment";
import { Fragment, FunctionComponent, useState } from "react";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { useFormatMessage } from "@utils/intlHook";
import { GetInquiryDetailQuery } from "@models/graphql";
import { useAddIncidentComment } from "@graphql/hocs/hooks/useAddIncidentComment";
import { Comment } from "@components/inquiryIncidentDetail/comment/comment";

import { InquiryIncidentDetailStyle } from "./inquiryIncidentDetailStyle";

export interface InquiryIncidentDetailProps {
    incident: NonNullable<GetInquiryDetailQuery["inquiry"]["statusHistory"][0]["incident"]>;
    timestamp: string | Date;
    inquiryId: string;
    readonly?: boolean;
}

export const InquiryIncidentDetail: FunctionComponent<InquiryIncidentDetailProps> = ({ incident, timestamp, inquiryId, readonly }) => {
    const formatMessage = useFormatMessage();
    const [r, setR] = useState(false);
    const { description, collector, comments, id: incidentId, images } = incident;
    const { oidcUser: user } = useReactOidc();

    const [saveCommentBtn, setSaveCommentBtn] = useState(false);
    const [commentValue, setSCommentValue] = useState("");

    const { addIncidentComment, isLoading } = useAddIncidentComment({});

    const onInputChange = ({ target }): void => {
        const value = target.value;

        setSaveCommentBtn(!!value);
        setSCommentValue(value);
    };

    const addComment = async (e: React.FormEvent): Promise<void> => {
        e.preventDefault();
        const { response } = await addIncidentComment({ incidentId, comment: commentValue });

        if (response && response.data && response.data.addIncidentComment.comments) {
            setSCommentValue("");
            setSaveCommentBtn(false);
        }
    };

    const handleReRender = (): void => {
        setR(!r);
    };

    const renderComments = (): JSX.Element => 
        <Fragment>
            {comments.map(comment => {
                const isMe = user && user.profile.oid === comment.user.id ? true : false;

                return (
                    <Comment
                        key={comment.id}
                        reRenderCallback={handleReRender}
                        incidentId={incidentId}
                        comment={comment}
                        isMe={isMe}
                        inquiryId={inquiryId}
                    />
                );
            })}
            { !readonly && 
                <>
                    <Divider />
                    <div className="newCommentForm">
                        <Form onSubmit={addComment}>
                            <Input placeholder="Schrijf hier een notitie" value={commentValue} onChange={onInputChange}></Input>
                            { saveCommentBtn && 
                                    <Button loading={isLoading} type="primary" onClick={addComment}>
                                        <FormattedMessage id="incidentDetail.addComment" />
                                    </Button>
                            }
                        </Form>
                    </div>
                </>
            }
        </Fragment>
        ;

    return (
        <InquiryIncidentDetailStyle>
            <section>
                <h1>
                    <Avatar icon="bell" className="bell" />
                    <span><FormattedMessage id="incidentDetail.collector" />: {collector.firstname} {collector.lastname}</span>
                    <span className="commentDate">{moment(timestamp).fromNow()}</span>
                </h1>
                <main>
                    <p>{description}</p>

                    <div className="images">
                        {images.map((image, index) => 
                            <img
                                alt="image"
                                onClick={() =>
                                    Modal.info({
                                        okText: formatMessage({ id: "close" }),
                                        width: 900,
                                        icon: null,
                                        content: <img style={{ width: "100%" }} alt="image" src={image} />
                                    })
                                }
                                src={image}
                                key={index}
                            ></img>)}
                    </div>

                    {renderComments()}
                </main>
            </section>
        </InquiryIncidentDetailStyle>
    );
};
