/* eslint-disable */

      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }

      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "UNION",
        "name": "Trigger",
        "possibleTypes": [
          {
            "name": "NotificationTrigger"
          },
          {
            "name": "ReminderTrigger"
          },
          {
            "name": "IncidentTrigger"
          }
        ]
      }
    ]
  }
};

      export default result;
    