import * as React from "react";
import { FunctionComponent } from "react";
import { TruckFormModalStyle } from "./truckFormModalStyle";
import { Modal, Form, Input, Select } from "antd";
import { TruckFragment, TruckInput, GetTrucksQuery } from "@models/graphql";
import { useCreateTruck } from "@graphql/hocs/hooks/useCreateTruck";
import { useUpdateTruck } from "@graphql/hocs/hooks/useUpdateTruck";
import { FormComponentProps } from "antd/lib/form";
import { FormattedMessage } from "react-intl";
import { useFormatMessage } from "@utils/intlHook";
import { useGetTruckTypes } from "@graphql/hocs/hooks/useGetTruckTypes";
import { ErrorMessage } from "@components/errorMessage/errorMessage";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";

export interface TruckFormModalProps extends FormComponentProps {
    visible: boolean;
    editItem: TruckFragment | null;
    closeCallback(): void;
}

export const TruckFormModalComponent: FunctionComponent<TruckFormModalProps> = ({ visible, closeCallback, editItem, form }) => {
    const { createTruck, isLoading: createLoading, error: createError } = useCreateTruck({
        update(cache, response) {
            const trucksData = cache.readQuery<GetTrucksQuery>({ query: GraphQLDocuments.getTrucks });

            if (!trucksData || !response.data || !response.data.createTruck) { return; }

            trucksData.trucks.items.push(response.data.createTruck);

            cache.writeQuery({ query: GraphQLDocuments.getTrucks, data: trucksData });
        }
    });
    const { updateTruck, isLoading: updateLoading, error: updateError } = useUpdateTruck();
    const { truckTypes, truckTypesLoading } = useGetTruckTypes();
    const formatMessage = useFormatMessage();

    const handleClose = (): void => {
        closeCallback();
        form.resetFields();
    };

    const handleSubmit = (): void => {
        form.validateFields(async (errors, values) => {
            if (errors) {
                return;
            }

            const truck: TruckInput = {
                name: values.name,
                suivoID: values.suivoID,
                tagCode: values.tagCode,
                truckTypeId: values.truckTypeId
            };

            if (editItem) {
                const { error } = await updateTruck({
                    id: editItem.id,
                    truck
                });

                if (!error) {
                    handleClose();
                }
            } else {

                const { error } = await createTruck({ truck });

                if (!error) {
                    handleClose();
                }
            }
        });
    };

    return (
        <Modal
            visible={visible}
            okButtonProps={{
                loading: createLoading || updateLoading
            }}
            title={<FormattedMessage id="truckForm.title" />}
            okText={<FormattedMessage id="save" />}
            cancelText={<FormattedMessage id="cancel" />}
            onCancel={handleClose}
            onOk={handleSubmit}
        >
            <TruckFormModalStyle>
                <Form onSubmit={handleSubmit}>
                    <Form.Item label={<FormattedMessage id="truckForm.name" />}>
                        {form.getFieldDecorator("name", {
                            rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }],
                            initialValue: editItem ? editItem.name : undefined
                        })(<Input placeholder={formatMessage({ id: "truckForm.name" })} />)}
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="truckForm.truckType" />}>
                        {form.getFieldDecorator("truckTypeId", {
                            rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }],
                            initialValue: editItem ? editItem.type.id : undefined
                        })(<Select placeholder={formatMessage({ id: "truckForm.truckType" })} loading={truckTypesLoading}>
                            {truckTypes.map(truckType => <Select.Option value={truckType.id} key={truckType.id}>{truckType.name}</Select.Option>)}
                        </Select>)}
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="truckForm.suivoID" />}>
                        {form.getFieldDecorator("suivoID", {
                            rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }],
                            initialValue: editItem ? editItem.suivoID : undefined
                        })(<Input placeholder={formatMessage({ id: "truckForm.suivoID" })} />)}
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="truckForm.tagCode" />}>
                        {form.getFieldDecorator("tagCode", {
                            rules: [
                                { required: true, message: formatMessage({ id: "requiredField" }) },
                                { pattern: /^\d*$/, message: formatMessage({ id: "isNotANumber" }) },
                                { len: 6, message: formatMessage({ id: "isNot6Characters" }) }
                            ],
                            validateTrigger: "submit",
                            initialValue: editItem ? editItem.tagCode : undefined
                        })(<Input maxLength={6} minLength={6} placeholder={formatMessage({ id: "truckForm.suivoID" })} />)}
                    </Form.Item>
                    <ErrorMessage error={createError || updateError} />
                </Form>
            </TruckFormModalStyle>
        </Modal>
    );
};

export const TruckFormModal = Form.create<TruckFormModalProps>()(TruckFormModalComponent);
