import { InquiryAddress, InquiryCoordinates } from "@models/graphql";

export const mapsGeocode = (address: InquiryAddress): Promise<InquiryCoordinates> => {
    const geocode = new google.maps.Geocoder();
    const { city, country, postalCode, street, streetNumber } = address;

    return new Promise<InquiryCoordinates>((resolve, reject) => {
        geocode.geocode(
            {
                address: `${street} ${streetNumber}, ${postalCode} ${city}, ${country}`
            },
            (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    resolve({
                        lat: results[0].geometry.location.lat(),
                        lng: results[0].geometry.location.lng()
                    });
                }

                reject(new Error("Error reverse geo-coding"));
            }
        );
    });
};
