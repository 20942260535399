/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { CreateCollectorMutation, CreateCollectorMutationVariables } from "@models/graphql";
import { useState } from "react";
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { ApolloError } from "apollo-client";

const defaultCreateCollectorOptions: MutationHookOptions<CreateCollectorMutation, CreateCollectorMutationVariables> = {

};

export const useCreateCollector = (options?: MutationHookOptions<CreateCollectorMutation, CreateCollectorMutationVariables>) => {
    const [hookState, setHookState] = useState<{
        isLoading: boolean;
        data?: CreateCollectorMutation;
        error?: ApolloError;
    }>({
        isLoading: false
    });

    const [mutation] = useMutation<CreateCollectorMutation, CreateCollectorMutationVariables>(GraphQLDocuments.createCollector);

    const createCollector = async (variables: CreateCollectorMutationVariables) => {
        setHookState({
            isLoading: true
        });

        try {
            const response = await mutation({
                ...defaultCreateCollectorOptions,
                ...options,
                variables
            });

            setHookState({
                data: response.data,
                isLoading: false
            });

            return {
                error: response.errors,
                data: response.data
            };
        } catch (error) {

            setHookState({
                error,
                isLoading: false
            });

            return {
                error
            };
        }
    };

    return {
        isLoading: hookState.isLoading,
        error: hookState.error,
        data: hookState.data,
        createCollector
    };
};
