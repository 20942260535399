import { InquiryIncident } from "@components/inquiryIncident/inquiryIncident";
import { InquiryDetailFragment, InquiryStatusType } from "@models/graphql";
import { COLORS } from "@style/colors";
import { Avatar, Collapse, Divider, Timeline } from "antd";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import { InquiryStatusHistoryStyle } from "./inquiryStatusHistoryStyle";

export interface InquiryStatusHistoryProps {
    inquiryId: string;
    history: InquiryDetailFragment["statusHistory"];
}

export interface HistoryElementProps {
    key: React.Key;
    element: InquiryDetailFragment["statusHistory"][0];
}

export const InquiryStatusHistory: FunctionComponent<InquiryStatusHistoryProps> = ({ inquiryId, history }: InquiryStatusHistoryProps) => {
    const historyIcons = {
        [InquiryStatusType.Incident]: "bell",
        [InquiryStatusType.Processing]: "mobile",
        [InquiryStatusType.Processed]: "check"
    };

    const HistoryElement = ({ element, key }: HistoryElementProps): JSX.Element => {
        const dot =
            element.type in historyIcons ? (
                <Avatar icon={historyIcons[element.type]} style={{ fontSize: "16px", background: COLORS.status(element.type) }} />
            ) : undefined;
        const hasIncident = !!element.incident;

        const mainBody = (
            <section className="timelineBody">
                <span className="subText">
                    <FormattedDate day="numeric" month="short" year="numeric" hour="2-digit" minute="2-digit" value={element.timestamp} />
                </span>
                <span className="mainText">
                    <FormattedMessage id={`inquiryStatusType.${element.type}`}></FormattedMessage> {element.description}
                    {element.deliveryDate && (
                        <div className="sub">
                            <FormattedMessage id="inquiryDetail.deliveryDate" />{" "}
                            <FormattedDate format="DD/MM/YYYY" value={element.deliveryDate} />
                        </div>
                    )}
                    {element.pickupDate && (
                        <div className="sub">
                            <FormattedMessage id="inquiryDetail.pickupDate" />{" "}
                            <FormattedDate format="DD/MM/YYYY" value={element.pickupDate} />
                        </div>
                    )}
                </span>
                {element.user && (
                    <span className="subText right">
                        {element.user.firstname} {element.user.lastname}
                    </span>
                )}
            </section>
        );

        return (
            <Timeline.Item color={COLORS.status(element.type)} dot={dot}>
                {hasIncident && element.incident && (
                    <Collapse bordered={false} expandIconPosition="right">
                        <Collapse.Panel key={key} header={mainBody}>
                            <InquiryIncident
                                incident={element.incident}
                                inquiryId={inquiryId}
                                timestamp={element.timestamp}
                                products={[]}
                                readonly
                            />
                        </Collapse.Panel>
                    </Collapse>
                )}
                {!hasIncident && mainBody}
            </Timeline.Item>
        );
    };

    return (
        <InquiryStatusHistoryStyle>
            <h1 className="title">
                <FormattedMessage id="inquiryStatusHistory.title" />
            </h1>
            <Divider />

            <Timeline>
                {history.map((elem, i) => (
                    <HistoryElement element={elem} key={i}></HistoryElement>
                ))}
            </Timeline>
        </InquiryStatusHistoryStyle>
    );
};
