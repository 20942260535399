import styled from "styled-components";

export const PrivateRouteStyle = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h1 {
        font-size: 300px;
        font-weight: 700;
        margin: 0;
        opacity: 0.05;
        line-height: 0px;
        margin: -30px;
    }

    h2 {
        font-weight: 600;
        font-size: 22px;
        margin: 0;
    }

    p {
        margin: 15px 0 30px;
    }

`;
