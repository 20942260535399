import { InquiryStatusType } from "@models/graphql";

export const COLORS = {
    text: "#282828",
    textTransparent(opacity: number) { return `rgba(40,40,40,${opacity})`; },
    lightGrey: "#F5F5F5",
    grey: "#BFBFBF",
    background: "#F8FAFB",
    primary: "#A0BC00",
    primaryTransparant(opacity: number) { return `rgba(160, 188, 0, ${opacity})`; },
    secondary: "#03A8B7",
    secondaryTransparant(opacity: number) { return `rgba(3, 168, 183, ${opacity})`; },
    error: "#E74C3C",
    errorLight: "#FFF8F8",
    orange: "#F39C12",
    status(status: InquiryStatusType) {
        switch (status) {
        case InquiryStatusType.Planned: return "#1EAAF4";
        case InquiryStatusType.Delivered: return "#9229D2";
        case InquiryStatusType.Processed: return "#29D277";
        case InquiryStatusType.Incident: return "#FF0000";
        case InquiryStatusType.Processing: return "#FF0000";
        case InquiryStatusType.ToBeRescheduled: return "#FF7A00";
        case InquiryStatusType.Canceled: return "#2F2F2F";
        case InquiryStatusType.OnHold: return "#BBBBBB";
        case InquiryStatusType.WaitingForPayment: return "#E9ED17";
        default: return "white";
        }
    }
};
