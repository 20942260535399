import { InquiryLocation } from "@models/graphql";

export const parseAddress = (result: google.maps.GeocoderResult): InquiryLocation => {
    const city = result.address_components.find(c => c.types.some(type => type === "locality"));
    const country = result.address_components.find(c => c.types.some(type => type === "country"));
    const postalCode = result.address_components.find(c => c.types.some(type => type === "postal_code"));
    const street = result.address_components.find(c => c.types.some(type => type === "route"));
    const streetNumber = result.address_components.find(c => c.types.some(type => type === "street_number"));

    return {
        address: {
            city: city ? city.long_name : "",
            country: country ? country.long_name : "",
            postalCode: postalCode ? postalCode.short_name : "",
            street: street ? street.long_name : "",
            streetNumber: streetNumber ? streetNumber.long_name : ""
        },
        coordinates: {
            lat: result.geometry.location.lat(),
            lng: result.geometry.location.lng()
        }
    };
};
