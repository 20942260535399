import styled from "styled-components";
import { WhitePage } from "@style/whitePage";
import { InnerContainer } from "@style/innerContainer";
import { COLORS } from "@style/colors";

export const ManagementPageStyle = styled(WhitePage)`
    background-color: ${COLORS.background};

    & > ${InnerContainer} {
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
    }

    h1 {
        margin: 20px auto;
        max-width: 1520px;
        padding: 20px 20px 0 20px;
        font-size: 20px;
        font-weight: bold;
    }

    a {
        background: #FFFFFF;
        box-shadow: 0 1px 1px 0 rgba(0,0,0,0.15);
        border-radius: 4px;
        margin-bottom: 30px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        width: calc(25% - 15px);
        height: 270px;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        transition: 0.3s;
        cursor: pointer;

        &:nth-of-type(4n) {
            margin-right: 0;
        }

        div {
            height: 50px;
            width: 120px;
            height: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: ${COLORS.lightGrey};
            border-radius: 60px;
            padding: 40px;
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        h2 {
            font-size: 16px;
            margin-top: 30px;
            text-align: center;
        }
    }

    a:hover {
        background-color: ${COLORS.primaryTransparant(0.1)};


    };
`;
