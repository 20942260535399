import { ErrorMessage } from "@components/errorMessage/errorMessage";
import { InquiryStatus } from "@components/inquiryStatus/inquiryStatus";
import { useGetInquiriesOverview } from "@graphql/hocs/hooks/useGetInquiriesOverview";
import { ApplicantType, InquiryOverviewFragment, InquiryStatusType, SortOrder } from "@models/graphql";
import { useFormatMessage } from "@utils/intlHook";
import { parseAddressToString } from "@utils/parseAddressToString";
import { useRouter } from "@utils/routerHook";
import { useQueryParameters } from "@utils/useQueryParameters";
import Table, { ColumnProps, PaginationConfig, SorterResult } from "antd/lib/table";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedDate } from "react-intl";
import { IncidentsTableStyle } from "./incidentsTableStyle";

export interface IncidentsTableProps {

}

export interface IncidentsTableFilter {
    productIds?: string[];
    statusses?: InquiryStatusType[];
    municipalities?: string[];
    applicantTypes?: ApplicantType[];
    search?: string;
    page: number;
    pageSize: number;
    sortOrder: SortOrder;
    sortField: string;
    incidentReasons?: string[];
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const incidentsTableFilter = () =>
    useQueryParameters<IncidentsTableFilter>(
        "/incidents{?productIds,statusses,incidentReasons,municipalities,applicantTypes,page,pageSize,search,sortOrder,sortField}",
        {
            page: 1,
            pageSize: 20,
            sortField: "lastUpdated",
            sortOrder: SortOrder.Desc
        },
        {
            applicantTypes: "array",
            municipalities: "array",
            page: "number",
            pageSize: "number",
            statusses: "array",
            productIds: "array",
            incidentReasons: "array"
        }
    );

export const IncidentsTable: FunctionComponent<IncidentsTableProps> = (props: IncidentsTableProps) => {
    const { parameters: filter, updateParameters: updateFilter } = incidentsTableFilter();
    const { history } = useRouter();
    const formatMessage = useFormatMessage();

    const { inquiries, inquiriesLoading, inquiriesError, inquiriesCount } = useGetInquiriesOverview({
        variables: {
            filter: {
                applicantTypes: filter.applicantTypes,
                municipalityIds: filter.municipalities,
                productIds: filter.productIds,
                search: filter.search,
                statusses: filter.incidentReasons ? [InquiryStatusType.Incident] : filter.statusses || [InquiryStatusType.Processing, InquiryStatusType.Incident, InquiryStatusType.OnHold, InquiryStatusType.ToBeRescheduled],
                incidentReasonIds: filter.incidentReasons
            },
            paging: {
                limit: filter.pageSize,
                offset: (filter.page - 1) * filter.pageSize
            },
            sort: {
                field: filter.sortField,
                order: filter.sortOrder
            }
        },
        pollInterval: 30000
    });

    const columns: ColumnProps<InquiryOverviewFragment>[] = [
        {
            title: formatMessage({ id: "incidentsTable.orderNumber" }),
            key: "orderNumber",
            width: 100,
            dataIndex: "orderNumber"
        },
        {
            title: formatMessage({ id: "incidentsTable.name" }),
            key: "name",
            render(_, record) {
                return record.applicant.name;
            }
        },
        {
            title: formatMessage({ id: "incidentsTable.applicantType" }),
            key: "applicantType",
            render(_, record) {
                return formatMessage({ id: `applicantType.${record.applicant.type}` });
            }
        },
        {
            title: formatMessage({ id: "incidentsTable.product" }),
            key: "product",
            render(_, record) {
                return record.products.map(product => <div className="product-item" key={product.product.id}>{product.amount} <em> x {product.product.name}</em></div>);
            }
        },
        {
            title: formatMessage({ id: "incidentsTable.address" }),
            key: "address",
            render(_, record) {
                return parseAddressToString(record.pickupAddress.address);
            }
        },
        {
            title: formatMessage({ id: "incidentsTable.status" }),
            key: "lastUpdated",
            sorter: true,
            width: 185,
            defaultSortOrder: "descend",
            render(_, record) {
                return (
                    <>
                        <InquiryStatus withPopup status={record.currentStatus} />
                        <FormattedDate day="numeric" month="short" year="numeric" hour="2-digit" minute="2-digit" value={record.lastUpdated} />
                    </>
                );
            }
        }
    ];

    const onTableChange = (
        pagination: PaginationConfig,
        filters: Record<keyof InquiryOverviewFragment, string[]>,
        sorter: SorterResult<InquiryOverviewFragment>
    ): void => {
        const newFilter: Partial<IncidentsTableFilter> = {};

        if (sorter) {
            if (!sorter.columnKey) {
                newFilter.sortOrder = undefined;
                newFilter.sortField = undefined;
            } else {
                newFilter.sortOrder = sorter.order === "ascend" ? SortOrder.Asc : SortOrder.Desc;
                newFilter.sortField = sorter.columnKey;
            }
        }

        if (pagination.current) {
            newFilter.page = pagination.current;
        }

        if (pagination.pageSize) {
            newFilter.pageSize = pagination.pageSize;
        }

        updateFilter(newFilter);
    };

    const onRow = (inquiry: InquiryOverviewFragment): { onClick(): void } => ({
        onClick: () => history.push(`/inquiries/${inquiry.id}`)
    });

    return (
        <IncidentsTableStyle>
            <ErrorMessage style={{ marginBottom: 25 }} error={inquiriesError} />
            <Table
                columns={columns}
                rowKey="id"
                pagination={{
                    hideOnSinglePage: false,
                    pageSize: filter.pageSize,
                    position: "both",
                    disabled: false,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    total: inquiriesCount,
                    current: filter.page
                }}
                onChange={onTableChange}
                dataSource={inquiries}
                loading={inquiriesLoading}
                onRow={onRow}
            // onRowClick={onRowClick}
            />
        </IncidentsTableStyle>
    );
};
