/* eslint-disable */
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: string;
  JSON: any;
};

export type AddIncidentFeedbackInput = {
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  images: Array<Scalars['String']>;
};

export type ApiResult = {
  __typename?: 'ApiResult';
  succes: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
};

/**
 * Applicant (Partij)
 * This is the entity that does an inquiry. This contains mainly contact information
 */
export type Applicant = {
  __typename?: 'Applicant';
  id: Scalars['ID'];
  personId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  type: ApplicantType;
  phone: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  address: ApplicantAddress;
};

export type ApplicantAddress = {
  __typename?: 'ApplicantAddress';
  street: Scalars['String'];
  streetNumber: Scalars['String'];
  numberAddition?: Maybe<Scalars['String']>;
  busNumber?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
};

export enum ApplicantType {
  Civilian = 'CIVILIAN',
  Association = 'ASSOCIATION',
  GroupPurchase = 'GROUP_PURCHASE',
  Municipality = 'MUNICIPALITY',
  Company = 'COMPANY',
  NonDomiciledCitizen = 'NON_DOMICILED_CITIZEN'
}

/**
 * Asset
 * This is a physical asset
 */
export type Asset = {
  __typename?: 'Asset';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: AssetType;
  tagCode: Scalars['String'];
  status: Status;
  lastAddress?: Maybe<AssetAddress>;
};

export type AssetAddress = {
  __typename?: 'AssetAddress';
  street: Scalars['String'];
  streetNumber: Scalars['String'];
  numberAddition?: Maybe<Scalars['String']>;
  busNumber?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
};

export type AssetAddressInput = {
  street: Scalars['String'];
  streetNumber: Scalars['String'];
  numberAddition?: Maybe<Scalars['String']>;
  busNumber?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
};

export type AssetDeleteResult = {
  __typename?: 'AssetDeleteResult';
  succeeded: Scalars['Boolean'];
};

export type AssetFilterInput = {
  search?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Status>>;
  assetTypeIds?: Maybe<Array<Scalars['ID']>>;
};

export type AssetInput = {
  assetTypeId: Scalars['ID'];
  name: Scalars['String'];
  tagCode: Scalars['String'];
  status: Status;
};

export type AssetPagingInput = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};

export type AssetsResult = {
  __typename?: 'AssetsResult';
  count: Scalars['Int'];
  items: Array<Asset>;
};

/**
 * AssetType
 * This is the type of an asset
 */
export type AssetType = {
  __typename?: 'AssetType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type AssetTypeInput = {
  name: Scalars['String'];
};

export type AssetTypesResult = {
  __typename?: 'AssetTypesResult';
  count: Scalars['Int'];
  items: Array<AssetType>;
};

export type CardsAddressInput = {
  street: Scalars['String'];
  streetNumber: Scalars['String'];
  numberAddition?: Maybe<Scalars['String']>;
  busNumber?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
};

export type CardsApplicant = {
  __typename?: 'CardsApplicant';
  id: Scalars['ID'];
  personId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  type: ApplicantType;
  phone: Scalars['String'];
  email: Scalars['String'];
  address: CardsApplicantAddress;
};

export type CardsApplicantAddress = {
  __typename?: 'CardsApplicantAddress';
  street: Scalars['String'];
  streetNumber: Scalars['String'];
  numberAddition?: Maybe<Scalars['String']>;
  busNumber?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
};

export type CardsApplicantInput = {
  id: Scalars['ID'];
  personId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  type: ApplicantType;
  phone: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  address: CardsAddressInput;
};

export type CardsInquiryInput = {
  inquiryId: Scalars['String'];
  paymentNumber?: Maybe<Scalars['String']>;
  applicant: CardsApplicantInput;
  products: Array<CardsInquiryProduct>;
  pickupAddress: CardsAddressInput;
  orderNumber: Scalars['String'];
  cardsPayment: Scalars['Boolean'];
};

export type CardsInquiryProduct = {
  amount: Scalars['Int'];
  productId: Scalars['ID'];
};

export type CardsPagingInput = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};

export type CardsProduct = {
  __typename?: 'CardsProduct';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum Channel {
  Sms = 'SMS',
  Email = 'EMAIL'
}

export type CheckResult = {
  __typename?: 'CheckResult';
  allowed: Scalars['Boolean'];
};

export type CollectionDay = {
  __typename?: 'CollectionDay';
  id: Scalars['ID'];
  date: Scalars['Date'];
  dayPart?: Maybe<DayPart>;
  addresses?: Maybe<Scalars['Int']>;
  maxAddresses?: Maybe<Scalars['Int']>;
  cargo?: Maybe<Scalars['Int']>;
  maxCargo?: Maybe<Scalars['Int']>;
  cargoPickup?: Maybe<Scalars['Int']>;
  cargoDelivery?: Maybe<Scalars['Int']>;
  group?: Maybe<CollectionGroup>;
};

export type CollectionDayProductInput = {
  productId: Scalars['ID'];
  amount: Scalars['Int'];
};

export type CollectionDayResult = {
  __typename?: 'CollectionDayResult';
  count: Scalars['Int'];
  items: Array<CollectionDay>;
};

/**
 * CollectionGroup
 * A group of cities combined for collection
 */
export type CollectionGroup = {
  __typename?: 'CollectionGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
  municipalities: Array<Municipality>;
};

export type CollectionGroupInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  municipalityIds: Array<Scalars['ID']>;
};

export type CollectionPagingInput = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};

/**
 * CollectionPattern
 * This pattern describes which collectionRounds are done on which days.
 */
export type CollectionPattern = {
  __typename?: 'CollectionPattern';
  id: Scalars['ID'];
  name: Scalars['String'];
  active: Scalars['Boolean'];
  deactivated: Scalars['Boolean'];
  from: Scalars['Date'];
  to: Scalars['Date'];
  dayCount: Scalars['Int'];
  days: Array<PatternDay>;
  fixedProductCycles: Array<FixedProductCycle>;
  groups: Array<CollectionGroup>;
  notPlannableDays: Array<Scalars['Date']>;
  configs: Array<CollectionPatternConfig>;
};

/**
 * CollectionPatternConfig
 * This is the configuration of a collection pattern. Ex. the amount of cargo per truck, the max addresses in a daypart
 */
export type CollectionPatternConfig = {
  __typename?: 'CollectionPatternConfig';
  group: CollectionGroup;
  maxAddressesPerHalfDay?: Maybe<Scalars['Int']>;
  maxAddressesPerFullDay?: Maybe<Scalars['Int']>;
  collectionPatternProductConfigs: Array<CollectionPatternProductConfig>;
};

export type CollectionPatternConfigInput = {
  groupId: Scalars['ID'];
  maxAddressesPerHalfDay?: Maybe<Scalars['Int']>;
  maxAddressesPerFullDay?: Maybe<Scalars['Int']>;
  collectionPatternProductConfigs: Array<CollectionPatternProductConfigInput>;
};

export type CollectionPatternDeleteResult = {
  __typename?: 'CollectionPatternDeleteResult';
  succeeded: Scalars['Boolean'];
};

export type CollectionPatternInput = {
  name: Scalars['String'];
  from: Scalars['Date'];
  to: Scalars['Date'];
  dayCount: Scalars['Int'];
  days: Array<PatternDayInput>;
  fixedProductCycles: Array<FixedProductCycleInput>;
  groups: Array<CollectionGroupInput>;
  notPlannableDays: Array<Scalars['Date']>;
  configs: Array<CollectionPatternConfigInput>;
};

export type CollectionPatternProductConfig = {
  __typename?: 'CollectionPatternProductConfig';
  products: Array<Product>;
  processor: Processor;
  truckConfigs: Array<CollectionPatternTruckConfig>;
};

export type CollectionPatternProductConfigInput = {
  productIds: Array<Scalars['ID']>;
  processorId: Scalars['ID'];
  truckConfigs: Array<CollectionPatternTruckConfigInput>;
};

export type CollectionPatternResult = {
  __typename?: 'CollectionPatternResult';
  count: Scalars['Int'];
  items: Array<CollectionPattern>;
};

/**
 * CollectionRound
 * This describes which products will be collected in which cities and with which truck.
 */
export type CollectionPatternRound = {
  __typename?: 'CollectionPatternRound';
  dayPart: DayPart;
  group: CollectionGroup;
  products: Array<Product>;
  truckType: TruckType;
};

export type CollectionPatternRoundInput = {
  dayPart: DayPart;
  groupId: Scalars['ID'];
  productIds: Array<Scalars['ID']>;
  truckTypeId: Scalars['ID'];
};

export type CollectionPatternsFilterInput = {
  productIds?: Maybe<Array<Scalars['String']>>;
};

export type CollectionPatternTruckConfig = {
  __typename?: 'CollectionPatternTruckConfig';
  truckType: TruckType;
  maxItemsPerCargo: Scalars['Int'];
  cargoPerDayHalfDay?: Maybe<Scalars['Int']>;
  cargoPerDayFullDay?: Maybe<Scalars['Int']>;
};

export type CollectionPatternTruckConfigInput = {
  truckTypeId: Scalars['ID'];
  maxItemsPerCargo: Scalars['Int'];
  cargoPerDayHalfDay?: Maybe<Scalars['Int']>;
  cargoPerDayFullDay?: Maybe<Scalars['Int']>;
};

export type CollectionRound = {
  __typename?: 'CollectionRound';
  id: Scalars['ID'];
  date: Scalars['Date'];
  processor: Processor;
  dayPart: DayPart;
  patternId?: Maybe<Scalars['ID']>;
  truck: Truck;
  group?: Maybe<CollectionGroup>;
  truckType?: Maybe<TruckType>;
  products?: Maybe<Array<Product>>;
  capacity?: Maybe<CollectionRoundCapacity>;
  tasks: Array<CollectionRoundTask>;
  deactivated: Scalars['Boolean'];
};

export type CollectionRoundCapacity = {
  __typename?: 'CollectionRoundCapacity';
  maxAddresses: Scalars['Int'];
  maxCargoPerDayPart: Scalars['Int'];
  maxItemsPerCargo: Scalars['Int'];
};

export type CollectionRoundTask = {
  __typename?: 'CollectionRoundTask';
  inquiry: Inquiry;
  type: CollectionRoundTaskType;
  cargo: Scalars['Int'];
  cargoPerProduct?: Maybe<Array<CollectionRoundTaskCargoPerProduct>>;
};

export type CollectionRoundTaskCargoPerProduct = {
  __typename?: 'CollectionRoundTaskCargoPerProduct';
  product: Product;
  cargo: Scalars['Int'];
  effectiveCargo: Scalars['Int'];
};

export enum CollectionRoundTaskType {
  Pickup = 'PICKUP',
  Delivery = 'DELIVERY'
}

export type CollectionRoundUnscheduleResult = {
  __typename?: 'CollectionRoundUnscheduleResult';
  succeeded: Scalars['Boolean'];
};

export type CollectionSortInput = {
  field: Scalars['String'];
  order: SortOrder;
};

/**
 * Collector
 * A collector is someone who uses the collector APP
 */
export type Collector = {
  __typename?: 'Collector';
  id: Scalars['ID'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  tagCode?: Maybe<Scalars['String']>;
};

export type CollectorDeleteResult = {
  __typename?: 'CollectorDeleteResult';
  succeeded: Scalars['Boolean'];
};

export type CollectorInput = {
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  tagCode: Scalars['String'];
};

export type CollectorsResult = {
  __typename?: 'CollectorsResult';
  count: Scalars['Int'];
  items: Array<Collector>;
};

export type CreateIncidentInput = {
  timestamp: Scalars['DateTime'];
  inquiryId: Scalars['ID'];
  reasonId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  images: Array<Scalars['String']>;
};

export type CreateIncidentReasonInput = {
  name: Scalars['String'];
  responsibility: Responsibility;
  productIds: Array<Scalars['ID']>;
};

export type CreateInquiryInput = {
  applicant: InquiryApplicantInput;
  products: Array<InquiryProductInput>;
  pickupAddress: InquiryLocationInput;
  checklist: Scalars['JSON'];
  digibeet?: Maybe<Scalars['Boolean']>;
  pickupDayId: Scalars['ID'];
  deliveryDayId?: Maybe<Scalars['ID']>;
  cardsPayment?: Maybe<Scalars['Boolean']>;
  paymentNumber?: Maybe<Scalars['String']>;
  media?: Maybe<Array<InquiryMediaInput>>;
  checks?: Maybe<InquiryChecksInput>;
};

export type CreateProductInput = {
  cardsId: Scalars['String'];
  name: Scalars['String'];
  deliveryMethod: DeliveryMethod;
  daysBeforePickup?: Maybe<Scalars['Int']>;
  scanMoments: Array<ScanMoment>;
  accessory: Scalars['Boolean'];
};

export type CsvResult = {
  __typename?: 'CSVResult';
  url: Scalars['String'];
};



export enum DayPart {
  Morning = 'MORNING',
  Afternoon = 'AFTERNOON',
  Fullday = 'FULLDAY'
}

export enum DeliveryMethod {
  Pickup = 'PICKUP',
  Delivery = 'DELIVERY',
  None = 'NONE'
}

export type Depot = {
  __typename?: 'Depot';
  name: Scalars['String'];
  address: TruckLocation;
};

/**
 * FixedProductCycle
 * This defines the amount of pattern cycles between the delivery and pickup date
 */
export type FixedProductCycle = {
  __typename?: 'FixedProductCycle';
  product: Product;
  fixedCyclesBetweenPickupAndDelivery: Scalars['Int'];
};

export type FixedProductCycleInput = {
  productId: Scalars['ID'];
  fixedCyclesBetweenPickupAndDelivery: Scalars['Int'];
};

/**
 * Incident
 * This can be used to log issues. This will trigger a flow to re-plan an inquiry
 */
export type Incident = {
  __typename?: 'Incident';
  id: Scalars['ID'];
  reason: IncidentReason;
  collector: Collector;
  description?: Maybe<Scalars['String']>;
  images: Array<Scalars['String']>;
  feedback?: Maybe<IncidentFeedback>;
  comments: Array<IncidentComment>;
  payments: Array<Payment>;
  inquiry: Inquiry;
};

/**
 * IncidentComment
 * This is a comment that a user can give on an incident
 */
export type IncidentComment = {
  __typename?: 'IncidentComment';
  id: Scalars['ID'];
  user: User;
  message: Scalars['String'];
  timestamp: Scalars['DateTime'];
};

export type IncidentDeleteResult = {
  __typename?: 'IncidentDeleteResult';
  succeeded: Scalars['Boolean'];
};

/**
 * IncidentFeedback
 * This is feedback given from an applicant on a created request
 */
export type IncidentFeedback = {
  __typename?: 'IncidentFeedback';
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  images: Array<Scalars['String']>;
};

/**
 * IncidentReason
 * Different reasons why an incident can be be created. Ex. the container was not accessible
 */
export type IncidentReason = {
  __typename?: 'IncidentReason';
  id: Scalars['ID'];
  name: Scalars['String'];
  responsibility: Responsibility;
  products: Array<Product>;
  systemReason: Scalars['Boolean'];
};

export type IncidentReasonInput = {
  name: Scalars['String'];
  responsibility: Responsibility;
  productIds: Array<Scalars['ID']>;
};

export type IncidentReasonResult = {
  __typename?: 'IncidentReasonResult';
  count: Scalars['Int'];
  items: Array<IncidentReason>;
};

export type IncidentReportItem = {
  __typename?: 'IncidentReportItem';
  date: Scalars['DateTime'];
  inquiry: Inquiry;
  incidentReason: IncidentReason;
  closed: Scalars['Boolean'];
};

/**
 * IncidentTrigger
 * This trigger is executed when an incident of a specific type is created
 */
export type IncidentTrigger = {
  __typename?: 'IncidentTrigger';
  incidentType: IncidentTriggerType;
  incident: IncidentReason;
};

export type IncidentTriggerInput = {
  type: IncidentTriggerType;
  incidentReasonId: Scalars['ID'];
};

export enum IncidentTriggerType {
  IncidentCreated = 'INCIDENT_CREATED'
}

/**
 * Inquiry
 * This is a request of an applicant to come collect some products. This also holds the history of the request.
 */
export type Inquiry = {
  __typename?: 'Inquiry';
  id: Scalars['ID'];
  orderNumber: Scalars['String'];
  applicant: Applicant;
  products: Array<InquiryProduct>;
  digibeet: Scalars['Boolean'];
  currentStatus: InquiryStatusType;
  lastUpdated: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  statusHistory: Array<InquiryStatus>;
  pickupAddress: InquiryLocation;
  checklist: Scalars['JSON'];
  deliveryDate?: Maybe<Scalars['Date']>;
  pickupDate?: Maybe<Scalars['Date']>;
  cardsPayment: Scalars['Boolean'];
  paymentNumber?: Maybe<Scalars['String']>;
  media?: Maybe<Array<InquiryMedia>>;
  checks?: Maybe<InquiryChecks>;
};

export type InquiryAddress = {
  __typename?: 'InquiryAddress';
  street: Scalars['String'];
  streetNumber: Scalars['String'];
  numberAddition?: Maybe<Scalars['String']>;
  busNumber?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
};

export type InquiryAddressInput = {
  street: Scalars['String'];
  streetNumber: Scalars['String'];
  numberAddition?: Maybe<Scalars['String']>;
  busNumber?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
};

export type InquiryApplicantInput = {
  id: Scalars['ID'];
  personId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  type: ApplicantType;
  phone: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  address: InquiryAddressInput;
};

/**
 * InquiryCheck
 * A configuration object that can tell the system to ignore certain steps in the invalidation process
 */
export type InquiryChecks = {
  __typename?: 'InquiryChecks';
  ignore?: Maybe<InquiryChecksIgnore>;
};

export type InquiryChecksIgnore = {
  __typename?: 'InquiryChecksIgnore';
  collectedItems?: Maybe<Scalars['Boolean']>;
};

export type InquiryChecksIgnoreInput = {
  collectedItems?: Maybe<Scalars['Boolean']>;
};

export type InquiryChecksInput = {
  ignore?: Maybe<InquiryChecksIgnoreInput>;
};

export type InquiryCoordinates = {
  __typename?: 'InquiryCoordinates';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type InquiryCoordinatesInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type InquiryFilterInput = {
  ids?: Maybe<Array<Scalars['ID']>>;
  search?: Maybe<Scalars['String']>;
  productIds?: Maybe<Array<Scalars['ID']>>;
  statusses?: Maybe<Array<InquiryStatusType>>;
  municipalityIds?: Maybe<Array<Scalars['ID']>>;
  applicantTypes?: Maybe<Array<ApplicantType>>;
  deliveryDate?: Maybe<Scalars['Date']>;
  pickupDate?: Maybe<Scalars['Date']>;
  publicDomain?: Maybe<Scalars['Boolean']>;
  collected?: Maybe<Scalars['Boolean']>;
  manual?: Maybe<Scalars['Boolean']>;
  incidentReasonIds?: Maybe<Array<Scalars['ID']>>;
};

export type InquiryLocation = {
  __typename?: 'InquiryLocation';
  coordinates: InquiryCoordinates;
  address: InquiryAddress;
  municipality?: Maybe<Municipality>;
};

export type InquiryLocationInput = {
  coordinates: InquiryCoordinatesInput;
  address: InquiryAddressInput;
  municipalityId: Scalars['ID'];
};

export type InquiryMedia = {
  __typename?: 'InquiryMedia';
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type InquiryMediaInput = {
  id: Scalars['ID'];
};

export type InquiryOrderInput = {
  dayPart: DayPart;
  ids: Array<Scalars['ID']>;
};

export type InquiryPagingInput = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};

/** InquiryProduct */
export type InquiryProduct = {
  __typename?: 'InquiryProduct';
  amount: Scalars['Int'];
  product: Product;
};

export type InquiryProductInput = {
  amount: Scalars['Int'];
  productId: Scalars['String'];
};

export type InquiryResult = {
  __typename?: 'InquiryResult';
  count: Scalars['Int'];
  items: Array<Inquiry>;
};

export type InquirySortInput = {
  field: Scalars['String'];
  order: SortOrder;
};

/**
 * InquiryStatus
 * This is a status update of an inquiry. Ex. the requested products are delivered.
 */
export type InquiryStatus = {
  __typename?: 'InquiryStatus';
  type: InquiryStatusType;
  timestamp: Scalars['DateTime'];
  user: User;
  description?: Maybe<Scalars['String']>;
  incident?: Maybe<Incident>;
  deliveryDate?: Maybe<Scalars['String']>;
  pickupDate?: Maybe<Scalars['String']>;
};

export enum InquiryStatusType {
  WaitingForPayment = 'WAITING_FOR_PAYMENT',
  Planned = 'PLANNED',
  Delivered = 'DELIVERED',
  Incident = 'INCIDENT',
  Processing = 'PROCESSING',
  ToBeRescheduled = 'TO_BE_RESCHEDULED',
  Canceled = 'CANCELED',
  OnHold = 'ON_HOLD',
  PickedUp = 'PICKED_UP',
  Processed = 'PROCESSED'
}

export type InvalidateResult = {
  __typename?: 'InvalidateResult';
  succeeded: Scalars['Boolean'];
};


export type LogisticReportItem = {
  __typename?: 'LogisticReportItem';
  date: Scalars['Date'];
  truck: Truck;
  processor: Processor;
  collector?: Maybe<Collector>;
  weightTicket?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  addressCount: Scalars['Int'];
  incidentCount: Scalars['Int'];
  totalDistance: Scalars['Float'];
  collectionDistance: Scalars['Float'];
  inquiries: Array<Inquiry>;
  municipalities: Array<Scalars['String']>;
};

/**
 * Media
 * Photo, video, ...
 */
export type Media = {
  __typename?: 'Media';
  id: Scalars['ID'];
  filename: Scalars['String'];
  type: Scalars['String'];
  url: Scalars['String'];
  user: User;
};

export type MediaFilterInput = {
  ids: Array<Scalars['ID']>;
};

export type MediaInput = {
  filename: Scalars['String'];
  type: Scalars['String'];
  content: Scalars['String'];
};

export type Municipality = {
  __typename?: 'Municipality';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  getCollectorToken: TokenResult;
  createCollector: Collector;
  updateCollector: Collector;
  deleteCollector: CollectorDeleteResult;
  createTruckType: TruckType;
  updateTruckType: TruckType;
  deleteTruckType: TruckDeleteResult;
  createTruck: Truck;
  updateTruck: Truck;
  deleteTruck: TruckDeleteResult;
  incidentReportsCSV: ReportCsvResult;
  orderReportsCSV: ReportCsvResult;
  logisticReportsCSV: ReportCsvResult;
  createProduct: Product;
  updateProduct: Product;
  deleteProduct: ProductDeleteResult;
  createProcessor: Processor;
  updateProcessor: Processor;
  deleteProcessor: ProcessorDeleteResult;
  createNotificationConfiguration: NotificationConfiguration;
  updateNotificationConfiguration: NotificationConfiguration;
  deleteNotificationConfiguration: NotificationDeleteResult;
  uploadMedia: Media;
  deleteMedia: OperationResult;
  createInquiry: Inquiry;
  updateInquiry: Inquiry;
  updateInquiryCollectionDay: Inquiry;
  updateInquiryTaskCollectionDay: Inquiry;
  inquiryPlanned: Inquiry;
  cancelInquiry: Inquiry;
  inquiryOnHold: Inquiry;
  inquiryIncident: Inquiry;
  inquiryIncidentInProgress: Inquiry;
  inquiryToBeRescheduled: Inquiry;
  inquiryDelivered: Inquiry;
  inquiryPickedUp: Inquiry;
  inquiryProcessed: Inquiry;
  deleteIncidentStatus: Inquiry;
  markInquiryAsCollected: Inquiry;
  createIncidentReason: IncidentReason;
  updateIncidentReason: IncidentReason;
  deleteIncidentReason: IncidentDeleteResult;
  createIncident: Incident;
  updateIncident: Incident;
  deleteIncident: IncidentDeleteResult;
  closeIncident: Incident;
  addIncidentComment: Incident;
  updateIncidentComment: Incident;
  deleteIncidentComment: IncidentDeleteResult;
  addIncidentFeedback: Incident;
  addPayment: Incident;
  createCollectionPattern: CollectionPattern;
  updateCollectionPattern: CollectionPattern;
  activateCollectionPattern: CollectionPattern;
  deactivateCollectionPattern: CollectionPattern;
  deleteCollectionPattern: CollectionPatternDeleteResult;
  scheduleInquiry: ScheduleResult;
  unscheduleInquiry: CollectionRoundUnscheduleResult;
  moveCollectionTask: Scalars['Date'];
  collectionRoundsCSV: CsvResult;
  addTruckToCollectionRound: CollectionRound;
  createStandAloneCollectionRound: CollectionRound;
  markInquiryTaskAsIncident: TruckTask;
  closeInquiryTask: TruckTask;
  closeProcessorTask: TruckTask;
  moveTruckSchedule: TruckSchedule;
  invalidateTruckSchedule: InvalidateResult;
  invalidateTruckScheduleByPattern: InvalidateResult;
  invalidateTruckScheduleByProcessorId: InvalidateResult;
  resetSchedule: TruckSchedule;
  fixedTruckScheduleOrder: TruckSchedule;
  truckScheduleCSV: CsvResult;
  uploadWeightData: UploadResult;
  cardsCreateInquiry: ApiResult;
  cardsPaymentRequest: ApiResult;
  createAssetType: AssetType;
  updateAssetType: AssetType;
  deleteAssetType: AssetDeleteResult;
  createAsset: Asset;
  updateAsset: Asset;
  deleteAsset: AssetDeleteResult;
  scanAssets: ScanResult;
  updateAssetStatus: Asset;
};


export type MutationGetCollectorTokenArgs = {
  tagCode: Scalars['String'];
};


export type MutationCreateCollectorArgs = {
  collector: CollectorInput;
};


export type MutationUpdateCollectorArgs = {
  id: Scalars['ID'];
  collector: CollectorInput;
};


export type MutationDeleteCollectorArgs = {
  id: Scalars['ID'];
};


export type MutationCreateTruckTypeArgs = {
  truckType?: Maybe<TruckTypeInput>;
};


export type MutationUpdateTruckTypeArgs = {
  id: Scalars['ID'];
  truckType: TruckTypeInput;
};


export type MutationDeleteTruckTypeArgs = {
  id: Scalars['ID'];
};


export type MutationCreateTruckArgs = {
  truck: TruckInput;
};


export type MutationUpdateTruckArgs = {
  id: Scalars['ID'];
  truck: TruckInput;
};


export type MutationDeleteTruckArgs = {
  id: Scalars['ID'];
};


export type MutationIncidentReportsCsvArgs = {
  from: Scalars['Date'];
  to: Scalars['Date'];
  filter?: Maybe<ReportFilterInput>;
};


export type MutationOrderReportsCsvArgs = {
  from: Scalars['Date'];
  to: Scalars['Date'];
  filter?: Maybe<ReportFilterInput>;
};


export type MutationLogisticReportsCsvArgs = {
  from: Scalars['Date'];
  to: Scalars['Date'];
};


export type MutationCreateProductArgs = {
  product: CreateProductInput;
};


export type MutationUpdateProductArgs = {
  id: Scalars['ID'];
  product: UpdateProductInput;
};


export type MutationDeleteProductArgs = {
  id: Scalars['ID'];
};


export type MutationCreateProcessorArgs = {
  processor: ProcessorInput;
};


export type MutationUpdateProcessorArgs = {
  id: Scalars['ID'];
  processor: ProcessorInput;
};


export type MutationDeleteProcessorArgs = {
  id: Scalars['ID'];
};


export type MutationCreateNotificationConfigurationArgs = {
  configuration: NotificationConfigurationInput;
};


export type MutationUpdateNotificationConfigurationArgs = {
  id: Scalars['ID'];
  configuration: NotificationConfigurationInput;
};


export type MutationDeleteNotificationConfigurationArgs = {
  id: Scalars['ID'];
};


export type MutationUploadMediaArgs = {
  input: MediaInput;
};


export type MutationDeleteMediaArgs = {
  id: Scalars['ID'];
};


export type MutationCreateInquiryArgs = {
  inquiry: CreateInquiryInput;
};


export type MutationUpdateInquiryArgs = {
  id: Scalars['ID'];
  input: UpdateInquiryInput;
};


export type MutationUpdateInquiryCollectionDayArgs = {
  inquiryId: Scalars['ID'];
  pickupDayId: Scalars['ID'];
  deliveryDayId?: Maybe<Scalars['ID']>;
  paymentNumber?: Maybe<Scalars['String']>;
};


export type MutationUpdateInquiryTaskCollectionDayArgs = {
  inquiryId: Scalars['ID'];
  type: CollectionRoundTaskType;
  collectionDay: Scalars['ID'];
};


export type MutationInquiryPlannedArgs = {
  inquiryId: Scalars['ID'];
  deliveryDate?: Maybe<Scalars['Date']>;
  pickupDate: Scalars['Date'];
};


export type MutationCancelInquiryArgs = {
  inquiryId: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
};


export type MutationInquiryOnHoldArgs = {
  inquiryId: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
};


export type MutationInquiryIncidentArgs = {
  inquiryId: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
  incidentId: Scalars['ID'];
  timestamp: Scalars['DateTime'];
};


export type MutationInquiryIncidentInProgressArgs = {
  inquiryId: Scalars['ID'];
};


export type MutationInquiryToBeRescheduledArgs = {
  inquiryId: Scalars['ID'];
};


export type MutationInquiryDeliveredArgs = {
  inquiryId: Scalars['ID'];
  timestamp: Scalars['DateTime'];
};


export type MutationInquiryPickedUpArgs = {
  inquiryId: Scalars['ID'];
  timestamp: Scalars['DateTime'];
};


export type MutationInquiryProcessedArgs = {
  inquiryId: Scalars['ID'];
  timestamp: Scalars['DateTime'];
};


export type MutationDeleteIncidentStatusArgs = {
  inquiryId: Scalars['ID'];
};


export type MutationMarkInquiryAsCollectedArgs = {
  inquiryId: Scalars['ID'];
};


export type MutationCreateIncidentReasonArgs = {
  incidentReason: IncidentReasonInput;
};


export type MutationUpdateIncidentReasonArgs = {
  incidentReason: IncidentReasonInput;
  incidentReasonId: Scalars['ID'];
};


export type MutationDeleteIncidentReasonArgs = {
  incidentReasonId: Scalars['ID'];
};


export type MutationCreateIncidentArgs = {
  incident: CreateIncidentInput;
  taskId?: Maybe<Scalars['ID']>;
};


export type MutationUpdateIncidentArgs = {
  id: Scalars['ID'];
  incident: UpdateIncidentInput;
};


export type MutationDeleteIncidentArgs = {
  incidentId: Scalars['ID'];
};


export type MutationCloseIncidentArgs = {
  incidentId: Scalars['ID'];
};


export type MutationAddIncidentCommentArgs = {
  incidentId: Scalars['ID'];
  comment: Scalars['String'];
};


export type MutationUpdateIncidentCommentArgs = {
  commentId: Scalars['ID'];
  comment: Scalars['String'];
};


export type MutationDeleteIncidentCommentArgs = {
  commentId: Scalars['ID'];
};


export type MutationAddIncidentFeedbackArgs = {
  incidentId: Scalars['ID'];
  feedback: AddIncidentFeedbackInput;
};


export type MutationAddPaymentArgs = {
  inquiryId: Scalars['ID'];
  incidentId: Scalars['ID'];
  products: Array<PaymentProduct>;
};


export type MutationCreateCollectionPatternArgs = {
  pattern: CollectionPatternInput;
};


export type MutationUpdateCollectionPatternArgs = {
  id: Scalars['ID'];
  pattern: CollectionPatternInput;
};


export type MutationActivateCollectionPatternArgs = {
  id: Scalars['ID'];
};


export type MutationDeactivateCollectionPatternArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCollectionPatternArgs = {
  id: Scalars['ID'];
};


export type MutationScheduleInquiryArgs = {
  inquiryId: Scalars['ID'];
  products: Array<CollectionDayProductInput>;
  pickupDayId: Scalars['ID'];
  deliveryDayId?: Maybe<Scalars['ID']>;
};


export type MutationUnscheduleInquiryArgs = {
  inquiryId: Scalars['ID'];
  force: Scalars['Boolean'];
};


export type MutationMoveCollectionTaskArgs = {
  inquiryId: Scalars['ID'];
  type: CollectionRoundTaskType;
  collectionDayId: Scalars['ID'];
  products: Array<CollectionDayProductInput>;
};


export type MutationCollectionRoundsCsvArgs = {
  from: Scalars['Date'];
  to: Scalars['Date'];
};


export type MutationAddTruckToCollectionRoundArgs = {
  collectionRoundId: Scalars['ID'];
  truckId: Scalars['ID'];
};


export type MutationCreateStandAloneCollectionRoundArgs = {
  date: Scalars['Date'];
  processorId: Scalars['ID'];
  truckId: Scalars['ID'];
  itemsPerCargo: Scalars['Int'];
};


export type MutationMarkInquiryTaskAsIncidentArgs = {
  taskId: Scalars['ID'];
};


export type MutationCloseInquiryTaskArgs = {
  taskId: Scalars['ID'];
  timestamp: Scalars['DateTime'];
};


export type MutationCloseProcessorTaskArgs = {
  taskId: Scalars['ID'];
  weightTicketId: Scalars['ID'];
};


export type MutationMoveTruckScheduleArgs = {
  id: Scalars['ID'];
  truckId: Scalars['ID'];
};


export type MutationInvalidateTruckScheduleArgs = {
  date: Scalars['Date'];
  truckId: Scalars['ID'];
};


export type MutationInvalidateTruckScheduleByPatternArgs = {
  patternId: Scalars['ID'];
};


export type MutationInvalidateTruckScheduleByProcessorIdArgs = {
  processorId: Scalars['ID'];
};


export type MutationResetScheduleArgs = {
  date: Scalars['Date'];
  truckId: Scalars['ID'];
};


export type MutationFixedTruckScheduleOrderArgs = {
  date: Scalars['Date'];
  truckId: Scalars['ID'];
  inquiryOrder: Array<InquiryOrderInput>;
};


export type MutationTruckScheduleCsvArgs = {
  date: Scalars['Date'];
  truckId: Scalars['ID'];
};


export type MutationUploadWeightDataArgs = {
  processorId: Scalars['ID'];
  weightInput: Array<WeightInputItem>;
};


export type MutationCardsCreateInquiryArgs = {
  inquiry: CardsInquiryInput;
};


export type MutationCardsPaymentRequestArgs = {
  inquiryId: Scalars['String'];
  paymentNumber?: Maybe<Scalars['String']>;
  trackNumber?: Maybe<Scalars['String']>;
  products: Array<CardsInquiryProduct>;
};


export type MutationCreateAssetTypeArgs = {
  assetType: AssetTypeInput;
};


export type MutationUpdateAssetTypeArgs = {
  id: Scalars['ID'];
  assetType: AssetTypeInput;
};


export type MutationDeleteAssetTypeArgs = {
  id: Scalars['ID'];
};


export type MutationCreateAssetArgs = {
  asset: AssetInput;
};


export type MutationUpdateAssetArgs = {
  id: Scalars['ID'];
  asset: AssetInput;
};


export type MutationDeleteAssetArgs = {
  id: Scalars['ID'];
};


export type MutationScanAssetsArgs = {
  tagCodes: Array<Scalars['String']>;
  address: AssetAddressInput;
  timestamp: Scalars['DateTime'];
  status: Status;
};


export type MutationUpdateAssetStatusArgs = {
  id: Scalars['ID'];
  status: Status;
};

/**
 * NotificationConfiguration
 * Configuration of a notification. It is possible to define which messages needs
 * to be send on which channel, bases on event per product
 */
export type NotificationConfiguration = {
  __typename?: 'NotificationConfiguration';
  id: Scalars['ID'];
  products: Array<Product>;
  trigger: Trigger;
  channel: Channel;
  rawTemplate: Scalars['JSON'];
  htmlTemplate: Scalars['String'];
};

export type NotificationConfigurationInput = {
  productIds: Array<Scalars['ID']>;
  trigger: TriggerInput;
  channel: Channel;
  rawTemplate: Scalars['JSON'];
  htmlTemplate: Scalars['String'];
};

export type NotificationConfigurationResult = {
  __typename?: 'NotificationConfigurationResult';
  count: Scalars['Int'];
  items: Array<NotificationConfiguration>;
};

export type NotificationDeleteResult = {
  __typename?: 'NotificationDeleteResult';
  succeeded: Scalars['Boolean'];
};

/**
 * NotificatonTrigger
 * This trigger is executed when a specific event is thrown
 */
export type NotificationTrigger = {
  __typename?: 'NotificationTrigger';
  notificationType: NotificationTriggerType;
};

export type NotificationTriggerInput = {
  type: NotificationTriggerType;
};

export enum NotificationTriggerType {
  InquiryCreated = 'INQUIRY_CREATED',
  DeliveryMoved = 'DELIVERY_MOVED',
  PickupMoved = 'PICKUP_MOVED',
  InquiryCanceled = 'INQUIRY_CANCELED',
  InquiryDelivered = 'INQUIRY_DELIVERED',
  InquiryPickedUp = 'INQUIRY_PICKED_UP',
  InquiryProcessed = 'INQUIRY_PROCESSED',
  InquiryOnHold = 'INQUIRY_ON_HOLD'
}

export type OperationResult = {
  __typename?: 'OperationResult';
  success: Scalars['Boolean'];
};

export type OrderReportItem = {
  __typename?: 'OrderReportItem';
  inquiry: Inquiry;
};

/**
 * PatternDay
 * This is a day in a pattern, this day has a list of rounds that will be scheduled on this day
 */
export type PatternDay = {
  __typename?: 'PatternDay';
  dayIndex: Scalars['Int'];
  collectionRound: Array<CollectionPatternRound>;
};

export type PatternDayInput = {
  dayIndex: Scalars['Int'];
  collectionRound: Array<CollectionPatternRoundInput>;
};

export type Payment = {
  __typename?: 'Payment';
  id: Scalars['ID'];
  timestamp: Scalars['DateTime'];
  user: User;
  products?: Maybe<Array<PaymentProductType>>;
};

export type PaymentProduct = {
  amount: Scalars['Int'];
  productId: Scalars['ID'];
};

export type PaymentProductType = {
  __typename?: 'PaymentProductType';
  amount: Scalars['Int'];
  productId: Scalars['ID'];
};

export type Processor = {
  __typename?: 'Processor';
  id: Scalars['ID'];
  name: Scalars['String'];
  address: ProcessorLocation;
  products: Array<Product>;
};

export type ProcessorAddress = {
  __typename?: 'ProcessorAddress';
  street: Scalars['String'];
  streetNumber: Scalars['String'];
  numberAddition?: Maybe<Scalars['String']>;
  busNumber?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
};

export type ProcessorAddressInput = {
  street: Scalars['String'];
  streetNumber: Scalars['String'];
  numberAddition?: Maybe<Scalars['String']>;
  busNumber?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
};

export type ProcessorCoordinates = {
  __typename?: 'ProcessorCoordinates';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type ProcessorCoordinatesInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type ProcessorDeleteResult = {
  __typename?: 'ProcessorDeleteResult';
  succeeded: Scalars['Boolean'];
};

export type ProcessorInput = {
  name: Scalars['String'];
  address: ProcessorLocationInput;
  productIds: Array<Scalars['ID']>;
};

export type ProcessorLocation = {
  __typename?: 'ProcessorLocation';
  coordinates: ProcessorCoordinates;
  address: ProcessorAddress;
};

export type ProcessorLocationInput = {
  coordinates: ProcessorCoordinatesInput;
  address: ProcessorAddressInput;
};

export type ProcessorsResult = {
  __typename?: 'ProcessorsResult';
  count: Scalars['Int'];
  items: Array<Processor>;
};

/**
 * Product
 * A product is something an applicant can request. (Container, mini-bag, ...).
 * This is a wrapper around the cards entity with more info regarding the delivery and pickup
 */
export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  name: Scalars['String'];
  deliveryMethod: DeliveryMethod;
  daysBeforePickup?: Maybe<Scalars['Int']>;
  scanMoments: Array<ScanMoment>;
  accessory: Scalars['Boolean'];
};

export type ProductAddress = {
  __typename?: 'ProductAddress';
  street: Scalars['String'];
  streetNumber: Scalars['String'];
  numberAddition?: Maybe<Scalars['String']>;
  busNumber?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
};

export type ProductAddressInput = {
  street: Scalars['String'];
  streetNumber: Scalars['String'];
  numberAddition?: Maybe<Scalars['String']>;
  busNumber?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
};

export type ProductDeleteResult = {
  __typename?: 'ProductDeleteResult';
  succeeded: Scalars['Boolean'];
};

export type ProductPagingInput = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};

export type ProductResult = {
  __typename?: 'ProductResult';
  count: Scalars['Int'];
  items: Array<Product>;
};

export type ProductsFilter = {
  search?: Maybe<Scalars['String']>;
  accessory?: Maybe<Scalars['Boolean']>;
};

export type ProductSortInput = {
  field: Scalars['String'];
  order: SortOrder;
};

export type Profile = {
  __typename?: 'Profile';
  permissions: Array<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  collectors: Array<Collector>;
  collector: Collector;
  profile: Profile;
  truckTypes: TruckTypeResult;
  truckType: TruckType;
  trucks: TrucksResult;
  truck: Truck;
  truckByTagCode: Truck;
  incidentReports: Array<IncidentReportItem>;
  orderReports: Array<OrderReportItem>;
  logisticReports: Array<LogisticReportItem>;
  products: ProductResult;
  product: Product;
  processors: ProcessorsResult;
  processor: Processor;
  notificationConfigurations: NotificationConfigurationResult;
  notificationConfiguration: NotificationConfiguration;
  media: Array<Media>;
  inquiries: InquiryResult;
  inquiry: Inquiry;
  checkIfInquiryIsAllowed: CheckResult;
  incidentReasons: IncidentReasonResult;
  incidentReason: IncidentReason;
  collectionPatterns: CollectionPatternResult;
  collectionPattern: CollectionPattern;
  freePickupDays: CollectionDayResult;
  freeDeliveryDays: CollectionDayResult;
  freeTrucks: Array<Truck>;
  collectionRounds: Array<CollectionRound>;
  truckSchedule: TruckSchedule;
  truckScheduleById: TruckSchedule;
  truckSchedulesByDate: Array<TruckSchedule>;
  cardsProducts: Array<CardsProduct>;
  cardsApplicants: Array<CardsApplicant>;
  cardsMunicipalities: Array<Municipality>;
  cardsStreets: Array<Scalars['String']>;
  assets: AssetsResult;
  asset: Asset;
  assetTypes: AssetTypesResult;
  assetType: AssetType;
};


export type QueryCollectorArgs = {
  id: Scalars['ID'];
};


export type QueryTruckTypeArgs = {
  id: Scalars['ID'];
};


export type QueryTruckArgs = {
  id: Scalars['ID'];
};


export type QueryTruckByTagCodeArgs = {
  tagCode: Scalars['String'];
};


export type QueryIncidentReportsArgs = {
  from: Scalars['Date'];
  to: Scalars['Date'];
  filter?: Maybe<ReportFilterInput>;
};


export type QueryOrderReportsArgs = {
  from: Scalars['Date'];
  to: Scalars['Date'];
  filter?: Maybe<ReportFilterInput>;
};


export type QueryLogisticReportsArgs = {
  from: Scalars['Date'];
  to: Scalars['Date'];
};


export type QueryProductsArgs = {
  filter?: Maybe<ProductsFilter>;
  paging?: Maybe<ProductPagingInput>;
  sort?: Maybe<ProductSortInput>;
};


export type QueryProductArgs = {
  id: Scalars['ID'];
};


export type QueryProcessorArgs = {
  id: Scalars['ID'];
};


export type QueryNotificationConfigurationArgs = {
  id: Scalars['ID'];
};


export type QueryMediaArgs = {
  filter: MediaFilterInput;
};


export type QueryInquiriesArgs = {
  filter?: Maybe<InquiryFilterInput>;
  paging?: Maybe<InquiryPagingInput>;
  sort?: Maybe<InquirySortInput>;
};


export type QueryInquiryArgs = {
  id: Scalars['ID'];
};


export type QueryCheckIfInquiryIsAllowedArgs = {
  applicantId: Scalars['ID'];
  productIds: Array<Scalars['String']>;
  applicantType: ApplicantType;
  deliveryAddress: InquiryAddressInput;
};


export type QueryIncidentReasonsArgs = {
  productId?: Maybe<Scalars['ID']>;
};


export type QueryIncidentReasonArgs = {
  id: Scalars['ID'];
};


export type QueryCollectionPatternsArgs = {
  filter?: Maybe<CollectionPatternsFilterInput>;
  sort?: Maybe<CollectionSortInput>;
  paging?: Maybe<CollectionPagingInput>;
};


export type QueryCollectionPatternArgs = {
  id: Scalars['ID'];
};


export type QueryFreePickupDaysArgs = {
  municipalityId: Scalars['ID'];
  products: Array<CollectionDayProductInput>;
  paging?: Maybe<CollectionPagingInput>;
  collectionGroupIds?: Maybe<Array<Scalars['ID']>>;
  municipalityIds?: Maybe<Array<Scalars['ID']>>;
};


export type QueryFreeDeliveryDaysArgs = {
  municipalityId: Scalars['ID'];
  products: Array<CollectionDayProductInput>;
  pickupDayId: Scalars['ID'];
  paging?: Maybe<CollectionPagingInput>;
  collectionGroupIds?: Maybe<Array<Scalars['ID']>>;
  municipalityIds?: Maybe<Array<Scalars['ID']>>;
};


export type QueryFreeTrucksArgs = {
  date: Scalars['Date'];
};


export type QueryCollectionRoundsArgs = {
  from: Scalars['Date'];
  to: Scalars['Date'];
};


export type QueryTruckScheduleArgs = {
  date: Scalars['Date'];
  truckTagCode?: Maybe<Scalars['String']>;
  truckId?: Maybe<Scalars['ID']>;
};


export type QueryTruckScheduleByIdArgs = {
  id: Scalars['ID'];
};


export type QueryTruckSchedulesByDateArgs = {
  date: Scalars['Date'];
};


export type QueryCardsApplicantsArgs = {
  municipality: Scalars['String'];
  street: Scalars['String'];
  search?: Maybe<Scalars['String']>;
};


export type QueryCardsStreetsArgs = {
  municipalityId: Scalars['ID'];
};


export type QueryAssetsArgs = {
  filter?: Maybe<AssetFilterInput>;
  paging?: Maybe<AssetPagingInput>;
};


export type QueryAssetArgs = {
  id: Scalars['ID'];
};


export type QueryAssetTypesArgs = {
  paging?: Maybe<AssetPagingInput>;
};


export type QueryAssetTypeArgs = {
  id: Scalars['ID'];
};

/**
 * ReminderTrigger
 * This trigger is executed a period of time before a reminderStatus. Ex. 7 days before delivery
 */
export type ReminderTrigger = {
  __typename?: 'ReminderTrigger';
  reminderType: ReminderTriggerType;
  timeAmount: Scalars['Int'];
  timeDuration: TimeDuration;
};

export type ReminderTriggerInput = {
  type: ReminderTriggerType;
  timeAmount: Scalars['Int'];
  timeDuration: TimeDuration;
};

export enum ReminderTriggerType {
  Delivery = 'DELIVERY',
  Pickup = 'PICKUP',
  Publicdomain = 'PUBLICDOMAIN',
  Collected = 'COLLECTED'
}

export type ReportCsvResult = {
  __typename?: 'ReportCSVResult';
  url: Scalars['String'];
};

export type ReportFilterInput = {
  productIds?: Maybe<Array<Scalars['String']>>;
  statusses?: Maybe<Array<InquiryStatusType>>;
};

export enum Responsibility {
  Civilian = 'CIVILIAN',
  PickupService = 'PICKUP_SERVICE'
}

export enum ScanMoment {
  Delivery = 'DELIVERY',
  Collection = 'COLLECTION',
  Processor = 'PROCESSOR'
}

export type ScanResult = {
  __typename?: 'ScanResult';
  succeeded: Scalars['Boolean'];
  error?: Maybe<ScanResultError>;
};

export type ScanResultError = {
  __typename?: 'ScanResultError';
  message: Scalars['String'];
  tagCodes?: Maybe<Array<Scalars['ID']>>;
};

export type ScheduleResult = {
  __typename?: 'ScheduleResult';
  deliveryDate?: Maybe<Scalars['Date']>;
  pickupDate: Scalars['Date'];
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum Status {
  InUse = 'IN_USE',
  Unused = 'UNUSED',
  Blocked = 'BLOCKED'
}

export enum TimeDuration {
  Hours = 'HOURS',
  Days = 'DAYS',
  Weeks = 'WEEKS'
}

export type TokenResult = {
  __typename?: 'TokenResult';
  token: Scalars['String'];
  collector: Collector;
};

export type Trigger = NotificationTrigger | ReminderTrigger | IncidentTrigger;

export type TriggerInput = {
  notification?: Maybe<NotificationTriggerInput>;
  reminder?: Maybe<ReminderTriggerInput>;
  incident?: Maybe<IncidentTriggerInput>;
};

/**
 * Truck
 * A physical truck
 */
export type Truck = {
  __typename?: 'Truck';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: TruckType;
  suivoID: Scalars['String'];
  tagCode: Scalars['String'];
};

export type TruckAddress = {
  __typename?: 'TruckAddress';
  street: Scalars['String'];
  streetNumber: Scalars['String'];
  numberAddition?: Maybe<Scalars['String']>;
  busNumber?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
};

export type TruckCoordinates = {
  __typename?: 'TruckCoordinates';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type TruckDeleteResult = {
  __typename?: 'TruckDeleteResult';
  succeeded: Scalars['Boolean'];
};

export type TruckInput = {
  name: Scalars['String'];
  truckTypeId: Scalars['ID'];
  suivoID: Scalars['String'];
  tagCode: Scalars['String'];
};

export type TruckLocation = {
  __typename?: 'TruckLocation';
  coordinates: TruckCoordinates;
  address: TruckAddress;
};

/**
 * TruckSchedule
 * This is the list of inquiries that a truck needs to finnish on a specific day
 */
export type TruckSchedule = {
  __typename?: 'TruckSchedule';
  id: Scalars['ID'];
  optimal: Scalars['Boolean'];
  truck: Truck;
  date: Scalars['Date'];
  tasks: Array<TruckTask>;
};

export type TrucksResult = {
  __typename?: 'TrucksResult';
  count: Scalars['Int'];
  items: Array<Truck>;
};

/**
 * TruckTask
 * A single task that needs to be done
 */
export type TruckTask = {
  __typename?: 'TruckTask';
  id: Scalars['ID'];
  index: Scalars['Int'];
  dayPart: DayPart;
  type: TruckTaskType;
  done: Scalars['Boolean'];
  task?: Maybe<CollectionRoundTask>;
  processor?: Maybe<Processor>;
  depot?: Maybe<Depot>;
  distance: Scalars['Float'];
  polyline: Scalars['String'];
};

export enum TruckTaskType {
  Inquiry = 'Inquiry',
  Depot = 'Depot',
  Processor = 'Processor'
}

/**
 * TruckType
 * Type of a truck
 */
export type TruckType = {
  __typename?: 'TruckType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type TruckTypeInput = {
  name: Scalars['String'];
};

export type TruckTypeResult = {
  __typename?: 'TruckTypeResult';
  count: Scalars['Int'];
  items: Array<TruckType>;
};

export type UpdateIncidentInput = {
  reasonId: Scalars['ID'];
  description: Scalars['String'];
  images: Array<Scalars['String']>;
};

export type UpdateIncidentReasonInput = {
  name?: Maybe<Scalars['String']>;
  responsibility?: Maybe<Responsibility>;
  productIds?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateInquiryInput = {
  pickupAddress?: Maybe<InquiryLocationInput>;
  media?: Maybe<Array<InquiryMediaInput>>;
  checklist?: Maybe<Scalars['JSON']>;
  checks?: Maybe<InquiryChecksInput>;
};

export type UpdateProductInput = {
  name?: Maybe<Scalars['String']>;
  deliveryMethod?: Maybe<DeliveryMethod>;
  daysBeforePickup?: Maybe<Scalars['Int']>;
  scanMoments?: Maybe<Array<ScanMoment>>;
  accessory?: Maybe<Scalars['Boolean']>;
};

export type UploadResult = {
  __typename?: 'UploadResult';
  succeeded: Scalars['Boolean'];
  errorIds: Array<Scalars['String']>;
};

/**
 * User
 * A user is an entity that can edit things in the orchestrationplatform. (Via the browser or the app)
 */
export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
};

export type WeightInputItem = {
  id: Scalars['String'];
  weight: Scalars['String'];
};

export type CollectionPatternFragment = (
  { __typename?: 'CollectionPattern' }
  & Pick<CollectionPattern, 'id' | 'name' | 'active' | 'deactivated' | 'from' | 'to' | 'dayCount' | 'notPlannableDays'>
  & { days: Array<(
    { __typename?: 'PatternDay' }
    & Pick<PatternDay, 'dayIndex'>
    & { collectionRound: Array<(
      { __typename?: 'CollectionPatternRound' }
      & Pick<CollectionPatternRound, 'dayPart'>
      & { group: (
        { __typename?: 'CollectionGroup' }
        & Pick<CollectionGroup, 'id'>
      ), products: Array<(
        { __typename?: 'Product' }
        & Pick<Product, 'id'>
      )>, truckType: (
        { __typename?: 'TruckType' }
        & Pick<TruckType, 'id'>
      ) }
    )> }
  )>, fixedProductCycles: Array<(
    { __typename?: 'FixedProductCycle' }
    & Pick<FixedProductCycle, 'fixedCyclesBetweenPickupAndDelivery'>
    & { product: (
      { __typename?: 'Product' }
      & Pick<Product, 'id'>
    ) }
  )>, groups: Array<(
    { __typename?: 'CollectionGroup' }
    & Pick<CollectionGroup, 'id' | 'name'>
    & { municipalities: Array<(
      { __typename?: 'Municipality' }
      & Pick<Municipality, 'id'>
    )> }
  )>, configs: Array<(
    { __typename?: 'CollectionPatternConfig' }
    & Pick<CollectionPatternConfig, 'maxAddressesPerHalfDay' | 'maxAddressesPerFullDay'>
    & { group: (
      { __typename?: 'CollectionGroup' }
      & Pick<CollectionGroup, 'id'>
    ), collectionPatternProductConfigs: Array<(
      { __typename?: 'CollectionPatternProductConfig' }
      & { processor: (
        { __typename?: 'Processor' }
        & Pick<Processor, 'id'>
      ), products: Array<(
        { __typename?: 'Product' }
        & Pick<Product, 'id'>
      )>, truckConfigs: Array<(
        { __typename?: 'CollectionPatternTruckConfig' }
        & Pick<CollectionPatternTruckConfig, 'maxItemsPerCargo' | 'cargoPerDayHalfDay' | 'cargoPerDayFullDay'>
        & { truckType: (
          { __typename?: 'TruckType' }
          & Pick<TruckType, 'id'>
        ) }
      )> }
    )> }
  )> }
);

export type CollectionRoundFragment = (
  { __typename?: 'CollectionRound' }
  & Pick<CollectionRound, 'id' | 'date' | 'dayPart' | 'patternId'>
  & { truckType?: Maybe<(
    { __typename?: 'TruckType' }
    & Pick<TruckType, 'id' | 'name'>
  )>, products?: Maybe<Array<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'accessory'>
  )>>, group?: Maybe<(
    { __typename?: 'CollectionGroup' }
    & Pick<CollectionGroup, 'id' | 'name'>
  )>, capacity?: Maybe<(
    { __typename?: 'CollectionRoundCapacity' }
    & Pick<CollectionRoundCapacity, 'maxAddresses' | 'maxItemsPerCargo' | 'maxCargoPerDayPart'>
  )>, tasks: Array<(
    { __typename?: 'CollectionRoundTask' }
    & Pick<CollectionRoundTask, 'type' | 'cargo'>
    & { inquiry: (
      { __typename?: 'Inquiry' }
      & InquiryOverviewFragment
    ), cargoPerProduct?: Maybe<Array<(
      { __typename?: 'CollectionRoundTaskCargoPerProduct' }
      & Pick<CollectionRoundTaskCargoPerProduct, 'cargo' | 'effectiveCargo'>
      & { product: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name'>
      ) }
    )>> }
  )>, truck: (
    { __typename?: 'Truck' }
    & Pick<Truck, 'id' | 'name'>
  ) }
);

export type CollectionRoundTaskFragment = (
  { __typename?: 'CollectionRoundTask' }
  & Pick<CollectionRoundTask, 'type'>
  & { inquiry: (
    { __typename?: 'Inquiry' }
    & TruckInquiryFragment
  ), cargoPerProduct?: Maybe<Array<(
    { __typename?: 'CollectionRoundTaskCargoPerProduct' }
    & Pick<CollectionRoundTaskCargoPerProduct, 'cargo' | 'effectiveCargo'>
    & { product: (
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name'>
    ) }
  )>> }
);

export type InquiryDetailFragment = (
  { __typename?: 'Inquiry' }
  & Pick<Inquiry, 'id' | 'orderNumber' | 'currentStatus' | 'cardsPayment' | 'paymentNumber' | 'deliveryDate' | 'pickupDate' | 'checklist'>
  & { applicant: (
    { __typename?: 'Applicant' }
    & Pick<Applicant, 'id' | 'name' | 'type' | 'phone' | 'email'>
    & { address: (
      { __typename?: 'ApplicantAddress' }
      & Pick<ApplicantAddress, 'street' | 'streetNumber' | 'city' | 'postalCode' | 'busNumber' | 'numberAddition'>
    ) }
  ), products: Array<(
    { __typename?: 'InquiryProduct' }
    & Pick<InquiryProduct, 'amount'>
    & { product: (
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'deliveryMethod'>
    ) }
  )>, pickupAddress: (
    { __typename?: 'InquiryLocation' }
    & { address: (
      { __typename?: 'InquiryAddress' }
      & Pick<InquiryAddress, 'street' | 'streetNumber' | 'city' | 'postalCode' | 'busNumber' | 'numberAddition' | 'country'>
    ), municipality?: Maybe<(
      { __typename?: 'Municipality' }
      & Pick<Municipality, 'id'>
    )>, coordinates: (
      { __typename?: 'InquiryCoordinates' }
      & Pick<InquiryCoordinates, 'lat' | 'lng'>
    ) }
  ), statusHistory: Array<(
    { __typename?: 'InquiryStatus' }
    & Pick<InquiryStatus, 'type' | 'timestamp' | 'description' | 'deliveryDate' | 'pickupDate'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstname' | 'lastname'>
    ), incident?: Maybe<(
      { __typename?: 'Incident' }
      & InquiryDetailIncidentFragment
    )> }
  )>, media?: Maybe<Array<(
    { __typename?: 'InquiryMedia' }
    & Pick<InquiryMedia, 'id' | 'url'>
  )>> }
);

export type InquiryDetailIncidentFragment = (
  { __typename?: 'Incident' }
  & Pick<Incident, 'id' | 'description' | 'images'>
  & { collector: (
    { __typename?: 'Collector' }
    & Pick<Collector, 'id' | 'firstname' | 'lastname'>
  ), reason: (
    { __typename?: 'IncidentReason' }
    & Pick<IncidentReason, 'id' | 'responsibility' | 'name'>
    & { products: Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name'>
    )> }
  ), feedback?: Maybe<(
    { __typename?: 'IncidentFeedback' }
    & Pick<IncidentFeedback, 'images' | 'phone' | 'email' | 'message'>
  )>, comments: Array<(
    { __typename?: 'IncidentComment' }
    & Pick<IncidentComment, 'id' | 'timestamp' | 'message'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstname' | 'lastname'>
    ) }
  )>, payments: Array<(
    { __typename?: 'Payment' }
    & Pick<Payment, 'id' | 'timestamp'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstname' | 'lastname'>
    ), products?: Maybe<Array<(
      { __typename?: 'PaymentProductType' }
      & Pick<PaymentProductType, 'productId' | 'amount'>
    )>> }
  )> }
);

export type InquiryOverviewFragment = (
  { __typename?: 'Inquiry' }
  & Pick<Inquiry, 'id' | 'orderNumber' | 'pickupDate' | 'deliveryDate' | 'paymentNumber' | 'currentStatus' | 'lastUpdated' | 'cardsPayment'>
  & { applicant: (
    { __typename?: 'Applicant' }
    & Pick<Applicant, 'id' | 'name' | 'type'>
  ), products: Array<(
    { __typename?: 'InquiryProduct' }
    & Pick<InquiryProduct, 'amount'>
    & { product: (
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'deliveryMethod' | 'accessory' | 'name'>
    ) }
  )>, pickupAddress: (
    { __typename?: 'InquiryLocation' }
    & { address: (
      { __typename?: 'InquiryAddress' }
      & Pick<InquiryAddress, 'street' | 'streetNumber' | 'city' | 'postalCode' | 'busNumber' | 'numberAddition'>
    ), municipality?: Maybe<(
      { __typename?: 'Municipality' }
      & Pick<Municipality, 'id' | 'name'>
    )> }
  ), statusHistory: Array<(
    { __typename?: 'InquiryStatus' }
    & Pick<InquiryStatus, 'type' | 'timestamp'>
    & { incident?: Maybe<(
      { __typename?: 'Incident' }
      & Pick<Incident, 'id'>
      & { reason: (
        { __typename?: 'IncidentReason' }
        & Pick<IncidentReason, 'id' | 'responsibility'>
      ), payments: Array<(
        { __typename?: 'Payment' }
        & Pick<Payment, 'id' | 'timestamp'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'firstname' | 'lastname'>
        ), products?: Maybe<Array<(
          { __typename?: 'PaymentProductType' }
          & Pick<PaymentProductType, 'productId' | 'amount'>
        )>> }
      )> }
    )> }
  )> }
);

export type InquiryProductFragment = (
  { __typename?: 'InquiryProduct' }
  & Pick<InquiryProduct, 'amount'>
  & { product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name'>
  ) }
);

export type NotificationConfigurationFragment = (
  { __typename?: 'NotificationConfiguration' }
  & Pick<NotificationConfiguration, 'id' | 'channel' | 'rawTemplate' | 'htmlTemplate'>
  & { trigger: (
    { __typename?: 'NotificationTrigger' }
    & Trigger_NotificationTrigger_Fragment
  ) | (
    { __typename?: 'ReminderTrigger' }
    & Trigger_ReminderTrigger_Fragment
  ) | (
    { __typename?: 'IncidentTrigger' }
    & Trigger_IncidentTrigger_Fragment
  ), products: Array<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name'>
  )> }
);

export type ProcessorFragment = (
  { __typename?: 'Processor' }
  & Pick<Processor, 'id' | 'name'>
  & { address: (
    { __typename?: 'ProcessorLocation' }
    & { coordinates: (
      { __typename?: 'ProcessorCoordinates' }
      & Pick<ProcessorCoordinates, 'lat' | 'lng'>
    ), address: (
      { __typename?: 'ProcessorAddress' }
      & Pick<ProcessorAddress, 'street' | 'streetNumber' | 'city' | 'postalCode' | 'country'>
    ) }
  ), products: Array<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name'>
  )> }
);

export type ProductFragment = (
  { __typename?: 'Product' }
  & Pick<Product, 'id' | 'name' | 'deliveryMethod' | 'accessory'>
);

export type ProductDetailFragment = (
  { __typename?: 'Product' }
  & Pick<Product, 'id' | 'name' | 'deliveryMethod' | 'daysBeforePickup' | 'scanMoments' | 'accessory'>
);

type Trigger_NotificationTrigger_Fragment = (
  { __typename?: 'NotificationTrigger' }
  & Pick<NotificationTrigger, 'notificationType'>
);

type Trigger_ReminderTrigger_Fragment = (
  { __typename?: 'ReminderTrigger' }
  & Pick<ReminderTrigger, 'reminderType' | 'timeAmount' | 'timeDuration'>
);

type Trigger_IncidentTrigger_Fragment = (
  { __typename?: 'IncidentTrigger' }
  & Pick<IncidentTrigger, 'incidentType'>
  & { incident: (
    { __typename?: 'IncidentReason' }
    & Pick<IncidentReason, 'id' | 'name'>
  ) }
);

export type TriggerFragment = Trigger_NotificationTrigger_Fragment | Trigger_ReminderTrigger_Fragment | Trigger_IncidentTrigger_Fragment;

export type TruckFragment = (
  { __typename?: 'Truck' }
  & Pick<Truck, 'id' | 'name' | 'tagCode' | 'suivoID'>
  & { type: (
    { __typename?: 'TruckType' }
    & Pick<TruckType, 'id' | 'name'>
  ) }
);

export type TruckInquiryFragment = (
  { __typename?: 'Inquiry' }
  & Pick<Inquiry, 'id' | 'orderNumber' | 'currentStatus' | 'checklist'>
  & { products: Array<(
    { __typename?: 'InquiryProduct' }
    & InquiryProductFragment
  )>, pickupAddress: (
    { __typename?: 'InquiryLocation' }
    & { coordinates: (
      { __typename?: 'InquiryCoordinates' }
      & Pick<InquiryCoordinates, 'lat' | 'lng'>
    ), address: (
      { __typename?: 'InquiryAddress' }
      & Pick<InquiryAddress, 'street' | 'streetNumber' | 'city' | 'postalCode' | 'country'>
    ) }
  ), applicant: (
    { __typename?: 'Applicant' }
    & Pick<Applicant, 'id' | 'name' | 'phone'>
  ) }
);

export type TruckLocationFragment = (
  { __typename?: 'TruckLocation' }
  & { coordinates: (
    { __typename?: 'TruckCoordinates' }
    & Pick<TruckCoordinates, 'lat' | 'lng'>
  ), address: (
    { __typename?: 'TruckAddress' }
    & Pick<TruckAddress, 'street' | 'streetNumber' | 'city' | 'postalCode' | 'country'>
  ) }
);

export type TruckTaskFragment = (
  { __typename?: 'TruckTask' }
  & Pick<TruckTask, 'id' | 'type' | 'done' | 'polyline' | 'distance' | 'dayPart'>
  & { task?: Maybe<(
    { __typename?: 'CollectionRoundTask' }
    & CollectionRoundTaskFragment
  )>, depot?: Maybe<(
    { __typename?: 'Depot' }
    & Pick<Depot, 'name'>
    & { address: (
      { __typename?: 'TruckLocation' }
      & { address: (
        { __typename?: 'TruckAddress' }
        & Pick<TruckAddress, 'street' | 'streetNumber' | 'city' | 'postalCode' | 'country'>
      ), coordinates: (
        { __typename?: 'TruckCoordinates' }
        & Pick<TruckCoordinates, 'lat' | 'lng'>
      ) }
    ) }
  )>, processor?: Maybe<(
    { __typename?: 'Processor' }
    & ProcessorFragment
  )> }
);

export type TruckTypeFragment = (
  { __typename?: 'TruckType' }
  & Pick<TruckType, 'id' | 'name'>
);

export type ActivateCollectionPatternMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ActivateCollectionPatternMutation = (
  { __typename?: 'Mutation' }
  & { activateCollectionPattern: (
    { __typename?: 'CollectionPattern' }
    & CollectionPatternFragment
  ) }
);

export type AddIncidentCommentMutationVariables = Exact<{
  incidentId: Scalars['ID'];
  comment: Scalars['String'];
}>;


export type AddIncidentCommentMutation = (
  { __typename?: 'Mutation' }
  & { addIncidentComment: (
    { __typename?: 'Incident' }
    & Pick<Incident, 'id'>
    & { comments: Array<(
      { __typename?: 'IncidentComment' }
      & Pick<IncidentComment, 'id' | 'timestamp' | 'message'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstname' | 'lastname'>
      ) }
    )> }
  ) }
);

export type AddIncidentFeedbackMutationVariables = Exact<{
  incidentId: Scalars['ID'];
  images: Array<Scalars['String']>;
  message: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
}>;


export type AddIncidentFeedbackMutation = (
  { __typename?: 'Mutation' }
  & { addIncidentFeedback: (
    { __typename?: 'Incident' }
    & Pick<Incident, 'id' | 'description' | 'images'>
    & { collector: (
      { __typename?: 'Collector' }
      & Pick<Collector, 'id' | 'firstname' | 'lastname'>
    ), reason: (
      { __typename?: 'IncidentReason' }
      & Pick<IncidentReason, 'id' | 'responsibility' | 'name'>
      & { products: Array<(
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name'>
      )> }
    ), feedback?: Maybe<(
      { __typename?: 'IncidentFeedback' }
      & Pick<IncidentFeedback, 'images' | 'phone' | 'email' | 'message'>
    )>, comments: Array<(
      { __typename?: 'IncidentComment' }
      & Pick<IncidentComment, 'id' | 'timestamp' | 'message'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstname' | 'lastname'>
      ) }
    )> }
  ) }
);

export type AddTruckToCollectionRoundMutationVariables = Exact<{
  collectionRoundId: Scalars['ID'];
  truckId: Scalars['ID'];
}>;


export type AddTruckToCollectionRoundMutation = (
  { __typename?: 'Mutation' }
  & { addTruckToCollectionRound: (
    { __typename?: 'CollectionRound' }
    & CollectionRoundFragment
  ) }
);

export type CancelInquiryMutationVariables = Exact<{
  inquiryId: Scalars['ID'];
  reason: Scalars['String'];
}>;


export type CancelInquiryMutation = (
  { __typename?: 'Mutation' }
  & { cancelInquiry: (
    { __typename?: 'Inquiry' }
    & { statusHistory: Array<(
      { __typename?: 'InquiryStatus' }
      & Pick<InquiryStatus, 'type' | 'timestamp' | 'description'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'firstname' | 'lastname'>
      ) }
    )> }
  ) }
);

export type CloseIncidentMutationVariables = Exact<{
  incidentId: Scalars['ID'];
}>;


export type CloseIncidentMutation = (
  { __typename?: 'Mutation' }
  & { closeIncident: (
    { __typename?: 'Incident' }
    & Pick<Incident, 'id' | 'description' | 'images'>
    & { collector: (
      { __typename?: 'Collector' }
      & Pick<Collector, 'id' | 'firstname' | 'lastname'>
    ), reason: (
      { __typename?: 'IncidentReason' }
      & Pick<IncidentReason, 'id' | 'responsibility' | 'name'>
      & { products: Array<(
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name'>
      )> }
    ), feedback?: Maybe<(
      { __typename?: 'IncidentFeedback' }
      & Pick<IncidentFeedback, 'images' | 'phone' | 'email' | 'message'>
    )>, comments: Array<(
      { __typename?: 'IncidentComment' }
      & Pick<IncidentComment, 'id' | 'timestamp' | 'message'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstname' | 'lastname'>
      ) }
    )> }
  ) }
);

export type CreateAssetMutationVariables = Exact<{
  asset: AssetInput;
}>;


export type CreateAssetMutation = (
  { __typename?: 'Mutation' }
  & { createAsset: (
    { __typename?: 'Asset' }
    & Pick<Asset, 'id' | 'name' | 'tagCode' | 'status'>
    & { type: (
      { __typename?: 'AssetType' }
      & Pick<AssetType, 'id' | 'name'>
    ), lastAddress?: Maybe<(
      { __typename?: 'AssetAddress' }
      & Pick<AssetAddress, 'street' | 'streetNumber' | 'city' | 'postalCode' | 'country'>
    )> }
  ) }
);

export type CreateAssetTypeMutationVariables = Exact<{
  assetType: AssetTypeInput;
}>;


export type CreateAssetTypeMutation = (
  { __typename?: 'Mutation' }
  & { createAssetType: (
    { __typename?: 'AssetType' }
    & Pick<AssetType, 'id' | 'name'>
  ) }
);

export type CreateCollectionPatternMutationVariables = Exact<{
  pattern: CollectionPatternInput;
}>;


export type CreateCollectionPatternMutation = (
  { __typename?: 'Mutation' }
  & { createCollectionPattern: (
    { __typename?: 'CollectionPattern' }
    & CollectionPatternFragment
  ) }
);

export type CreateCollectorMutationVariables = Exact<{
  collector: CollectorInput;
}>;


export type CreateCollectorMutation = (
  { __typename?: 'Mutation' }
  & { createCollector: (
    { __typename?: 'Collector' }
    & Pick<Collector, 'id' | 'firstname' | 'lastname' | 'tagCode'>
  ) }
);

export type CreateIncidentMutationVariables = Exact<{
  incident: CreateIncidentInput;
  taskId?: Maybe<Scalars['ID']>;
}>;


export type CreateIncidentMutation = (
  { __typename?: 'Mutation' }
  & { createIncident: (
    { __typename?: 'Incident' }
    & Pick<Incident, 'id'>
  ) }
);

export type CreateIncidentReasonMutationVariables = Exact<{
  incidentReason: IncidentReasonInput;
}>;


export type CreateIncidentReasonMutation = (
  { __typename?: 'Mutation' }
  & { createIncidentReason: (
    { __typename?: 'IncidentReason' }
    & Pick<IncidentReason, 'id' | 'name' | 'responsibility'>
    & { products: Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name'>
    )> }
  ) }
);

export type CreateInquiryMutationVariables = Exact<{
  inquiry: CreateInquiryInput;
}>;


export type CreateInquiryMutation = (
  { __typename?: 'Mutation' }
  & { createInquiry: (
    { __typename?: 'Inquiry' }
    & Pick<Inquiry, 'id' | 'orderNumber' | 'checklist'>
    & { applicant: (
      { __typename?: 'Applicant' }
      & Pick<Applicant, 'id' | 'name' | 'type' | 'phone' | 'email'>
      & { address: (
        { __typename?: 'ApplicantAddress' }
        & Pick<ApplicantAddress, 'street' | 'streetNumber' | 'city' | 'postalCode' | 'country'>
      ) }
    ), products: Array<(
      { __typename?: 'InquiryProduct' }
      & Pick<InquiryProduct, 'amount'>
      & { product: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name'>
      ) }
    )>, pickupAddress: (
      { __typename?: 'InquiryLocation' }
      & { address: (
        { __typename?: 'InquiryAddress' }
        & Pick<InquiryAddress, 'street' | 'streetNumber' | 'city' | 'postalCode'>
      ) }
    ), media?: Maybe<Array<(
      { __typename?: 'InquiryMedia' }
      & Pick<InquiryMedia, 'id' | 'url'>
    )>> }
  ) }
);

export type CreateNotificationConfigurationMutationVariables = Exact<{
  configuration: NotificationConfigurationInput;
}>;


export type CreateNotificationConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { createNotificationConfiguration: (
    { __typename?: 'NotificationConfiguration' }
    & NotificationConfigurationFragment
  ) }
);

export type CreateProcessorMutationVariables = Exact<{
  processor: ProcessorInput;
}>;


export type CreateProcessorMutation = (
  { __typename?: 'Mutation' }
  & { createProcessor: (
    { __typename?: 'Processor' }
    & ProcessorFragment
  ) }
);

export type CreateProductMutationVariables = Exact<{
  product: CreateProductInput;
}>;


export type CreateProductMutation = (
  { __typename?: 'Mutation' }
  & { createProduct: (
    { __typename?: 'Product' }
    & ProductDetailFragment
  ) }
);

export type CreateStandAloneCollectionRoundMutationVariables = Exact<{
  date: Scalars['Date'];
  processorId: Scalars['ID'];
  truckId: Scalars['ID'];
  itemsPerCargo: Scalars['Int'];
}>;


export type CreateStandAloneCollectionRoundMutation = (
  { __typename?: 'Mutation' }
  & { createStandAloneCollectionRound: (
    { __typename?: 'CollectionRound' }
    & CollectionRoundFragment
  ) }
);

export type CreateTruckMutationVariables = Exact<{
  truck: TruckInput;
}>;


export type CreateTruckMutation = (
  { __typename?: 'Mutation' }
  & { createTruck: (
    { __typename?: 'Truck' }
    & TruckFragment
  ) }
);

export type CreateTruckTypeMutationVariables = Exact<{
  truckType: TruckTypeInput;
}>;


export type CreateTruckTypeMutation = (
  { __typename?: 'Mutation' }
  & { createTruckType: (
    { __typename?: 'TruckType' }
    & Pick<TruckType, 'id' | 'name'>
  ) }
);

export type DeactivateCollectionPatternMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeactivateCollectionPatternMutation = (
  { __typename?: 'Mutation' }
  & { deactivateCollectionPattern: (
    { __typename?: 'CollectionPattern' }
    & CollectionPatternFragment
  ) }
);

export type DeleteAssetMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteAssetMutation = (
  { __typename?: 'Mutation' }
  & { deleteAsset: (
    { __typename?: 'AssetDeleteResult' }
    & Pick<AssetDeleteResult, 'succeeded'>
  ) }
);

export type DeleteAssetTypeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteAssetTypeMutation = (
  { __typename?: 'Mutation' }
  & { deleteAssetType: (
    { __typename?: 'AssetDeleteResult' }
    & Pick<AssetDeleteResult, 'succeeded'>
  ) }
);

export type DeleteCollectionPatternMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCollectionPatternMutation = (
  { __typename?: 'Mutation' }
  & { deleteCollectionPattern: (
    { __typename?: 'CollectionPatternDeleteResult' }
    & Pick<CollectionPatternDeleteResult, 'succeeded'>
  ) }
);

export type DeleteCollectorMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCollectorMutation = (
  { __typename?: 'Mutation' }
  & { deleteCollector: (
    { __typename?: 'CollectorDeleteResult' }
    & Pick<CollectorDeleteResult, 'succeeded'>
  ) }
);

export type DeleteIncidentCommentMutationVariables = Exact<{
  commentId: Scalars['ID'];
}>;


export type DeleteIncidentCommentMutation = (
  { __typename?: 'Mutation' }
  & { deleteIncidentComment: (
    { __typename?: 'IncidentDeleteResult' }
    & Pick<IncidentDeleteResult, 'succeeded'>
  ) }
);

export type DeleteIncidentReasonMutationVariables = Exact<{
  incidentReasonId: Scalars['ID'];
}>;


export type DeleteIncidentReasonMutation = (
  { __typename?: 'Mutation' }
  & { deleteIncidentReason: (
    { __typename?: 'IncidentDeleteResult' }
    & Pick<IncidentDeleteResult, 'succeeded'>
  ) }
);

export type DeleteNotificationConfigurationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteNotificationConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { deleteNotificationConfiguration: (
    { __typename?: 'NotificationDeleteResult' }
    & Pick<NotificationDeleteResult, 'succeeded'>
  ) }
);

export type DeleteProcessorMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteProcessorMutation = (
  { __typename?: 'Mutation' }
  & { deleteProcessor: (
    { __typename?: 'ProcessorDeleteResult' }
    & Pick<ProcessorDeleteResult, 'succeeded'>
  ) }
);

export type DeleteTruckMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTruckMutation = (
  { __typename?: 'Mutation' }
  & { deleteTruck: (
    { __typename?: 'TruckDeleteResult' }
    & Pick<TruckDeleteResult, 'succeeded'>
  ) }
);

export type DeleteTruckTypeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTruckTypeMutation = (
  { __typename?: 'Mutation' }
  & { deleteTruckType: (
    { __typename?: 'TruckDeleteResult' }
    & Pick<TruckDeleteResult, 'succeeded'>
  ) }
);

export type FixedTruckScheduleOrderMutationVariables = Exact<{
  date: Scalars['Date'];
  truckId: Scalars['ID'];
  inquiryOrder: Array<InquiryOrderInput>;
}>;


export type FixedTruckScheduleOrderMutation = (
  { __typename?: 'Mutation' }
  & { fixedTruckScheduleOrder: (
    { __typename?: 'TruckSchedule' }
    & Pick<TruckSchedule, 'id' | 'optimal' | 'date'>
    & { truck: (
      { __typename?: 'Truck' }
      & Pick<Truck, 'id' | 'name'>
      & { type: (
        { __typename?: 'TruckType' }
        & Pick<TruckType, 'id' | 'name'>
      ) }
    ), tasks: Array<(
      { __typename?: 'TruckTask' }
      & TruckTaskFragment
    )> }
  ) }
);

export type GetincidentReportsCsvMutationVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
  filter?: Maybe<ReportFilterInput>;
}>;


export type GetincidentReportsCsvMutation = (
  { __typename?: 'Mutation' }
  & { incidentReportsCSV: (
    { __typename?: 'ReportCSVResult' }
    & Pick<ReportCsvResult, 'url'>
  ) }
);

export type GetLogisticReportsCsvMutationVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;


export type GetLogisticReportsCsvMutation = (
  { __typename?: 'Mutation' }
  & { logisticReportsCSV: (
    { __typename?: 'ReportCSVResult' }
    & Pick<ReportCsvResult, 'url'>
  ) }
);

export type GetOrderReportsCsvMutationVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
  filter?: Maybe<ReportFilterInput>;
}>;


export type GetOrderReportsCsvMutation = (
  { __typename?: 'Mutation' }
  & { orderReportsCSV: (
    { __typename?: 'ReportCSVResult' }
    & Pick<ReportCsvResult, 'url'>
  ) }
);

export type GetTruckScheduleCsvMutationVariables = Exact<{
  date: Scalars['Date'];
  truckId: Scalars['ID'];
}>;


export type GetTruckScheduleCsvMutation = (
  { __typename?: 'Mutation' }
  & { truckScheduleCSV: (
    { __typename?: 'CSVResult' }
    & Pick<CsvResult, 'url'>
  ) }
);

export type InquiryOnHoldMutationVariables = Exact<{
  inquiryId: Scalars['ID'];
  reason: Scalars['String'];
}>;


export type InquiryOnHoldMutation = (
  { __typename?: 'Mutation' }
  & { inquiryOnHold: (
    { __typename?: 'Inquiry' }
    & { statusHistory: Array<(
      { __typename?: 'InquiryStatus' }
      & Pick<InquiryStatus, 'type' | 'timestamp' | 'description'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'firstname' | 'lastname'>
      ) }
    )> }
  ) }
);

export type InquiryProcessedMutationVariables = Exact<{
  inquiryId: Scalars['ID'];
  timestamp: Scalars['DateTime'];
}>;


export type InquiryProcessedMutation = (
  { __typename?: 'Mutation' }
  & { inquiryProcessed: (
    { __typename?: 'Inquiry' }
    & { statusHistory: Array<(
      { __typename?: 'InquiryStatus' }
      & Pick<InquiryStatus, 'type' | 'timestamp' | 'description'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'firstname' | 'lastname'>
      ) }
    )> }
  ) }
);

export type InquiryToBeRescheduledMutationVariables = Exact<{
  inquiryId: Scalars['ID'];
}>;


export type InquiryToBeRescheduledMutation = (
  { __typename?: 'Mutation' }
  & { inquiryToBeRescheduled: (
    { __typename?: 'Inquiry' }
    & { statusHistory: Array<(
      { __typename?: 'InquiryStatus' }
      & Pick<InquiryStatus, 'type' | 'timestamp' | 'description'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'firstname' | 'lastname'>
      ) }
    )> }
  ) }
);

export type MoveTruckScheduleMutationVariables = Exact<{
  id: Scalars['ID'];
  truckId: Scalars['ID'];
}>;


export type MoveTruckScheduleMutation = (
  { __typename?: 'Mutation' }
  & { moveTruckSchedule: (
    { __typename?: 'TruckSchedule' }
    & Pick<TruckSchedule, 'id' | 'date'>
    & { truck: (
      { __typename?: 'Truck' }
      & Pick<Truck, 'id'>
    ) }
  ) }
);

export type PaymentRequestMutationVariables = Exact<{
  inquiryId: Scalars['ID'];
  incidentId: Scalars['ID'];
  products: Array<PaymentProduct>;
}>;


export type PaymentRequestMutation = (
  { __typename?: 'Mutation' }
  & { addPayment: (
    { __typename?: 'Incident' }
    & InquiryDetailIncidentFragment
  ) }
);

export type ResetScheduleMutationVariables = Exact<{
  date: Scalars['Date'];
  truckId: Scalars['ID'];
}>;


export type ResetScheduleMutation = (
  { __typename?: 'Mutation' }
  & { resetSchedule: (
    { __typename?: 'TruckSchedule' }
    & Pick<TruckSchedule, 'id' | 'optimal' | 'date'>
    & { truck: (
      { __typename?: 'Truck' }
      & Pick<Truck, 'id' | 'name'>
      & { type: (
        { __typename?: 'TruckType' }
        & Pick<TruckType, 'id' | 'name'>
      ) }
    ), tasks: Array<(
      { __typename?: 'TruckTask' }
      & TruckTaskFragment
    )> }
  ) }
);

export type UpdateAssetMutationVariables = Exact<{
  asset: AssetInput;
  id: Scalars['ID'];
}>;


export type UpdateAssetMutation = (
  { __typename?: 'Mutation' }
  & { updateAsset: (
    { __typename?: 'Asset' }
    & Pick<Asset, 'id' | 'name' | 'tagCode' | 'status'>
    & { type: (
      { __typename?: 'AssetType' }
      & Pick<AssetType, 'id' | 'name'>
    ), lastAddress?: Maybe<(
      { __typename?: 'AssetAddress' }
      & Pick<AssetAddress, 'street' | 'streetNumber' | 'city' | 'postalCode' | 'country'>
    )> }
  ) }
);

export type UpdateAssetTypeMutationVariables = Exact<{
  assetType: AssetTypeInput;
  id: Scalars['ID'];
}>;


export type UpdateAssetTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateAssetType: (
    { __typename?: 'AssetType' }
    & Pick<AssetType, 'id' | 'name'>
  ) }
);

export type UpdateCollectionPatternMutationVariables = Exact<{
  id: Scalars['ID'];
  pattern: CollectionPatternInput;
}>;


export type UpdateCollectionPatternMutation = (
  { __typename?: 'Mutation' }
  & { updateCollectionPattern: (
    { __typename?: 'CollectionPattern' }
    & CollectionPatternFragment
  ) }
);

export type UpdateCollectorMutationVariables = Exact<{
  collector: CollectorInput;
  id: Scalars['ID'];
}>;


export type UpdateCollectorMutation = (
  { __typename?: 'Mutation' }
  & { updateCollector: (
    { __typename?: 'Collector' }
    & Pick<Collector, 'id' | 'firstname' | 'lastname' | 'tagCode'>
  ) }
);

export type UpdateIncidentCommentMutationVariables = Exact<{
  commentId: Scalars['ID'];
  comment: Scalars['String'];
}>;


export type UpdateIncidentCommentMutation = (
  { __typename?: 'Mutation' }
  & { updateIncidentComment: (
    { __typename?: 'Incident' }
    & { comments: Array<(
      { __typename?: 'IncidentComment' }
      & Pick<IncidentComment, 'id' | 'timestamp' | 'message'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstname' | 'lastname'>
      ) }
    )> }
  ) }
);

export type UpdateIncidentReasonMutationVariables = Exact<{
  incidentReason: IncidentReasonInput;
  incidentReasonId: Scalars['ID'];
}>;


export type UpdateIncidentReasonMutation = (
  { __typename?: 'Mutation' }
  & { updateIncidentReason: (
    { __typename?: 'IncidentReason' }
    & Pick<IncidentReason, 'id' | 'name' | 'responsibility'>
    & { products: Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name'>
    )> }
  ) }
);

export type UpdateInquiryMutationVariables = Exact<{
  inquiryId: Scalars['ID'];
  input: UpdateInquiryInput;
}>;


export type UpdateInquiryMutation = (
  { __typename?: 'Mutation' }
  & { updateInquiry: (
    { __typename?: 'Inquiry' }
    & InquiryDetailFragment
  ) }
);

export type UpdateInquiryCollectionDayMutationVariables = Exact<{
  inquiryId: Scalars['ID'];
  pickupDayId: Scalars['ID'];
  deliveryDayId?: Maybe<Scalars['ID']>;
}>;


export type UpdateInquiryCollectionDayMutation = (
  { __typename?: 'Mutation' }
  & { updateInquiryCollectionDay: (
    { __typename?: 'Inquiry' }
    & InquiryDetailFragment
  ) }
);

export type UpdateInquiryTaskCollectionDayMutationVariables = Exact<{
  inquiryId: Scalars['ID'];
  type: CollectionRoundTaskType;
  collectionDay: Scalars['ID'];
}>;


export type UpdateInquiryTaskCollectionDayMutation = (
  { __typename?: 'Mutation' }
  & { updateInquiryTaskCollectionDay: (
    { __typename?: 'Inquiry' }
    & InquiryDetailFragment
  ) }
);

export type UpdateNotificationConfigurationMutationVariables = Exact<{
  id: Scalars['ID'];
  configuration: NotificationConfigurationInput;
}>;


export type UpdateNotificationConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { updateNotificationConfiguration: (
    { __typename?: 'NotificationConfiguration' }
    & NotificationConfigurationFragment
  ) }
);

export type UpdateProcessorMutationVariables = Exact<{
  id: Scalars['ID'];
  processor: ProcessorInput;
}>;


export type UpdateProcessorMutation = (
  { __typename?: 'Mutation' }
  & { updateProcessor: (
    { __typename?: 'Processor' }
    & ProcessorFragment
  ) }
);

export type UpdateProductMutationVariables = Exact<{
  id: Scalars['ID'];
  product: UpdateProductInput;
}>;


export type UpdateProductMutation = (
  { __typename?: 'Mutation' }
  & { updateProduct: (
    { __typename?: 'Product' }
    & ProductDetailFragment
  ) }
);

export type UpdateTruckMutationVariables = Exact<{
  id: Scalars['ID'];
  truck: TruckInput;
}>;


export type UpdateTruckMutation = (
  { __typename?: 'Mutation' }
  & { updateTruck: (
    { __typename?: 'Truck' }
    & TruckFragment
  ) }
);

export type UpdateTruckTypeMutationVariables = Exact<{
  truckType: TruckTypeInput;
  id: Scalars['ID'];
}>;


export type UpdateTruckTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateTruckType: (
    { __typename?: 'TruckType' }
    & Pick<TruckType, 'id' | 'name'>
  ) }
);

export type UploadMediaMutationVariables = Exact<{
  input: MediaInput;
}>;


export type UploadMediaMutation = (
  { __typename?: 'Mutation' }
  & { uploadMedia: (
    { __typename?: 'Media' }
    & Pick<Media, 'id' | 'filename' | 'type' | 'url'>
  ) }
);

export type UploadWeightDataMutationVariables = Exact<{
  processorId: Scalars['ID'];
  weightInput: Array<WeightInputItem>;
}>;


export type UploadWeightDataMutation = (
  { __typename?: 'Mutation' }
  & { uploadWeightData: (
    { __typename?: 'UploadResult' }
    & Pick<UploadResult, 'succeeded' | 'errorIds'>
  ) }
);

export type CollectionRoundsCsvMutationVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;


export type CollectionRoundsCsvMutation = (
  { __typename?: 'Mutation' }
  & { collectionRoundsCSV: (
    { __typename?: 'CSVResult' }
    & Pick<CsvResult, 'url'>
  ) }
);

export type GetApplicantsQueryVariables = Exact<{
  search: Scalars['String'];
  municipality: Scalars['String'];
  street: Scalars['String'];
}>;


export type GetApplicantsQuery = (
  { __typename?: 'Query' }
  & { cardsApplicants: Array<(
    { __typename?: 'CardsApplicant' }
    & Pick<CardsApplicant, 'id' | 'name' | 'type' | 'phone' | 'personId' | 'email'>
    & { address: (
      { __typename?: 'CardsApplicantAddress' }
      & Pick<CardsApplicantAddress, 'street' | 'streetNumber' | 'numberAddition' | 'busNumber' | 'city' | 'postalCode' | 'country'>
    ) }
  )> }
);

export type GetAssetTypesQueryVariables = Exact<{
  paging?: Maybe<AssetPagingInput>;
}>;


export type GetAssetTypesQuery = (
  { __typename?: 'Query' }
  & { assetTypes: (
    { __typename?: 'AssetTypesResult' }
    & Pick<AssetTypesResult, 'count'>
    & { items: Array<(
      { __typename?: 'AssetType' }
      & Pick<AssetType, 'id' | 'name'>
    )> }
  ) }
);

export type GetAssetsQueryVariables = Exact<{
  filter?: Maybe<AssetFilterInput>;
  paging?: Maybe<AssetPagingInput>;
}>;


export type GetAssetsQuery = (
  { __typename?: 'Query' }
  & { assets: (
    { __typename?: 'AssetsResult' }
    & Pick<AssetsResult, 'count'>
    & { items: Array<(
      { __typename?: 'Asset' }
      & Pick<Asset, 'id' | 'name' | 'tagCode' | 'status'>
      & { type: (
        { __typename?: 'AssetType' }
        & Pick<AssetType, 'id' | 'name'>
      ), lastAddress?: Maybe<(
        { __typename?: 'AssetAddress' }
        & Pick<AssetAddress, 'street' | 'streetNumber' | 'city' | 'postalCode' | 'country'>
      )> }
    )> }
  ) }
);

export type GetCardsProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCardsProductsQuery = (
  { __typename?: 'Query' }
  & { cardsProducts: Array<(
    { __typename?: 'CardsProduct' }
    & Pick<CardsProduct, 'id' | 'name'>
  )> }
);

export type GetCollectionPatternQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCollectionPatternQuery = (
  { __typename?: 'Query' }
  & { collectionPattern: (
    { __typename?: 'CollectionPattern' }
    & CollectionPatternFragment
  ) }
);

export type GetCollectionPatternsQueryVariables = Exact<{
  filter?: Maybe<CollectionPatternsFilterInput>;
  sort?: Maybe<CollectionSortInput>;
  paging?: Maybe<CollectionPagingInput>;
}>;


export type GetCollectionPatternsQuery = (
  { __typename?: 'Query' }
  & { collectionPatterns: (
    { __typename?: 'CollectionPatternResult' }
    & Pick<CollectionPatternResult, 'count'>
    & { items: Array<(
      { __typename?: 'CollectionPattern' }
      & CollectionPatternFragment
    )> }
  ) }
);

export type GetCollectionRoundsQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;


export type GetCollectionRoundsQuery = (
  { __typename?: 'Query' }
  & { collectionRounds: Array<(
    { __typename?: 'CollectionRound' }
    & CollectionRoundFragment
  )> }
);

export type GetCollectorsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCollectorsQuery = (
  { __typename?: 'Query' }
  & { collectors: Array<(
    { __typename?: 'Collector' }
    & Pick<Collector, 'id' | 'firstname' | 'lastname' | 'tagCode'>
  )> }
);

export type GetFreeDeliveryDaysQueryVariables = Exact<{
  municipalityId: Scalars['ID'];
  pickupDayId: Scalars['ID'];
  products: Array<CollectionDayProductInput>;
  paging?: Maybe<CollectionPagingInput>;
  municipalityIds?: Maybe<Array<Scalars['ID']>>;
  collectionGroupIds?: Maybe<Array<Scalars['ID']>>;
}>;


export type GetFreeDeliveryDaysQuery = (
  { __typename?: 'Query' }
  & { freeDeliveryDays: (
    { __typename?: 'CollectionDayResult' }
    & Pick<CollectionDayResult, 'count'>
    & { items: Array<(
      { __typename?: 'CollectionDay' }
      & Pick<CollectionDay, 'date' | 'id' | 'dayPart' | 'addresses' | 'maxAddresses' | 'cargo' | 'maxCargo' | 'cargoPickup' | 'cargoDelivery'>
      & { group?: Maybe<(
        { __typename?: 'CollectionGroup' }
        & Pick<CollectionGroup, 'name'>
      )> }
    )> }
  ) }
);

export type GetFreePickupDaysQueryVariables = Exact<{
  municipalityId: Scalars['ID'];
  products: Array<CollectionDayProductInput>;
  paging?: Maybe<CollectionPagingInput>;
  municipalityIds?: Maybe<Array<Scalars['ID']>>;
  collectionGroupIds?: Maybe<Array<Scalars['ID']>>;
}>;


export type GetFreePickupDaysQuery = (
  { __typename?: 'Query' }
  & { freePickupDays: (
    { __typename?: 'CollectionDayResult' }
    & Pick<CollectionDayResult, 'count'>
    & { items: Array<(
      { __typename?: 'CollectionDay' }
      & Pick<CollectionDay, 'date' | 'id' | 'dayPart' | 'addresses' | 'maxAddresses' | 'cargo' | 'maxCargo' | 'cargoPickup' | 'cargoDelivery'>
      & { group?: Maybe<(
        { __typename?: 'CollectionGroup' }
        & Pick<CollectionGroup, 'name'>
      )> }
    )> }
  ) }
);

export type GetFreeTrucksQueryVariables = Exact<{
  date: Scalars['Date'];
}>;


export type GetFreeTrucksQuery = (
  { __typename?: 'Query' }
  & { freeTrucks: Array<(
    { __typename?: 'Truck' }
    & Pick<Truck, 'id' | 'name'>
    & { type: (
      { __typename?: 'TruckType' }
      & Pick<TruckType, 'id' | 'name'>
    ) }
  )> }
);

export type GetIncidentReasonsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetIncidentReasonsQuery = (
  { __typename?: 'Query' }
  & { incidentReasons: (
    { __typename?: 'IncidentReasonResult' }
    & Pick<IncidentReasonResult, 'count'>
    & { items: Array<(
      { __typename?: 'IncidentReason' }
      & Pick<IncidentReason, 'id' | 'name' | 'responsibility' | 'systemReason'>
      & { products: Array<(
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name'>
      )> }
    )> }
  ) }
);

export type GetIncidentReportsQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
  filter?: Maybe<ReportFilterInput>;
}>;


export type GetIncidentReportsQuery = (
  { __typename?: 'Query' }
  & { incidentReports: Array<(
    { __typename?: 'IncidentReportItem' }
    & Pick<IncidentReportItem, 'date' | 'closed'>
    & { inquiry: (
      { __typename?: 'Inquiry' }
      & Pick<Inquiry, 'currentStatus' | 'orderNumber' | 'id' | 'deliveryDate' | 'pickupDate'>
      & { products: Array<(
        { __typename?: 'InquiryProduct' }
        & Pick<InquiryProduct, 'amount'>
        & { product: (
          { __typename?: 'Product' }
          & Pick<Product, 'id' | 'name'>
        ) }
      )>, pickupAddress: (
        { __typename?: 'InquiryLocation' }
        & { municipality?: Maybe<(
          { __typename?: 'Municipality' }
          & Pick<Municipality, 'id' | 'name'>
        )> }
      ) }
    ), incidentReason: (
      { __typename?: 'IncidentReason' }
      & Pick<IncidentReason, 'id' | 'name'>
    ) }
  )> }
);

export type GetInquiriesCountQueryVariables = Exact<{
  filter?: Maybe<InquiryFilterInput>;
}>;


export type GetInquiriesCountQuery = (
  { __typename?: 'Query' }
  & { inquiries: (
    { __typename?: 'InquiryResult' }
    & Pick<InquiryResult, 'count'>
  ) }
);

export type GetInquiriesOverviewQueryVariables = Exact<{
  filter?: Maybe<InquiryFilterInput>;
  paging?: Maybe<InquiryPagingInput>;
  sort?: Maybe<InquirySortInput>;
}>;


export type GetInquiriesOverviewQuery = (
  { __typename?: 'Query' }
  & { inquiries: (
    { __typename?: 'InquiryResult' }
    & Pick<InquiryResult, 'count'>
    & { items: Array<(
      { __typename?: 'Inquiry' }
      & InquiryOverviewFragment
    )> }
  ) }
);

export type GetInquiryDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetInquiryDetailQuery = (
  { __typename?: 'Query' }
  & { inquiry: (
    { __typename?: 'Inquiry' }
    & InquiryDetailFragment
  ) }
);

export type GetLogisticReportsQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;


export type GetLogisticReportsQuery = (
  { __typename?: 'Query' }
  & { logisticReports: Array<(
    { __typename?: 'LogisticReportItem' }
    & Pick<LogisticReportItem, 'date' | 'weightTicket' | 'weight' | 'incidentCount' | 'totalDistance' | 'collectionDistance' | 'addressCount' | 'municipalities'>
    & { processor: (
      { __typename?: 'Processor' }
      & Pick<Processor, 'id' | 'name'>
    ), truck: (
      { __typename?: 'Truck' }
      & Pick<Truck, 'id' | 'name'>
    ), collector?: Maybe<(
      { __typename?: 'Collector' }
      & Pick<Collector, 'id' | 'lastname' | 'firstname'>
    )>, inquiries: Array<(
      { __typename?: 'Inquiry' }
      & Pick<Inquiry, 'id'>
      & { products: Array<(
        { __typename?: 'InquiryProduct' }
        & Pick<InquiryProduct, 'amount'>
        & { product: (
          { __typename?: 'Product' }
          & Pick<Product, 'id' | 'name'>
        ) }
      )> }
    )> }
  )> }
);

export type GetMunicipalitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMunicipalitiesQuery = (
  { __typename?: 'Query' }
  & { cardsMunicipalities: Array<(
    { __typename?: 'Municipality' }
    & Pick<Municipality, 'name' | 'id'>
  )> }
);

export type GetNotificationConfigurationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNotificationConfigurationsQuery = (
  { __typename?: 'Query' }
  & { notificationConfigurations: (
    { __typename?: 'NotificationConfigurationResult' }
    & Pick<NotificationConfigurationResult, 'count'>
    & { items: Array<(
      { __typename?: 'NotificationConfiguration' }
      & NotificationConfigurationFragment
    )> }
  ) }
);

export type GetOrderReportsQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
  filter?: Maybe<ReportFilterInput>;
}>;


export type GetOrderReportsQuery = (
  { __typename?: 'Query' }
  & { orderReports: Array<(
    { __typename?: 'OrderReportItem' }
    & { inquiry: (
      { __typename?: 'Inquiry' }
      & Pick<Inquiry, 'id' | 'createdAt' | 'paymentNumber' | 'digibeet' | 'orderNumber' | 'currentStatus' | 'deliveryDate' | 'pickupDate'>
      & { pickupAddress: (
        { __typename?: 'InquiryLocation' }
        & { municipality?: Maybe<(
          { __typename?: 'Municipality' }
          & Pick<Municipality, 'id' | 'name'>
        )> }
      ), products: Array<(
        { __typename?: 'InquiryProduct' }
        & Pick<InquiryProduct, 'amount'>
        & { product: (
          { __typename?: 'Product' }
          & Pick<Product, 'id' | 'name'>
        ) }
      )>, statusHistory: Array<(
        { __typename?: 'InquiryStatus' }
        & Pick<InquiryStatus, 'type' | 'deliveryDate' | 'pickupDate'>
      )>, applicant: (
        { __typename?: 'Applicant' }
        & Pick<Applicant, 'id' | 'type'>
        & { address: (
          { __typename?: 'ApplicantAddress' }
          & Pick<ApplicantAddress, 'city'>
        ) }
      ) }
    ) }
  )> }
);

export type GetProcessorsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProcessorsQuery = (
  { __typename?: 'Query' }
  & { processors: (
    { __typename?: 'ProcessorsResult' }
    & Pick<ProcessorsResult, 'count'>
    & { items: Array<(
      { __typename?: 'Processor' }
      & ProcessorFragment
    )> }
  ) }
);

export type GetProductQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetProductQuery = (
  { __typename?: 'Query' }
  & { product: (
    { __typename?: 'Product' }
    & ProductDetailFragment
  ) }
);

export type GetProductNamesQueryVariables = Exact<{
  filter?: Maybe<ProductsFilter>;
}>;


export type GetProductNamesQuery = (
  { __typename?: 'Query' }
  & { products: (
    { __typename?: 'ProductResult' }
    & Pick<ProductResult, 'count'>
    & { items: Array<(
      { __typename?: 'Product' }
      & ProductFragment
    )> }
  ) }
);

export type GetProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProfileQuery = (
  { __typename?: 'Query' }
  & { profile: (
    { __typename?: 'Profile' }
    & Pick<Profile, 'permissions'>
  ) }
);

export type GetStreetsQueryVariables = Exact<{
  municipalityId: Scalars['ID'];
}>;


export type GetStreetsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'cardsStreets'>
);

export type GetTruckScheduleQueryVariables = Exact<{
  date: Scalars['Date'];
  truckId?: Maybe<Scalars['ID']>;
}>;


export type GetTruckScheduleQuery = (
  { __typename?: 'Query' }
  & { truckSchedule: (
    { __typename?: 'TruckSchedule' }
    & Pick<TruckSchedule, 'id' | 'optimal' | 'date'>
    & { truck: (
      { __typename?: 'Truck' }
      & Pick<Truck, 'id' | 'name'>
      & { type: (
        { __typename?: 'TruckType' }
        & Pick<TruckType, 'id' | 'name'>
      ) }
    ), tasks: Array<(
      { __typename?: 'TruckTask' }
      & TruckTaskFragment
    )> }
  ) }
);

export type GetTruckTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTruckTypesQuery = (
  { __typename?: 'Query' }
  & { truckTypes: (
    { __typename?: 'TruckTypeResult' }
    & Pick<TruckTypeResult, 'count'>
    & { items: Array<(
      { __typename?: 'TruckType' }
      & TruckTypeFragment
    )> }
  ) }
);

export type GetTrucksQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTrucksQuery = (
  { __typename?: 'Query' }
  & { trucks: (
    { __typename?: 'TrucksResult' }
    & Pick<TrucksResult, 'count'>
    & { items: Array<(
      { __typename?: 'Truck' }
      & TruckFragment
    )> }
  ) }
);
