import { MutationFetchResult } from "@apollo/react-common";
import { ErrorMessage } from "@components/errorMessage/errorMessage";
import { NotificationFormModal } from "@components/notificationFormModal/notificationFormModal";
import { PageHeader } from "@components/pageHeader/pageHeader";
import { Permission, PERMISSIONS } from "@components/permission/permission";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { useDeleteNotificationConfiguration } from "@graphql/hocs/hooks/useDeleteNotificationConfiguration";
import { useGetNotificationConfigurations } from "@graphql/hocs/hooks/useGetNotificationConfigurations";
import { Channel, DeleteNotificationConfigurationMutation, GetNotificationConfigurationsQuery, NotificationConfigurationFragment } from "@models/graphql";
import { Icon } from "@style/icon";
import { InnerContainer } from "@style/innerContainer";
import { useFormatMessage } from "@utils/intlHook";
import { parseError } from "@utils/parseError";
import { isIncidentTrigger, isNotificationTrigger, isReminderTrigger } from "@utils/triggerHelpers";
import { Button, Icon as AntIcon, message, Popconfirm, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { NotificationsPageStyle } from "./notificationsPageStyle";

export interface NotificationsPageProps { }

export const NotificationsPage: FunctionComponent<NotificationsPageProps> = ({ }) => {
    const formatMessage = useFormatMessage();
    const [modalVisible, setModalVisible] = React.useState(false);
    const [editItem, setEditItem] = React.useState<NotificationConfigurationFragment | null>(null);
    const [deleteItemId, setDeleteItemId] = React.useState<string | null>(null);
    const { notificationConfigurations, notificationConfigurationsError, notificationConfigurationsLoading } = useGetNotificationConfigurations();
    const { deleteNotificationConfiguration, isLoading: deleteLoading } = useDeleteNotificationConfiguration();

    const handleCloseModal = (): void => {
        setModalVisible(false);
        setEditItem(null);
    };

    const handleCreateConfig = (): void => {
        setModalVisible(true);
    };

    const colums: ColumnProps<NotificationConfigurationFragment>[] = [
        {
            key: "products",
            title: formatMessage({ id: "notificationsPage.products" }),
            render(_, record) {
                return record.products.map(p => p.name).join(", ");
            }
        },
        {
            key: "trigger",
            title: formatMessage({ id: "notificationsPage.trigger" }),

            render(_, record) {
                const { trigger } = record;

                if (isNotificationTrigger(trigger)) {
                    return (
                        <span>
                            <em>
                                <FormattedMessage id="notificationType.notification" />:
                            </em>
                            <FormattedMessage id={`notificationTrigger.${trigger.notificationType}`} />
                        </span>
                    );
                }

                if (isReminderTrigger(trigger)) {
                    return (
                        <span>
                            <em>
                                <FormattedMessage id="notificationType.reminder" /> ({trigger.timeAmount}{" "}
                                <FormattedMessage id={`timeDuration.${trigger.timeDuration}`} />
                                ):
                            </em>
                            <FormattedMessage id={`notificationTrigger.${trigger.reminderType}`} />
                        </span>
                    );
                }

                if (isIncidentTrigger(trigger)) {
                    return (
                        <span>
                            <em>
                                <FormattedMessage id="notificationType.incident" /> ({trigger.incident.name}):
                            </em>
                            <FormattedMessage id={`notificationTrigger.${trigger.incidentType}`} />
                        </span>
                    );
                }

                return "";
            }
        },
        {
            key: "email",
            title: formatMessage({ id: "notificationsPage.email" }),
            align: "center",
            render(_, record) {
                return record.channel === Channel.Email && <Icon type="checkmark" />;
            }
        },
        {
            key: "sms",
            title: formatMessage({ id: "notificationsPage.sms" }),
            align: "center",
            render(_, record) {
                return record.channel === Channel.Sms && <Icon type="checkmark" />;
            }
        },
        {
            width: 80,
            render(_, record) {
                const handleDelete = async (): Promise<void> => {
                    try {
                        setDeleteItemId(record.id);
                        const { error } = await deleteNotificationConfiguration(
                            { id: record.id },
                            {
                                update(cache, response: MutationFetchResult<DeleteNotificationConfigurationMutation>) {
                                    const notifConfigData = cache.readQuery<GetNotificationConfigurationsQuery>({
                                        query: GraphQLDocuments.getNotificationConfigurations
                                    });

                                    if (notifConfigData && response.data && response.data.deleteNotificationConfiguration) {
                                        notifConfigData.notificationConfigurations.items = notifConfigData.notificationConfigurations.items.filter(p => p.id !== record.id);

                                        notifConfigData.notificationConfigurations.count -= 1;

                                        cache.writeQuery<GetNotificationConfigurationsQuery>({
                                            query: GraphQLDocuments.getNotificationConfigurations,
                                            data: notifConfigData
                                        });
                                    }
                                }
                            }
                        );

                        if (error) {
                            message.error(parseError(error, formatMessage));
                        } else {
                            message.success(formatMessage({
                                id: "notificationsPage.deleteSuccessful"
                            }));
                        }
                        setDeleteItemId(null);
                    } catch (error) {
                        message.error(parseError(error, formatMessage));
                    }
                };

                return (
                    <span className="buttons">
                        <Permission requiredPermissions={[PERMISSIONS.notificationConfigurations.update]}>
                            <Icon
                                onClick={() => {
                                    setEditItem(record);
                                    setModalVisible(true);
                                }}
                                type="pencil"
                            ></Icon>
                        </Permission>
                        <Permission requiredPermissions={[PERMISSIONS.notificationConfigurations.delete]}>
                            {record.id === deleteItemId && deleteLoading ?
                                <AntIcon type="loading" />
                                :
                                <Popconfirm
                                    onConfirm={handleDelete}
                                    placement="left"
                                    title={formatMessage({
                                        id: "notificationsPage.confirmDelete"
                                    })}
                                >
                                    <Icon type="trash"></Icon>
                                </Popconfirm>
                            }
                        </Permission>
                    </span>
                );
            }
        }
    ];

    return (
        <NotificationsPageStyle>
            <PageHeader title="notificationsPage.title">
                <Permission requiredPermissions={[PERMISSIONS.notificationConfigurations.create]}>
                    <Button onClick={handleCreateConfig} type="primary">
                        <FormattedMessage id="notificationsPage.newNotificationConfiguration" />
                    </Button>
                </Permission>
            </PageHeader>
            <NotificationFormModal visible={modalVisible} editItem={editItem} closeCallback={handleCloseModal} />
            <InnerContainer>
                <ErrorMessage error={notificationConfigurationsError} />
                <Table
                    rowKey="id"
                    pagination={false}
                    columns={colums}
                    dataSource={notificationConfigurations}
                    loading={notificationConfigurationsLoading}
                />
            </InnerContainer>
        </NotificationsPageStyle>
    );
};
