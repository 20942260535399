import styled from "styled-components";
import { COLORS } from "@style/colors";

export const TruckReschedulerStyle = styled.div`
    float: right;

    .ant-select {
        width: 250px;
        margin-right: 15px;
    }

    display: flex;
`;

export const FreeTruckItem = styled.span`
    color: ${COLORS.text};

    em {
        color: ${COLORS.textTransparent(0.5)} !important;
        font-style: normal;
    }
`;
