/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { UploadWeightDataMutation, UploadWeightDataMutationVariables } from "@models/graphql";

const defaultUploadWeightDataOptions: MutationHookOptions<UploadWeightDataMutation, UploadWeightDataMutationVariables> = {

};

export const useUploadWeightData = (options?: MutationHookOptions<UploadWeightDataMutation, UploadWeightDataMutationVariables>) => {
    const [mutation, { data, loading, error }] = useMutation<UploadWeightDataMutation, UploadWeightDataMutationVariables>(GraphQLDocuments.uploadWeightData);

    const uploadWeightData = async (variables: UploadWeightDataMutationVariables) => {
        try {
            const response = await mutation({
                ...defaultUploadWeightDataOptions,
                ...options,
                variables
            });

            return {
                error: response.errors,
                data: response.data
            };
        } catch (err) {
            return {
                error: err
            };
        }
    };

    return {
        isLoading: loading,
        error,
        data,
        uploadWeightData
    };
};
