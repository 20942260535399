import styled from "styled-components";

export const WeekBlockStyle = styled.div`
   & > main {
        padding: 20px 0 20px 20px;
        display: flex;
    }

    &.collapsed {
        & > main {
            height: 0;
            overflow: hidden;
        }
    }
`;
