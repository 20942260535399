import { autobind } from "core-decorators";
import * as React from "react";
import PlacesAutocomplete, { geocodeByAddress, geocodeByPlaceId, PropTypes } from "react-places-autocomplete";

import { parseAddress } from "@utils/parseAddress";
import { InquiryAddress, InquiryLocation } from "@models/graphql";
import { GoogleAutocompleteStyle } from "@components/googleAutocomplete/googleAutocompleteStyle";

export interface GoogleAutocompleteProps {
    value?: InquiryLocation;
    placeholder?: string;
    onChange?(value: InquiryLocation): void;
    onBlur?(e): void;
    onFocus?(e): void;
}

const searchOptions: PropTypes["searchOptions"] = {
    componentRestrictions: {
        country: ["be"]
    },
    types: ["address"]
};

@autobind
export class GoogleAutocomplete extends React.Component<GoogleAutocompleteProps, { address: string }> {
    public state: { address: string } = {
        address: ""
    };

    public constructor(props: GoogleAutocompleteProps) {
        super(props);

        if (props.value) {
            this.state = {
                address: this.parseAddressToString(props.value.address)
            };
        }
    }

    public render(): JSX.Element {
        return (
            <GoogleAutocompleteStyle>
                <PlacesAutocomplete
                    searchOptions={searchOptions}
                    value={this.state.address}
                    onChange={this.handleAutocompleteChange}
                    onSelect={this.handleSelect}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) =>
                        <div>
                            <input
                                {...getInputProps({
                                    placeholder: "Zoek adres ...",
                                    className: "location-search-input"
                                })}
                            />
                            <div className="autocomplete-dropdown-container">
                                {loading && <div>Laden...</div>}
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active ? "item active" : "item";
                                    // inline style for demonstration purpose

                                    return (
                                        // eslint-disable-next-line react/jsx-key
                                        <div {...getSuggestionItemProps(suggestion, { className })} key={suggestion.placeId}>
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    }
                </PlacesAutocomplete>
            </GoogleAutocompleteStyle>
        );
    }

    public componentWillReceiveProps(nextProps: GoogleAutocompleteProps): void {
        if (!nextProps.value && this.props.value) {
            this.setState({
                address: ""
            });
            return;
        }
        if (!this.props.value && nextProps.value) {
            this.setState({
                address: this.parseAddressToString(nextProps.value.address)
            });
            return;
        }
        if (
            this.props.value &&
            nextProps.value &&
            this.parseAddressToString(this.props.value.address) !== this.parseAddressToString(nextProps.value.address)
        ) {
            this.setState({
                address: this.parseAddressToString(nextProps.value.address)
            });
        }
    }

    private parseAddressToString(address: Partial<InquiryAddress>): string {
        if (!address.street && !address.streetNumber) {
            return `${address.city}, ${address.country}`;
        }

        if (!address.streetNumber) {
            return `${address.street}, ${address.city}, ${address.country}`;
        }

        return `${address.street} ${address.streetNumber}, ${address.city}, ${address.country}`;
    }

    private handleAutocompleteChange(address: string, ...rest): void {
        this.setState({
            address
        });
    }

    private async handleSelect(selectedAddress: string, placeId: string): Promise<void> {
        const { onChange } = this.props;

        try {
            this.handleAutocompleteChange(selectedAddress);
            const [result] = await geocodeByPlaceId(placeId);

            if (onChange && result) {
                onChange(parseAddress(result));
            }
        } catch (error) {
            console.warn(error);
        }
    }
}
