import * as React from "react";
import { FunctionComponent } from "react";
import { MarkerMapStyle } from "./markerMapStyle";
import GoogleMapReact from "google-map-react";
import { Marker } from "@components/marker/marker";
import { parseAddress } from "@utils/parseAddress";
import { InquiryLocation } from "@models/graphql";

export interface MarkerMapProps {
    position: { lat: number; lng: number } | null;
    onChange?(pos: InquiryLocation): void;
}

export const MarkerMap: FunctionComponent<MarkerMapProps> = ({ position, onChange }) => {

    const [markerPosition, setMarkerPosition] = React.useState<null | { lat: number; lng: number }>(position);
    const [center, setCenter] = React.useState<null | { lat: number; lng: number }>(position);
    const [draggable, setDraggable] = React.useState(true);

    const onMarkerDrag = (childKey: any, childProps: any, mouse: any): void => {
        setMarkerPosition({
            lat: mouse.lat,
            lng: mouse.lng
        });
    };

    React.useEffect(
        () => {
            setMarkerPosition(position);
            setCenter(position);
        },
        [position]
    );

    const onMarkerMouseDown = (): void => {
        setDraggable(false);
    };

    const onMarkerMouseUp = (): void => {
        setDraggable(true);

        if (markerPosition && onChange) {
            const geocode = new google.maps.Geocoder();
            geocode.geocode(
                {
                    location: {
                        lat: markerPosition.lat,
                        lng: markerPosition.lng
                    }
                },
                results => {
                    if (results.length) {
                        const result = parseAddress(results[0]);

                        onChange({
                            address: result.address,
                            coordinates: markerPosition
                        });
                    }
                }
            );
        }
    };

    return (
        <MarkerMapStyle>
            <GoogleMapReact
                defaultCenter={{
                    lat: 50.997505,
                    lng: 5.3654987
                }}
                options={{
                    scrollwheel: true
                }}
                defaultZoom={10}
                draggable={draggable}
                center={center || undefined}
                zoom={center ? 17 : undefined}
                onChildMouseMove={onMarkerDrag}
                onChildMouseDown={onMarkerMouseDown}
                onChildMouseUp={onMarkerMouseUp}
            >
                {markerPosition && <Marker lat={markerPosition.lat} lng={markerPosition.lng} />}
            </GoogleMapReact>
        </MarkerMapStyle>
    );
};
