import { CsvDownloadModal } from "@components/planningPageHeader/csvDownloadModal/csvDownloadModal";
import { useGetTruckSchedule } from "@graphql/hocs/hooks/useGetTruckSchedule";
import { useGetTruckScheduleCsv } from "@graphql/hocs/hooks/useGetTruckScheduleCsv";
import { Icon } from "@style/icon";
import { InnerContainer } from "@style/innerContainer";
import { Placeholder } from "@style/placeholder";
import { DATE_FORMAT } from "@utils/consts";
import { useFormatMessage } from "@utils/intlHook";
import { parseError } from "@utils/parseError";
import { useRouter } from "@utils/routerHook";
import { Button, message } from "antd";
import * as moment from "moment";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
import { TruckScheduleHeaderStyle } from "./truckScheduleHeaderStyle";

export interface TruckScheduleHeaderProps {}

export const TruckScheduleHeader: FunctionComponent<TruckScheduleHeaderProps> = ({}) => {
    const formatMessage = useFormatMessage();
    const [csvUrl, setCsvUrl] = React.useState<string>("");
    const [csvModalVisible, setcCvModalVisible] = React.useState(false);
    const { history, match } = useRouter<{ truckId: string; date: string }>();

    const { getTruckScheduleCsv, isLoading } = useGetTruckScheduleCsv();

    const { truckSchedule, truckScheduleLoading } = useGetTruckSchedule({
        variables: {
            date: match.params.date,
            truckId: match.params.truckId
        }
    });

    const handleGoBack = (): void => {
        // updateParameters({
        //     fromDate: planningFromDate,
        //     toDate: planningToDate
        // });
        history.goBack();
    };

    const handleExport = async (): Promise<void> => {
        const { data, error } = await getTruckScheduleCsv({
            date: match.params.date,
            truckId: match.params.truckId
        });

        if (data) {
            setcCvModalVisible(true);
            setCsvUrl(data.truckScheduleCSV.url);
        }

        if (error) {
            message.error(parseError(error, formatMessage));
        }
    };

    return (
        <TruckScheduleHeaderStyle>
            <CsvDownloadModal
                fileName={`Truckschedule ${moment(match.params.date, DATE_FORMAT).format("DD-MM-YYYY")} - ${
                    truckSchedule ? truckSchedule.truck.name : ""
                }`}
                url={csvUrl}
                visible={csvModalVisible}
                closeCallback={() => setcCvModalVisible(false)}
            />
            <InnerContainer>
                <div className="left">
                    <Icon onClick={handleGoBack} style={{ cursor: "pointer" }} type="back-arrow" />

                    {!truckSchedule || truckScheduleLoading ? (
                        <Placeholder />
                    ) : (
                        <div className="truck">
                            <em>{truckSchedule.truck.name}</em> - {truckSchedule.truck.type.name}
                        </div>
                    )}

                    <nav>
                        <NavLink exact to={`/planning/${match.params.truckId}/${match.params.date}`}>
                            <Icon type="list" />
                        </NavLink>
                        <NavLink exact to={`/planning/${match.params.truckId}/${match.params.date}/map`}>
                            <Icon type="location" />
                        </NavLink>
                    </nav>
                </div>

                <div className="right">
                    <Button onClick={handleExport} loading={isLoading} type="primary">
                        <FormattedMessage id="exportCsv" />
                    </Button>
                </div>
            </InnerContainer>
        </TruckScheduleHeaderStyle>
    );
};
