/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

import { GetProductNamesQuery, GetProductNamesQueryVariables } from "@models/graphql";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";

export const useGetProductNames = (options?: QueryHookOptions<GetProductNamesQuery, GetProductNamesQueryVariables>) => {
    const { data, error, loading } = useQuery<GetProductNamesQuery, GetProductNamesQueryVariables>(GraphQLDocuments.getProductNames, options);

    return {
        products: data && data.products && data.products.items ? data.products.items : [],
        productsLoading: loading,
        productsError: error
    };
};
