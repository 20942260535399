import * as React from "react";
import { FunctionComponent } from "react";

import { MarkerStyle } from "./markerStyle";
import { IMAGES } from "@assets/images";

export interface MarkerProps {
    lat: number;
    lng: number;
}

export const Marker: FunctionComponent<MarkerProps> = () => 
    <MarkerStyle>
        <img alt="marker" src={IMAGES.marker} />
    </MarkerStyle>
    ;
