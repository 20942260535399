import styled from "styled-components";
import { PlanningInquiryItemStyle } from "@components/planningInquiryItem/planningInquiryItemStyle";
import { COLORS } from "@style/colors";
import { InquiryStatusStyle } from "@components/inquiryStatus/inquiryStatusStyle";

export const TruckScheduleItemStyle = styled(PlanningInquiryItemStyle)`
    flex-direction: column;
    position: relative;

    &.processor {
        background: #f4f4f4;
        box-shadow: none;

        &:hover {
            border-color: transparent !important;
        }
    }

    .buttons {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }

    .address {
        max-width: 250px;
    }

    ${InquiryStatusStyle}{
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .taskType {
        position: absolute;
        right: 10px;
        top: 10px;

        width: 25px;
        height: 25px;
        background: ${COLORS.primary};
        color: white;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.6;

        &.depot,
        &.processor {
            background: white;
        }

        img {
            width: 15px;
        }
    }
`;
