import styled from "styled-components";

export const TableButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > * {
        margin-left: 15px;
        opacity: 0.5;
        transition: 0.3s;
        cursor: pointer;

        &:hover {
            opacity: 1;
        }
    }
`;
