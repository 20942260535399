import * as React from "react";
import { FunctionComponent } from "react";
import { AssetFormModalStyle } from "./assetFormModalStyle";
import { Asset, Status, AssetInput } from "@models/graphql";
import Form, { FormComponentProps } from "antd/lib/form";
import { Modal, Input, Select } from "antd";
import { FormattedMessage } from "react-intl";
import { useFormatMessage } from "@utils/intlHook";
import { useGetAssetTypes } from "@graphql/hocs/hooks/useGetAssetTypes";
import { useCreateAsset } from "@graphql/hocs/hooks/useCreateAsset";
import { useUpdateAsset } from "@graphql/hocs/hooks/useUpdateAsset";
import { ErrorMessage } from "@components/errorMessage/errorMessage";
import { useApolloClient } from "@apollo/react-hooks";
import { NormalizedCache } from "apollo-cache-inmemory";

export interface AssetFormModalProps extends FormComponentProps {
    visible: boolean;
    editItem: Asset | null;
    closeCallback(): void;
}

interface FormValues {
    name: string;
    assetTypeId: string;
    tagCode: string;
    status: Status;
}

export const AssetFormModalComponent: FunctionComponent<AssetFormModalProps> = ({ form, visible, editItem, closeCallback }) => {
    const formatMessage = useFormatMessage();
    const { assetTypes } = useGetAssetTypes();
    const client = useApolloClient();
    const { createAsset, isLoading: createLoading, error: createError } = useCreateAsset({
        async update(cache) {
            const data: NormalizedCache = (cache as any).data;

            Object.keys((data as any).data).forEach(key => key.match(/^Asset/) && data.delete(key));
            Object.keys((data as any).data).forEach(key => key.match(/^\$ROOT_QUERY\.assets/) && data.delete(key));

            await client.reFetchObservableQueries();
        }
    });
    const { updateAsset, isLoading: updateLoading, error: updateError } = useUpdateAsset();

    const handleClose = (): void => {
        closeCallback();
        form.resetFields();
    };
    const handleSubmit = (): void => {
        form.validateFields(async (errors, values: FormValues) => {
            if (errors) {
                return;
            }

            const asset: AssetInput = {
                name: values.name,
                tagCode: values.tagCode,
                assetTypeId: values.assetTypeId,
                status: values.status
            };

            if (editItem) {
                const { error } = await updateAsset({
                    id: editItem.id,
                    asset
                });
                if (!error) {
                    handleClose();
                }
            } else {
                const { error } = await createAsset({ asset });
                if (!error) {
                    handleClose();
                }
            }
        });
    };

    return (
        <Modal
            visible={visible}
            okButtonProps={{
                loading: createLoading || updateLoading
            }}
            title={<FormattedMessage id="assetForm.title" />}
            okText={<FormattedMessage id="save" />}
            cancelText={<FormattedMessage id="cancel" />}
            onCancel={handleClose}
            onOk={handleSubmit}
        >
            <AssetFormModalStyle>
                <Form onSubmit={handleSubmit}>
                    <Form.Item label={<FormattedMessage id="assetForm.name" />}>
                        {form.getFieldDecorator("name", {
                            rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }],
                            initialValue: editItem ? editItem.name : undefined
                        })(<Input placeholder={formatMessage({ id: "assetForm.name" })} />)}
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="assetForm.assetType" />}>
                        {form.getFieldDecorator("assetTypeId", {
                            rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }],
                            initialValue: editItem ? editItem.type.id : undefined
                        })(<Select placeholder={formatMessage({ id: "assetForm.assetType" })}>
                            {assetTypes.map(assetType => 
                                <Select.Option key={assetType.id} value={assetType.id}>
                                    {assetType.name}
                                </Select.Option>)}
                        </Select>)}
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="assetForm.tagCode" />}>
                        {form.getFieldDecorator("tagCode", {
                            rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }],
                            initialValue: editItem ? editItem.tagCode : undefined
                        })(<Input placeholder={formatMessage({ id: "assetForm.tagCode" })} />)}
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="assetForm.status" />}>
                        {form.getFieldDecorator("status", {
                            rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }],
                            initialValue: editItem ? editItem.status : undefined
                        })(<Select placeholder={formatMessage({ id: "assetForm.status" })}>
                            <Select.Option value={Status.InUse}>
                                <FormattedMessage id={`Status.${Status.InUse}`} />
                            </Select.Option>
                            <Select.Option value={Status.Unused}>
                                <FormattedMessage id={`Status.${Status.Unused}`} />
                            </Select.Option>
                            <Select.Option value={Status.Blocked}>
                                <FormattedMessage id={`Status.${Status.Blocked}`} />
                            </Select.Option>
                        </Select>)}
                    </Form.Item>
                    <ErrorMessage error={createError || updateError} />
                </Form>
            </AssetFormModalStyle>
        </Modal>
    );
};

export const AssetFormModal = Form.create<AssetFormModalProps>()(AssetFormModalComponent);
