/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { DeactivateCollectionPatternMutation, DeactivateCollectionPatternMutationVariables } from "@models/graphql";

const defaultDeactivateCollectionPatternOptions: MutationHookOptions<
    DeactivateCollectionPatternMutation,
    DeactivateCollectionPatternMutationVariables
> = {};

export const useDeactivateCollectionPattern = (
    options?: MutationHookOptions<DeactivateCollectionPatternMutation, DeactivateCollectionPatternMutationVariables>
) => {
    const [mutation, { data, loading, error }] = useMutation<
        DeactivateCollectionPatternMutation,
        DeactivateCollectionPatternMutationVariables
    >(GraphQLDocuments.deactivateCollectionPattern);

    const deactivateCollectionPattern = async (variables: DeactivateCollectionPatternMutationVariables) => {
        try {
            const response = await mutation({
                ...defaultDeactivateCollectionPatternOptions,
                ...options,
                variables
            });

            return {
                error: response.errors,
                data: response.data
            };
        } catch (err) {
            return {
                error
            };
        }
    };

    return {
        isLoading: loading,
        error,
        data,
        deactivateCollectionPattern
    };
};
