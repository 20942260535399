import * as React from "react";
import { FunctionComponent } from "react";
import { CollectorFormModalStyle } from "./collectorFormModalStyle";
import { Collector, GetCollectorsQuery, CollectorInput } from "@models/graphql";
import Form, { FormComponentProps } from "antd/lib/form";
import { useUpdateCollector } from "@graphql/hocs/hooks/useUpdateCollector";
import { FormattedMessage } from "react-intl";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { useCreateCollector } from "@graphql/hocs/hooks/useCreateCollector";
import { Modal, Input } from "antd";
import { ErrorMessage } from "@components/errorMessage/errorMessage";
import { useFormatMessage } from "@utils/intlHook";

export interface CollectorFormModalProps extends FormComponentProps {
    visible: boolean;
    editItem: Collector | null;
    closeCallback(): void;
}

export const CollectorFormModalComponent: FunctionComponent<CollectorFormModalProps> = ({ closeCallback, editItem, visible, form }) => {
    const formatMessage = useFormatMessage();
    const { createCollector, isLoading: createLoading, error: createError } = useCreateCollector({
        update(cache, response) {
            const collectorsData = cache.readQuery<GetCollectorsQuery>({ query: GraphQLDocuments.getCollectors });

            if (!collectorsData || !response.data || !response.data.createCollector) { return; }

            collectorsData.collectors.push(response.data.createCollector);

            cache.writeQuery({ query: GraphQLDocuments.getCollectors, data: collectorsData });
        }
    });
    const { updateCollector, isLoading: updateLoading, error: updateError } = useUpdateCollector();

    const handleClose = (): void => {
        closeCallback();
        form.resetFields();
    };

    const handleSubmit = (): void => {
        form.validateFields(async (errors, values) => {
            if (errors) {
                return;
            }

            const collector: CollectorInput = {
                firstname: values.firstname,
                lastname: values.lastname,
                tagCode: values.tagCode
            };

            if (editItem) {
                const { error } = await updateCollector({
                    id: editItem.id,
                    collector
                });

                if (!error) {
                    handleClose();
                }
            } else {

                const { error } = await createCollector({ collector });

                if (!error) {
                    handleClose();
                }
            }
        });
    };
    return (
        <Modal
            visible={visible}
            okButtonProps={{
                loading: createLoading || updateLoading
            }}
            title="test"
            okText={<FormattedMessage id="save" />}
            cancelText={<FormattedMessage id="cancel" />}
            onCancel={handleClose}
            onOk={handleSubmit}
        >
            <CollectorFormModalStyle>
                <Form onSubmit={handleSubmit}>
                    <Form.Item label={<FormattedMessage id="collectorForm.firstname" />}>
                        {form.getFieldDecorator("firstname", {
                            rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }],
                            initialValue: editItem ? editItem.firstname : undefined
                        })(<Input placeholder={formatMessage({ id: "collectorForm.firstname" })} />)}
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="collectorForm.lastname" />}>
                        {form.getFieldDecorator("lastname", {
                            rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }],
                            initialValue: editItem ? editItem.lastname : undefined
                        })(<Input placeholder={formatMessage({ id: "collectorForm.lastname" })} />)}
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="collectorForm.tagCode" />}>
                        {form.getFieldDecorator("tagCode", {
                            rules: [
                                { required: true, message: formatMessage({ id: "requiredField" }) },
                                { pattern: /^\d*$/, message: formatMessage({ id: "isNotANumber" }) },
                                { len: 6, message: formatMessage({ id: "isNot6Characters" }) }
                            ],
                            validateTrigger: "submit",
                            initialValue: editItem ? editItem.tagCode : undefined
                        })(<Input maxLength={6} minLength={6} placeholder={formatMessage({ id: "collectorForm.tagCode" })} />)}
                    </Form.Item>
                    <ErrorMessage error={createError || updateError} />
                </Form>
            </CollectorFormModalStyle>
        </Modal>
    );
};

export const CollectorFormModal = Form.create<CollectorFormModalProps>()(CollectorFormModalComponent);
