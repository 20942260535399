import { getUserManager } from "@axa-fr/react-oidc-context";

import { defaultDataIdFromObject, InMemoryCache, IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink, from } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { User, UserManager } from "oidc-client";
import introspectionQueryResultData from "@graphql/fragmentTypes";

import { CardsApplicant } from "@models/graphql";
import * as environment from "environment";

const authLink = setContext(async (_, { headers }) => {
    const newHeaders = { ...headers };

    const userManager: UserManager = getUserManager();

    const user: User | null = await userManager.getUser();

    if (!user || user.expired) {
        await userManager.signinRedirect();
    }

    if (user) {
        let token = user.id_token;
        if (environment.ENVIRONMENT === "Test") {
            token = user.access_token;
        }

        newHeaders.Authorization = `Bearer ${token}`;
        newHeaders.selectedCustomer = localStorage.getItem("selectedCustomer");
    }

    return { headers: newHeaders };
});

// const debounceLink = new DebounceLink(300);

const link = from([
    authLink as ApolloLink,
    // debounceLink,
    new HttpLink({ uri: "/graphql" }) as ApolloLink
]);

// Send mutations containing an Upload scalar to the upload link. Send the rest to the batchlink.

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
});

export const client = new ApolloClient({
    link,
    defaultOptions: {
        mutate: {
            errorPolicy: "all"
        },
        query: {
            errorPolicy: "all"
        },
        watchQuery: {
            errorPolicy: "all"
        }
    },
    cache: new InMemoryCache({
        fragmentMatcher,
        dataIdFromObject(obj) {
            if (obj.__typename === "CardsApplicant") {
                const applicant = obj as CardsApplicant;

                if (applicant.personId) {
                    return `${applicant.personId}:${applicant.id}`;
                }
                return applicant.id;

            }

            return defaultDataIdFromObject(obj);
        }
    })
});
