import styled from "styled-components";
import { PageHeaderStyle } from "@components/pageHeader/pageHeaderStyle";
import { COLORS } from "@style/colors";

export const PlanningPageHeaderStyle = styled(PageHeaderStyle)`
    font-weight: 200;

    .date {

        display: flex;
        align-items: center;
    }

    .week {
        color: ${COLORS.primary};
        font-weight: bold;
    }

    .left {
        display: flex;
        align-items: center;
    }

    .datePicker {
        position: relative;
        
        .value {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 5;
            background-color: #fff;
            pointer-events: none;
            padding: 8px;
        }
    }

    .ant-input:focus {
        box-shadow: none;
    }

    .ant-calendar-picker {
        width: 180px;
    }

    .ant-calendar-picker-input {
        border: none;
        padding: 0;
        cursor: pointer;
        outline: none;
        color: transparent;
    }

    .buttons {
        margin-left: 25px;
        display: flex;

        .icon {
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            background-color: ${COLORS.primary};
            border-radius: 10px;
            font-size: 8px;
            cursor: pointer;
            transition: 0.2s;

            &:hover {
                opacity: 0.8;
            }

            &:not(:last-of-type) {
                margin-right: 10px;
            }
        }
    }
`;
