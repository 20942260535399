/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { CreateAssetTypeMutation, CreateAssetTypeMutationVariables } from "@models/graphql";

const defaultCreateAssetTypeOptions: MutationHookOptions<CreateAssetTypeMutation, CreateAssetTypeMutationVariables> = {};

export const useCreateAssetType = (options?: MutationHookOptions<CreateAssetTypeMutation, CreateAssetTypeMutationVariables>) => {
    const [mutation, { data, loading, error }] = useMutation<CreateAssetTypeMutation, CreateAssetTypeMutationVariables>(
        GraphQLDocuments.createAssetType
    );

    const createAssetType = async (variables: CreateAssetTypeMutationVariables) => {
        try {
            const response = await mutation({
                ...defaultCreateAssetTypeOptions,
                ...options,
                variables
            });

            return {
                error: response.errors,
                data: response.data
            };
        } catch (err) {
            return {
                error: err
            };
        }
    };

    return {
        isLoading: loading,
        error,
        data,
        createAssetType
    };
};
