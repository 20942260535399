import styled from "styled-components";

import { COLORS } from "@style/colors";

export const PatternUnplannableDaysStyle = styled.div`
    .grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 40px;
        grid-row-gap: 40px;

        @media(max-width: 1439px)and (min-width: 1080px) {
            grid-template-columns: repeat(3, 1fr);

        }

        @media(max-width: 1079px) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .selectable-selectbox {
        border: 1px dashed ${COLORS.text};
        border-radius: 4px;
        pointer-events: none;
    }

    .info {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 40px;

        span {
            position: relative;
        }

        span::after {
            content: "";
            display: block;
            position: absolute;
            left: -30px;
            width: 15px;
            height: 15px;
            border-radius: 4px;
            background-color: ${COLORS.secondary};
            top: 3px;
        }
    }
`;
