import { InquiryAddress } from "@models/graphql";

export const parseAddressToString = (address: Partial<InquiryAddress>): string => {

    const { busNumber, city, numberAddition, street, streetNumber } = address;

    if (city && street && streetNumber && busNumber && numberAddition) {
        return `${street} ${streetNumber}${numberAddition}, bus ${busNumber}, ${city}`;
    }

    if (city && street && streetNumber && numberAddition) {
        return `${street} ${streetNumber}${numberAddition}, ${city}`;
    }

    if (city && street && streetNumber && busNumber) {
        return `${street} ${streetNumber}, bus ${busNumber}, ${city}`;
    }

    if (city && street && streetNumber) {
        return `${street} ${streetNumber}, ${city}`;
    }

    if (city && street) {
        return `${street}, ${city}`;
    }

    if (city) {
        return `${street}, ${city}`;
    }

    return "";
};
