import { Radio, Progress, Tooltip, Spin } from "antd";
import * as React from "react";
import * as moment from "moment";
import { FunctionComponent } from "react";

import { parseAddressToString } from "@utils/parseAddressToString";
import { CollectionRoundFragment, InquiryOverviewFragment, CollectionDayProductInput } from "@models/graphql";

import { InquiryDeliveryModalStyle, DeliveryItem } from "./inquiryDeliveryModalStyle";
import { COLORS } from "@style/colors";
import { Icon } from "@style/icon";
import { CapacityTooltip } from "@components/planningWeekOverview/collectionRoundBlock/collectionRoundBlockStyle";
import { useGetFreeDeliveryDays } from "@graphql/hocs/hooks/useGetFreeDeliveryDays";
import ApolloClient from "apollo-client";
import { RadioChangeEvent } from "antd/lib/radio";
import { ErrorMessage } from "@components/errorMessage/errorMessage";

export interface InquiryDeliveryModalProps {
    inquiry: InquiryOverviewFragment;
    pickupDayId: string;
    pickupCollectionRound: CollectionRoundFragment;
    client: ApolloClient<any>;
    formatMessage(opts: ReactIntl.FormattedMessage.MessageDescriptor): string;
    setDeliveryDayId(id: string): void;
}

export const InquiryDeliveryModal: FunctionComponent<InquiryDeliveryModalProps> = ({
    inquiry,
    pickupCollectionRound,
    formatMessage,
    client,
    pickupDayId,
    setDeliveryDayId
}) => {
    const {
        freeDeliveryDays,
        freeDeliveryDaysLoading,
        fetchMoreFreeDeliveryDays,
        freeDeliveryDaysCount,
        freeDeliveryDaysError
    } = useGetFreeDeliveryDays({
        variables: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            municipalityId: inquiry.pickupAddress.municipality!.id,
            pickupDayId,
            products: inquiry.products.map<CollectionDayProductInput>((prod) => ({
                amount: prod.amount,
                productId: prod.product.id
            })),
            paging: {
                limit: 5,
                offset: 0
            }
        },
        client
    });

    const fetchMoreCollectionDays = (): void => {
        fetchMoreFreeDeliveryDays(freeDeliveryDays.length);
    };

    const handleDeliveryDayChange = (ev: RadioChangeEvent): void => {
        setDeliveryDayId(ev.target.value);
    };

    return (
        <InquiryDeliveryModalStyle>
            <div className="info">
                <div className="excl">!</div>
                {formatMessage({ id: "inquiry.deliveryWarning" })}
            </div>

            <div className="inquiry">
                <em>
                    {inquiry.applicant.name}({parseAddressToString(inquiry.pickupAddress.address)})
                </em>
                <div className="products">
                    {inquiry.products.map((prod) => (
                        <div key={prod.product.id} className="product">
                            {prod.amount} x {prod.product.name}
                        </div>
                    ))}
                </div>
            </div>
            <div className="pickupDay">
                {formatMessage({ id: "inquiry.chosenPickupDay" })}: <em>{pickupCollectionRound.date}</em>
            </div>
            <div className="deliveryMoments">
                {formatMessage({ id: "inquiry.chooseDeliveryMoment" })}
                <ErrorMessage error={freeDeliveryDaysError} />
                <Spin spinning={freeDeliveryDaysLoading}>
                    <Radio.Group onChange={handleDeliveryDayChange}>
                        {freeDeliveryDays.map((day) => {
                            if (
                                day.cargo === null ||
                                day.cargo === undefined ||
                                day.maxCargo === null ||
                                day.maxCargo === undefined ||
                                day.addresses === null ||
                                day.addresses === undefined ||
                                day.maxAddresses === null ||
                                day.maxAddresses === undefined
                            ) {
                                return null;
                            }

                            let progress = 0;

                            if (day.cargo / day.maxCargo > day.addresses / day.maxAddresses) {
                                progress = (day.cargo / day.maxCargo) * 100;
                            } else {
                                progress = (day.addresses / day.maxAddresses) * 100;
                            }

                            return (
                                <Radio key={day.id} value={day.id}>
                                    <DeliveryItem>
                                        <em>
                                            {moment(day.date).format("dd DD MMM YYYY")} — {formatMessage({ id: `daypart.${day.dayPart}` })}
                                        </em>
                                        <Progress
                                            strokeColor={
                                                progress < 100
                                                    ? COLORS.primaryTransparant(0.8)
                                                    : progress > 100
                                                        ? COLORS.error
                                                        : COLORS.orange
                                            }
                                            percent={progress}
                                            showInfo={false}
                                            strokeWidth={4}
                                        />
                                        <Tooltip
                                            placement="leftTop"
                                            overlayClassName="capacity-tooltip"
                                            title={
                                                <CapacityTooltip>
                                                    <div className="item">
                                                        {day.cargo} / {day.maxCargo} {formatMessage({ id: "cargos" })}
                                                    </div>
                                                    <div className="item">
                                                        {day.addresses} / {day.maxAddresses} {formatMessage({ id: "adresses" })}
                                                    </div>
                                                </CapacityTooltip>
                                            }
                                        >
                                            <Icon type="info" />
                                        </Tooltip>
                                    </DeliveryItem>
                                </Radio>
                            );
                        })}
                    </Radio.Group>
                </Spin>

                {freeDeliveryDays.length < freeDeliveryDaysCount && (
                    <span onClick={fetchMoreCollectionDays} className="loadMore">
                        {formatMessage({ id: "freeDays.loadMore" })}
                    </span>
                )}
            </div>
        </InquiryDeliveryModalStyle>
    );
};
