import { Select, Tooltip, Progress, message } from "antd";
import * as classnames from "classnames";
import { FunctionComponent } from "react";
import * as React from "react";
import * as moment from "moment";
import { Droppable } from "react-beautiful-dnd";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { Icon } from "@style/icon";
import { GetCollectionRoundsQuery } from "@models/graphql";
import { useGetTrucks } from "@graphql/hocs/hooks/useGetTrucks";
import { PlanningInquiryItem } from "@components/planningInquiryItem/planningInquiryItem";

import { CollectionRoundBlockStyle, SelectOptionStyle, CapacityTooltip } from "./collectionRoundBlockStyle";
import { COLORS } from "@style/colors";
import { useAddTruckToCollectionRound } from "@graphql/hocs/hooks/useAddTruckToCollectionRound";
import { useFormatMessage } from "@utils/intlHook";
import { parseError } from "@utils/parseError";
import { usePlanningContext } from "@components/planningProvider/planningProvider";

export interface CollectionRoundBlockProps {
    collectionRound: GetCollectionRoundsQuery["collectionRounds"][0];
}

export const CollectionRoundBlock: FunctionComponent<CollectionRoundBlockProps> = ({ collectionRound }) => {
    const { trucks } = useGetTrucks();
    const formatMessage = useFormatMessage();
    const { disabledCollectionRounds, isDragging } = usePlanningContext();
    const { addTruckToCollectionRound, isLoading } = useAddTruckToCollectionRound();
    const [selectedTruck, setSelectedTruck] = React.useState(collectionRound.truck.id);

    const availaibleTrucks = React.useMemo(
        () => trucks.filter(truck => !collectionRound.truckType || truck.type.id === collectionRound.truckType.id || truck.id === collectionRound.truck.id),
        [trucks]
    );

    const cargo = React.useMemo(() => {
        if (!collectionRound.capacity) {
            return { availableCargo: 0, usedCargo: 0 };
        }

        const usedCargo = collectionRound.tasks.reduce((sum, obj) => sum + obj.cargo, 0);
        const availableCargo = collectionRound.capacity.maxItemsPerCargo * collectionRound.capacity.maxCargoPerDayPart;

        return { usedCargo, availableCargo };
    }, [collectionRound]);

    let progress = 0;

    if (collectionRound.capacity && cargo.usedCargo / cargo.availableCargo > collectionRound.tasks.length / collectionRound.capacity.maxAddresses) {
        progress = cargo.usedCargo / cargo.availableCargo * 100;
    } else if (collectionRound.capacity) {
        progress = collectionRound.tasks.length / collectionRound.capacity.maxAddresses * 100;
    }

    const handleTruckChange = async (truckId: string): Promise<void> => {
        setSelectedTruck(truckId);
        const { error } = await addTruckToCollectionRound({ collectionRoundId: collectionRound.id, truckId });

        if (!error) {
            message.success(formatMessage({ id: "collectionRound.truckUpdatedSuccess" }));
        } else {
            message.error(parseError(error, formatMessage));

            setSelectedTruck(collectionRound.truck.id);
        }
    };

    const disabled = disabledCollectionRounds.some(id => id === collectionRound.id) || moment(collectionRound.date).isBefore(moment(), "day");

    return (
        <Droppable isDropDisabled={disabled} droppableId={collectionRound.id} >
            {(provided, snapshot) =>
                <CollectionRoundBlockStyle className={classnames({ isDraggingOver: snapshot.isDraggingOver, disabled: disabled && isDragging })}>
                    <header>
                        <div className="top">
                            <Select
                                loading={isLoading}
                                value={selectedTruck}
                                onChange={handleTruckChange}
                                placeholder={<FormattedMessage id="collectionRound.selectTruck" />}
                            >
                                {availaibleTrucks.map(truck =>
                                    <Select.Option key={truck.id} value={truck.id}>
                                        <SelectOptionStyle>
                                            <em>{truck.name}</em> - <i>{truck.type.name}</i>
                                        </SelectOptionStyle>
                                    </Select.Option>)}
                            </Select>

                            <Tooltip title={<FormattedMessage id="truckDetail.tooltip" />}>
                                <Link to={`/planning/${selectedTruck}/${collectionRound.date}`}>
                                    <Icon type="forward-arrow" />
                                </Link>
                            </Tooltip>
                        </div>

                        {collectionRound.patternId && collectionRound.capacity ?
                            <div className="info">
                                <div className="roundInfo">
                                    <FormattedMessage id={`daypart.${collectionRound.dayPart}`} />
                                    <div className="group">{collectionRound.group && collectionRound.group.name}</div>
                                </div>
                                <div className="products">
                                    {collectionRound.products &&
                                        collectionRound.products.map(prod =>
                                            <div key={prod.id} className="prod">
                                                &bull; {prod.name}
                                            </div>)}
                                </div>
                                <div className="progress">
                                    <Progress
                                        strokeColor={progress < 100 ? COLORS.primaryTransparant(0.8) : progress > 100 ? COLORS.error : COLORS.orange}
                                        percent={progress}
                                        showInfo={false}
                                        strokeWidth={4}
                                    />
                                    <Tooltip
                                        placement="leftTop"
                                        overlayClassName="capacity-tooltip"
                                        title={
                                            <CapacityTooltip>
                                                <div className="item">
                                                    {cargo.usedCargo} / {cargo.availableCargo} <FormattedMessage id="cargos" />
                                                </div>
                                                <div className="item">
                                                    {collectionRound.tasks.length} / {collectionRound.capacity.maxAddresses}{" "}
                                                    <FormattedMessage id="adresses" />
                                                </div>
                                            </CapacityTooltip>
                                        }
                                    >
                                        <Icon type="info" />
                                    </Tooltip>
                                </div>
                            </div>
                            :
                            <div className="info">
                                <FormattedMessage id="collectionRound.extraTruck" />
                            </div>
                        }
                    </header>

                    <main ref={provided.innerRef} {...provided.droppableProps}>
                        {collectionRound.tasks.map((task, index) =>
                            <PlanningInquiryItem date={collectionRound.date} type={task.type} key={`${collectionRound.id}${task.inquiry.id}`} index={index} inquiry={task.inquiry} sidebarItem={false} cargoPerProduct={task.cargoPerProduct} />)}
                        {provided.placeholder}
                    </main>
                </CollectionRoundBlockStyle>
            }
        </Droppable>
    );
};
