import { useGetProfile } from "@graphql/hocs/hooks/useGetProfile";
import * as React from "react";
import { createContext, FunctionComponent, useContext, useState } from "react";
import { ApolloError } from "apollo-client";

interface PermissionContext {
    permissions: Set<string>;
    permissionsLoaded: boolean;
    permissionsError?: ApolloError;
    setPermissions(permissions: Set<string>): void;
    hasPermissions(requiredPermissions: string[]): boolean;
}

const PermissionContext = createContext<PermissionContext>({
    permissions: new Set(),
    permissionsLoaded: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setPermissions: () => { },
    hasPermissions: () => false
});

export const PermissionProvider: FunctionComponent = ({ children }) => {
    const [permissionsLoaded, setPermissionsLoaded] = useState(false);
    const [permissions, setPermissions] = useState<Set<string>>(new Set());
    const { profile, profileError } = useGetProfile();

    React.useEffect(() => {
        if (profile) {
            setPermissions(new Set(profile.permissions));
        } else {
            setPermissions(new Set());
        }

        if (profile || profileError) {
            setPermissionsLoaded(true);
        }

    }, [profile, profileError, setPermissionsLoaded]);

    const hasPermissions = (requiredPermissions: string[]): boolean => requiredPermissions.every(p => permissions.has(p));

    return <PermissionContext.Provider
        value={{
            permissions,
            setPermissions,
            hasPermissions,
            permissionsLoaded,
            permissionsError: profileError
        }}
    >{children}</PermissionContext.Provider>;
};

export const usePermissions = (): PermissionContext => useContext(PermissionContext);
