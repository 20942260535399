import { Icon, Spin } from "antd";
import * as React from "react";

import { SpinnerStyle } from "@components/spinner/spinnerStyle";
import { FormattedMessage } from "react-intl";

export interface SpinnerProps {
    long?: boolean;
    big?: boolean;
}

export const Spinner: React.StatelessComponent<SpinnerProps> = ({ big, long }) => 
    <SpinnerStyle>
        <Spin indicator={<Icon type="loading" style={{ fontSize: big ? 50 : 24 }} spin />} />
        {long ? <FormattedMessage id="loading" /> : null}
    </SpinnerStyle>
    ;
