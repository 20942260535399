/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { GetFreePickupDaysQuery, GetFreePickupDaysQueryVariables } from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

export const useGetFreePickupDays = (options?: QueryHookOptions<GetFreePickupDaysQuery, GetFreePickupDaysQueryVariables>) => {
    const { data, error, loading, fetchMore } = useQuery<GetFreePickupDaysQuery, GetFreePickupDaysQueryVariables>(GraphQLDocuments.getFreePickupDays, { ...options, notifyOnNetworkStatusChange: true });

    const fetch = (offset: number) => fetchMore({
        variables: {
            paging: {
                limit: 5,
                offset
            }
        },
        updateQuery: (prev: GetFreePickupDaysQuery, { fetchMoreResult }): GetFreePickupDaysQuery => {
            if (!fetchMoreResult) { return prev; }

            return {
                ...prev,
                freePickupDays: {
                    ...prev.freePickupDays,
                    count: fetchMoreResult.freePickupDays.count,
                    items: [...prev.freePickupDays.items, ...fetchMoreResult.freePickupDays.items]
                }
            };
        }
    });

    return {
        freePickupDays: data && data.freePickupDays ? data.freePickupDays.items : [],
        freePickupDaysCount: data && data.freePickupDays ? data.freePickupDays.count : 0,
        freePickupDaysLoading: loading,
        freePickupDaysError: error,
        fetchMoreFreePickupDays: fetch
    };
};
