import styled from "styled-components";

export const CommentStyle = styled.div`
    h2 {
        font-size: 16px;
    }

    .editBtns {
        display: flex;
        justify-content: flex-end;
        margin-top: -15px;
        margin-bottom: 5px;

        .icon {
            cursor: pointer;
        }

        .editIcon,
        .anticon {
            padding: 10px;
            opacity: 0.5;
            transition: 0.2s;

            &:hover {
                opacity: 1;
            }
        }
    }
`;
