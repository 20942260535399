/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { GetStreetsQuery, GetStreetsQueryVariables } from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

export const useGetStreets = (options?: QueryHookOptions<GetStreetsQuery, GetStreetsQueryVariables>) => {
    const { data, error, loading } = useQuery<GetStreetsQuery, GetStreetsQueryVariables>(GraphQLDocuments.getStreets, options);

    return {
        streets: data && data.cardsStreets ? data.cardsStreets : [],
        streetsLoading: loading,
        streetsError: error
    };
};
