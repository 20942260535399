import * as React from "react";
import { FunctionComponent } from "react";
import { PageHeaderStyle } from "./pageHeaderStyle";
import { FormattedMessage } from "react-intl";
import { InnerContainer } from "@style/innerContainer";
import { Link } from "react-router-dom";
import { Icon } from "@style/icon";
import { useRouter } from "@utils/routerHook";

export interface PageHeaderProps {
    title: string;
    dontTranslateTitle?: boolean;
    link?: string;
    backArrow?: boolean;
    shadow?: boolean;
    goBack?: boolean;
}

export const PageHeader: FunctionComponent<PageHeaderProps> = ({ title, children, link, backArrow, shadow, dontTranslateTitle, goBack }) => {
    const { history } = useRouter();

    const handleLink = (e: React.MouseEvent): void => {
        e.preventDefault();

        if (goBack) {
            history.goBack();

            return;
        }

        if (link) {
            history.push(link);

        }

    };

    return (
        <PageHeaderStyle shadow={shadow}>
            <InnerContainer>

                {link ? <Link onClick={handleLink} to={link}>{backArrow && <Icon type="back-arrow" />}{!dontTranslateTitle ? <FormattedMessage id={title} /> : title}</Link> : !dontTranslateTitle ? <FormattedMessage tagName="h1" id={title} /> : <h1>{title}</h1>}

                <aside>
                    {children}
                </aside>
            </InnerContainer>
        </PageHeaderStyle>
    );
};
