import styled from "styled-components";

import { COLORS } from "@style/colors";

interface StyleButtonStyleProps {
    active: boolean;
    bold: boolean;
    italic: boolean;
    underline: boolean;
}

export const StyleButtonStyle = styled.div`
    font-weight: ${(props: StyleButtonStyleProps) => props.bold ? 600 : 400};
    cursor: pointer;
    opacity: ${(props: StyleButtonStyleProps) => props.active ? 1 : 0.5};

    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: ${(props: StyleButtonStyleProps) => props.underline ? "underline" : "none"};
    font-style: ${(props: StyleButtonStyleProps) => props.italic ? "italic" : "none"};


    &:hover {
        opacity: 1;
        color: ${COLORS.primary};
    }
`;
