import { useApolloClient } from "@apollo/react-hooks";
import { Button, message, Spin } from "antd";
import { NormalizedCache } from "apollo-cache-inmemory";
import * as classnames from "classnames";
import * as moment from "moment";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { useQueryParameters } from "@utils/useQueryParameters";
import { useRouter } from "@utils/routerHook";
import { parseCollectionPatternToForm, parseFormToCollectionPatternInput } from "@utils/parsePatternFormValues";
import { useFormatMessage } from "@utils/intlHook";
import { DATE_FORMAT } from "@utils/consts";
import { InnerContainer } from "@style/innerContainer";
import { Icon } from "@style/icon";
import { CollectionGroupInput } from "@models/graphql";
import { useUpdateCollectionPattern } from "@graphql/hocs/hooks/useUpdateCollectionPattern";
import { useGetCollectionPattern } from "@graphql/hocs/hooks/useGetCollectionPattern";
import { useCreateCollectionPattern } from "@graphql/hocs/hooks/useCreateCollectionPattern";
import { useUnplannableDays } from "@components/patternOverviewTab/unplannableDaysProvider";
import { PatternFormValues, useForm, useGetFormValues } from "@components/patternOverviewTab/patternFormProvider";
import { useEditCollectionPattern } from "@components/patternOverviewTab/patternEdittingProvider";
import { GeneralPatternForm } from "@components/patternOverviewTab/generalPatternForm/generalPatternForm";
import { useFormCollectionRound } from "@components/patternOverviewTab/collectionRoundProvider";
import { useFormCollectionGroups } from "@components/patternOverviewTab/collectionGroupsProvider";
import { Step, StepProps } from "@components/patternFormHeader/step/step";
import { ErrorMessage } from "@components/errorMessage/errorMessage";

import { PatternFormHeaderStyle, Steps } from "./patternFormHeaderStyle";

export interface PatternFormHeaderProps { }

export const PatternFormHeader: FunctionComponent<PatternFormHeaderProps> = () => {
    const { location, history } = useRouter<{ patternId?: string }>();
    const formatMessage = useFormatMessage();
    const client = useApolloClient();
    const { parameters } = useQueryParameters<{ page: string; editId?: string }>("/management/calendar/pattern{/page}{?editId}");

    const { validateFields, setFieldsValue } = useForm();
    const { days, config: cfg, groups: colGroups, from, to, name, processors } = useGetFormValues();
    const { collectionGroups } = useFormCollectionGroups();
    const { unplannableDays, setUnplannableDays } = useUnplannableDays();
    const { setEditItem, isEditting, editPatternItem, setEditItemState, setIsActive, setActiveDates } = useEditCollectionPattern();
    const { setCollectionRounds } = useFormCollectionRound();
    const { setCollectionGroups } = useFormCollectionGroups();

    const { createCollectionPattern, isLoading: createLoading, error: createError } = useCreateCollectionPattern({
        async update(cache) {
            const data: NormalizedCache = (cache as any).data;

            Object.keys((data as any).data).forEach(key => key.match(/^CollectionPattern/) && data.delete(key));
            Object.keys((data as any).data).forEach(key => key.match(/^\$ROOT_QUERY\.collectionPatterns/) && data.delete(key));

            await client.reFetchObservableQueries();
        }
    });
    const { updateCollectionPattern, isLoading: updateLoading, error: updateError } = useUpdateCollectionPattern({
        async update(cache, response) {
            const data: NormalizedCache = (cache as any).data;

            Object.keys((data as any).data).forEach(key => key.match(/^CollectionPattern/) && data.delete(key));
            Object.keys((data as any).data).forEach(key => key.match(/^\$ROOT_QUERY\.collectionPatterns/) && data.delete(key));

            if (response.data && response.data.updateCollectionPattern.active) {
                Object.keys((data as any).data).forEach(key => key.match(/^CollectionRound/) && data.delete(key));
                Object.keys((data as any).data).forEach(key => key.match(/^\$ROOT_QUERY\.collectionRounds/) && data.delete(key));
            }

            await client.reFetchObservableQueries();
        }
    });

    const { collectionPattern, collectionPatternError, collectionPatternLoading } = useGetCollectionPattern({
        variables: {
            id: parameters.editId || ""
        },
        skip: !parameters.editId
    });

    React.useEffect(() => setEditItemState({ loading: collectionPatternLoading, error: collectionPatternError }), [
        collectionPatternLoading,
        collectionPatternError
    ]);

    React.useEffect(() => {
        if (collectionPattern && !isEditting) {
            const { collRounds, fields } = parseCollectionPatternToForm(collectionPattern);

            setCollectionGroups(collectionPattern.groups.map<CollectionGroupInput>(group => ({
                id: group.id,
                municipalityIds: group.municipalities.map(m => m.id),
                name: group.name
            })));

            setCollectionRounds(collRounds);

            setUnplannableDays(collectionPattern.notPlannableDays);

            setIsActive(collectionPattern.active);
            setActiveDates({
                from: moment(collectionPattern.from, DATE_FORMAT),
                to: moment(collectionPattern.to, DATE_FORMAT)
            });

            setEditItem(fields);

            setFieldsValue({
                from: fields.from,
                to: fields.to,
                name: fields.name,
                dayCount: fields.dayCount
            });
        }
    }, [collectionPattern, isEditting]);

    const getStepTwoDone = (): boolean => {
        if (!days) {
            return false;
        }

        if (
            days.some(day =>
                !day.collectionRound ||
                Object.values(day.collectionRound).some(round => !round.dayPart || !round.groupId || !round.productIds || !round.productIds.length || !round.truckTypeId))
        ) {
            return false;
        }

        return true;
    };

    const getStepThreeDone = (): boolean => {
        if (!cfg || !colGroups || !days || !processors) {
            return false;
        }

        if (Object.values(colGroups).some(group => group.addressesPerFullDay === undefined || group.addressesPerHalfDay === undefined)) {
            return false;
        }

        if (!Object.values(cfg).every(truckType => Object.values(truckType).every(group => Object.values(group).every(cap => Object.values(cap).every(value => value || value === 0))))) {
            return false;
        }

        if (Object.values(processors).some(prod => Object.values(prod).some(group => !group.processorId))) {
            return false;
        }

        return true;
    };

    const stepTwoDone = React.useMemo(getStepTwoDone, [days, editPatternItem]);
    const stepThreeDone = React.useMemo(getStepThreeDone, [cfg, colGroups, days, editPatternItem]);

    const steps: StepProps[] = [
        {
            link: `/management/calendar/pattern/groups${parameters.editId ? `?editId=${parameters.editId}` : ""}`,
            number: 1,
            label: "patternDetail.groups",
            done: collectionGroups.length > 0
        },
        {
            link: `/management/calendar/pattern/pattern${parameters.editId ? `?editId=${parameters.editId}` : ""}`,
            number: 2,
            label: "patternDetail.pattern",
            done: stepTwoDone
        },
        {
            link: `/management/calendar/pattern/capacity${parameters.editId ? `?editId=${parameters.editId}` : ""}`,
            number: 3,
            label: "patternDetail.capacity",
            done: stepThreeDone
        },
        {
            link: `/management/calendar/pattern/unplannable-days${parameters.editId ? `?editId=${parameters.editId}` : ""}`,
            number: 4,
            label: "patternDetail.unplannableDays",
            done: !!from && !!to
        }
    ];

    const renderButtons = (): JSX.Element => {
        const index = steps.findIndex(step => step.link === location.pathname + location.search);

        return (
            <div className="buttons">
                <Link className={classnames({ disabled: index === 0 })} to={steps[index - 1] ? steps[index - 1].link : "#"}>
                    <Icon type="arrow-left" />
                    <FormattedMessage id="patternDetail.previousStep" />
                </Link>
                <Link className={classnames({ disabled: index + 1 === steps.length })} to={steps[index + 1] ? steps[index + 1].link : "#"}>
                    <FormattedMessage id="patternDetail.nextStep" />
                    <Icon type="arrow-right" />
                </Link>
            </div>
        );
    };

    const handleSubmit = (): void => {
        validateFields(async (errors, vals: PatternFormValues) => {
            if (errors) {
                return;
            }

            if (!stepTwoDone) {
                return;
            }

            if (!days) {
                return;
            }

            let uDays = unplannableDays;
            if (collectionPattern) {
                uDays = [...collectionPattern.notPlannableDays, ...unplannableDays];
            }

            const pattern = parseFormToCollectionPatternInput(vals, uDays, collectionGroups);

            if (editPatternItem && parameters.editId) {
                const response = await updateCollectionPattern({
                    id: parameters.editId,
                    pattern
                });

                if (!response) {
                    return;
                }
            } else {
                const response = await createCollectionPattern({
                    pattern
                });

                if (!response) {
                    return;
                }
            }
            message.success(formatMessage({ id: "patternForm.saveSuccessful" }));
            history.push("/management/calendar");
        });
    };

    return (
        <PatternFormHeaderStyle>
            <Spin spinning={collectionPatternLoading}>
                <InnerContainer className="top">
                    <GeneralPatternForm />
                    <div className="buttons">
                        <Button
                            disabled={collectionGroups.length < 1 || !stepTwoDone || !stepThreeDone || !from || !to || !name}
                            loading={createLoading || updateLoading}
                            onClick={handleSubmit}
                            type="primary"
                        >
                            <FormattedMessage id="save" />
                        </Button>
                    </div>
                </InnerContainer>

                <InnerContainer>
                    <ErrorMessage error={collectionPatternError} />
                    <ErrorMessage error={createError} />
                    <ErrorMessage error={updateError} />
                </InnerContainer>
            </Spin>
            <Steps>
                <InnerContainer>
                    <div className="steps">
                        {steps.map(step =>
                            <Step key={step.number} {...step} />)}
                    </div>

                    {renderButtons()}
                </InnerContainer>
            </Steps>
        </PatternFormHeaderStyle>
    );
};
