import { Form } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { WrappedFormUtils } from "antd/lib/form/Form";
import { Moment } from "moment";
import * as React from "react";
import { createContext, ReactNode, useContext } from "react";

import { isEqual } from "@utils/isEqualArray";
import { DayPart, ProductFragment, TruckTypeFragment } from "@models/graphql";
import { useGetTruckTypes } from "@graphql/hocs/hooks/useGetTruckTypes";
import { useGetProductNames } from "@graphql/hocs/hooks/useGetProductNames";

export interface PatternFormValues {
    name?: string;
    dayCount?: number;
    from: Moment;
    to: Moment;
    days?: {
        collectionRound?: {
            [index: string]: {
                dayPart?: DayPart;
                groupId?: string;
                productIds?: string[];
                truckTypeId?: string;
            };
        };
    }[];
    groups?: {
        [groupId: string]: {
            addressesPerFullDay?: number;
            addressesPerHalfDay?: number;
        };
    };
    processors?: {
        [productIds: string]: {
            [groupId: string]: {
                processorId: string;
            };
        };
    };
    config?: {
        [productIds: string]: {
            [truckTypeId: string]: {
                [groupId: string]: {
                    capacity: number;
                    halfDay?: number;
                    fullDay?: number;
                };
            };
        };
    };
    fixedProductCycles?: {
        [productId: string]: number;
    };
}

interface FormContext {
    form: WrappedFormUtils<PatternFormValues> | null;
}

export const FormContext = createContext<FormContext>({} as any);

type FormContextProviderProps = { children?: ReactNode } & FormComponentProps;
function FormContextProviderImpl(props: FormContextProviderProps): JSX.Element {
    const { form, children } = props;

    return (
        <FormContext.Provider
            value={{
                form
            }}
        >
            {children}
        </FormContext.Provider>
    );
}

export const PatternFormProvider = Form.create()(FormContextProviderImpl);

export function useForm(): WrappedFormUtils {
    const state = useContext(FormContext);
    if (!state.form) {
        throw new Error("Missing FormContextProvider in its parent.");
    }

    return state.form;
}

export const useGetFormValues = (): PatternFormValues => {
    const state = useContext(FormContext);
    if (!state.form) {
        throw new Error("Missing FormContextProvider in its parent.");
    }

    return state.form.getFieldsValue() as PatternFormValues;
};

interface Combo {
    products: ProductFragment[];
    combinations: {
        truckType: TruckTypeFragment;
        groups: {
            groupId: string;
            halfDay: boolean;
            fullDay: boolean;
        }[];
    }[];
}

export const useGetUniqueProductCombinations = (): Combo[] => {
    const state = useContext(FormContext);
    const { products } = useGetProductNames({ variables: { filter: { accessory: false } } });
    const { truckTypes } = useGetTruckTypes();

    if (!state.form) {
        throw new Error("Missing FormContextProvider in its parent.");
    }

    const values = state.form.getFieldsValue() as PatternFormValues;

    return React.useMemo(() => {
        const combos: Combo[] = [];

        if (!products.length || !truckTypes.length) {
            return [];
        }

        if (!values.days) {
            return [];
        }

        for (const day of values.days) {
            if (!day || !day.collectionRound) {
                continue;
            }

            Object.values(day.collectionRound).forEach(val => {
                const { productIds, truckTypeId, groupId, dayPart } = val;

                if (!productIds || !truckTypeId || !groupId || !dayPart) {
                    return;
                }

                const uniqueMatch = combos.find(comb => isEqual(comb.products.map(prod => prod.id), productIds));

                if (uniqueMatch) {
                    const truckMatch = uniqueMatch.combinations.find(comb => comb.truckType.id === truckTypeId);

                    if (truckMatch) {
                        const groupMatch = truckMatch.groups.find(group => group.groupId === groupId);

                        if (!groupMatch) {
                            truckMatch.groups.push({
                                groupId,
                                fullDay: dayPart === DayPart.Fullday,
                                halfDay: dayPart !== DayPart.Fullday
                            });
                        } else {
                            groupMatch.fullDay = dayPart === DayPart.Fullday || groupMatch.fullDay;
                            groupMatch.halfDay = dayPart !== DayPart.Fullday || groupMatch.halfDay;
                        }
                    } else {
                        const truckTypeMatch = truckTypes.find(truckType => truckType.id === truckTypeId);

                        if (!truckTypeMatch) {
                            throw new Error("No truckmatch");
                        }

                        uniqueMatch.combinations.push({
                            truckType: truckTypeMatch,
                            groups: [
                                {
                                    groupId,
                                    fullDay: dayPart === DayPart.Fullday,
                                    halfDay: dayPart !== DayPart.Fullday
                                }
                            ]
                        });
                    }
                } else {
                    const truckTypeMatch = truckTypes.find(truckType => truckType.id === truckTypeId);

                    if (!truckTypeMatch) {
                        throw new Error("No truckmatch");
                    }

                    combos.push({
                        products: productIds.map<ProductFragment>(prod => {
                            const match = products.find(p => p.id === prod);
                            if (!match) {
                                throw new Error("No product found");
                            }

                            return match;
                        }),
                        combinations: [
                            {
                                truckType: truckTypeMatch,
                                groups: [
                                    {
                                        groupId,
                                        fullDay: dayPart === DayPart.Fullday,
                                        halfDay: dayPart !== DayPart.Fullday
                                    }
                                ]
                            }
                        ]
                    });
                }
            });
        }

        return combos;
    }, [values, products, truckTypes]);
};
