/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { CancelInquiryMutation, CancelInquiryMutationVariables } from "@models/graphql";

const defaultCancelInquiryOptions: MutationHookOptions<CancelInquiryMutation, CancelInquiryMutationVariables> = {};

export const useCancelInquiry = (options?: MutationHookOptions<CancelInquiryMutation, CancelInquiryMutationVariables>) => {
    const [mutation, { data, loading, error }] = useMutation<CancelInquiryMutation, CancelInquiryMutationVariables>(
        GraphQLDocuments.cancelInquiry
    );

    const cancelInquiry = async (variables: CancelInquiryMutationVariables) => {
        try {
            const response = await mutation({
                ...defaultCancelInquiryOptions,
                ...options,
                variables
            });

            return {
                error: undefined,
                response
            };
        } catch (err) {
            return {
                error: err,
                response: undefined
            };
        }
    };

    return {
        isLoading: loading,
        error,
        data,
        cancelInquiry: cancelInquiry
    };
};
