import * as React from "react";
import { FunctionComponent } from "react";
import { StyleButtonStyle } from "./styleButtonStyle";

export interface StyleButtonProps {
    style: string;
    active: boolean;
    label: string | JSX.Element;
    onToggle(style: string): void;
}

export const StyleButton: FunctionComponent<StyleButtonProps> = ({ onToggle, style, active, label }) => {

    const handleToggle = (e: React.MouseEvent): void => {
        e.preventDefault();

        onToggle(style);
    };

    return (
        <StyleButtonStyle bold={style === "BOLD"} italic={style === "ITALIC"} underline={style === "UNDERLINE"} active={active} onMouseDown={handleToggle}>
            {label}
        </StyleButtonStyle>
    );
};
