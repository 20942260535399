/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { ResetScheduleMutation, ResetScheduleMutationVariables } from "@models/graphql";

const defaultResetScheduleOptions: MutationHookOptions<ResetScheduleMutation, ResetScheduleMutationVariables> = {};

export const useResetSchedule = (options?: MutationHookOptions<ResetScheduleMutation, ResetScheduleMutationVariables>) => {
    const [mutation, { data, loading, error }] = useMutation<ResetScheduleMutation, ResetScheduleMutationVariables>(
        GraphQLDocuments.resetSchedule
    );

    const resetSchedule = async (variables: ResetScheduleMutationVariables) => {
        try {
            const response = await mutation({
                ...defaultResetScheduleOptions,
                ...options,
                variables
            });

            return {
                error: response.errors,
                data: response.data
            };
        } catch (err) {
            return {
                error: err
            };
        }
    };

    return {
        isLoading: loading,
        error,
        data,
        resetSchedule
    };
};
