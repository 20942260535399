import styled from "styled-components";
import { COLORS } from "@style/colors";
import { FirstColumn } from "@components/patternCapacity/capacityTable/capacityTableStyle";

export const CapacityTableColumnStyle = styled(FirstColumn)`
    margin-right: 5px;
    padding-right: 5px;
    min-width: 330px;
    z-index: 1;

    h2, h3 {
        border-radius: 0;
    }


    h1 {
        background-color: ${COLORS.lightGrey};
    }

    border-right: 1px solid ${COLORS.lightGrey};

`;
