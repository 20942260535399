import { Button, Spin, Tooltip } from "antd";
import { ApolloError } from "apollo-client";
import * as moment from "moment";
import { FunctionComponent } from "react";
import * as React from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { Prompt } from "react-router";

import { DATE_FORMAT } from "@utils/consts";
import { DayPart, InquiryOrderInput, TruckTaskFragment, TruckTaskType } from "@models/graphql";
import { useFixedTruckScheduleOrder } from "@graphql/hocs/hooks/useFixedTruckScheduleOrder";
import { TruckScheduleItem } from "@components/truckScheduleSidebar/truckScheduleItem/truckScheduleItem";
import { ErrorMessage } from "@components/errorMessage/errorMessage";

import { TruckScheduleSidebarStyle, UnsavedBlock, NotOptimalBlock } from "./truckScheduleSidebarStyle";
import { useResetSchedule } from "@graphql/hocs/hooks/useResetSchedule";

export interface TruckScheduleSidebarProps {
    truckScheduleTasks: TruckTaskFragment[];
    truckScheduleDate?: moment.Moment;
    truckId?: string;
    isUnsaved: boolean;
    loading: boolean;
    optimal: boolean;
    setTruckScheduleTasks(tasks: TruckTaskFragment[]): void;
    setActiveTask(taskId: string | null): void;
    setIsUnsaved(unsaved: boolean): void;
    setOptimal(optimal: boolean): void;
    resetTasks(): void;
}

export const TruckScheduleSidebar: FunctionComponent<TruckScheduleSidebarProps> = ({
    truckScheduleTasks,
    truckScheduleDate,
    truckId,
    setTruckScheduleTasks,
    setActiveTask,
    isUnsaved,
    resetTasks,
    loading,
    setIsUnsaved,
    optimal,
    setOptimal
}) => {
    const { fixedTruckScheduleOrder, isLoading: fixedLoading } = useFixedTruckScheduleOrder();
    const { resetSchedule, isLoading: resetLoading, error: resetError } = useResetSchedule();
    const [error, setError] = React.useState<null | ApolloError>(null);
    const { afternoonTasks, fullDayTasks, morningTasks } = React.useMemo(() => {
        const tFullDay: TruckTaskFragment[] = [];
        const tAfternoon: TruckTaskFragment[] = [];
        const tMorning: TruckTaskFragment[] = [];

        truckScheduleTasks.forEach(task => {
            if (task.dayPart === DayPart.Afternoon) {
                tAfternoon.push(task);
            }
            if (task.dayPart === DayPart.Morning) {
                tMorning.push(task);
            }
            if (task.dayPart === DayPart.Fullday) {
                tFullDay.push(task);
            }
        });

        return {
            fullDayTasks: tFullDay,
            morningTasks: tMorning,
            afternoonTasks: tAfternoon
        };
    }, [truckScheduleTasks]);

    const isToday = moment(truckScheduleDate).isSame(moment());

    const handleDragEnd = (result: DropResult): void => {
        if (result.destination && result.destination.droppableId === result.source.droppableId && result.destination.index === result.source.index) {
            return;
        }

        if (result.destination) {
            let sourceTask: TruckTaskFragment | undefined;
            let destTask: TruckTaskFragment | undefined;

            if (result.source.droppableId === DayPart.Afternoon) {
                sourceTask = afternoonTasks[result.source.index];
            } else if (result.source.droppableId === DayPart.Morning) {
                sourceTask = morningTasks[result.source.index];
            } else {
                sourceTask = fullDayTasks[result.source.index];
            }

            if (result.destination.droppableId === DayPart.Afternoon) {
                destTask = afternoonTasks[result.destination.index];
            } else if (result.destination.droppableId === DayPart.Morning) {
                destTask = morningTasks[result.destination.index];
            } else {
                destTask = fullDayTasks[result.destination.index];
            }

            const sourceIndex = truckScheduleTasks.findIndex(t => sourceTask && t.id === sourceTask.id);
            let destIndex = truckScheduleTasks.findIndex(t => destTask && t.id === destTask.id);

            if (sourceIndex < 0) {
                return;
            }

            if (destIndex < 0) {
                if (result.destination.droppableId === DayPart.Afternoon) {
                    destIndex = afternoonTasks.length;
                } else if (result.destination.droppableId === DayPart.Morning) {
                    destIndex = morningTasks.length;
                } else {
                    destIndex = fullDayTasks.length;
                }
            }

            sourceTask.dayPart = result.destination.droppableId as DayPart;
            truckScheduleTasks.splice(sourceIndex, 1);
            truckScheduleTasks.splice(destIndex, 0, sourceTask);
            truckScheduleTasks = truckScheduleTasks.filter(t => t.type === TruckTaskType.Inquiry);

            setTruckScheduleTasks(truckScheduleTasks);
            setIsUnsaved(true);
        }
    };

    const renderTasks = (tasks: TruckTaskFragment[], dayPart: DayPart): JSX.Element =>
        <Droppable droppableId={dayPart} isDropDisabled={isToday}>
            {provided =>
                <section>
                    <h2>
                        {moment(truckScheduleDate).format("dddd DD MMMM")} - &nbsp;
                        <FormattedMessage id={`daypart.${dayPart}`} />
                        <span className="distance">
                            <FormattedNumber value={tasks.reduce((prev, cur) => prev + cur.distance, 0)} /> KM
                        </span>
                    </h2>
                    <main ref={provided.innerRef} {...provided.droppableProps}>
                        {tasks.map((truckTask, index) => <TruckScheduleItem key={truckTask.id} setActiveTask={setActiveTask} truckTask={truckTask} dragIndex={index} />)}
                        {provided.placeholder}
                    </main>
                </section>
            }
        </Droppable>
        ;

    const handleSave = async (): Promise<void> => {
        setError(null);
        if (!truckScheduleDate || !truckId) {
            return;
        }
        const inquiryOrder: InquiryOrderInput[] = [];
        if (morningTasks.length) {
            inquiryOrder.push({
                dayPart: DayPart.Morning,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                ids: morningTasks.map(t => t.task!.inquiry.id)
            });
        }
        if (afternoonTasks.length) {
            inquiryOrder.push({
                dayPart: DayPart.Afternoon,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                ids: afternoonTasks.map(t => t.task!.inquiry.id)
            });
        }
        if (fullDayTasks.length) {
            inquiryOrder.push({
                dayPart: DayPart.Fullday,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                ids: fullDayTasks.map(t => t.task!.inquiry.id)
            });
        }

        const { data, error: aError } = await fixedTruckScheduleOrder({
            date: truckScheduleDate.format(DATE_FORMAT),
            inquiryOrder,
            truckId
        });

        if (data && data.fixedTruckScheduleOrder) {
            setIsUnsaved(false);
            setTruckScheduleTasks(data.fixedTruckScheduleOrder.tasks);
            setOptimal(false);
        }

        if (aError) {
            setError(aError);
        }
    };

    const handleResetLocal = (): void => {
        setError(null);
        resetTasks();
    };

    const renderUnsaved = (): JSX.Element | null => {
        if (!isUnsaved) {
            return null;
        }

        return (
            <UnsavedBlock>
                <FormattedMessage id="tasksChanged" />

                {fixedLoading && <em>De nieuwe route wordt berekend...</em>}
                <div>
                    <Button disabled={fixedLoading} onClick={handleResetLocal} style={{ opacity: 0.8 }}>
                        <FormattedMessage id="cancel" />
                    </Button>
                    <Button loading={fixedLoading} onClick={handleSave}>
                        <FormattedMessage id="save" />
                    </Button>
                </div>
                <ErrorMessage error={error} />
            </UnsavedBlock>
        );
    };

    const handleReset = async (): Promise<void> => {
        if (!truckScheduleDate || !truckId) {
            return;
        }
        const { data } = await resetSchedule({ date: truckScheduleDate.format(DATE_FORMAT), truckId });

        if (data && data.resetSchedule) {
            setTruckScheduleTasks(data.resetSchedule.tasks);
            setOptimal(true);
        }
    };

    const renderNotOptimal = (): JSX.Element | null => {
        if (optimal || isUnsaved || truckScheduleDate?.isSameOrBefore(moment(), "d")) {
            return null;
        }

        return (
            <NotOptimalBlock>
                <Tooltip placement="right" title="De taken zijn manueel aangepast, om dit ongedaan te maken kan je deze terug optimaliseren.">
                    <Button loading={resetLoading} onClick={handleReset} type="primary">
                        Aanvragen optimaliseren
                    </Button>
                    <ErrorMessage error={resetError} />
                </Tooltip>
            </NotOptimalBlock>
        );
    };

    return (
        <TruckScheduleSidebarStyle>
            <Spin style={{ minHeight: 200 }} spinning={loading}>
                <Prompt when={isUnsaved} message="Je hebt niet opgeslagen wijzigingen, als je doorgaat gaan deze verloren." />
                {renderUnsaved()}
                {renderNotOptimal()}
                <DragDropContext onDragEnd={handleDragEnd}>
                    {morningTasks.length ? renderTasks(morningTasks, DayPart.Morning) : null}
                    {afternoonTasks.length ? renderTasks(afternoonTasks, DayPart.Afternoon) : null}
                    {fullDayTasks.length ? renderTasks(fullDayTasks, DayPart.Fullday) : null}
                </DragDropContext>
            </Spin>
        </TruckScheduleSidebarStyle>
    );
};
