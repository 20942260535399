import * as React from "react";
import { ComponentType, useReducer } from "react";
import { initialState } from "./initialState";
import { rootReducer } from "./reducers/root";
import { DispatchContext, StateContext } from "./useGlobalState";

export const GlobalStateProvider: ComponentType = ({ children }) => {
    const [state, dispatch] = useReducer(rootReducer, initialState);

    return (
        <DispatchContext.Provider value={dispatch}>
            <StateContext.Provider value={state}>
                {children}
            </StateContext.Provider>
        </DispatchContext.Provider>
    );
};
