/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { FunctionComponent } from "react";
import { ApplicantAutocompleteStyle } from "./applicantAutocompleteStyle";
import { AutoComplete, Icon } from "antd";
import { useGetApplicants } from "@graphql/hocs/hooks/useGetApplicants";
import { GetApplicantsQuery } from "@models/graphql";
import { useFormatMessage } from "@utils/intlHook";
import { ErrorMessage } from "@components/errorMessage/errorMessage";

export interface ApplicantAutocompleteProps {
    value?: string | GetApplicantsQuery["cardsApplicants"][0];
    municipality?: string;
    street?: string;
    onChange?(val): void;
}

export const ApplicantAutocompleteComponent: FunctionComponent<ApplicantAutocompleteProps> = (props, ref) => {
    const formatMessage = useFormatMessage();
    const [searchValue, setSearchValue] = React.useState("");
    const { applicants, applicantsLoading, applicantsError } = useGetApplicants({
        variables: {
            search: searchValue,
            municipality: props.municipality!,
            street: props.street!
        },
        skip: searchValue.length < 2 || !props.municipality || !props.street
    });

    const onAutocompleteChange = (index: string): void => {
        if (props.onChange) {
            const applicant = applicants[parseInt(index, 10)];

            props.onChange(applicant);
        }
    };

    let value: string | undefined = undefined;

    if (props.value !== undefined && props.value !== null && typeof value !== "string") {
        const indexMatch = applicants.findIndex(app => app.id === (props.value as GetApplicantsQuery["cardsApplicants"][0]).id && app.personId === (props.value as GetApplicantsQuery["cardsApplicants"][0]).personId);
        if (indexMatch > -1) {
            value = indexMatch.toString();
        }
    }

    const handleSearch = (val: string): void => {
        if (props.onChange) {
            props.onChange(undefined);
        }
        setSearchValue(val);
    };

    return (
        <ApplicantAutocompleteStyle>
            {applicantsLoading && <Icon type="loading" />}
            <AutoComplete
                allowClear
                value={value || searchValue}
                disabled={!props.municipality || !props.street}
                filterOption={false}
                onSearch={handleSearch}
                onSelect={onAutocompleteChange}
                ref={ref}
                placeholder={formatMessage({ id: "createInquiryPage.applicant" })}
            >
                {applicants.map((applicant, index) => <AutoComplete.Option key={index} value={index.toString()}>{applicant.name}</AutoComplete.Option>)}
            </AutoComplete>
            <ErrorMessage error={applicantsError} />
        </ApplicantAutocompleteStyle>
    );
};

export const ApplicantAutocomplete = React.forwardRef(ApplicantAutocompleteComponent);
