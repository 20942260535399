import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { GetLogisticReportsQuery, GetLogisticReportsQueryVariables } from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useGetLogisticReports = (options?: QueryHookOptions<GetLogisticReportsQuery, GetLogisticReportsQueryVariables>) => {
    const { data, error, loading } = useQuery<GetLogisticReportsQuery, GetLogisticReportsQueryVariables>(GraphQLDocuments.getLogisticReports, options);

    return {
        logisticReports: data && data.logisticReports ? data.logisticReports : [],
        logisticReportsLoading: loading,
        logisticReportsError: error
    };
};
