import { Checkbox, Form, Input, Modal, Radio } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { FunctionComponent } from "react";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { useFormatMessage } from "@utils/intlHook";
import { GetIncidentReasonsQuery, Responsibility } from "@models/graphql";
import { useUpdateIncidentReason } from "@graphql/hocs/hooks/useUpdateIncidentReason";
import { useGetProductNames } from "@graphql/hocs/hooks/useGetProductNames";
import { useCreateIncidentReason } from "@graphql/hocs/hooks/useCreateIncidentReason";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { Spinner } from "@components/spinner/spinner";
import { ErrorMessage } from "@components/errorMessage/errorMessage";

import { IncidentReasonFormStyle } from "./incidentReasonFormStyle";

interface FormValues {
    name: string;
    productIds: string[];
    responsibility: Responsibility;
}

export interface IncidentReasonFormProps extends FormComponentProps<FormValues> {
    visible: boolean;
    editItem: GetIncidentReasonsQuery["incidentReasons"]["items"][0] | null;
    closeCallback(): void;
}

export const IncidentReasonFormComponent: FunctionComponent<IncidentReasonFormProps> = ({ closeCallback, visible, form, editItem }) => {
    const formatMessage = useFormatMessage();

    const { createIncidentReason, error: createError, isLoading: createLoading } = useCreateIncidentReason({
        refetchQueries: [{ query: GraphQLDocuments.getIncidentReasons }],
        awaitRefetchQueries: true
    });
    const { updateIncidentReason, error: updateError, isLoading: updateLoading } = useUpdateIncidentReason({});

    const { products, productsLoading } = useGetProductNames({ variables: { filter: { accessory: false } } });

    const onClose = (): void => {
        form.resetFields();
        closeCallback();
    };

    const onSubmit = (e: React.FormEvent): void => {
        e.preventDefault();

        form.validateFields(async (errors, values) => {
            if (!errors) {
                const incidentReason = {
                    name: values.name,
                    productIds: values.productIds,
                    responsibility: values.responsibility
                };

                if (editItem) {
                    const response = await updateIncidentReason({
                        incidentReason,
                        incidentReasonId: editItem.id
                    });

                    if (response && response.data && response.data.updateIncidentReason) {
                        onClose();
                    }
                } else {
                    const response = await createIncidentReason({
                        incidentReason
                    });

                    if (response && response.data && response.data.createIncidentReason) {
                        onClose();
                    }
                }
            }
        });
    };

    return (
        <Modal
            onCancel={onClose}
            visible={visible}
            okText={<FormattedMessage id="save" />}
            cancelText={<FormattedMessage id="cancel" />}
            onOk={onSubmit}
            okButtonProps={{ loading: createLoading || updateLoading }}
            title={<FormattedMessage id="incidentReasonForm.title" />}
        >
            <IncidentReasonFormStyle>
                <Form onSubmit={onSubmit}>
                    <Form.Item label={formatMessage({ id: "incidentReasonForm.name" })}>
                        {form.getFieldDecorator("name", {
                            initialValue: editItem ? editItem.name : undefined,
                            rules: [
                                {
                                    required: true,
                                    message: formatMessage({
                                        id: "requiredField"
                                    })
                                }
                            ]
                        })(<Input
                            placeholder={formatMessage({
                                id: "incidentReasonForm.name"
                            })}
                        />)}
                    </Form.Item>

                    <Form.Item>
                        {form.getFieldDecorator("responsibility", {
                            initialValue: editItem ? editItem.responsibility : Responsibility.Civilian,
                            rules: [
                                {
                                    required: true,
                                    message: formatMessage({
                                        id: "requiredField"
                                    })
                                }
                            ]
                        })(<Radio.Group>
                            <Radio value={Responsibility.Civilian}>
                                <FormattedMessage id="responsibility.CIVILIAN" />
                            </Radio>
                            <Radio value={Responsibility.PickupService}>
                                <FormattedMessage id="responsibility.PICKUP_SERVICE" />
                            </Radio>
                        </Radio.Group>)}
                    </Form.Item>

                    <Form.Item
                        label={formatMessage({
                            id: "incidentReasonForm.products"
                        })}
                    >
                        {productsLoading && <Spinner />}
                        {form.getFieldDecorator("productIds", {
                            initialValue: editItem ? editItem.products.map(prod => prod.id) : [],
                            rules: [
                                {
                                    required: true,
                                    message: formatMessage({
                                        id: "requiredField"
                                    })
                                }
                            ]
                        })(<Checkbox.Group>
                            {products.map(product => 
                                <Checkbox key={product.id} value={product.id}>
                                    {product.name}
                                </Checkbox>)}
                        </Checkbox.Group>)}
                    </Form.Item>
                </Form>

                <ErrorMessage error={updateError || createError} />
            </IncidentReasonFormStyle>
        </Modal>
    );
};

export const IncidentReasonForm = Form.create<IncidentReasonFormProps>()(IncidentReasonFormComponent);
