import styled from "styled-components";
import { COLORS } from "@style/colors";

export const CollectionRoundBlockStyle = styled.div`
    width: 400px;
    background-color: ${COLORS.background};
    border-radius: 4px;
    padding: 20px;

    position: relative;

    .icon {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        opacity: 0.4;
        display: block;
        z-index: 1;
        transition: 0.2s;

        &:hover {
            opacity: 1;
        }
    }

    .ant-select {
        width: 100%;
        font-size: 12px;
    }

    .ant-form-item-label {
        line-height: 20px;
        font-size: 12px;
    }

    .ant-form-item {
        margin-bottom: 15px;
    }
`;
