/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ApolloError } from "apollo-client";
import { useState } from "react";
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";

import { CreateCollectionPatternMutation, CreateCollectionPatternMutationVariables } from "@models/graphql";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";

const defaultCreateCollectionPatternOptions: MutationHookOptions<CreateCollectionPatternMutation, CreateCollectionPatternMutationVariables> = {

};

export const useCreateCollectionPattern = (options?: MutationHookOptions<CreateCollectionPatternMutation, CreateCollectionPatternMutationVariables>) => {
    const [hookState, setHookState] = useState<{
        isLoading: boolean;
        data?: CreateCollectionPatternMutation;
        error?: ApolloError;
    }>({
        isLoading: false
    });

    const [mutation] = useMutation<CreateCollectionPatternMutation, CreateCollectionPatternMutationVariables>(GraphQLDocuments.createCollectionPattern);

    const createCollectionPattern = async (variables: CreateCollectionPatternMutationVariables) => {
        setHookState({
            isLoading: true
        });

        try {
            const response = await mutation({
                ...defaultCreateCollectionPatternOptions,
                ...options,
                variables
            });

            setHookState({
                data: response.data,
                isLoading: false
            });

            return response;
        } catch (error) {
            setHookState({
                error,
                isLoading: false
            });
        }
    };

    return {
        isLoading: hookState.isLoading,
        error: hookState.error,
        data: hookState.data,
        createCollectionPattern
    };
};
