import { MutationFetchResult } from "@apollo/react-common";
import { CollectorFormModal } from "@components/collectorFormModal/collectorFormModal";
import { ErrorMessage } from "@components/errorMessage/errorMessage";
import { PageHeader } from "@components/pageHeader/pageHeader";
import { Permission, PERMISSIONS } from "@components/permission/permission";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { useDeleteCollector } from "@graphql/hocs/hooks/useDeleteCollector";
import { useGetCollectors } from "@graphql/hocs/hooks/useGetCollectors";
import { Collector, DeleteCollectorMutation, GetCollectorsQuery } from "@models/graphql";
import { Icon } from "@style/icon";
import { InnerContainer } from "@style/innerContainer";
import { useFormatMessage } from "@utils/intlHook";
import { parseError } from "@utils/parseError";
import { Button, Icon as AntIcon, message, Popconfirm } from "antd";
import Table, { ColumnProps } from "antd/lib/table";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { CollectorsPageStyle } from "./collectorsPageStyle";

export interface CollectorsPageProps {}

export const CollectorsPage: FunctionComponent<CollectorsPageProps> = ({}) => {
    const [deleteId, setDeleteId] = React.useState<string | null>(null);
    const formatMessage = useFormatMessage();
    const [modalVisible, setModalVisible] = React.useState(false);
    const [editItem, setEditItem] = React.useState<Collector | null>(null);
    const {
        collectors,
        collectorsError,
        collectorsLoading
    } = useGetCollectors();

    const { deleteCollector, isLoading: deleteLoading } = useDeleteCollector();

    const columns: ColumnProps<Collector>[] = [
        {
            title: formatMessage({ id: "collectorsPage.firstname" }),
            dataIndex: "firstname",
            key: "firstname"
        },
        {
            title: formatMessage({ id: "collectorsPage.lastname" }),
            dataIndex: "lastname",
            key: "lastname"
        },
        {
            title: formatMessage({ id: "collectorsPage.QR" }),
            dataIndex: "tagCode"
        },
        {
            width: 80,
            render(_, record) {
                const handleDelete = async (): Promise<void> => {
                    try {
                        setDeleteId(record.id);
                        const { error } = await deleteCollector(
                            { id: record.id },
                            {
                                update(
                                    cache,
                                    response: MutationFetchResult<
                                        DeleteCollectorMutation
                                    >
                                ) {
                                    const collectorsData = cache.readQuery<
                                        GetCollectorsQuery
                                    >({
                                        query: GraphQLDocuments.getCollectors
                                    });

                                    if (
                                        collectorsData &&
                                        response.data &&
                                        response.data.deleteCollector
                                    ) {
                                        collectorsData.collectors = collectorsData.collectors.filter(p => p.id !== record.id);

                                        cache.writeQuery<GetCollectorsQuery>({
                                            query:
                                                GraphQLDocuments.getCollectors,
                                            data: collectorsData
                                        });
                                    }
                                }
                            }
                        );

                        if (error) {
                            message.error(parseError(error, formatMessage));
                        } else {
                            message.success(formatMessage({
                                id: "collectorsTable.deleteSuccessful"
                            }));
                        }
                        setDeleteId(null);
                    } catch (error) {
                        message.error(parseError(error, formatMessage));
                    }
                };

                return (
                    <span className="buttons">
                        <Permission requiredPermissions={[PERMISSIONS.collectors.update]}>

                            <Icon
                                onClick={() => {
                                    setEditItem(record);
                                    setModalVisible(true);
                                }}
                                type="pencil"
                            />
                        </Permission>
                        <Permission requiredPermissions={[PERMISSIONS.collectors.delete]}>
                            {record.id === deleteId && deleteLoading ? 
                                <AntIcon type="loading" />
                                : 
                                <Popconfirm
                                    onConfirm={handleDelete}
                                    placement="left"
                                    title={formatMessage({
                                        id: "collectorsPage.confirmDelete"
                                    })}
                                >
                                    <Icon type="trash"></Icon>
                                </Popconfirm>
                            }
                        </Permission>
                    </span>
                );
            }
        }
    ];
    const handleCloseModal = (): void => {
        setModalVisible(false);
        setEditItem(null);
    };

    const handleCreateCollector = (): void => {
        setEditItem(null);
        setModalVisible(true);
    };

    return (
        <CollectorsPageStyle>
            <PageHeader title="collectorsPage.title">
                <Permission requiredPermissions={[PERMISSIONS.collectors.create]}>
                    <Button onClick={handleCreateCollector} type="primary">
                        <FormattedMessage id="collectorsPage.newCollector" />
                    </Button>
                </Permission>
            </PageHeader>
            <CollectorFormModal
                closeCallback={handleCloseModal}
                visible={modalVisible}
                editItem={editItem}
            />

            <InnerContainer>
                <ErrorMessage error={collectorsError} />
                <Table
                    loading={collectorsLoading}
                    dataSource={collectors}
                    columns={columns}
                    rowKey="id"
                />
            </InnerContainer>
        </CollectorsPageStyle>
    );
};
