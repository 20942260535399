import * as React from "react";
import { FunctionComponent } from "react";

import { CsvDownloadModalStyle } from "./csvDownloadModalStyle";
import { Modal, Button, message } from "antd";
import { FormattedMessage } from "react-intl";
import { Icon } from "@style/icon";
import { useFormatMessage } from "@utils/intlHook";
import { saveAs } from "file-saver";

export interface CsvDownloadModalProps {
    url: string;
    fileName: string;
    visible: boolean;
    closeCallback(): void;
}

export const CsvDownloadModal: FunctionComponent<CsvDownloadModalProps> = ({ url, visible, closeCallback, fileName }) => {
    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const formatMessage = useFormatMessage();

    const handleCopy = (): void => {
        if (inputRef.current) {
            inputRef.current.select();
            document.execCommand("copy");

            message.success(formatMessage({ id: "copySuccesful" }));
        }
    };

    const handleDownload = (): void => {
        saveAs(url.replace(/^.*\/csv-files\//, "/csv-files/"), `${fileName}.csv`);
    };

    return <Modal bodyStyle={{ padding: 0 }} onCancel={closeCallback} footer={null} visible={visible}>
        <CsvDownloadModalStyle>
            <header>
                <Icon type="checkmark" /><FormattedMessage id="exportSucceeded" />
            </header>
            <main>
                <h1>
                    <FormattedMessage id="fileLocation" />:
                </h1>
                <a href={url} target="__blank">{url}</a>
                <input readOnly ref={inputRef} type="text" value={url} />
            </main>
            <footer>
                <Button onClick={handleCopy}><FormattedMessage id="copyLink" /></Button>
                <Button onClick={handleDownload} type="primary"><FormattedMessage id="directDownload" /></Button>
            </footer>
        </CsvDownloadModalStyle>
    </Modal>;
}
    ;
