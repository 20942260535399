import * as React from "react";
import { FunctionComponent } from "react";
import { ReportsPageStyle } from "./reportsPageStyle";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Icon } from "@style/icon";
import { InnerContainer } from "@style/innerContainer";

export interface ReportsPageProps {

}

export const ReportsPage: FunctionComponent<ReportsPageProps> = (props: ReportsPageProps) =>
    <ReportsPageStyle>
        <h1><FormattedMessage id="reportsPage.title" /></h1>

        <InnerContainer>
            <Link to="/reports/logistics">
                <Icon type="truck" />
                <FormattedMessage id="reports.logistics" />
            </Link>
            <Link to="/reports/inquiries">
                <Icon type="people" />
                <FormattedMessage id="reports.inquiries" />
            </Link>
            <Link to="/reports/incidents">
                <Icon type="incident" />
                <FormattedMessage id="reports.incidents" />
            </Link>
        </InnerContainer>
    </ReportsPageStyle>
    ;
