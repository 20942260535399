import { createGlobalStyle } from "styled-components";
import { COLORS } from "@style/colors";

export const GlobalStyle = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap');

    * {
        box-sizing: border-box;
    }

    body,
    html,
    #app-container {
        height: 100%;
        font-family: "Open Sans", sans-serif;
        background-color: ${COLORS.background};
        color: ${COLORS.text};

        .ant-table-column-sorter-down.on, .ant-table-column-sorter-up.on {
            color: ${COLORS.text};
        }

        [type="reset"]{
            appearance: none;
        }

        .ant-checkbox-wrapper {
            margin-bottom: 15px;

            &:hover {
                .ant-checkbox + span {
                    color: ${COLORS.textTransparent(1)};
                }
            }

            &.ant-checkbox-wrapper-checked {
                .ant-checkbox + span {
                    color: ${COLORS.textTransparent(1)};
                }
            }

            & + .ant-checkbox-wrapper {
                margin-left: 0;
            }

            .ant-checkbox {

                &.ant-checkbox-checked + span {
                    color: ${COLORS.textTransparent(1)};
                }

                & + span {
                    color: ${COLORS.textTransparent(0.5)};

                    transition: 0.3s;
                    display: inline-flex;
                    justify-content: space-between;
                    align-items: center;
                    width: calc(100% - 16px);
                }
            }
        }
    }

    .capacity-tooltip {
        .ant-tooltip-inner {
            background-color: #fff;
            padding: 0;
        }

        &.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
            border-left-color: white !important;

        }

        .ant-tooltip-arrow {
        }
    }

    .inquiryDeliveryModal {
        .ant-modal-body {
            padding: 0 !important;
        }

        .ant-modal-confirm-body .ant-modal-confirm-content {
            margin-top: 0;
        }

        .ant-modal-confirm-btns {
            display: flex;
            justify-content: flex-end;
            padding: 10px;
            border-top: 1px solid ${COLORS.textTransparent(0.1)};
            width: 100%;

            .ant-btn {
                height: 35px;
                width: 165px;
            }
        }
    }

    .sentry-error-embed-wrapper {
        x-index: 2000 !important;
    }
`;
