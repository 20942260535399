import { Checkbox, Form, Input, InputNumber, message, Modal, Radio, Select, Spin } from "antd";
import { FormComponentProps } from "antd/lib/form";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";

import { useFormatMessage } from "@utils/intlHook";
import { CreateProductInput, DeliveryMethod, GetProductNamesQuery, ScanMoment } from "@models/graphql";
import { useUpdateProduct } from "@graphql/hocs/hooks/useUpdateProduct";
import { useGetProduct } from "@graphql/hocs/hooks/useGetProduct";
import { useGetCardsProducts } from "@graphql/hocs/hooks/useGetCardsProducts";
import { useCreateProduct } from "@graphql/hocs/hooks/useCreateProduct";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { ErrorMessage } from "@components/errorMessage/errorMessage";

import { ProductDetailModalStyle, SubTitle } from "./productDetailModalStyle";

export interface ProductDetailModalProps extends FormComponentProps {
    editId?: string | null;
    visible?: boolean;
    closeCallback(): void;
}

interface FormValues {
    cardsId: string;
    name: string;
    scanMoments: ScanMoment[];
    deliveryMethod: DeliveryMethod;
    daysBeforePickup?: number;
    accessory: boolean;
}

export const ProductDetailModalComponent: FunctionComponent<ProductDetailModalProps> = ({ form, editId, visible, closeCallback }) => {
    const { cardsProducts } = useGetCardsProducts();
    const formatMessage = useFormatMessage();

    const { updateProduct, error: updateError, isLoading: updateLoading } = useUpdateProduct();
    const { createProduct, error: createError, isLoading: createLoading } = useCreateProduct({
        update(cache, response) {
            const productsData = cache.readQuery<GetProductNamesQuery>({ query: GraphQLDocuments.getProductNames });

            if (!productsData || !response.data) {
                return;
            }

            productsData.products.count += 1;
            productsData.products.items.push(response.data.createProduct);

            cache.writeQuery<GetProductNamesQuery>({ data: productsData, query: GraphQLDocuments.getProductNames });
        }
    });

    const { product, productLoading } = useGetProduct({
        variables: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            id: editId!
        },
        skip: !editId
    });

    React.useEffect(() => {
        if (product) {
            form.setFieldsValue({
                deliveryMethod: product.deliveryMethod
            });
        }
    }, [product]);

    const deliveryMethod: DeliveryMethod = form.getFieldValue("deliveryMethod");

    const handleClose = (): void => {
        form.resetFields();
        closeCallback();
    };

    const handleSubmit = (): void => {
        form.validateFields(async (errors, values: FormValues) => {
            if (errors) {
                return;
            }

            const prod: CreateProductInput = {
                cardsId: values.cardsId,
                daysBeforePickup: values.daysBeforePickup,
                name: values.name,
                scanMoments: values.scanMoments || [],
                deliveryMethod: values.deliveryMethod,
                accessory: values.accessory
            };

            if (editId) {
                delete prod.cardsId;
                const { error } = await updateProduct({ id: editId, product: prod });

                if (!error) {
                    handleClose();
                    message.success(formatMessage({ id: "productDetail.updatedSuccessfuly" }));
                }
            } else {
                const { error } = await createProduct({ product: prod });

                if (!error) {
                    handleClose();
                    message.success(formatMessage({ id: "productDetail.createdSuccessfuly" }));
                }
            }
        });
    };

    return (
        <Modal
            cancelText={formatMessage({ id: "cancel" })}
            okText={formatMessage({ id: "save" })}
            visible={visible}
            closable={false}
            width={750}
            okButtonProps={{ loading: updateLoading || createLoading }}
            onOk={handleSubmit}
            onCancel={handleClose}
        >
            <ProductDetailModalStyle>
                <Spin spinning={productLoading}>
                    <Form>
                        <Form.Item label={<FormattedMessage id="productDetail.cardsProduct" />}>
                            {form.getFieldDecorator("cardsId", {
                                initialValue: product && product.id,
                                rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }]
                            })(<Select disabled={!!editId} placeholder={<FormattedMessage id="productDetail.cardsProduct" />}>
                                {cardsProducts.map(cardP =>
                                    <Select.Option value={cardP.id} key={cardP.id}>
                                        {cardP.name}
                                    </Select.Option>)}
                            </Select>)}
                        </Form.Item>
                        <Form.Item label={<FormattedMessage id="productDetail.name" />}>
                            {form.getFieldDecorator("name", {
                                initialValue: product && product.name,
                                rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }]
                            })(<Input placeholder={formatMessage({ id: "productDetail.name" })} />)}
                        </Form.Item>
                        <SubTitle>
                            <FormattedMessage id="productDetail.deliveryMethod" />
                        </SubTitle>
                        <Form.Item>
                            {form.getFieldDecorator("deliveryMethod", {
                                initialValue: product && product.deliveryMethod || DeliveryMethod.None,
                                rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }]
                            })(<Radio.Group>
                                <Radio value={DeliveryMethod.Pickup}>Opgehaald</Radio>
                                <Radio value={DeliveryMethod.Delivery}>Geleverd</Radio>
                                <Radio value={DeliveryMethod.None}>Geen van beide</Radio>
                            </Radio.Group>)}
                        </Form.Item>

                        {deliveryMethod === DeliveryMethod.Pickup &&
                            <Form.Item label={<FormattedMessage id="productDetail.daysBeforePickup" />}>
                                {form.getFieldDecorator("daysBeforePickup", {
                                    initialValue: product && product.daysBeforePickup,
                                    rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }]
                                })(<InputNumber min={1} placeholder={formatMessage({ id: "days" })} />)}

                                <FormattedMessage id="productDetail.daysBeforePickupInfo" tagName="em" />
                            </Form.Item>
                        }

                        <SubTitle>
                            <FormattedMessage id="productDetail.scanMoments" />
                        </SubTitle>
                        <Form.Item>
                            {form.getFieldDecorator("scanMoments", {
                                initialValue: product && product.scanMoments
                            })(<Checkbox.Group>
                                <Checkbox value={ScanMoment.Collection}>
                                    <FormattedMessage id={`scanMoment.${ScanMoment.Collection}`} />
                                </Checkbox>
                                <Checkbox value={ScanMoment.Delivery}>
                                    <FormattedMessage id={`scanMoment.${ScanMoment.Delivery}`} />
                                </Checkbox>
                                <Checkbox value={ScanMoment.Processor}>
                                    <FormattedMessage id={`scanMoment.${ScanMoment.Processor}`} />
                                </Checkbox>
                            </Checkbox.Group>)}
                        </Form.Item>
                        <Form.Item label="Accesoire">
                            <em>
                                Als je dit product aanduid als accesoire, dan wordt dit niet meegeteld als &lsquo;vracht&lsquo; en kan zodoende ook
                                niet opgehaald worden.
                            </em>
                            {form.getFieldDecorator("accessory", {
                                initialValue: product ? product.accessory : false,
                                valuePropName: "checked"
                            })(<Checkbox>
                                <FormattedMessage id="accessory" />
                            </Checkbox>)}
                        </Form.Item>
                        <ErrorMessage error={updateError || createError} />
                    </Form>
                </Spin>
            </ProductDetailModalStyle>
        </Modal>
    );
};

export const ProductDetailModal = Form.create<ProductDetailModalProps>()(ProductDetailModalComponent);
