import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import * as environment from "environment";

export const initErrorTracking = (history: History): void => {
    Sentry.init({
        // enabled: !environment.DEVELOPMENT,
        enabled: true,
        dsn: environment.SENTRY_DSN,
        release: `limburg-dispatcher-app@${process.env.npm_package_version}`,
        // release: `limburg-dispatcher-app@${BUILD_NUMBER}`,
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
            })
        ],
        environment: environment.ENVIRONMENT,

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,

        beforeSend(event) {
            if (event.exception) {
                Sentry.showReportDialog({
                    eventId: event.event_id,
                    title: "Er heeft zich een probleem voorgedaan.",
                    subtitle: "Ons team is op de hoogte gesteld.",
                    subtitle2: "Als je ons kan helpen met extra informatie, kan je hieronder vertellen wat er gebeurd is.",
                    labelName: "Naam",
                    labelEmail: "E-mailadres",
                    labelComments: "Wat is er gebeurd?",
                    labelSubmit: "Feedback verzenden",
                    labelClose: "Sluiten",
                    lang: "nl",
                    successMessage: "Je feedback is verstuurd. Dank je wel !"
                });
            }

            return event;
        }
    });
};
