/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { FixedTruckScheduleOrderMutation, FixedTruckScheduleOrderMutationVariables } from "@models/graphql";

const defaultFixedTruckScheduleOrderOptions: MutationHookOptions<
    FixedTruckScheduleOrderMutation,
    FixedTruckScheduleOrderMutationVariables
> = {};

export const useFixedTruckScheduleOrder = (
    options?: MutationHookOptions<FixedTruckScheduleOrderMutation, FixedTruckScheduleOrderMutationVariables>
) => {
    const [mutation, { data, loading, error }] = useMutation<FixedTruckScheduleOrderMutation, FixedTruckScheduleOrderMutationVariables>(
        GraphQLDocuments.fixedTruckScheduleOrder
    );

    const fixedTruckScheduleOrder = async (variables: FixedTruckScheduleOrderMutationVariables) => {
        try {
            const response = await mutation({
                ...defaultFixedTruckScheduleOrderOptions,
                ...options,
                variables
            });

            return {
                error: response.errors,
                data: response.data
            };
        } catch (err) {
            return {
                error: err
            };
        }
    };

    return {
        isLoading: loading,
        error,
        data,
        fixedTruckScheduleOrder
    };
};
