import styled from "styled-components";

export const RichTextEditorStyle = styled.div`
    border: 1px solid #e7e7e7;
    border-radius: 4px;
    overflow: hidden;

    .toolBar {
        background: #FAFAFA;
        border-radius: 4px 4px 0 0;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 10px;

        .ant-select {
            width: 280px;
            margin-left: auto;
        }
    }

    section.editor {
        display: flex;
        min-height: 200px;
    }



    .DraftEditor-root {
        padding: 15px;
        flex: 1;

        .DraftEditor-editorContainer {
            height: 100%;
        }

        h1 {
            font-size: 1.6rem;
        }

        h2 {
            font-size: 1.4rem;
        }

        h3 {
            font-size: 1.2rem;
        }

        a {
            text-decoration: underline;
            cursor: text;
        }
    }

   
`;

export const Divider = styled.span`
    width: 1px;
    height: 15px;
    background: #c3c3c3;
    margin: 0 15px;
`;
