/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { CreateInquiryMutation, CreateInquiryMutationVariables } from "@models/graphql";

const defaultCreateInquiryOptions: MutationHookOptions<CreateInquiryMutation, CreateInquiryMutationVariables> = {};

export const useCreateInquiry = (options?: MutationHookOptions<CreateInquiryMutation, CreateInquiryMutationVariables>) => {
    const [mutation, { error, loading, data }] = useMutation<CreateInquiryMutation, CreateInquiryMutationVariables>(
        GraphQLDocuments.createInquiry
    );

    const createInquiry = async (variables: CreateInquiryMutationVariables) => {
        try {
            const response = await mutation({
                ...defaultCreateInquiryOptions,
                ...options,
                variables
            });

            return {
                error: undefined,
                response
            };
        } catch (err) {
            return {
                error: err,
                response: undefined
            };
        }
    };

    return {
        isLoading: loading,
        error,
        data,
        createInquiry
    };
};
