import { IncidentTrigger, NotificationTrigger, ReminderTrigger, TriggerFragment } from "@models/graphql";

export const isNotificationTrigger = (trigger: TriggerFragment): trigger is NotificationTrigger =>
    (trigger as NotificationTrigger).__typename === "NotificationTrigger";

export const isReminderTrigger = (trigger: TriggerFragment): trigger is ReminderTrigger =>
    (trigger as ReminderTrigger).__typename === "ReminderTrigger";

export const isIncidentTrigger = (trigger: TriggerFragment): trigger is IncidentTrigger =>
    (trigger as IncidentTrigger).__typename === "IncidentTrigger";
