import { Button, Checkbox, Select, Radio } from "antd";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";

import { parseStringArray } from "@utils/useQueryParameters";
import { useFormatMessage } from "@utils/intlHook";
import { Icon } from "@style/icon";
import { ApplicantType, InquiryStatusType } from "@models/graphql";
import { useGetProductNames } from "@graphql/hocs/hooks/useGetProductNames";
import { useGetMunicipalities } from "@graphql/hocs/hooks/useGetMunicipalities";
import { Spinner } from "@components/spinner/spinner";
import { InquiryStatus } from "@components/inquiryStatus/inquiryStatus";
import { inquiriesTableFilter } from "@components/inquiriesTable/inquiriesTable";

import { InquiryOverviewFilterStyle, SearchBox } from "./inquiryOverviewFilterStyle";
import { RadioChangeEvent } from "antd/lib/radio";

export interface InquiryOverviewFilterProps { }

export const InquiryOverviewFilter: FunctionComponent<InquiryOverviewFilterProps> = () => {
    const { updateParameters: updateFilter, parameters: filter } = inquiriesTableFilter();

    const { municipalities, municipalitiesLoading } = useGetMunicipalities();

    const { products, productsLoading } = useGetProductNames();

    const formatMessage = useFormatMessage();

    const onProductsChange = (value: string[]): void => {
        updateFilter({
            productIds: value,
            page: 1
        });
    };

    const onStatusChange = (value: string[]): void => {
        updateFilter({
            statusses: value as InquiryStatusType[],
            page: 1
        });
    };

    const onMunicipalitiesChange = (value: string[]): void => {
        updateFilter({
            municipalities: value,
            page: 1
        });
    };

    const onApplicantTypeChange = (value: string[]): void => {
        updateFilter({
            applicantTypes: value as ApplicantType[],
            page: 1
        });
    };

    const onSearch = (ev: React.ChangeEvent<HTMLInputElement>): void => {
        updateFilter({
            search: ev.target.value || undefined,
            page: 1
        });
    };

    const handleManualChange = (ev: RadioChangeEvent): void => {
        updateFilter({
            manual: ev.target.value
        });
    };

    const resetFilter = (): void => updateFilter({}, true);

    const notFiltered = React.useMemo(
        () => !filter.applicantTypes && !filter.municipalities && !filter.productIds && !filter.statusses && !filter.search && filter.manual === undefined,
        [filter]
    );

    return (
        <InquiryOverviewFilterStyle>
            {!notFiltered &&
                <Button onClick={resetFilter}>
                    <Icon type="reset" /> Reset filter
                </Button>
            }
            <section>
                <SearchBox>
                    <Icon type="search" />
                    <input value={filter.search || ""} onChange={onSearch} placeholder={formatMessage({ id: "inquiryOverviewFilter.search" })} />
                </SearchBox>
            </section>
            <section>
                <FormattedMessage id="inquiryOverviewFilter.products" tagName="h1" />
                {productsLoading && <Spinner />}
                <Checkbox.Group onChange={onProductsChange} value={parseStringArray(filter.productIds)}>
                    {products.map(product =>
                        <Checkbox key={product.id} value={product.id}>
                            {product.name}
                        </Checkbox>)}
                </Checkbox.Group>
            </section>
            <section>
                <FormattedMessage id="inquiryOverviewFilter.manual" tagName="h1" />
                <Radio.Group value={filter.manual} onChange={handleManualChange}>
                    <Radio value={true} >
                        <FormattedMessage id="yes" />
                    </Radio>
                    <Radio value={false} >
                        <FormattedMessage id="no" />
                    </Radio>
                </Radio.Group>
            </section>
            <section>
                <FormattedMessage id="inquiryOverviewFilter.status" tagName="h1" />
                <Checkbox.Group value={parseStringArray(filter.statusses)} onChange={onStatusChange}>
                    <Checkbox value={InquiryStatusType.Planned}>
                        <FormattedMessage id={`inquiryStatusType.${InquiryStatusType.Planned}`} />{" "}
                        <InquiryStatus status={InquiryStatusType.Planned} />
                    </Checkbox>
                    <Checkbox value={InquiryStatusType.Delivered}>
                        <FormattedMessage id={`inquiryStatusType.${InquiryStatusType.Delivered}`} />{" "}
                        <InquiryStatus status={InquiryStatusType.Delivered} />
                    </Checkbox>
                    <Checkbox value={InquiryStatusType.Processed}>
                        <FormattedMessage id={`inquiryStatusType.${InquiryStatusType.Processed}`} />{" "}
                        <InquiryStatus status={InquiryStatusType.Processed} />
                    </Checkbox>
                    <Checkbox value={InquiryStatusType.Incident}>
                        <FormattedMessage id={`inquiryStatusType.${InquiryStatusType.Incident}`} />{" "}
                        <InquiryStatus status={InquiryStatusType.Incident} />
                    </Checkbox>
                    <Checkbox value={InquiryStatusType.Processing}>
                        <FormattedMessage id={`inquiryStatusType.${InquiryStatusType.Processing}`} />{" "}
                        <InquiryStatus status={InquiryStatusType.Processing} />
                    </Checkbox>
                    <Checkbox value={InquiryStatusType.ToBeRescheduled}>
                        <FormattedMessage id={`inquiryStatusType.${InquiryStatusType.ToBeRescheduled}`} />{" "}
                        <InquiryStatus status={InquiryStatusType.ToBeRescheduled} />
                    </Checkbox>
                    <Checkbox value={InquiryStatusType.OnHold}>
                        <FormattedMessage id={`inquiryStatusType.${InquiryStatusType.OnHold}`} />
                        <InquiryStatus status={InquiryStatusType.OnHold} />
                    </Checkbox>
                    <Checkbox value={InquiryStatusType.WaitingForPayment}>
                        <FormattedMessage id={`inquiryStatusType.${InquiryStatusType.WaitingForPayment}`} />
                        <InquiryStatus status={InquiryStatusType.WaitingForPayment} />
                    </Checkbox>
                    <Checkbox value={InquiryStatusType.Canceled}>
                        <FormattedMessage id={`inquiryStatusType.${InquiryStatusType.Canceled}`} />{" "}
                        <InquiryStatus status={InquiryStatusType.Canceled} />
                    </Checkbox>
                </Checkbox.Group>
            </section>
            <section>
                <FormattedMessage id="inquiryOverviewFilter.municipalities" tagName="h1" />
                <Select
                    value={parseStringArray(filter.municipalities)}
                    onChange={onMunicipalitiesChange}
                    loading={municipalitiesLoading}
                    placeholder={formatMessage({ id: "inquiryOverviewFilter.municipalities" })}
                    filterOption={(val, el) => (el.props.children as string).toLowerCase().includes(val.toLowerCase())}
                    mode="multiple"
                >
                    {municipalities.map(m =>
                        <Select.Option key={m.id} value={m.id}>
                            {m.name}
                        </Select.Option>)}
                </Select>
            </section>
            <section>
                <FormattedMessage id="inquiryOverviewFilter.applicantType" tagName="h1" />
                <Checkbox.Group value={parseStringArray(filter.applicantTypes)} onChange={onApplicantTypeChange}>
                    <Checkbox value={ApplicantType.Civilian}>
                        <FormattedMessage id={`applicantType.${ApplicantType.Civilian}`} />{" "}
                    </Checkbox>
                    <Checkbox value={ApplicantType.GroupPurchase}>
                        <FormattedMessage id={`applicantType.${ApplicantType.GroupPurchase}`} />{" "}
                    </Checkbox>
                    <Checkbox value={ApplicantType.Association}>
                        <FormattedMessage id={`applicantType.${ApplicantType.Association}`} />{" "}
                    </Checkbox>
                    <Checkbox value={ApplicantType.Municipality}>
                        <FormattedMessage id={`applicantType.${ApplicantType.Municipality}`} />{" "}
                    </Checkbox>
                    <Checkbox value={ApplicantType.NonDomiciledCitizen}>
                        <FormattedMessage id={`applicantType.${ApplicantType.NonDomiciledCitizen}`} />{" "}
                    </Checkbox>
                </Checkbox.Group>
            </section>
        </InquiryOverviewFilterStyle>
    );
};
