import styled from "styled-components";
import { InquiryStatusStyle } from "@components/inquiryStatus/inquiryStatusStyle";

export const InquiriesTableStyle = styled.div`
    flex: 1;

    ${InquiryStatusStyle} {
        margin-right: 15px;
    }

    .ant-table-row {
        cursor: pointer;
    }

    .product-item {
        font-size: 12px;
        
        &:not(:last-of-type) {
            margin-bottom: 5px;
        }

        em {
            font-style: normal;
            opacity: 0.6;
        }
    }

    small {
        font-size: 14px;
        opacity: 0.4;
    }
`;

