import { Button } from "antd";
import TextArea from "antd/lib/input/TextArea";
import * as React from "react";
import { FunctionComponent, useState } from "react";
import { FormattedMessage } from "react-intl";

import { StepIcon } from "@pages/stepIcon/stepIcon";
import { IncidentFeedback, InquiryStatusType, Maybe } from "@models/graphql";
import { useCloseIncident } from "@graphql/hocs/hooks/useCloseIncident";
import { useAddIncidentFeedback } from "@graphql/hocs/hooks/useAddIncidentFeedback";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { Uploader } from "@components/uploader/uploader";
import { ErrorMessage } from "@components/errorMessage/errorMessage";

import { InquiryIncidentFeedbackStyle } from "./inquiryIncidentFeedbackStyle";

export interface InquiryIncidentDetailProps {
    incidentId: string;
    inquiryId: string;
    feedback?: Maybe<IncidentFeedback>;
    readonly?: boolean;
}

export const InquiryIncidentFeedback: FunctionComponent<InquiryIncidentDetailProps> = ({ feedback, incidentId, inquiryId, readonly }) => {
    const [feedbackMessage, setFeedbackMessage] = useState<string>(feedback ? feedback.message : "");
    const [feedbackPhotos, setFeedbackPhotos] = useState<string[]>(feedback ? feedback.images : []);

    const [partOneDone, setPartOneDone] = useState(!!feedbackMessage);
    const [partTwoDone, setPartTwoDone] = useState(!!feedbackPhotos.length);

    const { addIncidentFeedback, isLoading: feedbackLoading, error: feedbackError } = useAddIncidentFeedback({
        refetchQueries: [
            { query: GraphQLDocuments.getInquiryDetail, variables: { id: inquiryId } },
            { query: GraphQLDocuments.getInquiriesCount, variables: { filter: { statusses: [InquiryStatusType.Incident] } } }
        ],
        awaitRefetchQueries: true
    });
    const { closeIncident, isLoading: closeLoading, error: closeError } = useCloseIncident({
        refetchQueries: [
            {
                query: GraphQLDocuments.getInquiryDetail,
                variables: {
                    id: inquiryId
                }
            },
            {
                query: GraphQLDocuments.getInquiriesOverview, variables: {
                    filter: {
                        statusses: [InquiryStatusType.ToBeRescheduled]
                    }
                }
            }
        ],
        awaitRefetchQueries: true
    });

    const feedbackChanged = ({ target }): void => {
        const value = target.value;

        setPartOneDone(!!value);
        setFeedbackMessage(value);
    };

    const imageHandler = (images: string[]): void => {
        setPartTwoDone(!!images.length);
        setFeedbackPhotos(images);
    };

    const onSave = async (): Promise<void> => {
        await addIncidentFeedback({
            incidentId,
            images: feedbackPhotos,
            message: feedbackMessage
        });
    };

    const onClose = async (): Promise<void> => {
        await closeIncident({
            incidentId
        });
    };

    return (
        <InquiryIncidentFeedbackStyle>
            <section>
                <h1 className={partOneDone ? "done" : ""}>
                    <StepIcon done={partOneDone} step={1} />
                    <FormattedMessage id="incidentDetail.feedbackTitle" />
                </h1>
                <main>
                    <TextArea rows={4} value={feedbackMessage} onChange={feedbackChanged} readOnly={readonly} />
                </main>

                <h1 className={partTwoDone ? "done" : ""}>
                    <StepIcon done={partTwoDone} step={2} />
                    <FormattedMessage id="incidentDetail.photoTitle" />
                </h1>
                <main>
                    <Uploader imageHandler={imageHandler} value={feedbackPhotos} readonly={readonly} />
                </main>
                { !readonly && <main className="confirm">
                    <Button onClick={onSave} loading={feedbackLoading} disabled={!partOneDone}>
                        Feedback opslaan
                    </Button>
                    <Button
                        onClick={onClose}
                        loading={closeLoading}
                        disabled={!feedback || !feedback.message || feedbackLoading}
                    >
                        Incident afwerken
                    </Button>
                </main> }
                <ErrorMessage error={feedbackError} />
                <ErrorMessage error={closeError} />
            </section>
        </InquiryIncidentFeedbackStyle>
    );
};
