import { Action } from "@globalState/actions/action";
import { isUpdatePlanningDate } from "@globalState/actions/mainActions/updatePlanningDate";
import { Reducer } from "react";
import * as moment from "moment";
import { DATE_FORMAT } from "@utils/consts";

export interface MainReducerState {
    planningFromDate: string;
    planningToDate: string;
}

export const initialMainState: MainReducerState = {
    planningFromDate: moment().startOf("w").format(DATE_FORMAT),
    planningToDate: moment().endOf("w").format(DATE_FORMAT)
};

export const mainReducer: Reducer<MainReducerState, Action> = (state: MainReducerState = initialMainState, action: Action) => {
    if (isUpdatePlanningDate(action)) {

        return {
            planningFromDate: action.fromDate,
            planningToDate: action.toDate
        };

    }

    return state;
};
