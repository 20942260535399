import * as React from "react";
import { FunctionComponent } from "react";
import { Route } from "react-router";

import { TruckScheduleTable } from "@components/truckScheduleTable/truckScheduleTable";
import { TruckScheduleHeader } from "@components/truckScheduleHeader/truckScheduleHeader";
import { TruckMapContainer } from "@components/truckMapContainer/truckMapContainer";

import { TruckSchedulePageStyle } from "./truckSchedulePageStyle";

export interface TruckSchedulePageProps {

}

export const TruckSchedulePage: FunctionComponent<TruckSchedulePageProps> = ({ }) =>
    <TruckSchedulePageStyle>
        <TruckScheduleHeader />

        <Route path="/planning/:truckId/:date" exact component={TruckScheduleTable} />
        <Route path="/planning/:truckId/:date/map" exact component={TruckMapContainer} />
    </TruckSchedulePageStyle>;

