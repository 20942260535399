/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { UpdateProcessorMutation, UpdateProcessorMutationVariables } from "@models/graphql";

const defaultUpdateProcessorOptions: MutationHookOptions<UpdateProcessorMutation, UpdateProcessorMutationVariables> = {

};

export const useUpdateProcessor = (options?: MutationHookOptions<UpdateProcessorMutation, UpdateProcessorMutationVariables>) => {
    const [mutation, { data, loading, error }] = useMutation<UpdateProcessorMutation, UpdateProcessorMutationVariables>(GraphQLDocuments.updateProcessor);

    const updateProcessor = async (variables: UpdateProcessorMutationVariables) => {
        try {
            const response = await mutation({
                ...defaultUpdateProcessorOptions,
                ...options,
                variables
            });

            return {
                error: response.errors,
                data: response.data
            };
        } catch (err) {
            return {
                error: err
            };
        }
    };

    return {
        isLoading: loading,
        error,
        data,
        updateProcessor
    };
};
