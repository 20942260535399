import * as React from "react";
import { FunctionComponent } from "react";
import { SearchBarStyle } from "./searchBarStyle";
import { planningParams } from "@pages/planningPage/planningPage";
import { Icon } from "@style/icon";
import { useFormatMessage } from "@utils/intlHook";

export interface SearchBarProps {

}

export const SearchBar: FunctionComponent<SearchBarProps> = () => {
    const { updateParameters, parameters } = planningParams();
    const formatMessage = useFormatMessage();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault();

        updateParameters({
            search: e.currentTarget.value
        });
    };

    return (
        <SearchBarStyle>
            <Icon type="search" /> <input type="text" onChange={handleChange} placeholder={formatMessage({ id: "inquiryOverviewFilter.search" })} value={parameters.search} />
        </SearchBarStyle>
    );
};
