import { ErrorMessage } from "@components/errorMessage/errorMessage";
import { Permission, PERMISSIONS } from "@components/permission/permission";
import { TruckTypeFormModal } from "@components/truckTypeFormModal/truckTypeFormModal";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { useDeleteTruckType } from "@graphql/hocs/hooks/useDeleteTruckType";
import { useGetTruckTypes } from "@graphql/hocs/hooks/useGetTruckTypes";
import { GetTruckTypesQuery, TruckType, TruckTypeFragment } from "@models/graphql";
import { Icon } from "@style/icon";
import { TableButtons } from "@style/tableButtons";
import { useFormatMessage } from "@utils/intlHook";
import { parseError } from "@utils/parseError";
import { Button, Icon as AntIcon, message, Popconfirm, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { TruckTypesTableStyle } from "./truckTypesTableStyle";

export interface TruckTypesTableProps {

}

export const TruckTypesTable: FunctionComponent<TruckTypesTableProps> = ({ }) => {
    const formatMessage = useFormatMessage();
    const [modalVisible, setModalVisible] = React.useState(false);
    const [editItem, setEditItem] = React.useState<TruckType | null>(null);
    const [deleteItemId, setDeleteItemId] = React.useState<string | null>(null);
    let deletedItem: string | null = null;

    const { truckTypes, truckTypesError, truckTypesLoading } = useGetTruckTypes();
    const { deleteTruckType } = useDeleteTruckType({
        update(cache, response) {
            if (!response.data || !response.data.deleteTruckType.succeeded) {
                return;
            }

            const trucksTypesData = cache.readQuery<GetTruckTypesQuery>({ query: GraphQLDocuments.getTruckTypes });

            if (!trucksTypesData) {
                return;
            }

            trucksTypesData.truckTypes.items = trucksTypesData.truckTypes.items.filter(truckType => truckType.id !== deletedItem);

            cache.writeQuery<GetTruckTypesQuery>({
                data: trucksTypesData,
                query: GraphQLDocuments.getTruckTypes
            });
        }
    });

    const handleDeleteTruckType = async (truckTypeId: string): Promise<void> => {
        setDeleteItemId(truckTypeId);
        // This one is for cache update purposes, the state has not updated when this deleteTruck is called; So using a local variable instead.
        deletedItem = truckTypeId;
        const { error } = await deleteTruckType({ id: truckTypeId });

        if (error) {
            message.error(parseError(error, formatMessage));
        } else {
            message.success(formatMessage({ id: "trucksTable.deleteSuccessful" }));
        }
    };

    const columns: ColumnProps<TruckTypeFragment>[] = [
        {
            key: "name",
            dataIndex: "name",
            title: <FormattedMessage id="truckTypesTable.name" />
        },
        {
            key: "buttons",
            width: 120,
            render(_, record) {
                return <TableButtons>
                    <Permission requiredPermissions={[PERMISSIONS.trucksTypes.update]}>
                        <Icon type="pencil" onClick={() => {
                            setEditItem(record);
                            setModalVisible(true);
                        }}
                        />
                    </Permission>
                    <Permission requiredPermissions={[PERMISSIONS.trucksTypes.delete]}>
                        <Popconfirm placement="left" onConfirm={() => handleDeleteTruckType(record.id)} title={<FormattedMessage id="truckTypesTable.deleteConfirm" />}>
                            {deleteItemId === record.id ? <AntIcon type="loading" /> : <Icon type="trash" />}
                        </Popconfirm>
                    </Permission>
                </TableButtons>;
            }
        }
    ];

    const handleClose = (): void => {
        setEditItem(null);
        setModalVisible(false);
    };

    return (
        <TruckTypesTableStyle>
            <Permission requiredPermissions={[PERMISSIONS.trucksTypes.create]}>
                <Button type="primary" onClick={() => setModalVisible(true)}>
                    +&nbsp;<FormattedMessage id="truckTypesTable.createTruckType" />
                </Button>
            </Permission>
            <ErrorMessage error={truckTypesError} />
            <TruckTypeFormModal
                visible={modalVisible}
                editItem={editItem}
                closeCallback={handleClose}
            />
            <Table
                loading={truckTypesLoading}
                dataSource={truckTypes}
                columns={columns}
                rowKey="id"
                pagination={false}
            />
        </TruckTypesTableStyle>
    );
};
