import { StepIconStyle } from "@pages/stepIcon/stepIconStyle";
import { COLORS } from "@style/colors";
import styled from "styled-components";

export const ChangeCollectionRoundModalStyle = styled.div`
    .ant-radio-group {
        display: block;
    }

    .ant-alert {
        margin-top: 15px;
    }

    .ant-radio-wrapper {
        margin-bottom: 15px;
    }

    section {
        background: #ffffff;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        margin-bottom: 30px;
        overflow: hidden;

        ${StepIconStyle} {
            margin-right: 15px;
        }

        main {
            padding: 25px;

            &.split {
                display: flex;
            }

            &.split > div {
                width: 50%;

                &:first-of-type {
                    margin-right: 25px;
                }
            }
            .ant-radio-wrapper {
                width: 100%;
                display: flex;
                align-items: center;
                margin-top: 10px;
            }

            .ant-radio-group {
                display: block;
            }

            span.ant-radio + * {
                flex: 1;
            }

            .ant-checkbox-wrapper {
                margin-bottom: 0 !important;
            }

            /* i {
                font-size: 12px;
                opacity: 0.8;
                padding: 10px 0;
                display: block;
                line-height: 130%;
            } */
        }

        h1 {
            display: block;
            width: 100%;
            height: 45px;
            display: flex;
            align-items: center;
            padding: 0 15px;
            background-color: ${COLORS.lightGrey};
            color: ${COLORS.text};
            transition: 0.3s;

            &.done {
                background-color: ${COLORS.primary};
                color: white;
            }
        }

        .loadMore {
            cursor: pointer;
            color: ${COLORS.primary};

            &:hover {
                opacity: 0.8;
            }
        }
    }
`;
