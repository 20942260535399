import styled from "styled-components";

export const MapTaskStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 16px;
    width: 40px;
    height: 40px;
    transform: translate(-20px, -20px);
    border-radius: 100%;
    background: ${(props: { processor?: boolean }) => props.processor ? "#F5F5F5" : "#96B301"};
    border: 3px solid #ffffff;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);

    img {
        width: 20px;
    }
`;
