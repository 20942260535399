import { ChangeCollectionRoundModal } from "@components/changeCollectionRoundModal/changeCollectionRoundModal";
import { Error } from "@components/error/error";
import { InquiryCancelModal } from "@components/inquiryCancelModal/inquiryCancelModal";
import { InquiryIncident } from "@components/inquiryIncident/inquiryIncident";
import { InquiryIncidentModal } from "@components/inquiryIncidentModal/inquiryIncidentModal";
import { InquiryInformationSidebar } from "@components/inquiryInformationSidebar/inquiryInformationSidebar";
import { InquiryOnholdModal } from "@components/inquiryOnholdModal/inquiryOnholdModal";
import { InquiryStatusHistory } from "@components/inquiryStatusHistory/inquiryStatusHistory";
import { PageHeader } from "@components/pageHeader/pageHeader";
import { Permission, PERMISSIONS } from "@components/permission/permission";
import { Spinner } from "@components/spinner/spinner";
import {
    UpdateInquiryPickupAddressModal
} from "@components/updateInquiryPickupAddressModal/updateInquiryPickupAddressModal";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { useGetInquiryDetail } from "@graphql/hocs/hooks/useGetInquiryDetail";
import { useInquiryProcessed } from "@graphql/hocs/hooks/useInquiryProcessed";
import { useInquiryToBeRescheduled } from "@graphql/hocs/hooks/useInquiryToBeRescheduled";
import { GetInquiryDetailQuery, InquiryOverviewFragment, InquiryStatusType } from "@models/graphql";
import { useFormatMessage } from "@utils/intlHook";
import { parseError } from "@utils/parseError";
import { useQuery, useUpdateQuery } from "@utils/routerHook";
import { Alert, Button } from "antd";
import { NormalizedCache } from "apollo-cache-inmemory";
import * as React from "react";
import { FunctionComponent, useState } from "react";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps } from "react-router";

import { InquiryDetailPageStyle } from "./inquiryDetailPageStyle";

export interface InquiryDetailPageProps extends RouteComponentProps<{ inquiryId: string }> { }

export const InquiryDetailPage: FunctionComponent<InquiryDetailPageProps> = ({ match }: InquiryDetailPageProps) => {
    const { inquiryId } = match.params;
    const formatMessage = useFormatMessage();
    const updateUrlQuery = useUpdateQuery({ replace: true });
    const urlQuery = useQuery();
    const [cancelModalVisible, setCancelModalVisible] = useState<boolean>(false);
    const [onholdModalVisible, setOnholdModalVisible] = useState<boolean>(false);
    const [incidentModalVisible, setIncidentModalVisible] = useState<boolean>(false);
    const [rescheduleModalVisible, setRescheduleModalVisible] = useState<boolean>(false);

    const { inquiry, inquiryLoading, inquiryError, originalError } = useGetInquiryDetail({
        variables: {
            id: inquiryId
        }
    });

    const { inquiryToBeRescheduled, isLoading: toBeRescheduledLoading, error: toBeRescheduledError } = useInquiryToBeRescheduled({
        update(cache, response) {
            if (!response || !response.data || !response.data.inquiryToBeRescheduled) {
                return;
            }
            const data: NormalizedCache = (cache as any).data;

            Object.keys((data as any).data).forEach(key => key.match(/^CollectionRound/) && data.delete(key));
            Object.keys((data as any).data).forEach(key => key.match(/^\$ROOT_QUERY\.collectionRounds/) && data.delete(key));

            const inqFragment = cache.readFragment<InquiryOverviewFragment>({
                fragment: GraphQLDocuments.inquiryOverview,
                fragmentName: "inquiryOverview",
                id: `Inquiry:${inquiryId}`
            });

            if (inqFragment) {
                inqFragment.currentStatus = InquiryStatusType.ToBeRescheduled;
                inqFragment.lastUpdated = new Date().toISOString();

                cache.writeFragment<InquiryOverviewFragment>({
                    fragment: GraphQLDocuments.inquiryOverview,
                    fragmentName: "inquiryOverview",
                    id: `Inquiry:${inquiryId}`,
                    data: inqFragment
                });
            }
        },
        refetchQueries: [{ query: GraphQLDocuments.getInquiryDetail, variables: { id: inquiryId } }],
        awaitRefetchQueries: true
    });

    const { inquiryProcessed, isLoading: processedLoading, error: processedError } = useInquiryProcessed({
        update(cache, response) {
            if (!response || !response.data || !response.data.inquiryProcessed) {
                return;
            }
            const data: NormalizedCache = (cache as any).data;

            Object.keys((data as any).data).forEach(key => key.match(/^CollectionRound/) && data.delete(key));
            Object.keys((data as any).data).forEach(key => key.match(/^\$ROOT_QUERY\.collectionRounds/) && data.delete(key));

            const inqFragment = cache.readFragment<InquiryOverviewFragment>({
                fragment: GraphQLDocuments.inquiryOverview,
                fragmentName: "inquiryOverview",
                id: `Inquiry:${inquiryId}`
            });

            if (inqFragment) {
                inqFragment.currentStatus = InquiryStatusType.Processed;
                inqFragment.lastUpdated = new Date().toISOString();

                cache.writeFragment<InquiryOverviewFragment>({
                    fragment: GraphQLDocuments.inquiryOverview,
                    fragmentName: "inquiryOverview",
                    id: `Inquiry:${inquiryId}`,
                    data: inqFragment
                });
            }
        },
        refetchQueries: [{ query: GraphQLDocuments.getInquiryDetail, variables: { id: inquiryId } }],
        awaitRefetchQueries: true
    });

    let incident: GetInquiryDetailQuery["inquiry"]["statusHistory"][0]["incident"] | undefined;

    if (inquiry && inquiry.statusHistory[0] && inquiry.statusHistory[0].type === InquiryStatusType.Incident) {
        incident = inquiry.statusHistory[0].incident;
    } else if (inquiry && inquiry.statusHistory[0] && inquiry.statusHistory[0].type === InquiryStatusType.Processing) {
        incident = inquiry.statusHistory[1].incident;
    }

    const handleCloseRescheduleModal = (): void => {
        setRescheduleModalVisible(false);
    };

    const handleOpenRescheduleModal = (): void => {
        setRescheduleModalVisible(true);
    };

    const setInquiryAsDelivered = async (id: string): Promise<void> => {
        try {
            await inquiryToBeRescheduled({ inquiryId: id });
        } catch(error) {
            console.error(error);
        }
    };

    const setInquiryAsProcessed = async (id: string): Promise<void> => {
        try {
            await inquiryProcessed({ inquiryId: id , timestamp: new Date().toISOString() });
        } catch(error) {
            console.error(error);
        }
    };

    const renderButtons = (): null | JSX.Element => {
        if (!inquiry || inquiry.currentStatus === InquiryStatusType.Canceled) {
            return null;
        }

        if(inquiry.currentStatus === InquiryStatusType.Processed) {
            return(	<Button
                loading={toBeRescheduledLoading}
                onClick={() => {
                    setInquiryAsDelivered(inquiry.id);
                }}
            >
                <FormattedMessage id="inquiryDetail.processedToReschedule" />
            </Button>);
        }

        return (
            <>
                <Permission requiredPermissions={[PERMISSIONS.inquiries.cancel]}>
                    <Button
                        onClick={() => {
                            setCancelModalVisible(true);
                        }}
                    >
                        <FormattedMessage id="cancel" />
                    </Button>
                </Permission>
                {inquiry && inquiry.currentStatus !== InquiryStatusType.OnHold && inquiry.currentStatus !== InquiryStatusType.Incident &&
                    <Button
                        onClick={() => {
                            setOnholdModalVisible(true);
                        }}
                    >
                        <FormattedMessage id="inquiryDetail.onHold" />
                    </Button>
                }
                {inquiry && inquiry.currentStatus === InquiryStatusType.Planned &&
                    <Button
                        type="danger"
                        onClick={() => {
                            setIncidentModalVisible(true);
                        }}
                    >
                        <FormattedMessage id="inquiryDetail.createIncident" />
                    </Button>
                }
                {inquiry && inquiry.currentStatus === InquiryStatusType.Incident &&
                    <Button
                        type="primary"
                        loading={processedLoading}
                        onClick={() => {
                            setInquiryAsProcessed(inquiry.id);
                        }}
                    >
                        <FormattedMessage id="inquiryDetail.markAsProcessed" />
                    </Button>
                }
                {inquiry && inquiry.currentStatus !== InquiryStatusType.Incident &&
                    <Button onClick={handleOpenRescheduleModal} type="primary">
                        {inquiry.currentStatus === InquiryStatusType.OnHold ?
                            <FormattedMessage id="inquiryDetail.plan" />
                            :
                            <FormattedMessage id="inquiryDetail.reschedule" />
                        }
                    </Button>
                }
            </>
        );
    };

    if (toBeRescheduledError) {
        return (
            <InquiryDetailPageStyle>
                <Error errorMessage={parseError(toBeRescheduledError, formatMessage)} originalError={toBeRescheduledError} />
            </InquiryDetailPageStyle>
        );
    }

    if (inquiryError) {
        return (
            <InquiryDetailPageStyle>
                <Error errorMessage={parseError(inquiryError, formatMessage)} originalError={originalError} />
            </InquiryDetailPageStyle>
        );
    }

    if (processedError) {
        return (
            <InquiryDetailPageStyle>
                <Error errorMessage={parseError(processedError, formatMessage)} originalError={processedError} />
            </InquiryDetailPageStyle>
        );
    }

    return (
        <InquiryDetailPageStyle>
            <ChangeCollectionRoundModal visible={rescheduleModalVisible} inquiry={inquiry} closeCallback={handleCloseRescheduleModal} />
            <UpdateInquiryPickupAddressModal visible={!!urlQuery.updateInquiry} inquiry={inquiry} closeCallback={() => updateUrlQuery({ updateInquiry: undefined })} />

            <PageHeader dontTranslateTitle backArrow goBack title={inquiry ? inquiry.orderNumber : ""} link="/inquiries" shadow={true}>
                {renderButtons()}
            </PageHeader>

            <div className="container">
                <InquiryInformationSidebar loading={inquiryLoading} inquiryError={inquiryError} inquiry={inquiry} />

                {!inquiry ?
                    <div className="main">
                        <Spinner big />
                    </div>
                    :
                    <div className="main">
                        {inquiry.currentStatus === InquiryStatusType.OnHold &&
                            <Alert type="info" showIcon message={<FormattedMessage id="inquiryDetail.onHoldInfo" />} />
                        }
                        {incident &&
                            <InquiryIncident
                                products={inquiry.products}
                                inquiryId={inquiryId}
                                incident={incident}
                                timestamp={inquiry.statusHistory[0].timestamp}
                            ></InquiryIncident>
                        }
                        <InquiryStatusHistory inquiryId={inquiryId} history={inquiry.statusHistory}></InquiryStatusHistory>
                    </div>
                }
            </div>

            <InquiryCancelModal
                visible={cancelModalVisible}
                closeCallback={() => {
                    setCancelModalVisible(false);
                }}
                inquiryId={inquiryId}
            />
            <InquiryOnholdModal
                visible={onholdModalVisible}
                closeCallback={() => {
                    setOnholdModalVisible(false);
                }}
                inquiryId={inquiryId}
            />
            <InquiryIncidentModal
                visible={incidentModalVisible}
                closeCallback={() => {
                    setIncidentModalVisible(false);
                }}
                inquiryId={inquiryId}
            />
        </InquiryDetailPageStyle>
    );
};
