import { DataProxy } from "apollo-cache";

export class ApolloUtils {

    public static deleteTypenamesFromCache(proxy: DataProxy, typenames: string[]): void {
        const data = (proxy as any).data;

        Object.keys((data as any).data).forEach(key => {
            const typename = (data as any).data[key].__typename;

            if (typenames.includes(typename)) {
                data.delete(key);
            }
        });
    }
}
