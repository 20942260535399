import { InquiriesTable } from "@components/inquiriesTable/inquiriesTable";
import { InquiryOverviewFilter } from "@components/inquiryOverviewFilter/inquiryOverviewFilter";
import { PageHeader } from "@components/pageHeader/pageHeader";
import { Permission, PERMISSIONS } from "@components/permission/permission";
import { InnerContainer } from "@style/innerContainer";
import { Button } from "antd";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { InquiryOverviewPageStyle } from "./inquiryOverviewPageStyle";

export interface InquiryOverviewPageProps {}

export const InquiryOverviewPage: FunctionComponent<InquiryOverviewPageProps> = () => (
    <InquiryOverviewPageStyle>
        <PageHeader title="inquiryOverview.title">
            <Permission requiredPermissions={[PERMISSIONS.inquiries.create]}>
                <Link to="/inquiries/create">
                    <Button type="primary">
                        <FormattedMessage id="inquiryOverview.newInquiry" />
                    </Button>
                </Link>
            </Permission>
        </PageHeader>
        <InnerContainer>
            <InquiryOverviewFilter />
            <InquiriesTable />
        </InnerContainer>
    </InquiryOverviewPageStyle>
);
