import { createContext, FunctionComponent, useContext, useState } from "react";
import * as React from "react";

interface UnplannableDaysContext {
    unplannableDays: string[];

    setUnplannableDays(dates: string[]): void;
}

const UnplannableDaysContext = createContext<UnplannableDaysContext>({} as any);

export const UnplannableDaysProvider: FunctionComponent = ({ children }) => {
    const [unplannableDays, setUnplannableDays] = useState<string[]>([]);

    const context: UnplannableDaysContext = {

        unplannableDays,
        setUnplannableDays
    };

    return <UnplannableDaysContext.Provider value={context}>{children}</UnplannableDaysContext.Provider>;
};

export const useUnplannableDays = (): UnplannableDaysContext => useContext(UnplannableDaysContext);
