
import styled from "styled-components";
import { COLORS } from "@style/colors";

export const InquiryIncidentDetailStyle = styled.span`
    flex: 1;
    margin-right: 10px;

    section {
        background: #FFFFFF;
        box-shadow: 0 1px 1px 0 rgba(0,0,0,0.15);
        border-radius: 4px;
        margin-bottom: 30px;
        overflow: hidden;

        p {
            line-height: 2;
        }

        .images {
            margin-bottom: 15px;
            display: flex;
            img {
                display: block;
                max-height: 100px;
            }
        }

        main {
            padding: 15px;

            .ant-radio-wrapper {
                margin-bottom: 15px;
            }

            .ant-checkbox-wrapper {
                margin-bottom: 0 !important;
            }

        }


        .newCommentForm form {
            display: flex;

            button {
                margin-left: 10px
            }
        }

        h1 {
            width: 100%;
            min-height: 45px;
            display: flex;
            align-items: center;
            padding: 0 15px;
            background-color: ${COLORS.error};
            color: white;

            flex-wrap: wrap;

            .commentDate {
                margin-left: auto;
            }

            .ant-avatar {
                background-color: white;
                margin-right: 20px;
                color: ${COLORS.error};
            }
        }

        h2 {
            width: calc(100% + 30px);
            margin-left: -15px;
            height: 45px;
            display: flex;
            align-items: center;
            padding: 0 15px;
            background-color: ${COLORS.lightGrey};
            color: ${COLORS.primary};

            .commentDate {
                color: gray;
                margin-left: auto;
            }
        }
    }
`;
