/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { GetProcessorsQuery, GetProcessorsQueryVariables } from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

export const useGetProcessors = (options?: QueryHookOptions<GetProcessorsQuery, GetProcessorsQueryVariables>) => {
    const { data, error, loading } = useQuery<GetProcessorsQuery, GetProcessorsQueryVariables>(GraphQLDocuments.getProcessors, options);

    return {
        processors: data && data.processors ? data.processors.items : [],
        processorsLoading: loading,
        processorsError: error
    };
};
