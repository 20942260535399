
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"collectionRoundTask"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CollectionRoundTask"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"type"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"inquiry"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"truckInquiry"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"cargoPerProduct"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"product"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"cargo"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"effectiveCargo"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":209}};
    doc.loc.source = {"body":"#import \"./truckInquiry.graphql\"\n\nfragment collectionRoundTask on CollectionRoundTask {\n  type\n  inquiry {\n    ...truckInquiry\n  }\n\tcargoPerProduct {\n\t\tproduct {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t\tcargo\n\t\teffectiveCargo\n\t}\n}","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./truckInquiry.graphql").definitions));


      module.exports = doc;
    
