/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { GetCardsProductsQuery, GetCardsProductsQueryVariables } from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

export const useGetCardsProducts = (options?: QueryHookOptions<GetCardsProductsQuery, GetCardsProductsQueryVariables>) => {
    const { data, error, loading } = useQuery<GetCardsProductsQuery, GetCardsProductsQueryVariables>(GraphQLDocuments.getCardsProducts, options);

    return {
        cardsProducts: data && data.cardsProducts ? data.cardsProducts : [],
        cardsProductsLoading: loading,
        cardsProductsError: error
    };
};
