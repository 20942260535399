/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { CreateNotificationConfigurationMutation, CreateNotificationConfigurationMutationVariables } from "@models/graphql";

const defaultCreateNotificationConfigurationOptions: MutationHookOptions<
    CreateNotificationConfigurationMutation,
    CreateNotificationConfigurationMutationVariables
> = {};

export const useCreateNotificationConfiguration = (
    options?: MutationHookOptions<CreateNotificationConfigurationMutation, CreateNotificationConfigurationMutationVariables>
) => {
    const [mutation, { data, loading, error }] = useMutation<
        CreateNotificationConfigurationMutation,
        CreateNotificationConfigurationMutationVariables
    >(GraphQLDocuments.createNotificationConfiguration);

    const createNotificationConfiguration = async (variables: CreateNotificationConfigurationMutationVariables) => {
        try {
            const response = await mutation({
                ...defaultCreateNotificationConfigurationOptions,
                ...options,
                variables
            });

            return {
                error: response.errors,
                data: response.data
            };
        } catch (err) {
            return {
                error: err
            };
        }
    };

    return {
        isLoading: loading,
        error,
        data,
        createNotificationConfiguration
    };
};
