import { Button, InputNumber, Popconfirm, message } from "antd";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import * as moment from "moment";

import { useFormatMessage } from "@utils/intlHook";
import { CardsInquiryProduct, GetInquiryDetailQuery } from "@models/graphql";
import { usePaymentRequest } from "@graphql/hocs/hooks/usePaymentRequest";
import { ErrorMessage } from "@components/errorMessage/errorMessage";

import { InquiryInvoiceSenderStyle, ProductItem } from "./inquiryInvoiceSenderStyle";

export interface InquiryInvoiceSenderProps {
    incidentId: string;
    previousPayments: NonNullable<GetInquiryDetailQuery["inquiry"]["statusHistory"][0]["incident"]>["payments"];
    products: GetInquiryDetailQuery["inquiry"]["products"];
    inquiryId: string;
}

export const InquiryInvoiceSender: FunctionComponent<InquiryInvoiceSenderProps> = ({
    incidentId,
    products,
    inquiryId,
    previousPayments
}) => {
    const formatMessage = useFormatMessage();
    const [productsValue, setProductsValue] = React.useState([...products]);

    const { paymentRequest, error, isLoading } = usePaymentRequest({ awaitRefetchQueries: true, refetchQueries: ["getInquiryDetail"] });

    const onSend = async (): Promise<void> => {
        const response = await paymentRequest({
            inquiryId,
            incidentId,
            products: productsValue.map<CardsInquiryProduct>((prod) => ({ amount: prod.amount, productId: prod.product.id }))
        });

        if (response && response.data && response.data.addPayment) {
            message.success(formatMessage({ id: "paymentRequest.successful" }));
        }
    };

    const previousPayment = !!previousPayments.length && previousPayments[previousPayments.length - 1];

    return (
        <InquiryInvoiceSenderStyle>
            <FormattedMessage tagName="h1" id="inquiryInvoiceSender.title" />
            <main>
                <FormattedMessage id="inquiryInvoiceSender.info" />

                <div className="products">
                    {products.map((product, index) => (
                        <ProductItem key={index}>
                            <span className="name">{product.product.name}</span>
                            x
                            <InputNumber
                                onChange={(val) => {
                                    productsValue[index] = {
                                        ...productsValue[index],
                                        amount: val || 0
                                    };
                                    setProductsValue([...productsValue]);
                                }}
                                min={0}
                                value={productsValue[index].amount}
                            />
                        </ProductItem>
                    ))}
                </div>

                <ErrorMessage error={error} />

                <Popconfirm onConfirm={onSend} title={formatMessage({ id: "inquiryInvoiceSender.confirm" })} className="popConfirm">
                    <Button loading={isLoading} type="ghost">
                        <FormattedMessage id="inquiryInvoiceSender.send" />
                    </Button>
                </Popconfirm>
                {previousPayment && (
                    <div className="invoiceWarning">
                        <FormattedMessage
                            id="inquiryInvoiceSender.previousPayment"
                            values={{
                                name: `${previousPayment.user.lastname} ${previousPayment.user.firstname}`,
                                date: moment(previousPayment.timestamp).fromNow()
                            }}
                        />
                    </div>
                )}
            </main>
        </InquiryInvoiceSenderStyle>
    );
};
