import { ErrorMessage } from "@components/errorMessage/errorMessage";
import { RichTextEditor } from "@components/richTextEditor/richTextEditor";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { useCreateNotificationConfiguration } from "@graphql/hocs/hooks/useCreateNotificationConfiguration";
import { useGetIncidentReasons } from "@graphql/hocs/hooks/useGetIncidentReasons";
import { useGetProductNames } from "@graphql/hocs/hooks/useGetProductNames";
import { useUpdateNotificationConfiguration } from "@graphql/hocs/hooks/useUpdateNotificationConfiguration";
import { Channel, GetNotificationConfigurationsQuery, IncidentTriggerType, NotificationConfigurationFragment, NotificationConfigurationInput, NotificationTriggerType, ReminderTriggerType, TimeDuration, TriggerInput } from "@models/graphql";
import { NOTIFICATION_VARIABLES, TRIGGER_TYPES } from "@utils/consts";
import { useFormatMessage } from "@utils/intlHook";
import { isIncidentTrigger, isNotificationTrigger, isReminderTrigger } from "@utils/triggerHelpers";
import { InputNumber, Modal, Radio, Select } from "antd";
import Form, { FormComponentProps } from "antd/lib/form";
import { convertFromRaw, RawDraftContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { NotificationFormModalStyle } from "./notificationFormModalStyle";

export interface NotificationFormModalProps extends FormComponentProps {
    visible: boolean;
    editItem: NotificationConfigurationFragment | null;
    closeCallback(): void;
}

interface FormValues {
    productIds: string[];
    notificationType: string;
    triggerType: string;
    channel: Channel;
    template: RawDraftContentState;
    timeDuration: TimeDuration;
    timeAmount: number;
    incidentReasonId: string;
}

export const NotificationFormModalComponent: FunctionComponent<NotificationFormModalProps> = ({ visible, closeCallback, form, editItem }) => {
    const formatMessage = useFormatMessage();

    const { products, productsLoading } = useGetProductNames({ variables: { filter: { accessory: false } } });
    const { incidentReasons, incidentReasonsLoading } = useGetIncidentReasons();

    const { createNotificationConfiguration, error: createError, isLoading: createLoading } = useCreateNotificationConfiguration({
        update(cache, response) {
            const notifConfigData = cache.readQuery<GetNotificationConfigurationsQuery>({ query: GraphQLDocuments.getNotificationConfigurations });

            if (notifConfigData && response.data) {
                notifConfigData.notificationConfigurations.items.push(response.data.createNotificationConfiguration);
                notifConfigData.notificationConfigurations.count += 1;

                cache.writeQuery<GetNotificationConfigurationsQuery>({
                    query: GraphQLDocuments.getNotificationConfigurations,
                    data: notifConfigData
                });
            }
        }
    });
    const { updateNotificationConfiguration, error: updateError, isLoading: updateLoading } = useUpdateNotificationConfiguration();

    const handleClose = (): void => {
        closeCallback();
        form.resetFields();
    };

    const handleSubmit = (): void => {
        form.validateFields(async (errors, values: FormValues) => {
            if (errors) {
                return;
            }

            const trigger: TriggerInput = {};

            if (values.notificationType === "notification") {
                trigger.notification = {
                    type: values.triggerType as NotificationTriggerType
                };
            } else if (values.notificationType === "reminder") {
                trigger.reminder = {
                    type: values.triggerType as ReminderTriggerType,
                    timeAmount: values.timeAmount,
                    timeDuration: values.timeDuration
                };
            } else if (values.notificationType === "incident") {
                trigger.incident = {
                    type: IncidentTriggerType.IncidentCreated,
                    incidentReasonId: values.incidentReasonId
                };
            }

            let template = stateToHTML(convertFromRaw(values.template));

            if (values.channel === Channel.Sms) {
                template = new DOMParser().parseFromString(template, "text/html").body.textContent || "";
            }

            const configuration: NotificationConfigurationInput = {
                channel: values.channel,
                rawTemplate: values.template,
                htmlTemplate: template,
                productIds: values.productIds,
                trigger
            };

            if (editItem) {
                const { error } = await updateNotificationConfiguration({
                    id: editItem.id,
                    configuration
                });

                if (!error) {
                    handleClose();
                }
            } else {
                const { error } = await createNotificationConfiguration({ configuration });

                if (!error) {
                    handleClose();
                }
            }
        });
    };

    const values = form.getFieldsValue() as FormValues;

    const handleNotificationTypeChange = (): void => {
        form.setFieldsValue({
            triggerType: undefined
        });
    };

    const variables = React.useMemo(() => {
        const vars = [...NOTIFICATION_VARIABLES.default];

        if (values.triggerType && NOTIFICATION_VARIABLES[values.triggerType]) {
            vars.push(...NOTIFICATION_VARIABLES[values.triggerType]);
        }

        return vars;
    }, [values.triggerType]);

    const isRich = React.useMemo(() => {
        if (!values.channel) {
            if (editItem) {
                return editItem.channel === Channel.Email;
            }

            return true;
        }

        return values.channel === Channel.Email;
    }, [values.channel, editItem]);

    React.useEffect(() => {
        if (editItem) {
            let notificationType = "notification";
            let triggerType: string | undefined = undefined;

            if (isNotificationTrigger(editItem.trigger)) {
                triggerType = editItem.trigger.notificationType;
            }

            if (isIncidentTrigger(editItem.trigger)) {
                notificationType = "incident";
                triggerType = editItem.trigger.incidentType;
            }

            if (isReminderTrigger(editItem.trigger)) {
                notificationType = "reminder";
                triggerType = editItem.trigger.reminderType;
            }

            form.setFieldsValue({
                notificationType,
                triggerType
            });
        }
    }, [editItem]);

    const validateTemplate = (rule: any, value: RawDraftContentState | undefined, callback: (error?: string) => void): void => {
        if (!value || value.blocks.every(block => !block.text)) {
            return callback(formatMessage({ id: "requiredField" }));
        }

        return callback();
    };

    return (
        <Modal
            okButtonProps={{
                loading: createLoading || updateLoading
            }}
            visible={visible}
            onOk={handleSubmit}
            okText={<FormattedMessage id="save" />}
            cancelText={<FormattedMessage id="cancel" />}
            onCancel={handleClose}
            title={formatMessage({ id: "notificationForm.title" })}
            width={800}
            destroyOnClose={true}
        >
            <NotificationFormModalStyle>
                <Form onSubmit={handleSubmit}>
                    <Form.Item
                        label={formatMessage({
                            id: "notificationForm.product"
                        })}
                    >
                        {form.getFieldDecorator("productIds", {
                            initialValue: editItem ? editItem.products.map(p => p.id) : undefined,
                            rules: [
                                {
                                    required: true,
                                    message: formatMessage({
                                        id: "requiredField"
                                    })
                                }
                            ]
                        })(<Select
                            placeholder={formatMessage({
                                id: "notificationForm.product"
                            })}
                            mode="multiple"
                        >
                            {products.map(prod =>
                                <Select.Option key={prod.id} value={prod.id}>
                                    {prod.name}
                                </Select.Option>)}
                        </Select>)}
                    </Form.Item>
                    <Form.Item>
                        {form.getFieldDecorator("notificationType", {
                            initialValue: "notification",
                            rules: [
                                {
                                    required: true,
                                    message: formatMessage({
                                        id: "requiredField"
                                    })
                                }
                            ]
                        })(<Radio.Group onChange={handleNotificationTypeChange}>
                            <Radio value="notification">
                                <FormattedMessage id="notificationType.notification" />
                            </Radio>
                            <Radio value="reminder">
                                <FormattedMessage id="notificationType.reminder" />
                            </Radio>
                            <Radio value="incident">
                                <FormattedMessage id="notificationType.incident" />
                            </Radio>
                        </Radio.Group>)}
                    </Form.Item>
                    <div className="row">
                        {values.notificationType !== "incident" &&
                            <Form.Item
                                label={formatMessage({
                                    id: "notificationForm.triggerType"
                                })}
                            >
                                {form.getFieldDecorator("triggerType", {
                                    rules: [
                                        {
                                            required: true,
                                            message: formatMessage({
                                                id: "requiredField"
                                            })
                                        }
                                    ]
                                })(<Select
                                    placeholder={formatMessage({
                                        id: "notificationForm.triggerType"
                                    })}
                                    loading={productsLoading}
                                >
                                    {TRIGGER_TYPES[values.notificationType] &&
                                        TRIGGER_TYPES[values.notificationType].map(trigger =>
                                            <Select.Option key={trigger} value={trigger}>
                                                <FormattedMessage id={`notificationTrigger.${trigger}`} />
                                            </Select.Option>)}
                                </Select>)}
                            </Form.Item>
                        }
                        {values.notificationType === "reminder" &&
                            <>
                                <Form.Item
                                    style={{ width: 200 }}
                                    label={formatMessage({
                                        id: "notificationForm.timeAmount"
                                    })}
                                >
                                    {form.getFieldDecorator("timeAmount", {
                                        initialValue: editItem && isReminderTrigger(editItem.trigger) ? editItem.trigger.timeAmount : undefined,
                                        rules: [
                                            {
                                                required: true,
                                                message: formatMessage({
                                                    id: "requiredField"
                                                })
                                            }
                                        ]
                                    })(<InputNumber
                                        min={1}
                                        precision={0}
                                        placeholder={formatMessage({
                                            id: "notificationForm.timeAmount"
                                        })}
                                    />)}
                                </Form.Item>
                                <Form.Item style={{ width: 200 }} label=" " colon={false} required={false}>
                                    {form.getFieldDecorator("timeDuration", {
                                        initialValue:
                                            editItem && isReminderTrigger(editItem.trigger) ? editItem.trigger.timeDuration : TimeDuration.Days,
                                        rules: [
                                            {
                                                required: true,
                                                message: formatMessage({
                                                    id: "requiredField"
                                                })
                                            }
                                        ]
                                    })(<Select>
                                        {Object.values(TimeDuration).map(dur =>
                                            <Select.Option key={dur} value={dur}>
                                                <FormattedMessage id={`timeDuration.${dur}`} />
                                            </Select.Option>)}
                                    </Select>)}
                                </Form.Item>
                            </>
                        }
                    </div>
                    {values.notificationType === "incident" &&
                        <Form.Item
                            label={formatMessage({
                                id: "notificationForm.incidentReason"
                            })}
                        >
                            {form.getFieldDecorator("incidentReasonId", {
                                initialValue: editItem && isIncidentTrigger(editItem.trigger) ? editItem.trigger.incident.id : undefined
                            })(<Select
                                placeholder={formatMessage({
                                    id: "notificationForm.incidentReason"
                                })}
                                loading={incidentReasonsLoading}
                            >
                                {incidentReasons
                                    .filter(r => (values.productIds || []).every(pId => r.products.some(p => p.id === pId)))
                                    .map(reason =>
                                        <Select.Option key={reason.id} value={reason.id}>
                                            {reason.name}
                                        </Select.Option>)}
                            </Select>)}
                        </Form.Item>
                    }
                    <Form.Item>
                        {form.getFieldDecorator("channel", {
                            initialValue: editItem ? editItem.channel : Channel.Email
                        })(<Radio.Group>
                            <Radio value={Channel.Email}>Email</Radio>
                            <Radio value={Channel.Sms}>SMS</Radio>
                        </Radio.Group>)}
                    </Form.Item>
                    <Form.Item>
                        {form.getFieldDecorator("template", {
                            initialValue: editItem ? editItem.rawTemplate : undefined,
                            rules: [{ validator: validateTemplate }]
                        })(<RichTextEditor variables={variables} isRich={isRich} />)}
                    </Form.Item>
                    <ErrorMessage error={createError || updateError} />
                </Form>
            </NotificationFormModalStyle>
        </Modal>
    );
};

export const NotificationFormModal = Form.create<NotificationFormModalProps>()(NotificationFormModalComponent);
