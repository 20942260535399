/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { UpdateCollectorMutation, UpdateCollectorMutationVariables } from "@models/graphql";

const defaultUpdateCollectorOptions: MutationHookOptions<UpdateCollectorMutation, UpdateCollectorMutationVariables> = {

};

export const useUpdateCollector = (options?: MutationHookOptions<UpdateCollectorMutation, UpdateCollectorMutationVariables>) => {
    const [mutation, { data, loading, error }] = useMutation<UpdateCollectorMutation, UpdateCollectorMutationVariables>(GraphQLDocuments.updateCollector);

    const updateCollector = async (variables: UpdateCollectorMutationVariables) => {
        try {
            const response = await mutation({
                ...defaultUpdateCollectorOptions,
                ...options,
                variables
            });

            return {
                error: response.errors,
                data: response.data
            };
        } catch (err) {
            return {
                error: err
            };
        }
    };

    return {
        isLoading: loading,
        error,
        data,
        updateCollector
    };
};
