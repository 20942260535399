/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { UpdateIncidentReasonMutation, UpdateIncidentReasonMutationVariables } from "@models/graphql";

const defaultUpdateIncidentReasonOptions: MutationHookOptions<UpdateIncidentReasonMutation, UpdateIncidentReasonMutationVariables> = {};

export const useUpdateIncidentReason = (
    options?: MutationHookOptions<UpdateIncidentReasonMutation, UpdateIncidentReasonMutationVariables>
) => {
    const [mutation, { data, loading, error }] = useMutation<UpdateIncidentReasonMutation, UpdateIncidentReasonMutationVariables>(
        GraphQLDocuments.updateIncidentReason
    );

    const updateIncidentReason = async (variables: UpdateIncidentReasonMutationVariables) => {
        try {
            const response = await mutation({
                ...defaultUpdateIncidentReasonOptions,
                ...options,
                variables
            });

            return {
                error: response.errors,
                data: response.data
            };
        } catch (err) {
            return { error: err };
        }
    };

    return {
        isLoading: loading,
        error,
        data,
        updateIncidentReason
    };
};
