import * as React from "react";
import * as classnames from "classnames";
import { FunctionComponent } from "react";
import { ColorPickerStyle, PopupContent, COLOR_MAP } from "./colorPickerStyle";
import { Popover } from "antd";
import { COLORS } from "@style/colors";

export interface ColorPickerProps {
    activeStyle: Immutable.OrderedSet<string>;
    onToggle(style: string): void;
}

export const ColorPicker: FunctionComponent<ColorPickerProps> = ({ activeStyle, onToggle }) => {

    const match = activeStyle.find(style => {
        if (style) {
            return Object.keys(COLOR_MAP).some(k => k === style);
        }

        return false;
    }) || "text";

    return (
        <ColorPickerStyle onMouseDown={e => e.preventDefault()}>
            <Popover content={<PopupContent >

                <span onClick={() => onToggle("primary")} className={classnames("primary", { active: match === "primary" })} />
                <span onClick={() => onToggle("error")} className={classnames("error", { active: match === "error" })} />
                <span onClick={() => onToggle("secondary")} className={classnames("secondary", { active: match === "secondary" })} />
                <span onClick={() => onToggle("warning")} className={classnames("warning", { active: match === "warning" })} />
                <span onClick={() => onToggle("grey")} className={classnames("grey", { active: match === "grey" })} />
            </PopupContent>}
            >
                <span style={{ backgroundColor: COLOR_MAP[match] ? COLOR_MAP[match].color : COLORS.text }} ></span>
            </Popover>
        </ColorPickerStyle>
    );
};
