import styled from "styled-components";
import { WhitePage } from "@style/whitePage";
import { InnerContainer } from "@style/innerContainer";

export const InquiryOverviewPageStyle = styled(WhitePage)`
    & > ${InnerContainer} {
        margin-top: 30px;
        display: flex;
    }
`;
