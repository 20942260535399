const actionPrefix = "FNS/";

export const ActionTypes = {
    OpenModal: `${actionPrefix}MAIN/OPEN_MODAL`,
    CloseModal: `${actionPrefix}MAIN/CLOSE_MODAL`,
    Login: `${actionPrefix}AUTH/LOGIN`,
    Logout: `${actionPrefix}AUTH/LOGOUT`,
    UpdatePlanningDate: `${actionPrefix}MAIN/UPDATE_PLANNING_DATE`,
    SetGeneralInfo: `${actionPrefix}PATTERN/SET_GENERAL_INFO`,
    AddCollectionRound: `${actionPrefix}PATTERN/ADD_COLLECTION_ROUND`,
    RemoveCollectionRound: `${actionPrefix}PATTERN/REMOVE_COLLECTION_ROUND`,
    UpdateCollectionRound: `${actionPrefix}PATTERN/UPDATE_COLLECTION_ROUND`
};
