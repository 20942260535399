/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as uriTemplate from "uri-templates";

import { useLocation, useNavigate } from "@utils/routerHook";

export const parseStringArray = (val?: string | string[]) => {
    if (typeof val === "string") {
        return [val];
    }

    return val;
};

export const useQueryParameters = <T>(templateString: string, initialValue?: T, parseTemplate?: Partial<Record<keyof T, "array" | "number" | "boolean">>) => {
    const template = uriTemplate(templateString);
    const navigate = useNavigate<Partial<T>>(template, { replace: true });
    const loc = useLocation();
    let parameters: T = template.fromUri(`${loc.pathname}${loc.search}`) as any || {};

    const updateParameters = (params: Partial<T>, replace?: boolean) => {

        const currentFilter: T = template.fromUri(`${loc.pathname}${loc.search}`) as any || {};

        if (replace) {
            navigate(params);
        } else {
            navigate({ ...currentFilter, ...params });
        }

    };

    if (initialValue) {
        parameters = { ...initialValue, ...parameters };
    }

    if (parseTemplate) {

        Object.entries(parameters).map(([key, value]) => {

            const type = parseTemplate[key];

            switch (type) {
            case "array": parameters[key] = parseStringArray(value); break;
            case "number": parameters[key] = parseInt(value, 10); break;
            case "boolean": parameters[key] = value === "true"; break;
            default: parameters[key] = value;
            }
        });
    }

    return {
        updateParameters,
        parameters
    };
};
