import styled from "styled-components";
import { WhitePage } from "@style/whitePage";

export const CollectionCalendarPageStyle = styled(WhitePage)`
    .topBar {
        margin-bottom: 25px;
        display: flex;
        justify-content: flex-end;
    }
`;
