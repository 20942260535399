import * as React from "react";
import * as moment from "moment";
import { FunctionComponent } from "react";

import { useRouter } from "@utils/routerHook";
import { useGetTruckSchedule } from "@graphql/hocs/hooks/useGetTruckSchedule";
import { TruckScheduleSidebar } from "@components/truckScheduleSidebar/truckScheduleSidebar";
import { TasksMapView } from "@components/tasksMapView/tasksMapView";

import { TruckMapContainerStyle } from "./truckMapContainerStyle";
import { TruckTaskFragment } from "@models/graphql";

export interface TruckMapContainerProps { }

export const TruckMapContainer: FunctionComponent<TruckMapContainerProps> = ({ }) => {
    const {
        match: {
            params: { date, truckId }
        }
    } = useRouter<{ truckId: string; date: string }>();

    const [isUnsaved, setIsUnsaved] = React.useState(false);
    const [optimal, setOptimal] = React.useState(true);
    const [truckScheduleTasks, setTruckScheduleTasks] = React.useState<TruckTaskFragment[]>([]);
    const [activeTaskId, setActiveTask] = React.useState<string | null>(null);

    const { truckSchedule, truckScheduleLoading } = useGetTruckSchedule({
        variables: {
            date,
            truckId
        }
    });

    const resetTasks = (): void => {
        setTruckScheduleTasks(truckSchedule ? truckSchedule.tasks : []);
        setIsUnsaved(false);
    };

    React.useEffect(() => {
        if (truckSchedule) {
            setTruckScheduleTasks(truckSchedule.tasks);
            setOptimal(truckSchedule.optimal);
        }
    }, [truckSchedule]);

    return (
        <TruckMapContainerStyle>
            <TruckScheduleSidebar
                optimal={optimal}
                setOptimal={setOptimal}
                loading={truckScheduleLoading}
                resetTasks={resetTasks}
                isUnsaved={isUnsaved}
                setIsUnsaved={setIsUnsaved}
                truckScheduleTasks={truckScheduleTasks}
                truckScheduleDate={truckSchedule && moment(truckSchedule.date)}
                setTruckScheduleTasks={setTruckScheduleTasks}
                setActiveTask={setActiveTask}
                truckId={truckSchedule && truckSchedule.truck.id}
            />
            <TasksMapView activeTaskId={activeTaskId} isUnsaved={isUnsaved} truckScheduleTasks={truckScheduleTasks} />
        </TruckMapContainerStyle>
    );
};
