import { MutationFetchResult } from "@apollo/react-common";
import { AssetTypeFormModal } from "@components/assetTypeFormModal/assetTypeFormModal";
import { ErrorMessage } from "@components/errorMessage/errorMessage";
import { PageHeader } from "@components/pageHeader/pageHeader";
import { Permission, PERMISSIONS } from "@components/permission/permission";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { useDeleteAssetType } from "@graphql/hocs/hooks/useDeleteAssetType";
import { useGetAssetTypes } from "@graphql/hocs/hooks/useGetAssetTypes";
import { AssetType, DeleteAssetTypeMutation, GetAssetTypesQuery } from "@models/graphql";
import { Icon } from "@style/icon";
import { InnerContainer } from "@style/innerContainer";
import { useFormatMessage } from "@utils/intlHook";
import { parseError } from "@utils/parseError";
import { Button, Icon as AntIcon, message, Popconfirm, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { AssetTypePageStyle } from "./assetTypePageStyle";

export interface AssetTypePageProps {

}

export const AssetTypePage: FunctionComponent<AssetTypePageProps> = ({ }) => {
    const [deleteId, setDeleteId] = React.useState<string | null>(null);
    const formatMessage = useFormatMessage();
    const [modalVisible, setModalVisible] = React.useState(false);
    const [editItem, setEditItem] = React.useState<AssetType | null>(null);
    const { assetTypes, assetTypesError, assetTypesLoading } = useGetAssetTypes();

    const { deleteAssetType, isLoading: deleteLoading } = useDeleteAssetType();

    const columns: ColumnProps<AssetType>[] = [
        {
            title: formatMessage({ id: "assetTypesPage.name" }),
            dataIndex: "name",
            key: "name"
        },

        {
            width: 80,
            render(_, record) {

                const handleDelete = async (): Promise<void> => {
                    try {
                        setDeleteId(record.id);
                        const { error } = await deleteAssetType({ id: record.id }, {
                            update(cache, response: MutationFetchResult<DeleteAssetTypeMutation>) {
                                const collectorsData = cache.readQuery<GetAssetTypesQuery>({ query: GraphQLDocuments.getAssetTypes });

                                if (collectorsData && response.data && response.data.deleteAssetType) {
                                    collectorsData.assetTypes.items = collectorsData.assetTypes.items.filter(p => p.id !== record.id);
                                    collectorsData.assetTypes.count -= 1;

                                    cache.writeQuery<GetAssetTypesQuery>({ query: GraphQLDocuments.getAssetTypes, data: collectorsData });
                                }
                            }
                        });

                        if (error) {
                            message.error(parseError(error, formatMessage));
                        } else {
                            message.success(formatMessage({ id: "assetTypesTable.deleteSuccessful" }));
                        }
                        setDeleteId(null);

                    } catch (error) {
                        message.error(parseError(error, formatMessage));
                    }
                };

                return (
                    <span className="buttons">
                        <Permission requiredPermissions={[PERMISSIONS.assetTypes.update]}>
                            <Icon onClick={() => {
                                setEditItem(record);
                                setModalVisible(true);
                            }} type="pencil"
                            ></Icon>
                        </Permission>
                        <Permission requiredPermissions={[PERMISSIONS.assetTypes.delete]}>
                            {record.id === deleteId && deleteLoading ?
                                <AntIcon type="loading" />
                                :
                                <Popconfirm onConfirm={handleDelete} placement="left" title={formatMessage({ id: "assetTypesPage.confirmDelete" })}>
                                    <Icon type="trash"></Icon>
                                </Popconfirm>
                            }
                        </Permission>
                    </span>
                );
            }
        }
    ];
    const handleCloseModal = (): void => {
        setModalVisible(false);
        setEditItem(null);
    };

    const handleCreateCollector = (): void => {
        setEditItem(null);
        setModalVisible(true);
    };
    return (
        <AssetTypePageStyle>
            <PageHeader title="assetTypesPage.title">
                <Permission requiredPermissions={[PERMISSIONS.assetTypes.create]}>
                    <Button onClick={handleCreateCollector} type="primary" ><FormattedMessage id="assetTypesPage.newAssetType" /></Button>
                </Permission>
            </PageHeader>
            <AssetTypeFormModal
                closeCallback={handleCloseModal}
                visible={modalVisible}
                editItem={editItem}
            />

            <InnerContainer>
                <ErrorMessage error={assetTypesError} />
                <Table
                    loading={assetTypesLoading}
                    dataSource={assetTypes}
                    columns={columns}
                    rowKey="id"
                />
            </InnerContainer>
        </AssetTypePageStyle>
    );
};
