import styled from "styled-components";

export const PatternOverviewTabStyle = styled.div`
    .ant-input-number {
        width: 200px;
        .ant-input-number-handler-wrap {
            opacity: 1;
        }
    }
`;
