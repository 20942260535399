import { Form, Modal } from "antd";
import { FormComponentProps } from "antd/lib/form";
import TextArea from "antd/lib/input/TextArea";
import { FunctionComponent } from "react";
import * as React from "react";

import { useFormatMessage } from "@utils/intlHook";
import { InquiryOverviewFragment, InquiryStatusType } from "@models/graphql";
import { useInquiryOnhold } from "@graphql/hocs/hooks/useInquiryOnhold";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { ErrorMessage } from "@components/errorMessage/errorMessage";

import { InquiryOnholdModalStyle } from "./inquiryOnholdModalStyle";
import { NormalizedCache } from "apollo-cache-inmemory";

interface FormValues {
    reason: string;
}
export interface InquiryOnholdModalProps extends FormComponentProps<FormValues> {
    visible: boolean;
    inquiryId: string;
    closeCallback(): void;
}

export const InquiryOnholdModalComponent: FunctionComponent<InquiryOnholdModalProps> = ({ visible, closeCallback, inquiryId, form }) => {
    const formatMessage = useFormatMessage();
    const { inquiryOnhold, isLoading: onholdLoading, error: onholdError } = useInquiryOnhold({
        update(cache, response) {
            if (!response || !response.data || !response.data.inquiryOnHold) {
                return;
            }

            const data: NormalizedCache = (cache as any).data;

            Object.keys((data as any).data).forEach(key => key.match(/^CollectionRound/) && data.delete(key));
            Object.keys((data as any).data).forEach(key => key.match(/^\$ROOT_QUERY\.collectionRounds/) && data.delete(key));

            const inqFragment = cache.readFragment<InquiryOverviewFragment>({
                fragment: GraphQLDocuments.inquiryOverview,
                fragmentName: "inquiryOverview",
                id: `Inquiry:${inquiryId}`
            });

            if (inqFragment) {

                inqFragment.currentStatus = InquiryStatusType.OnHold;
                inqFragment.lastUpdated = new Date().toISOString();

                cache.writeFragment<InquiryOverviewFragment>({
                    fragment: GraphQLDocuments.inquiryOverview,
                    fragmentName: "inquiryOverview",
                    id: `Inquiry:${inquiryId}`,
                    data: inqFragment
                });
            }
        },
        refetchQueries: [{ query: GraphQLDocuments.getInquiryDetail, variables: { id: inquiryId } }],
        awaitRefetchQueries: true
    });

    const handleOK = (): void => {
        form.validateFields(async (errors, values) => {
            if (!errors) {
                const { response } = await inquiryOnhold({
                    inquiryId,
                    reason: values.reason
                });

                if (response && response.data && response.data.inquiryOnHold) {
                    closeCallback();
                }
            }
        });
    };

    return (
        <InquiryOnholdModalStyle>
            <Modal
                title={formatMessage({ id: "inquiryDetail.onholdModalTitle" })}
                visible={visible}
                onOk={handleOK}
                confirmLoading={onholdLoading}
                onCancel={closeCallback}
                okText={formatMessage({ id: "save" })}
                cancelText={formatMessage({ id: "cancel" })}
            >
                <Form>
                    <Form.Item label={formatMessage({ id: "inquiryDetail.reason" })}>
                        {form.getFieldDecorator("reason", {
                            rules: [
                                { required: true, message: formatMessage({ id: "requiredField" }) }
                            ]
                        })(<TextArea rows={4} />)}
                    </Form.Item>
                    <ErrorMessage error={onholdError} />
                </Form>
            </Modal>
        </InquiryOnholdModalStyle>
    );
};

export const InquiryOnholdModal = Form.create<InquiryOnholdModalProps>()(InquiryOnholdModalComponent);
