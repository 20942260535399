import styled from "styled-components";
import { COLORS } from "@style/colors";

export const InquiryDeliveryModalStyle = styled.div`
    div.info {
        display: flex;
        align-items: center;
        padding: 15px;

        .excl {
            width: 20px;
            height: 20px;
            background-color: ${COLORS.secondary};
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            margin-right: 15px;
        }
    }

    .inquiry {
        padding: 25px;
        background: #f8fafb;
        border-top: 1px solid ${COLORS.textTransparent(0.1)};
        border-bottom: 1px solid ${COLORS.textTransparent(0.1)};

        .products {
            margin-top: 15px;
            opacity: 0.7;
        }
    }

    .pickupDay {
        padding: 25px;
        border-bottom: 1px solid ${COLORS.textTransparent(0.1)};

        em {
            font-weight: 600;
        }
    }

    .deliveryMoments {
        padding: 25px;

        .ant-radio-wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            margin-top: 10px;
        }

        .ant-radio-group {
            display: block;
        }

        span.ant-radio + * {
            flex: 1;
        }
    }

    .loadMore {
        display: block;
        margin-top: 15px;
        opacity: 0.5;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
            opacity: 1;
        }
    }
`;

export const DeliveryItem = styled.div`
    display: flex;

    em {
        min-width: 250px;
        flex-shrink: 0;
    }

    .icon {
        padding: 5px;
    }
`;
