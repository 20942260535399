/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ApolloError } from "apollo-client";
import { useState } from "react";
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";

import { CreateIncidentReasonMutation, CreateIncidentReasonMutationVariables } from "@models/graphql";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";

const defaultCreateIncidentReasonOptions: MutationHookOptions<CreateIncidentReasonMutation, CreateIncidentReasonMutationVariables> = {

};

export const useCreateIncidentReason = (options?: MutationHookOptions<CreateIncidentReasonMutation, CreateIncidentReasonMutationVariables>) => {
    const [hookState, setHookState] = useState<{
        isLoading: boolean;
        data?: CreateIncidentReasonMutation;
        error?: ApolloError;
    }>({
        isLoading: false
    });

    const [mutation] = useMutation<CreateIncidentReasonMutation, CreateIncidentReasonMutationVariables>(GraphQLDocuments.createIncidentReason);

    const createIncidentReason = async (variables: CreateIncidentReasonMutationVariables) => {
        setHookState({
            isLoading: true
        });

        try {
            const response = await mutation({
                ...defaultCreateIncidentReasonOptions,
                ...options,
                variables
            });

            setHookState({
                data: response.data,
                isLoading: false
            });

            return response;
        } catch (error) {
            setHookState({
                error,
                isLoading: false
            });
        }
    };

    return {
        isLoading: hookState.isLoading,
        error: hookState.error,
        data: hookState.data,
        createIncidentReason
    };
};
