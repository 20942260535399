import { IncidentReasonForm } from "@components/incidentReasonForm/incidentReasonForm";
import { PageHeader } from "@components/pageHeader/pageHeader";
import { Permission, PERMISSIONS } from "@components/permission/permission";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { useDeleteIncidentReason } from "@graphql/hocs/hooks/useDeleteIncidentReason";
import { useGetIncidentReasons } from "@graphql/hocs/hooks/useGetIncidentReasons";
import { GetIncidentReasonsQuery, IncidentReason } from "@models/graphql";
import { Icon } from "@style/icon";
import { InnerContainer } from "@style/innerContainer";
import { useFormatMessage } from "@utils/intlHook";
import { parseError } from "@utils/parseError";
import { Button, message, Popconfirm, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { IncidentReasonStyle } from "./incidentReasonStyle";

export interface IncidentReasonProps {
}

export const IncidentReasonPage: FunctionComponent<IncidentReason> = () => {
    const formatMessage = useFormatMessage();

    const { incidentReasons, incidentReasonsLoading } = useGetIncidentReasons();
    const [modalVisible, setModalVisible] = React.useState(false);
    const [editItem, setEditItem] = React.useState<GetIncidentReasonsQuery["incidentReasons"]["items"][0] | null>(null);
    const { deleteIncidentReason, isLoading } = useDeleteIncidentReason();

    const colums: ColumnProps<GetIncidentReasonsQuery["incidentReasons"]["items"][0]>[] = [
        {
            title: "Naam",
            dataIndex: "name"
        },
        {
            title: "Verantwoordelijkheid",
            width: 0,
            dataIndex: "responsibility",
            render(_, r) {
                return <FormattedMessage id={`responsibility.${r.responsibility}`} />;
            }
        },
        {
            title: "Producten",
            dataIndex: "products",
            render(_, r) {
                return r.products.map(p => p.name).join(", ");
            }
        },
        {
            width: 80,
            render(_, record) {
                const onDelete = async (): Promise<void> => {
                    try {
                        await deleteIncidentReason(
                            {
                                incidentReasonId: record.id
                            },
                            {
                                update(cache, response) {
                                    const cacheData = cache.readQuery<GetIncidentReasonsQuery>({ query: GraphQLDocuments.getIncidentReasons });

                                    if (!cacheData || !response || !response.data || !response.data.deleteIncidentReason.succeeded) {
                                        return;
                                    }

                                    cacheData.incidentReasons.items = cacheData.incidentReasons.items.filter(res => res.id !== record.id);
                                    cacheData.incidentReasons.count -= 1;

                                    cache.writeQuery<GetIncidentReasonsQuery>({
                                        query: GraphQLDocuments.getIncidentReasons,
                                        data: { incidentReasons: cacheData.incidentReasons }
                                    });

                                }
                            }
                        );
                    } catch (error) {
                        message.error(parseError(error, formatMessage));
                    }
                };

                if (record.systemReason) {
                    return;
                }

                return (
                    <span className="buttons" >
                        <Permission requiredPermissions={[PERMISSIONS.incidentReasons.update]}>
                            <Icon onClick={() => {
                                setEditItem(record);
                                setModalVisible(true);
                            }} type="pencil"
                            ></Icon>
                        </Permission>
                        <Permission requiredPermissions={[PERMISSIONS.incidentReasons.delete]}>
                            <Popconfirm okButtonProps={{ loading: isLoading }} onConfirm={onDelete} placement="left" title={formatMessage({ id: "incidentReasonPage.confirmDelete" })}>
                                <Icon type="trash"></Icon>
                            </Popconfirm>
                        </Permission>
                    </span>
                );
            }
        }
    ];

    const addNewIncident = (): void => {
        setEditItem(null);
        setModalVisible(true);
    };

    return (
        <IncidentReasonStyle>
            <PageHeader title="incidentReason.title">
                <Permission requiredPermissions={[PERMISSIONS.incidentReasons.create]}>
                    <Button onClick={addNewIncident} type="primary" ><FormattedMessage id="incidentReason.newReason" /></Button>
                </Permission>
            </PageHeader>

            <IncidentReasonForm
                visible={modalVisible}
                closeCallback={() => setModalVisible(false)}
                editItem={editItem}
            />

            <InnerContainer>
                <Table
                    rowKey="id"
                    loading={incidentReasonsLoading}
                    columns={colums}
                    dataSource={incidentReasons}

                />
            </InnerContainer>
        </IncidentReasonStyle>
    );
};
