import styled from "styled-components";
import { COLORS } from "@style/colors";
import { Placeholder } from "@style/placeholder";

export const InquiryInformationSidebarStyle = styled.div`
    flex-basis: 340px;
    border-right: 1px solid lightgray;

    h1 {
        margin: 0;
    }

    .sectionHeader {
        font-weight: 900;
        background-color: ${COLORS.lightGrey};
        padding: 20px 30px;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .sidebarBlockBody {
        padding: 20px 30px;
        background: white;

        .date em {
            display: inline-block;
            font-style: normal;
            width: 80px;
        }

        .date:not(:last-of-type) {
            margin-bottom: 15px;
        }

        small {
            font-size: 14px;
            opacity: 0.4;
        }
    }

    .ant-avatar {
        background-color: ${COLORS.primary};
        margin-right: 20px;
    }

    .right {
        float: right;
    }

    .extraInfo {
        color: ${COLORS.textTransparent(0.5)};
    }

    .iconBlock {
        margin-bottom: 15px;

        strong {
            color: ${COLORS.textTransparent(0.5)};
            font-style: oblique;
            font-weight: 300;
        }
    }

    em {
        font-weight: 600;
    }

    strong {
        display: block;
        margin-bottom: 4px;
    }

    ${Placeholder} {
        height: 20px;
        display: inline-block;
        width: 200px;
        margin-bottom: -4px;

        & + ${Placeholder} {
            margin-top: 15px;
        }
    }

    .images {
        margin-bottom: 15px;

        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        img {
            display: block;
            max-height: 100px;

            padding: 0.2em;
        }
    }
`;
