/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { MoveTruckScheduleMutation, MoveTruckScheduleMutationVariables } from "@models/graphql";

const defaultMoveTruckScheduleOptions: MutationHookOptions<MoveTruckScheduleMutation, MoveTruckScheduleMutationVariables> = {};

export const useMoveTruckSchedule = (options?: MutationHookOptions<MoveTruckScheduleMutation, MoveTruckScheduleMutationVariables>) => {
    const [mutation, { data, loading, error }] = useMutation<MoveTruckScheduleMutation, MoveTruckScheduleMutationVariables>(
        GraphQLDocuments.moveTruckSchedule
    );

    const moveTruckSchedule = async (variables: MoveTruckScheduleMutationVariables) => {
        try {
            const response = await mutation({
                ...defaultMoveTruckScheduleOptions,
                ...options,
                variables
            });

            return {
                error: undefined,
                response
            };
        } catch (err) {
            return {
                error: err,
                response: undefined
            };
        }
    };

    return {
        isLoading: loading,
        error,
        data,
        moveTruckSchedule
    };
};
