import styled from "styled-components";
import { COLORS } from "@style/colors";

export const PatternDayBlockStyle = styled.div`
    margin-bottom: 25px;

    header {
        width: 100%;
        height: 50px;
        background-color: ${COLORS.primary};
        color: white;
        display: flex;
        align-items: center;
        padding: 15px;
        border-radius: 4px;

        justify-content: space-between;
    }

    .add {
        opacity: 0.6;
        transition: .2s;
        cursor: pointer;

        &:hover {
            opacity: 1;
        }
    }

    main {
        display: flex;
        margin-top: 25px;

        & > div {
            &:not(:last-of-type) {
                margin-right: 15px;
            }
        }
    }
`;
