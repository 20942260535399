import { IMAGES } from "@assets/images";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { Permission, PERMISSIONS } from "@components/permission/permission";
import { useGetInquiriesCount } from "@graphql/hocs/hooks/useGetInquiriesCount";
import { InquiryStatusType } from "@models/graphql";
import { InnerContainer } from "@style/innerContainer";
import { useFormatMessage } from "@utils/intlHook";
import { useLocation } from "@utils/routerHook";
import { Badge, Breadcrumb } from "antd";
// import "antd/es/badge/style";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
import { HeaderStyle } from "./headerStyle";

export interface HeaderProps { }

export const Header: FunctionComponent<HeaderProps> = () => {
    const { pathname } = useLocation();
    const { oidcUser: user, logout } = useReactOidc();
    const formatMessage = useFormatMessage();
    const { inquiriesCount } = useGetInquiriesCount({ variables: { filter: { statusses: [InquiryStatusType.Incident] } } });

    const breadcrumbNameMap = {
        "/management": formatMessage({ id: "settingsPage.title" }),
        "/management/trucks": formatMessage({ id: "settingsPage.trucksTitle" }),
        "/management/products": formatMessage({ id: "settingsPage.productsTitle" }),
        "/management/products/detail": formatMessage({ id: "settingsPage.productsDetailTitle" }),
        "/management/incidents": formatMessage({ id: "settingsPage.incidentsTitle" }),
        "/management/processors": formatMessage({ id: "settingsPage.processorsTitle" }),
        "/management/notifications": formatMessage({ id: "settingsPage.notificationsTitle" }),
        "/management/inventory": formatMessage({ id: "settingsPage.inventoryTitle" }),
        "/management/collectors": formatMessage({ id: "settingsPage.usersTitle" }),
        "/management/calendar": formatMessage({ id: "settingsPage.calendarTitle" }),
        "/management/weight-data": formatMessage({ id: "settingsPage.weightDataTitle" }),
        "/management/calendar/pattern": formatMessage({ id: "patternDetail.title" }),
        "/reports": formatMessage({ id: "reportsPage.title" }),
        "/reports/incidents": formatMessage({ id: "incidentReportsPage.title" }),
        "/reports/inquiries": formatMessage({ id: "orderReportsPage.title" }),
        "/reports/logistics": formatMessage({ id: "logisticsReportsPage.title" })
    };

    const parsePath = (path: string): string => {
        if (path.match(/\/management\/calendar\/pattern.*/)) {
            return "/management/calendar/pattern";
        }

        if (path.match(/\/management\/products\/detail.*/)) {
            return "/management/products/detail";
        }

        return path;
    };

    const currPath = parsePath(pathname);
    const pathSnippets = currPath.split("/").filter(i => i);
    const breadCrumbItems = pathSnippets.map((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
        const name = breadcrumbNameMap[url];

        return <Breadcrumb.Item key={url}>{url === currPath ? name : <NavLink to={url}>{name}</NavLink>}</Breadcrumb.Item>;
    });

    return (
        <HeaderStyle>
            <InnerContainer>

                <img src={IMAGES.logo} alt="Logo" />

                <nav>
                    <Permission requiredPermissions={[PERMISSIONS.inquiries.read]}>
                        <NavLink to="/inquiries">
                            <FormattedMessage tagName="em" id="nav.inquiries" />
                        </NavLink>
                    </Permission>
                    <Permission requiredPermissions={[PERMISSIONS.inquiries.read]}>
                        <NavLink to="/incidents">
                            <Badge title="Incidenten" offset={[5, -5]} count={inquiriesCount}>
                                <FormattedMessage tagName="em" id="nav.incidents" />
                            </Badge>
                        </NavLink>
                    </Permission>
                    <Permission requiredPermissions={[PERMISSIONS.assets.read]}>
                        <NavLink to="/assets">
                            <FormattedMessage tagName="em" id="nav.assets" />
                        </NavLink>
                    </Permission>
                    <Permission requiredPermissions={[PERMISSIONS.frontend.planning]}>
                        <NavLink to="/planning">
                            <FormattedMessage tagName="em" id="nav.planning" />
                        </NavLink>
                    </Permission>
                    <Permission requiredPermissions={[PERMISSIONS.frontend.management]}>
                        <NavLink to="/management">
                            <FormattedMessage tagName="em" id="nav.management" />
                        </NavLink>
                    </Permission>
                    <Permission requiredPermissions={[PERMISSIONS.reports.read]}>
                        <NavLink to="/reports">
                            <FormattedMessage tagName="em" id="nav.reports" />
                        </NavLink>
                    </Permission>
                </nav>

                <aside>
                    <FormattedMessage id="header.welcome" values={{ name: user?.profile?.name || "" }} />
                    <a onClick={() => logout()}>
                        <FormattedMessage id="header.logout" />
                    </a>
                </aside>
            </InnerContainer>
            {(pathname.startsWith("/management/") || pathname.startsWith("/reports/")) &&
                <InnerContainer>
                    <Breadcrumb>{breadCrumbItems}</Breadcrumb>
                </InnerContainer>
            }
        </HeaderStyle>
    );
};
