import { Form, Modal, Input } from "antd";
import { FormComponentProps } from "antd/lib/form";
import * as React from "react";
import { FunctionComponent } from "react";

import { GetTruckTypesQuery, TruckType, TruckTypeInput } from "@models/graphql";
import { useUpdateTruckType } from "@graphql/hocs/hooks/useUpdateTruckType";
import { useCreateTruckType } from "@graphql/hocs/hooks/useCreateTruckType";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";

import { TruckTypeFormModalStyle } from "./truckTypeFormModalStyle";
import { FormattedMessage } from "react-intl";
import { useFormatMessage } from "@utils/intlHook";
import { ErrorMessage } from "@components/errorMessage/errorMessage";

export interface TruckTypeFormModalProps extends FormComponentProps {
    visible: boolean;
    editItem: TruckType | null;
    closeCallback(): void;
}

export const TruckTypeFormModalComponent: FunctionComponent<TruckTypeFormModalProps> = ({ visible, closeCallback, form, editItem }) => {
    const formatMessage = useFormatMessage();

    const { createTruckType, isLoading: createLoading, error: createError } = useCreateTruckType({
        update(cache, response) {
            const trucksTypesData = cache.readQuery<GetTruckTypesQuery>({ query: GraphQLDocuments.getTruckTypes });

            if (!trucksTypesData || !response.data || !response.data.createTruckType) { return; }

            trucksTypesData.truckTypes.items.push(response.data.createTruckType);

            cache.writeQuery({ query: GraphQLDocuments.getTruckTypes, data: trucksTypesData });
        }
    });
    const { updateTruckType, isLoading: updateLoading, error: updateError } = useUpdateTruckType();

    const handleClose = (): void => {
        closeCallback();
        form.resetFields();
    };

    const handleSubmit = (): void => {
        form.validateFields(async (errors, values) => {
            if (errors) {
                return;
            }

            const truckType: TruckTypeInput = {
                name: values.name
            };

            if (editItem) {
                const { error } = await updateTruckType({
                    id: editItem.id,
                    truckType
                });

                if (!error) {
                    handleClose();
                }
            } else {

                const { error } = await createTruckType({ truckType });

                if (!error) {
                    handleClose();
                }
            }
        });
    };

    return (
        <Modal
            visible={visible}
            okButtonProps={{
                loading: createLoading || updateLoading
            }}
            title={<FormattedMessage id="truckTypeForm.title" />}
            okText={<FormattedMessage id="save" />}
            cancelText={<FormattedMessage id="cancel" />}
            onCancel={handleClose}
            onOk={handleSubmit}
        >

            <TruckTypeFormModalStyle>
                <Form onSubmit={handleSubmit}>
                    <Form.Item label={<FormattedMessage id="truckTypeForm.name" />}>
                        {form.getFieldDecorator("name", {
                            rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }],
                            initialValue: editItem ? editItem.name : undefined
                        })(<Input placeholder={formatMessage({ id: "truckTypeForm.name" })} />)}
                    </Form.Item>
                    <ErrorMessage error={createError || updateError} />
                </Form>
            </TruckTypeFormModalStyle>
        </Modal>
    );
};

export const TruckTypeFormModal = Form.create<TruckTypeFormModalProps>()(TruckTypeFormModalComponent);
