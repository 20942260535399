import { Action } from "@globalState/actions/action";
import { ActionTypes } from "@globalState/actions/types";

export interface UpdatePlanningDateOptions {
    fromDate: string;
    toDate: string;
}

export const updatePlanningDateAction = (data: UpdatePlanningDateOptions): UpdatePlanningDate => ({
    type: ActionTypes.UpdatePlanningDate,
    ...data
});

export interface UpdatePlanningDate extends Action {
    fromDate: string;
    toDate: string;
}

export const isUpdatePlanningDate = (action: Action): action is UpdatePlanningDate => action.type === ActionTypes.UpdatePlanningDate;
