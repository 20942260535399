import styled from "styled-components";

import { InnerContainer } from "@style/innerContainer";
import { COLORS } from "@style/colors";
import { Placeholder } from "@style/placeholder";

export const TruckScheduleHeaderStyle = styled.div`
    background-color: white;
    height: 70px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.10);
    position: relative;
    z-index: 5;

    h1 {
        margin: 0;
        padding: 0;
        font-size: 20px;
        font-weight: bold;
    }

   ${InnerContainer} {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
            display: flex;
            align-items: center;
            color: ${COLORS.textTransparent(0.5)};

            ${Placeholder} {
                width: 150px;
                height: 25px;
            }

            em {
                font-weight: 600;
                color: ${COLORS.text};
            }

            & > .icon {
                margin-right: 25px;
                color: ${COLORS.text};
            }

            nav {
                margin-left: 25px;
                padding-left: 25px;
                border-left: 1px solid ${COLORS.textTransparent(0.1)};
                display: flex;
                align-items: center;

                a {
                    opacity: 0.6;
                    display: block;
                    cursor: pointer;
                    color: ${COLORS.text};

                    &.active , &:hover {
                        opacity: 1;
                        color: ${COLORS.primary};
                    }

                    &:last-of-type {
                        margin-left: 15px;
                    }
                }
            }
        }      
    }

`;
