import styled from "styled-components";
import { PlanningSideBarStyle } from "@components/planningSideBar/planningSideBarStyle";
import { COLORS } from "@style/colors";
import { ErrorMessageStyle } from "@components/errorMessage/errorMessageStyle";

export const TruckScheduleSidebarStyle = styled(PlanningSideBarStyle)`
    overflow: auto;
    main {
        padding: 25px;
    }

    section {
        h2 {
            background: ${COLORS.lightGrey};
            height: 45px;
            font-size: 12px;
            display: flex;
            align-items: center;
            padding: 0 25px;

            .distance {
                margin-left: auto;
                font-weight: 600;
                font-size: 0.8rem;
            }
        }
    }
`;

export const NotOptimalBlock = styled.div`
    padding: 25px;

    .ant-btn {
        width: 100%;
    }

    ${ErrorMessageStyle} {
        background: ${COLORS.error};
        color: white;
        font-size: 12px;
    }
`;

export const UnsavedBlock = styled.div`
    padding: 25px;
    color: white;
    background: ${COLORS.primary};

    em {
        display: block;
        opacity: 0.6;
        margin: 10px 0;
    }

    & > div {
        width: 100%;
        display: flex;
        margin-top: 15px;

        .ant-btn {
            width: 100%;
            color: ${COLORS.primary};
            font-weight: 600;

            &:not(:last-of-type) {
                margin-right: 15px;
            }
        }
    }

    ${ErrorMessageStyle} {
        background: ${COLORS.error};
        color: white;
        font-size: 12px;
    }
`;
