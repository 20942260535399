/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { DeleteProcessorMutation, DeleteProcessorMutationVariables } from "@models/graphql";

const defaultDeleteProcessorOptions: MutationHookOptions<DeleteProcessorMutation, DeleteProcessorMutationVariables> = {};

export const useDeleteProcessor = (options?: MutationHookOptions<DeleteProcessorMutation, DeleteProcessorMutationVariables>) => {
    const [mutation, { data, loading, error }] = useMutation<DeleteProcessorMutation, DeleteProcessorMutationVariables>(
        GraphQLDocuments.deleteProcessor
    );

    const deleteProcessor = async (
        variables: DeleteProcessorMutationVariables,
        opts?: MutationHookOptions<DeleteProcessorMutation, DeleteProcessorMutationVariables>
    ) => {
        try {
            const response = await mutation({
                ...defaultDeleteProcessorOptions,
                ...options,
                ...opts,
                variables
            });

            return {
                error: response.errors,
                data: response.data
            };
        } catch (err) {
            return {
                error: err
            };
        }
    };

    return {
        isLoading: loading,
        error,
        data,
        deleteProcessor
    };
};
