import styled from "styled-components";
import { COLORS } from "@style/colors";
import { MutationLoadingStyle } from "@components/mutationLoading/mutationLoadingStyle";

export const PlanningInquiryItemStyle = styled.div`
    display: flex;
    padding: 15px;
    justify-content: space-between;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    background: white;
    border: 1px solid transparent;
    border-radius: 4px;
    transition: box-shadow 0.4s, border-color 0.4s;
    width: 100%;
    margin-bottom: 15px;
    position: relative;
    font-size: 12px;

    &.disabled {
        opacity: 0.4;
    }

    .address {
        margin-bottom: 10px;
        line-height: 150%;
        font-weight: 200;
    }

    em {
        font-weight: 600;
        margin-right: 15px;
    }

    .products {
        display: flex;
        opacity: 0.4;
    }

    .buttons {
        display: flex;
        align-items: center;

        .icon {
            background: #f5f5f5;
            color: ${COLORS.primary};
            width: 25px;
            height: 25px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;

            &:hover {
                color: white;
                background-color: ${COLORS.primary};
            }

            &:not(:last-child) {
                margin-right: 15px;
            }
        }
    }

    .type {
        font-weight: 200;
        color: ${COLORS.primary};
        font-size: 12px;
        margin-top: 10px;
    }

    &:hover,
    &.isDragging {
        border-color: ${COLORS.primaryTransparant(0.5)};
    }

    &.isDragging {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
        border-color: ${COLORS.primaryTransparant(1)};
    }

    .products {
        display: flex;
        flex-direction: column;

        .product:not(:last-of-type) {
            margin-bottom: 5px;
        }
    }

    ${MutationLoadingStyle} {
        position: absolute;
        bottom: -10px;
        right: -10px;
    }
`;
