import styled from "styled-components";
import { COLORS } from "@style/colors";
import { InnerContainer } from "@style/innerContainer";
import { ErrorMessageStyle } from "@components/errorMessage/errorMessageStyle";

export const PatternFormHeaderStyle = styled.div`
    .top {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .ant-btn {
            margin-left: 15px;
            width: 145px;
        }
    }

    ${ErrorMessageStyle} {
        margin-top: 25px;
    }
`;

export const Steps = styled.div`
    background-color: ${COLORS.lightGrey};
    width: 100%;
    height: 50px;
    margin: 30px 0;

    ${InnerContainer} {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
            align-items: center;

            &.disabled {
                color: ${COLORS.textTransparent(0.1)};
                pointer-events: none;
            }
        }

        a:last-of-type:not(:first-of-type) {
            margin-left: 20px;
            padding-left: 20px;
            border-left: 1px solid ${COLORS.textTransparent(0.15)};
        }

        .buttons {
            .icon {
                font-size: 10px;
                padding-top: 3px;
                display: inline-block;
            }

            .arrow-left {
                margin-right: 20px;
            }

            .arrow-right {
                margin-left: 20px;
            }
        }


        .steps {
            display: flex;
            align-items: center;
            height: 100%;
        }
    }
`;
