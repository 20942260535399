/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useApolloClient } from "@apollo/react-hooks";
import { ApplicantAutocomplete } from "@components/applicantAutocomplete/applicantAutocomplete";
import { ErrorMessage } from "@components/errorMessage/errorMessage";
import { InquiryDeliveryLocation } from "@components/inquiryDeliveryLocation/inquiryDeliveryLocation";
import { DeliveryItem } from "@components/inquiryDeliveryModal/inquiryDeliveryModalStyle";
import { PageHeader } from "@components/pageHeader/pageHeader";
import { PERMISSIONS } from "@components/permission/permission";
import { usePermissions } from "@components/permission/permissionProvider";
import { CapacityTooltip } from "@components/planningWeekOverview/collectionRoundBlock/collectionRoundBlockStyle";
import { useCreateInquiry } from "@graphql/hocs/hooks/useCreateInquiry";
import { useGetFreeDeliveryDays } from "@graphql/hocs/hooks/useGetFreeDeliveryDays";
import { useGetFreePickupDays } from "@graphql/hocs/hooks/useGetFreePickupDays";
import { useGetMunicipalities } from "@graphql/hocs/hooks/useGetMunicipalities";
import { useGetProductNames } from "@graphql/hocs/hooks/useGetProductNames";
import { useGetStreets } from "@graphql/hocs/hooks/useGetStreets";
import { CollectionDay, DeliveryMethod, GetApplicantsQuery, InquiryCoordinates, InquiryLocation, UploadMediaMutation } from "@models/graphql";
import { StepIcon } from "@pages/stepIcon/stepIcon";
import { COLORS } from "@style/colors";
import { Icon } from "@style/icon";
import { useFormatMessage } from "@utils/intlHook";
import { mapsGeocode } from "@utils/mapsGeocode";
import { useRouter } from "@utils/routerHook";
import { Alert, Button, Checkbox, Form, Input, InputNumber, Progress, Radio, Select, Spin, Tooltip } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { FormComponentProps } from "antd/lib/form";
import { NormalizedCache } from "apollo-cache-inmemory";
import * as moment from "moment";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { CreateInquiryPageStyle, ProductItem } from "./createInquiryPageStyle";

export interface CreateInquiryPageProps extends FormComponentProps { }

interface FormValues {
    applicant: GetApplicantsQuery["cardsApplicants"][0];
    deliveryLocation?: InquiryLocation;
    differentDeliveryLocation?: boolean;
    products: { productId: string; amount: number }[];
    pickupDayId: string;
    publicDomain?: boolean;
    deliveryLocationDescription?: string;
    municipalityId?: string;
    deliveryMunicipalityId?: string;
    street?: string;
    deliveryDayId?: string;
    phone: string;
    digibeet: boolean;
    cardsPayment: boolean;
    email?: string;
    addition: string;
    busNumber: string;
    media: UploadMediaMutation["uploadMedia"][];
		ignoreCollectedItems?: boolean;
}

export const CreateInquiryPageComponent: FunctionComponent<CreateInquiryPageProps> = ({ form }) => {
    const { permissions } = usePermissions();

    const formatMessage = useFormatMessage();
    const client = useApolloClient();
    /**
     * STATE
     */
    const [productCount, setProductCount] = React.useState(0);
    const [selectedProducts, setProducts] = React.useState<{ index: number; initialValue?: string }[]>([{ index: 0 }]);
    const [position, setPosition] = React.useState<null | { lat: number; lng: number }>(null);
    const [, forceUpdate] = React.useReducer((x) => x + 1, 0);
    const { history } = useRouter();

    /**
     * QUERIES & MUTATIONS
     */

    const formValues: FormValues = form.getFieldsValue() as FormValues;
    const {
        products: formProducts,
        deliveryLocation,
        differentDeliveryLocation,
        applicant,
        municipalityId,
        street,
        pickupDayId,
        digibeet
    } = formValues;

    React.useEffect(() => {
        forceUpdate("");
    }, [selectedProducts.length]);

    const { products } = useGetProductNames();
    const { createInquiry, error, isLoading } = useCreateInquiry();
    const { municipalities, municipalitiesLoading } = useGetMunicipalities();

    const deliveryMunicipality = React.useMemo(() => {
        if (!differentDeliveryLocation || !municipalities.length || !deliveryLocation) {
            return null;
        }

        const match = municipalities.find(m => m.name.toLowerCase().includes(deliveryLocation.address.city.toLowerCase()));

        return match || null;
    }, [differentDeliveryLocation, deliveryLocation, municipalities]);

    const selectedMunicipality = React.useMemo(() => {
        if (differentDeliveryLocation && deliveryMunicipality) {
            return deliveryMunicipality;
        }

        return municipalities.find(m => m.id === municipalityId);
    }, [municipalities, municipalityId, differentDeliveryLocation, deliveryMunicipality]);

    const applicantMunicipality = React.useMemo(() => municipalities.find(m => m.id === municipalityId), [municipalities, municipalityId]);

    const { streets, streetsLoading } = useGetStreets({
        variables: {
            municipalityId: selectedMunicipality ? selectedMunicipality.id : ""
        },
        skip: !selectedMunicipality
    });

    const skipPickupDays = React.useMemo(() => {
        if (!formProducts) {
            return true;
        }

        if (formProducts.some(prod => !prod.productId)) {
            return true;
        }

        if (!deliveryLocation && differentDeliveryLocation) {
            return true;
        }

        if (!differentDeliveryLocation && !applicant) {
            return true;
        }

        return false;
    }, [formProducts, deliveryLocation, applicant, differentDeliveryLocation]);

    const { freeDeliveryDays, fetchMoreFreeDeliveryDays, freeDeliveryDaysCount, freeDeliveryDaysLoading } = useGetFreeDeliveryDays({
        variables: {
            municipalityId: selectedMunicipality ? selectedMunicipality.id : "",
            products: (formProducts || []).filter(p => p !== null),
            pickupDayId,
            paging: {
                offset: 0,
                limit: 5
            }
        },
        skip: skipPickupDays || !pickupDayId,
        fetchPolicy: "network-only"
    });

    const { freePickupDays, freePickupDaysLoading, freePickupDaysCount, fetchMoreFreePickupDays } = useGetFreePickupDays({
        variables: {
            municipalityId: selectedMunicipality ? selectedMunicipality.id : "",
            products: (formProducts || []).filter(p => p !== null),
            paging: {
                offset: 0,
                limit: 5
            }
        },
        skip: skipPickupDays,
        fetchPolicy: "network-only"
    });

    /**
     * Handlers
     */

    const errors = form.getFieldsError();
    const partOneDone =
        form.isFieldTouched("applicant") &&
        !errors["applicant"] &&
        (!form.getFieldValue("differentDeliveryLocation") || form.getFieldValue("deliveryLocation") && !errors["deliveryLocation"]);
    const partTwoDone =
        selectedProducts.every(prod => form.isFieldTouched(`products[${prod.index}].productId`) || !!prod.initialValue) &&
        !!errors.products &&
        (errors.products as { amount?: number; productId?: string }[]).every(prod => !prod.amount && !prod.productId);
    const partThreeDone = form.isFieldTouched("pickupDayId") && !errors["pickupDayId"];
    const partFourDone = form.isFieldTouched("deliveryDayId") && !errors["deliveryDayId"];

    const onSubmit = (): void => {
        form.validateFields(async (errs, values: FormValues) => {
            if (!errs) {
                try {
                    let coordinates: InquiryCoordinates | undefined;
                    let address = values.applicant.address;
                    let municipalityIdVal = values.municipalityId!;

                    if (!differentDeliveryLocation && address) {
                        coordinates = await mapsGeocode(address as any);
                    } else {
                        municipalityIdVal = values.deliveryMunicipalityId || deliveryMunicipality!.id;
                        address = {
                            street: values.deliveryLocation!.address.street,
                            streetNumber: values.deliveryLocation!.address.streetNumber || "0",
                            busNumber: values.deliveryLocation!.address.busNumber,
                            numberAddition: values.deliveryLocation!.address.numberAddition,
                            city: values.deliveryLocation!.address.city,
                            postalCode: values.deliveryLocation!.address.postalCode,
                            country: values.deliveryLocation!.address.country
                        };
                        address.numberAddition = values.addition;
                        address.busNumber = values.busNumber;
                        coordinates = values.deliveryLocation!.coordinates;
                    }

                    if (!coordinates) {
                        throw new Error("No coordinates found for applicant location");
                    }

                    delete address.__typename;
                    delete values.applicant.__typename;
                    delete values.applicant.address.__typename;

                    const { response } = await createInquiry({
                        inquiry: {
                            digibeet: values.digibeet,
                            applicant: {
                                ...values.applicant,
                                phone: values.phone,
                                email: values.email
                            },
                            checklist: {
                                publicDomain: values.publicDomain || false,
                                deliveryLocationDescription: values.deliveryLocationDescription || ""
                            },
                            checks: {
                                ignore: {
                                    collectedItems: values.ignoreCollectedItems
                                }
                            },
                            pickupDayId: values.pickupDayId,
                            pickupAddress: {
                                address,
                                coordinates,
                                municipalityId: municipalityIdVal
                            },
                            products: values.products,
                            deliveryDayId: values.deliveryDayId,
                            cardsPayment: values.cardsPayment,
                            media: values.media ? values.media.filter((item) => !!item).map(item => ({
                                id: item.id
                            })) : undefined
                        }
                    });

                    if (response && response.data && response.data.createInquiry) {
                        history.push(`/inquiries/${response.data.createInquiry.id}`);
                        const data: NormalizedCache = (client.cache as any).data;

                        Object.keys((data as any).data).forEach(key => key.match(/^Inquiry/) && data.delete(key));
                        Object.keys((data as any).data).forEach(key => key.match(/^\$ROOT_QUERY\.inquiries/) && data.delete(key));

                        Object.keys((data as any).data).forEach(key => key.match(/^CollectinRound/) && data.delete(key));
                        Object.keys((data as any).data).forEach(key => key.match(/^\$ROOT_QUERY\.collectionRounds/) && data.delete(key));
                    }
                } catch (err) {
                    console.error(err);
                }
            }
        });
    };

    const resetCollectionRound = (): void => {
        form.setFieldsValue({
            pickupDayId: undefined
        });
    };

    const handleDigibeetChange = (val: CheckboxChangeEvent): void => {
        if (val.target.checked) {
            form.setFieldsValue({
                cardsPayment: true
            });
        }
    };

    const deleteProduct = (productIndex: number): void => {
        setProducts(selectedProducts.filter(p => p.index !== productIndex));
        resetCollectionRound();
    };

    const addProduct = (productId: string): void => {
        setProducts([...selectedProducts, { index: productCount + 1, initialValue: productId }]);
        setProductCount(productCount + 1);
        resetCollectionRound();
    };

    const onDeliveryChange = (location: InquiryLocation): void => {
        setPosition(location.coordinates);
        resetCollectionRound();
    };

    const handleMunicipalityChange = (): void => {
        form.setFieldsValue({
            street: undefined,
            applicant: undefined
        });
    };

    const handleStreetChange = (): void => {
        form.setFieldsValue({
            applicant: undefined
        });
    };

    /**
     * RENDER
     */
    const renderProducts = (): JSX.Element[] => selectedProducts.map(product =>
        <ProductItem key={product.index}>
            <Form.Item>
                {form.getFieldDecorator(`products[${product.index}].productId`, {
                    initialValue: product.initialValue,
                    rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }]
                })(<Select onChange={resetCollectionRound} placeholder={formatMessage({ id: "createInquiryPage.product" })}>
                    {products.map(p =>
                        <Select.Option key={p.id} value={p.id}>
                            {p.name}
                        </Select.Option>)}
                </Select>)}
            </Form.Item>
            <Form.Item>
                {form.getFieldDecorator(`products[${product.index}].amount`, {
                    initialValue: 1,
                    rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }]
                })(<InputNumber
                    onChange={resetCollectionRound}
                    min={1}
                    precision={0}
                    placeholder={formatMessage({ id: "createInquiryPage.amount" })}
                />)}
            </Form.Item>

            {selectedProducts.length > 1 && <Icon type="trash" onClick={() => deleteProduct(product.index)} />}
        </ProductItem>);

    const renderDayItem = (day: CollectionDay): JSX.Element | null => {
        if (
            day.cargo === null || day.cargo === undefined ||
            day.maxCargo === null || day.maxCargo === undefined ||
            day.addresses === null || day.addresses === undefined ||
            day.maxAddresses === null || day.maxAddresses === undefined
        ) {
            return null;
        }

        let progress = 0;

        if (day.cargo / day.maxCargo > day.addresses / day.maxAddresses) {
            progress = day.cargo / day.maxCargo * 100;
        } else {
            progress = day.addresses / day.maxAddresses * 100;
        }

        return (
            <Radio key={day.date} value={day.id}>
                <DeliveryItem>
                    <em>
                        {moment(day.date).format("dd DD MMM YYYY")} — {formatMessage({ id: `daypart.${day.dayPart}` })}
                    </em>
                    <Progress
                        strokeColor={progress < 100 ? COLORS.primaryTransparant(0.8) : progress > 100 ? COLORS.error : COLORS.orange}
                        percent={progress}
                        showInfo={false}
                        strokeWidth={4}
                    />
                    <Tooltip
                        placement="leftTop"
                        overlayClassName="capacity-tooltip"
                        title={
                            <CapacityTooltip>
                                <div className="item">
                                    {day.cargo} / {day.maxCargo} {formatMessage({ id: "effectiveCargos" })}
                                </div>
                                <div className="item">
                                    {day.addresses} / {day.maxAddresses} {formatMessage({ id: "adresses" })}
                                    <div className="sub">{day.cargoPickup} {formatMessage({ id: "pickups" })}</div>
                                    <div className="sub">{day.cargoDelivery} {formatMessage({ id: "deliveries" })}</div>
                                </div>
                            </CapacityTooltip>
                        }
                    >
                        <Icon type="info" />
                    </Tooltip>
                </DeliveryItem>
            </Radio>
        );
    };

    const renderDeliveryDay = (): JSX.Element | null => {
        if (!formProducts || !products) {
            return null;
        }

        const needsDelivery = formProducts.some(prod =>
            products.some(pr => pr.id === prod.productId && pr.deliveryMethod === DeliveryMethod.Delivery));

        if (!needsDelivery) {
            return null;
        }

        return (
            <section>
                <h1 className={partThreeDone ? "done" : ""}>
                    <StepIcon done={partFourDone} step={4} />
                    <FormattedMessage id="createInquiryPage.deliveryDay" />
                </h1>
                <main>
                    <Spin spinning={freeDeliveryDaysLoading}>
                        <Form.Item>
                            {!pickupDayId && <Alert type="info" message={formatMessage({ id: "selectDeliveryDayFirst" })} />}
                            {form.getFieldDecorator("deliveryDayId", {
                                rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }]
                            })(<Radio.Group>{freeDeliveryDays.map(renderDayItem)}</Radio.Group>)}
                            {pickupDayId && freeDeliveryDaysCount === 0 && !freeDeliveryDaysLoading &&
                                <Alert type="info" message={formatMessage({ id: "noFreeDeliveryDays" })} />
                            }
                            {freeDeliveryDays.length < freeDeliveryDaysCount && pickupDayId &&
                                <span onClick={() => fetchMoreFreeDeliveryDays(freeDeliveryDays.length)} className="loadMore">
                                    {formatMessage({ id: "freeDays.loadMore" })}
                                </span>
                            }
                        </Form.Item>
                    </Spin>
                </main>
            </section>
        );
    };

    const canChangeDigibeetOrPayment = permissions.has(PERMISSIONS.inquiries.changePayment);

    return (
        <>
            <PageHeader shadow backArrow title="createInquiryPage.title" link="/inquiries">
                <Button loading={isLoading} type="primary" onClick={onSubmit}>
                    <FormattedMessage id="save" />
                </Button>
            </PageHeader>
            <CreateInquiryPageStyle>
                <ErrorMessage style={{ marginBottom: 25 }} error={error} />
                <Form>
                    <section>
                        <h1 className={partOneDone ? "done" : ""}>
                            <StepIcon done={partOneDone} step={1} />
                            <FormattedMessage id="createInquiryPage.party" />
                        </h1>
                        <main className="split">
                            <div>
                                <Form.Item style={{ margin: 0 }} label={formatMessage({ id: "createInquiryPage.digibeet" })}>
                                    {form.getFieldDecorator("digibeet", { initialValue: true, valuePropName: "checked" })(<Checkbox disabled={!canChangeDigibeetOrPayment} onChange={handleDigibeetChange}>
                                        <FormattedMessage id="createInquiryPage.digibeet" />
                                    </Checkbox>)}
                                </Form.Item>
                                <Form.Item style={{ margin: 0 }} label={formatMessage({ id: "createInquiryPage.cardsPayment" })}>
                                    {form.getFieldDecorator("cardsPayment", { initialValue: true, valuePropName: "checked" })(<Checkbox disabled={!canChangeDigibeetOrPayment || digibeet !== false}>
                                        <FormattedMessage id="createInquiryPage.cardsPayment" />
                                    </Checkbox>)}
                                </Form.Item>
                                <Form.Item label={formatMessage({ id: "createInquiryPage.municipality" })} colon={false}>
                                    {form.getFieldDecorator("municipalityId", {
                                        rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }]
                                    })(<Select
                                        onChange={handleMunicipalityChange}
                                        loading={municipalitiesLoading}
                                        placeholder={formatMessage({ id: "createInquiryPage.municipality" })}
                                        showSearch
                                        filterOption={(val, el) => (el.props.children as string).toLowerCase().includes(val.toLowerCase())}
                                    >
                                        {municipalities.map(m =>
                                            <Select.Option key={m.id} value={m.id}>
                                                {m.name}
                                            </Select.Option>)}
                                    </Select>)}
                                </Form.Item>
                                <Form.Item label={formatMessage({ id: "createInquiryPage.street" })} colon={false}>
                                    {form.getFieldDecorator("street", {
                                        rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }]
                                    })(<Select
                                        onChange={handleStreetChange}
                                        disabled={!selectedMunicipality}
                                        loading={streetsLoading}
                                        placeholder={formatMessage({ id: "createInquiryPage.street" })}
                                        showSearch
                                    >
                                        {streets.map(s =>
                                            <Select.Option key={s} value={s}>
                                                {s}
                                            </Select.Option>)}
                                    </Select>)}
                                </Form.Item>
                                <Form.Item label={formatMessage({ id: "createInquiryPage.applicant" })} colon={false}>
                                    {form.getFieldDecorator("applicant", {
                                        rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }]
                                    })(<ApplicantAutocomplete
                                        municipality={applicantMunicipality ? applicantMunicipality.name : undefined}
                                        street={street}
                                    />)}
                                </Form.Item>

                                <Form.Item label={formatMessage({ id: "createInquiryPage.email" })} colon={false}>
                                    {form.getFieldDecorator("email", {
                                        rules: [{ required: !digibeet, message: formatMessage({ id: "requiredField" }) }]
                                    })(<Input placeholder={formatMessage({ id: "createInquiryPage.email" })} />)}
                                </Form.Item>
                                <Form.Item label={formatMessage({ id: "createInquiryPage.phone" })} colon={false}>
                                    {form.getFieldDecorator("phone", {
                                        rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }]
                                    })(<Input placeholder={formatMessage({ id: "createInquiryPage.phone" })} />)}
                                </Form.Item>
                                <Form.Item label={formatMessage({ id: "createInquiryPage.publicProperty" })} colon={false}>
                                    {form.getFieldDecorator("publicDomain")(<Checkbox>
                                        <FormattedMessage id="createInquiryPage.publicProperty" />
                                    </Checkbox>)}
                                </Form.Item>
                                <Form.Item label={formatMessage({ id: "createInquiryPage.deliveryLocationDescription" })} colon={false}>
                                    {form.getFieldDecorator("deliveryLocationDescription")(<Input.TextArea style={{ marginBottom: 25 }} />)}
                                </Form.Item>
                            </div>
                            
                            <div>
                                <Form.Item style={{ margin: 0 }} label={formatMessage({ id: "createInquiryPage.deliveryLocation" })}>
                                    {form.getFieldDecorator("differentDeliveryLocation")(<Checkbox>
                                        <FormattedMessage id="createInquiryPage.differentLocation" />
                                    </Checkbox>)}
                                </Form.Item>

                                { differentDeliveryLocation && <InquiryDeliveryLocation
                                    form={form}
                                    deliveryMunicipality={deliveryMunicipality}
                                    onDeliveryChange={onDeliveryChange}
                                    position={position}
                                    setPosition={setPosition}
                                />}
                            </div>
                        </main>
                    </section>

                    <section>
                        <h1 className={partTwoDone ? "done" : ""}>
                            <StepIcon done={partTwoDone} step={2} />
                            <FormattedMessage id="createInquiryPage.product" />
                        </h1>
                        <main>
														
                            <Form.Item label={formatMessage({ id: "createInquiryPage.ignoreCollectedItems" })} colon={false}>
                                {form.getFieldDecorator("ignoreCollectedItems")(<Checkbox>
                                    <FormattedMessage id="createInquiryPage.ignoreCollectedItems" />
                                </Checkbox>)}
                            </Form.Item>

                            {renderProducts()}

                            <Select value={undefined} onSelect={addProduct} placeholder={formatMessage({ id: "createInquiryPage.addProduct" })}>
                                {products.map(p =>
                                    <Select.Option key={p.id} value={p.id}>
                                        {p.name}
                                    </Select.Option>)}
                            </Select>
                        </main>
                    </section>
                    <section>
                        <h1 className={partThreeDone ? "done" : ""}>
                            <StepIcon done={partThreeDone} step={3} />
                            <FormattedMessage id="createInquiryPage.pickupDay" />
                        </h1>
                        <main>
                            <Spin spinning={freePickupDaysLoading}>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    {skipPickupDays &&
                                        <Alert type="info" message={formatMessage({ id: "createInquiryPage.selectPartyAndProductsFirst" })} />
                                    }
                                    {form.getFieldDecorator("pickupDayId", {
                                        rules: [{ required: true, message: formatMessage({ id: "requiredField" }) }]
                                    })(<Radio.Group>{freePickupDays.map(renderDayItem)}</Radio.Group>)}
                                    {!skipPickupDays && freePickupDaysCount === 0 && !freeDeliveryDaysLoading &&
                                        <Alert type="info" message={formatMessage({ id: "noFreePickupDays" })} />
                                    }
                                    {freePickupDays.length < freePickupDaysCount && !skipPickupDays &&
                                        <span onClick={() => fetchMoreFreePickupDays(freePickupDays.length)} className="loadMore">
                                            {formatMessage({ id: "freeDays.loadMore" })}
                                        </span>
                                    }
                                </Form.Item>
                            </Spin>
                        </main>
                    </section>
                    {renderDeliveryDay()}
                </Form>
            </CreateInquiryPageStyle>
        </>
    );
};

export const CreateInquiryPage = Form.create()(CreateInquiryPageComponent);
