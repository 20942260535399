import styled from "styled-components";

import { COLORS } from "@style/colors";

export const InquiryOverviewFilterStyle = styled.aside`
    width: 275px;
    margin-right: 50px;

    section {
        margin: 25px 0;
        h1 {
            color: ${COLORS.primary};
            font-size: 14px;
            font-weight: 600;
            padding-bottom: 15px;
            margin-bottom: 20px;

            border-bottom: 1px solid ${COLORS.primaryTransparant(0.25)}
        }
    }


    .ant-checkbox-group {
        display: flex;
        flex-direction: column;
    }

    .ant-btn {
        color: ${COLORS.primary};
        border-color: ${COLORS.primary};
        width: 100%;
        text-align: left;

        .icon {
            margin-right: 15px;
        }
    }



    .ant-select {
        width: 100%;
    }

`;

export const SearchBox = styled.div`
    display: flex;
    align-items: center;

    .icon {
        margin-right: 10px;
    }

    input {
        border: none;
        flex: 1;
        height: 30px;
        margin: 0;
        padding: 0;
        display: block;
        outline: none;

        &::placeholder {
            opacity: 0.5;
        }
    }
`;
