import styled from "styled-components";

import { COLORS } from "@style/colors";
import { ProcessorsPageStyle } from "@pages/processorsPage/processorsPageStyle";

export const NotificationsPageStyle = styled(ProcessorsPageStyle)`
    .icon.checkmark {
        color: ${COLORS.primary};
        font-size: 10px;
        margin-top: 2px;
        display: block;
    }

    em {
        margin-right: 10px;
        font-weight: 600;
    }
`;
