/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { GetCollectorsQuery, GetCollectorsQueryVariables } from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

export const useGetCollectors = (options?: QueryHookOptions<GetCollectorsQuery, GetCollectorsQueryVariables>) => {
    const { data, error, loading } = useQuery<GetCollectorsQuery, GetCollectorsQueryVariables>(GraphQLDocuments.getCollectors, options);

    return {
        collectors: data && data.collectors ? data.collectors : [],
        collectorsLoading: loading,
        collectorsError: error
    };
};
