import styled from "styled-components";
import { InquiryStatusType } from "@models/graphql";
import { COLORS } from "@style/colors";

export const InquiryStatusStyle = styled.span`
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: ${(props: { status: InquiryStatusType }) => 
        // tslint:disable-next-line: switch-default
        COLORS.status(props.status)
};

    border: ${(props: { status: InquiryStatusType }) => {

        if (props.status === InquiryStatusType.Processing) {
            return "1px solid #FF0000";
        }

        return "";
    }};
`;
