/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { UpdateInquiryCollectionDayMutation, UpdateInquiryCollectionDayMutationVariables } from "@models/graphql";

const defaultUpdateInquiryCollectionDayOptions: MutationHookOptions<
    UpdateInquiryCollectionDayMutation,
    UpdateInquiryCollectionDayMutationVariables
> = {};

export const useUpdateInquiryCollectionDay = (
    options?: MutationHookOptions<UpdateInquiryCollectionDayMutation, UpdateInquiryCollectionDayMutationVariables>
) => {
    const [mutation, { data, loading, error }] = useMutation<
        UpdateInquiryCollectionDayMutation,
        UpdateInquiryCollectionDayMutationVariables
    >(GraphQLDocuments.updateInquiryCollectionDay);

    const updateInquiryCollectionDay = async (variables: UpdateInquiryCollectionDayMutationVariables) => {
        try {
            const response = await mutation({
                ...defaultUpdateInquiryCollectionDayOptions,
                ...options,
                variables
            });

            return {
                data: response.data,
                error: response.errors
            };
        } catch (err) {
            return {
                error: err
            };
        }
    };

    return {
        isLoading: loading,
        error,
        data,
        updateInquiryCollectionDay
    };
};
