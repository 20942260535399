import { IMAGES } from "@assets/images";
import { Permission, PERMISSIONS } from "@components/permission/permission";
import { InnerContainer } from "@style/innerContainer";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { ManagementPageStyle } from "./managementPageStyle";

export interface SettingsPageProps {
    match: any;
}

export const ManagementPage: FunctionComponent<SettingsPageProps> = () => {

    const items = [
        {
            icon: IMAGES.calendar,
            text: "settingsPage.calendarTitle",
            link: "/management/calendar",
            requiredPermissions: [PERMISSIONS.collectionPatterns.read]
        },
        {
            icon: IMAGES.products,
            text: "settingsPage.productsTitle",
            link: "/management/products",
            requiredPermissions: [PERMISSIONS.products.read]
        },
        {
            icon: IMAGES.incidents,
            text: "settingsPage.incidentsTitle",
            link: "/management/incidents",
            requiredPermissions: [PERMISSIONS.incidentReasons.read]
        },
        {
            icon: IMAGES.notifications,
            text: "settingsPage.notificationsTitle",
            link: "/management/notifications",
            requiredPermissions: [PERMISSIONS.notificationConfigurations.read]
        },
        {
            icon: IMAGES.users,
            text: "settingsPage.usersTitle",
            link: "/management/collectors",
            requiredPermissions: [PERMISSIONS.users.read]
        },
        {
            icon: IMAGES.inventory,
            text: "settingsPage.inventoryTitle",
            link: "/management/inventory",
            requiredPermissions: [PERMISSIONS.assetTypes.read]
        },
        {
            icon: IMAGES.trucks,
            text: "settingsPage.trucksTitle",
            link: "/management/trucks",
            requiredPermissions: [PERMISSIONS.trucks.read]
        },
        {
            icon: IMAGES.processors,
            text: "settingsPage.processorsTitle",
            link: "/management/processors",
            requiredPermissions: [PERMISSIONS.processors.read]
        },
        {
            icon: IMAGES.upload,
            text: "settingsPage.weightDataTitle",
            link: "/management/weight-data",
            requiredPermissions: [PERMISSIONS.reports.csvUpload]
        }
    ];

    return (
        <ManagementPageStyle>
            <h1><FormattedMessage id="settingsPage.title"></FormattedMessage></h1>
            <InnerContainer>
                {items.map(item =>
                    <Permission requiredPermissions={item.requiredPermissions} key={item.text}>
                        <Link to={item.link}>
                            <div>
                                <img src={item.icon} alt={item.text} />
                            </div>

                            <h2><FormattedMessage id={item.text}></FormattedMessage></h2>
                        </Link>
                    </Permission>)}
            </InnerContainer>
        </ManagementPageStyle>
    );
};
