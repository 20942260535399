import styled from "styled-components";
import { ErrorMessageStyle } from "@components/errorMessage/errorMessageStyle";
import { COLORS } from "@style/colors";

export const PatternTableStyle = styled.div`


    ${ErrorMessageStyle} {
        margin: 25px 0;
    }

    .deactivated-row {
        color: ${COLORS.textTransparent(0.2)};
    }

    .icon {
        cursor: pointer;

        &.active {
            color: ${COLORS.primary};
            font-size: 16px;
        }

        &.deactivated {
            color: ${COLORS.orange};
            opacity: 0.3;
            cursor: initial;
        }

        &.not-active {
            font-size: 16px;
            opacity: 0.3;
            
            &:hover {
                color: ${COLORS.primary};
                opacity: 1;
            }
        }
    }


    .buttons {
        width: 100%;
        display: flex;
        justify-content: flex-end;


        .anticon {
            margin-left: 15px;
            padding: 5px;
        }



        .icon {
            padding: 5px;
            opacity: 0.5;
            transition: 0.2s;

            &:hover {
                opacity: 1;
            }

            &:not(:first-of-type) {
                margin-left: 15px;
            }
        }
    }
`;
