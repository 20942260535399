/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ErrorMessage } from "@components/errorMessage/errorMessage";
import { InquiryDeliveryLocation } from "@components/inquiryDeliveryLocation/inquiryDeliveryLocation";
import { useGetMunicipalities } from "@graphql/hocs/hooks/useGetMunicipalities";
import { useUpdateInquiry } from "@graphql/hocs/hooks/useUpdateInquiry";
import { InquiryDetailFragment, InquiryLocation } from "@models/graphql";
import { useFormatMessage } from "@utils/intlHook";
import { Checkbox, Form, Input, Modal } from "antd";
import { FormComponentProps } from "antd/lib/form";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { UpdateInquiryPickupAddressModalStyle } from "./updateInquiryPickupAddressModalStyle";

export interface UpdateInquiryPickupAddressModalProps extends FormComponentProps {
    inquiry?: InquiryDetailFragment;
    visible: boolean;
    closeCallback(): void;
}

export const UpdateInquiryPickupAddressModalComponent: FunctionComponent<UpdateInquiryPickupAddressModalProps> = ({
    inquiry,
    closeCallback,
    visible,
    form
}) => {
    const [position, setPosition] = React.useState<null | { lat: number; lng: number }>(null);

    const formatMessage = useFormatMessage();
    const [updateInquiry, { error, loading }] = useUpdateInquiry();
    const { municipalities } = useGetMunicipalities();

    const deliveryLocation = form.getFieldValue("deliveryLocation");

    const deliveryMunicipality = React.useMemo(() => {
        if (!municipalities.length || !deliveryLocation) {
            return null;
        }

        const match = municipalities.find((m) => m.name.toLowerCase().includes(deliveryLocation.address.city.toLowerCase()));

        return match || null;
    }, [deliveryLocation, municipalities]);

    React.useEffect(() => {
        if (inquiry?.pickupAddress.coordinates) {
            setPosition(inquiry.pickupAddress.coordinates);
        }
    }, [inquiry]);

    const handleClose = (): void => {
        form.resetFields();
        closeCallback();
    };

    const onDeliveryChange = (location: InquiryLocation): void => {
        setPosition(location.coordinates);
    };

    const onSubmit = (): void => {
        if (!inquiry) {
            return;
        }

        form.validateFields(async (errors, values) => {
            if (errors) {
                return;
            }

            const municipalityIdVal = values.deliveryMunicipalityId || deliveryMunicipality!.id;
            const address = {
                street: values.deliveryLocation!.address.street,
                streetNumber: values.deliveryLocation!.address.streetNumber || "0",
                busNumber: values.deliveryLocation!.address.busNumber,
                numberAddition: values.deliveryLocation!.address.numberAddition,
                city: values.deliveryLocation!.address.city,
                postalCode: values.deliveryLocation!.address.postalCode,
                country: values.deliveryLocation!.address.country
            };
            address.numberAddition = values.addition;
            address.busNumber = values.busNumber;
            const coordinates = values.deliveryLocation!.coordinates;
            delete coordinates.__typename;

            if (!coordinates) {
                throw new Error("No coordinates found for applicant location");
            }

            const result = await updateInquiry({
                variables: {
                    inquiryId: inquiry.id,
                    input: {
                        media: values.media
                            ?.filter((item) => !!item)
                            .map((item) => ({
                                id: item.id
                            })),
                        pickupAddress: {
                            address,
                            coordinates,
                            municipalityId: municipalityIdVal
                        },
                        checklist: {
                            publicDomain: values.publicDomain || false,
                            deliveryLocationDescription: values.deliveryLocationDescription || ""
                        }
                    }
                }
            });

            if (!result.errors) {
                handleClose();
            }
        });
    };

    return (
        <Modal
            title={formatMessage({ id: "updateInquiryPickupAddressModal.title" })}
            onCancel={handleClose}
            onOk={onSubmit}
            visible={visible}
            cancelText={formatMessage({ id: "cancel" })}
            okText={formatMessage({ id: "save" })}
            okButtonProps={{
                loading
            }}
        >
            <UpdateInquiryPickupAddressModalStyle>
                <Form.Item colon={false} style={{ marginBottom: 0 }}>
                    {form.getFieldDecorator("publicDomain", {
                        initialValue: inquiry?.checklist?.publicDomain
                    })(
                        <Checkbox defaultChecked={inquiry?.checklist?.publicDomain}>
                            <FormattedMessage id="createInquiryPage.publicProperty" />
                        </Checkbox>
                    )}
                </Form.Item>
                <Form.Item
                    label={formatMessage({ id: "createInquiryPage.deliveryLocationDescription" })}
                    colon={false}
                    style={{ marginBottom: 0 }}
                >
                    {form.getFieldDecorator("deliveryLocationDescription", {
                        initialValue: inquiry?.checklist?.deliveryLocationDescription
                    })(<Input.TextArea style={{ marginBottom: 25 }} />)}
                </Form.Item>

                <InquiryDeliveryLocation
                    form={form}
                    inquiry={inquiry}
                    deliveryMunicipality={deliveryMunicipality}
                    position={position}
                    setPosition={setPosition}
                    onDeliveryChange={onDeliveryChange}
                />

                <ErrorMessage error={error} />
            </UpdateInquiryPickupAddressModalStyle>
        </Modal>
    );
};

export const UpdateInquiryPickupAddressModal = Form.create<UpdateInquiryPickupAddressModalProps>()(
    UpdateInquiryPickupAddressModalComponent
);
