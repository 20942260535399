import styled from "styled-components";

export const TrucksTableStyle = styled.div`
    display: flex;
    flex-direction:column;
    
    .ant-btn {
        margin-bottom: 15px;
        margin-left: auto;
    }
`;
