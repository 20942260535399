import styled from "styled-components";

import { WhitePage } from "@style/whitePage";
import { InnerContainer } from "@style/innerContainer";
import { ErrorMessageStyle } from "@components/errorMessage/errorMessageStyle";

export const IncidentReasonStyle = styled(WhitePage)`
    & > ${InnerContainer} {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
    }

    ${ErrorMessageStyle} {
        margin-bottom: 15px;
    }

    .icon {
        margin-left: 10px;
        opacity: 0.5;
        cursor: pointer;

        &:hover {
            opacity: 1;
        }
    }

    .ant-table-wrapper {
        width: 100%;
    }

`;
