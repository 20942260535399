/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { GetMunicipalitiesQuery, GetMunicipalitiesQueryVariables } from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

export const useGetMunicipalities = (options?: QueryHookOptions<GetMunicipalitiesQuery, GetMunicipalitiesQueryVariables>) => {
    const { data, error, loading } = useQuery<GetMunicipalitiesQuery, GetMunicipalitiesQueryVariables>(GraphQLDocuments.getMunicipalities, options);

    return {
        municipalities: data && data.cardsMunicipalities ? data.cardsMunicipalities : [],
        municipalitiesLoading: loading,
        municipalitiesError: error
    };
};
