import { ErrorMessage } from "@components/errorMessage/errorMessage";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { useCreateIncident } from "@graphql/hocs/hooks/useCreateIncident";
import { useGetIncidentReasons } from "@graphql/hocs/hooks/useGetIncidentReasons";
import { InquiryOverviewFragment, InquiryStatusType } from "@models/graphql";
import { useFormatMessage } from "@utils/intlHook";
import { Form, Modal, Radio } from "antd";
import { FormComponentProps } from "antd/lib/form";
import TextArea from "antd/lib/input/TextArea";
import { NormalizedCache } from "apollo-cache-inmemory";
import { FunctionComponent } from "react";
import * as React from "react";

import { InquiryIncidentModalStyle } from "./inquiryIncidentModalStyle";

interface FormValues {
    reason: string;
		comment: string;
}
export interface InquiryIncidentModalProps extends FormComponentProps<FormValues> {
    visible: boolean;
    inquiryId: string;
    closeCallback(): void;
}

export const InquiryIncidentModalComponent: FunctionComponent<InquiryIncidentModalProps> = ({ visible, closeCallback, inquiryId, form }) => {

    const { incidentReasons } = useGetIncidentReasons();

    const formatMessage = useFormatMessage();
    const { createIncident, isLoading: incidentLoading, error: incidentError } = useCreateIncident({
        update(cache, response) {
            if (!response || !response.data || !response.data.createIncident) {
                return;
            }

            const data: NormalizedCache = (cache as any).data;

            Object.keys((data as any).data).forEach(key => key.match(/^CollectionRound/) && data.delete(key));
            Object.keys((data as any).data).forEach(key => key.match(/^\$ROOT_QUERY\.collectionRounds/) && data.delete(key));

            const inqFragment = cache.readFragment<InquiryOverviewFragment>({
                fragment: GraphQLDocuments.inquiryOverview,
                fragmentName: "inquiryOverview",
                id: `Inquiry:${inquiryId}`
            });

            if (inqFragment) {

                inqFragment.currentStatus = InquiryStatusType.Incident;
                inqFragment.lastUpdated = new Date().toISOString();

                cache.writeFragment<InquiryOverviewFragment>({
                    fragment: GraphQLDocuments.inquiryOverview,
                    fragmentName: "inquiryOverview",
                    id: `Inquiry:${inquiryId}`,
                    data: inqFragment
                });
            }
        },
        refetchQueries: [{ query: GraphQLDocuments.getInquiryDetail, variables: { id: inquiryId } }],
        awaitRefetchQueries: true
    });

    const handleOK = (): void => {
        form.validateFields(async (errors, values) => {
            if (!errors) {
                const result = await createIncident({
                    incident: {
                        inquiryId,
                        images: [],
                        timestamp: new Date().toISOString(),
                        reasonId: values.reason,
                        description: values.comment
                    }
                });

                if (result && result.data && result.data.createIncident) {
                    closeCallback();
                }
            }
        });
    };

    return (
        <Modal
            title={formatMessage({ id: "inquiryDetail.incidentModalTitle" })}
            visible={visible}
            onOk={handleOK}
            confirmLoading={incidentLoading}
            onCancel={closeCallback}
            okText={formatMessage({ id: "save" })}
            cancelText={formatMessage({ id: "cancel" })}
        >
            <InquiryIncidentModalStyle>
                <Form>
                    <Form.Item>
                        {form.getFieldDecorator("reason", {
                            rules: [
                                {
                                    required: true,
                                    message: formatMessage({ id: "requiredField" }) 
                                }
                            ]
                        })(
                            <Radio.Group>
                                {incidentReasons.map((item) => (
                                    <Radio key={item.id} value={item.id}>
                                        {item.name}
                                    </Radio>
                                ))}
                            </Radio.Group>
                        )}
                    </Form.Item>

                    <Form.Item label={formatMessage({ id: "inquiryDetail.comment" })}>
                        {form.getFieldDecorator("comment", {
                            rules: [
                                { required: true, message: formatMessage({ id: "requiredField" }) }
                            ]
                        })(<TextArea rows={4} />)}
                    </Form.Item>
                    <ErrorMessage error={incidentError} />
                </Form>
            </InquiryIncidentModalStyle>
        </Modal>
    );
};

export const InquiryIncidentModal = Form.create<InquiryIncidentModalProps>()(InquiryIncidentModalComponent);
