import styled from "styled-components";
import { COLORS } from "@style/colors";

export const InquiryInvoiceSenderStyle = styled.div`
    background: #FFFFFF;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.15);
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 30px;

    h1 {
        background: ${COLORS.lightGrey};
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 20px;
    }

    main {
        padding: 20px;
        display: flex;
        flex-direction: column;

        line-height: 150%;
    }

    .ant-btn {
        margin-top: 20px;
        width: 250px;
    }

    .products {
        border: 1px dashed ${COLORS.textTransparent(0.2)};
        padding: 10px;
        border-radius: 4px;
        margin-top: 15px;
    }

    .invoiceWarning {
        margin-top: 5px;
        color: ${COLORS.textTransparent(0.5)}
    }
`;

export const ProductItem = styled.div`
    display: flex;
    align-items: center;

    &:not(:first-of-type) {
        margin-top: 15px;
    }

    .ant-input-number {
        margin-left: 10px;
    }

    .name {
        flex: 1;
        margin-right: 10px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
