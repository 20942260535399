import { DatePicker, Form, Input } from "antd";
import * as React from "react";
import { FunctionComponent } from "react";

import { useFormatMessage } from "@utils/intlHook";
import { useForm, useGetFormValues } from "@components/patternOverviewTab/patternFormProvider";

import { GeneralPatternFormStyle } from "./generalPatternFormStyle";
import * as moment from "moment";
import { Moment } from "moment";
import { FormattedMessage } from "react-intl";
import { useEditCollectionPattern } from "@components/patternOverviewTab/patternEdittingProvider";

export interface GeneralPatternFormProps {

}

export const GeneralPatternForm: FunctionComponent<GeneralPatternFormProps> = ({ }) => {
    const formatMessage = useFormatMessage();
    const { getFieldDecorator, validateFields } = useForm();
    const { to, from } = useGetFormValues();
    const { isActive, editPatternItem } = useEditCollectionPattern();

    const validateFromDate = (rule: any, value: Moment | null, callback: (error?: string) => void): void => {
        if (!value) {
            return callback(formatMessage({ id: "requiredField" }));
        }

        if (value.isSameOrBefore(moment(), "day") && !isActive) {
            return callback(formatMessage({ id: "patternForm.isSameOrBeforeTodayError" }));
        }

        if (to) {
            validateFields(["to"]);
        }

        return callback();
    };

    const validateToDate = (rule: any, value: Moment | null, callback: (error?: string) => void): void => {
        if (!value) {
            return callback(formatMessage({ id: "requiredField" }));
        }

        if (value.isSameOrBefore(moment(), "day")) {
            return callback(formatMessage({ id: "patternForm.isSameOrBeforeTodayError" }));
        }

        if (from && value.isSameOrBefore(from, "day")) {
            return callback(formatMessage({ id: "patternForm.isSameOrBeforeError" }));
        }

        return callback();
    };

    return (
        <GeneralPatternFormStyle>
            <Form>
                <Form.Item colon={false} required={false} label={<FormattedMessage id="patternForm.title" />}>
                    {getFieldDecorator("name", {
                        rules: [
                            { required: true, message: formatMessage({ id: "requiredField" }) }
                        ],
                        initialValue: editPatternItem ? editPatternItem.name : undefined
                    })(<Input style={{ width: 250 }} placeholder={formatMessage({ id: "patternForm.titlePlaceholder" })} />)}
                </Form.Item>
                <Form.Item colon={false} label={<FormattedMessage id="patternForm.fromPlaceholder" />}>
                    {getFieldDecorator("from", {
                        rules: [
                            { validator: validateFromDate }
                        ],
                        initialValue: editPatternItem ? editPatternItem.from : undefined
                    })(<DatePicker disabled={isActive} disabledDate={date => !date || date.isSameOrBefore(moment(), "day")} placeholder={formatMessage({ id: "patternForm.fromPlaceholder" })} />)}
                </Form.Item>
                <Form.Item colon={false} label={<FormattedMessage id="patternForm.toPlaceholder" />}>
                    {getFieldDecorator("to", {
                        rules: [
                            { validator: validateToDate }
                        ],
                        initialValue: editPatternItem ? editPatternItem.to : undefined
                    })(<DatePicker disabledDate={date => !date || date.isSameOrBefore(editPatternItem ? editPatternItem.to.clone().subtract(1, "d") : from || moment(), "day")} placeholder={formatMessage({ id: "patternForm.toPlaceholder" })} />)}
                </Form.Item>
            </Form>
        </GeneralPatternFormStyle>
    );
};
