import styled from "styled-components";
import { COLORS } from "@style/colors";

export const SubListStyle = styled.div`
    header {
        background: #F5F5F5;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 25px;
    }

    .amount {
        background-color: ${COLORS.error};
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        border-radius: 100%;
        font-size: 12px;
    }

    &.draggingOver {

    }

    .items {
        transition: 0.3s;
        padding: 25px;
    }
`;
