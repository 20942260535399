import { AssetFormModal } from "@components/assetFormModal/assetFormModal";
import { AssetsFilter } from "@components/assetsFilter/assetsFilter";
import { AssetsTable } from "@components/assetsTable/assetsTable";
import { PageHeader } from "@components/pageHeader/pageHeader";
import { Permission, PERMISSIONS } from "@components/permission/permission";
import { Asset } from "@models/graphql";
import { InnerContainer } from "@style/innerContainer";
import { Button } from "antd";
import * as React from "react";
import { FunctionComponent } from "react";
import { AssetsPageStyle } from "./assetsPageStyle";

export interface AssetsPageProps { }

export const AssetsPage: FunctionComponent<AssetsPageProps> = ({ }) => {
    const [deleteId, setDeleteId] = React.useState<string | null>(null);
    const [modalVisible, setModalVisible] = React.useState(false);
    const [editItem, setEditItem] = React.useState<Asset | null>(null);

    const handleClose = (): void => {
        setEditItem(null);
        setModalVisible(false);
    };

    const handleEdit = (item: Asset): void => {
        setModalVisible(true);
        setEditItem(item);
    };

    const handleCreateAsset = (): void => {
        setEditItem(null);
        setModalVisible(true);
    };

    return (
        <AssetsPageStyle>
            <AssetFormModal editItem={editItem} closeCallback={handleClose} visible={modalVisible} />
            <PageHeader title="assetsPage.title">
                <Permission requiredPermissions={[PERMISSIONS.assets.create]}>
                    <Button onClick={handleCreateAsset} type="primary">
                        Asset toevoegen
                    </Button>
                </Permission>
            </PageHeader>
            <InnerContainer>
                <AssetsFilter />
                <AssetsTable editCallback={handleEdit} deleteId={deleteId} deleteCallback={setDeleteId} />
            </InnerContainer>
        </AssetsPageStyle>
    );
};
