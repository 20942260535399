import * as React from "react";
import { FunctionComponent } from "react";
import { StepIconStyle } from "./stepIconStyle";
import { Icon } from "@style/icon";

export interface StepIconProps {
    step: number;
    done: boolean;
}

export const StepIcon: FunctionComponent<StepIconProps> = ({ done, step }) => {

    if (done) {
        return (
            <StepIconStyle className="done">
                <Icon type="checkmark" />
            </StepIconStyle>
        );

    }

    return (
        <StepIconStyle className="notdone">
            {step}
        </StepIconStyle>
    );
};
