/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { GetOrderReportsQuery, GetOrderReportsQueryVariables } from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

export const useGetOrderReports = (options?: QueryHookOptions<GetOrderReportsQuery, GetOrderReportsQueryVariables>) => {
    const { data, error, loading } = useQuery<GetOrderReportsQuery, GetOrderReportsQueryVariables>(GraphQLDocuments.getOrderReports, options);

    return {
        orderReports: data && data.orderReports ? data.orderReports : [],
        orderReportsLoading: loading,
        orderReportsError: error
    };
};
