import { ErrorMessage } from "@components/errorMessage/errorMessage";
import { Permission, PERMISSIONS } from "@components/permission/permission";
import { useActivateCollectionPattern } from "@graphql/hocs/hooks/useActivateCollectionPattern";
import { useDeleteCollectionPattern } from "@graphql/hocs/hooks/useDeleteCollectionPattern";
import { useGetCollectionPatterns } from "@graphql/hocs/hooks/useGetCollectionPatterns";
import { CollectionPatternFragment } from "@models/graphql";
import { Icon } from "@style/icon";
import { DATE_FORMAT } from "@utils/consts";
import { useFormatMessage } from "@utils/intlHook";
import { parseError } from "@utils/parseError";
import { useRouter } from "@utils/routerHook";
import { Icon as AntIcon, message, Popconfirm } from "antd";
import Table, { ColumnProps } from "antd/lib/table";
import * as moment from "moment";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { PatternTableStyle } from "./patternTableStyle";
import { useDeactivateCollectionPattern } from "@graphql/hocs/hooks/useDeactivateCollectionPattern";
import { ApolloUtils } from "@utils/apolloUtils";

export interface PatternTableProps { }

export const PatternTable: FunctionComponent<PatternTableProps> = () => {
    const formatMessage = useFormatMessage();
    const { collectionPatterns, collectionPatternsError, collectionPatternsLoading, refetch } = useGetCollectionPatterns();
    const [deleteId, setDeleteId] = React.useState<string | null>(null);
    const [activateId, setActivateId] = React.useState<string | null>(null);
    const [deactivateId, setDeactivateId] = React.useState<string | null>(null);

    const queriesToRefetch: string[] = ["getCollectionPattern", "getCollectionPatterns", "getFreeTrucks", "getFreePickupDays", "getFreeDeliveryDays", "getCollectionRounds"];
    const typenamesToDelete: string[] = ["CollectionPattern", "CollectionPatternResult", "Truck", "CollectionDayResult", "CollectionDay", "CollectionRound"];
    const { deleteCollectionPattern, isLoading: deleteLoading } = useDeleteCollectionPattern({
        refetchQueries: queriesToRefetch,
        awaitRefetchQueries: true,
        update(cache) {
            ApolloUtils.deleteTypenamesFromCache(cache, typenamesToDelete);
        }
    });

    const { activateCollectionPattern, isLoading: activateLoading } = useActivateCollectionPattern({
        refetchQueries: queriesToRefetch,
        awaitRefetchQueries: true,
        update(cache) {
            ApolloUtils.deleteTypenamesFromCache(cache, typenamesToDelete);
        }
    });

    const { deactivateCollectionPattern, isLoading: deactivateLoading } = useDeactivateCollectionPattern({
        refetchQueries: queriesToRefetch,
        awaitRefetchQueries: true,
        update(cache) {
            ApolloUtils.deleteTypenamesFromCache(cache, typenamesToDelete);
        }
    });

    const { history } = useRouter();

    const deleteItem = async (id: string): Promise<void> => {
        setDeleteId(id);
        const { data, error } = await deleteCollectionPattern({ id });

        if (data) {
            if (data.deleteCollectionPattern.succeeded) {
                message.success(formatMessage({ id: "patternTable.deleteSuccessful" }));
            } else {
                message.error(formatMessage({ id: "patternTable.deleteFailed" }));
            }
        } else {
            message.error(parseError(error, formatMessage));
        }

        setDeleteId(null);
    };

    const activateItem = async (id: string): Promise<void> => {
        setActivateId(id);
        const { data, error } = await activateCollectionPattern({ id });

        if (data) {
            if (data.activateCollectionPattern) {
                message.success(formatMessage({ id: "patternTable.activateSuccessful" }));
            } else {
                message.error(formatMessage({ id: "patternTable.activateFailed" }));
            }
        } else {
            message.error(parseError(error, formatMessage));
        }

        setActivateId(null);
    };

    const deactivateItem = async (id: string): Promise<void> => {
        setDeactivateId(id);
        const { data, error } = await deactivateCollectionPattern({ id });

        if (data) {
            if (data.deactivateCollectionPattern) {
                message.success(formatMessage({ id: "patternTable.deactivateSuccessful" }));
            } else {
                message.error(formatMessage({ id: "patternTable.deactivateFailed" }));
            }
        } else {
            message.error(parseError(error, formatMessage));
        }

        setDeactivateId(null);
    };

    const columns: ColumnProps<CollectionPatternFragment>[] = [
        {
            title: <FormattedMessage id="patternTable.name" />,
            key: "name",
            dataIndex: "name"
        },
        {
            title: <FormattedMessage id="patternTable.active" />,
            key: "active",
            dataIndex: "active",
            width: 200,
            render(active, record) {
                if (!active) {
                    if (record.id === activateId && activateLoading || record.id === deactivateId && deactivateLoading) {
                        return <AntIcon type="loading" />;
                    }

                    if (record.deactivated) {
                        return <Icon className="deactivated" type="power" />;
                    }

                    return (
                        <Popconfirm
                            placement="right"
                            onConfirm={() => activateItem(record.id)}
                            title={formatMessage({
                                id: "patternTable.activateConfirm"
                            })}
                        >
                            <Icon className="not-active" type="power" />
                        </Popconfirm>
                    );
                }

                return (
                    <Popconfirm
                        placement="right"
                        onConfirm={() => deactivateItem(record.id)}
                        title={formatMessage({
                            id: "patternTable.deactivateConfirm"
                        })}
                        overlayStyle={{ width: 375 }}
                    >
                        <Icon className="active" type="power" />
                    </Popconfirm>
                );

            }
        },
        {
            title: <FormattedMessage id="patternTable.dayCount" />,
            key: "dayCount",
            dataIndex: "dayCount",
            width: 200
        },
        {
            title: <FormattedMessage id="patternTable.from" />,
            key: "from",
            dataIndex: "from",
            width: 200,
            render(date: string) {
                return moment(date, DATE_FORMAT).format("DD-MM-YYYY");
            }
        },
        {
            title: <FormattedMessage id="patternTable.to" />,
            key: "to",
            dataIndex: "to",
            width: 200,
            render(date: string) {
                return moment(date, DATE_FORMAT).format("DD-MM-YYYY");
            }
        },
        {
            title: <FormattedMessage id="patternTable.actions" />,
            key: "actions",
            width: 90,
            render(_, record) {
                return (
                    <div className="buttons">
                        {/* TODO: Implement Duplicate */}
                        {/* <Icon type="duplicate" /> */}
                        <Permission requiredPermissions={[PERMISSIONS.collectionPatterns.update]}>
                            <Icon onClick={() => history.push(`/management/calendar/pattern/groups?editId=${record.id}`)} type="pencil" />
                        </Permission>

                        <Permission requiredPermissions={[PERMISSIONS.collectionPatterns.delete]}>
                            {record.id === deleteId && deleteLoading ?
                                <AntIcon type="loading" />
                                :
                                !record.active && !record.deactivated &&
                                <Popconfirm
                                    placement="left"
                                    onConfirm={() => deleteItem(record.id)}
                                    title={formatMessage({
                                        id: "patternTable.deleteConfirm"
                                    })}
                                >
                                    <Icon type="trash" />
                                </Popconfirm>

                            }
                        </Permission>
                    </div>
                );
            }
        }
    ];

    return (
        <PatternTableStyle>
            <ErrorMessage refetch={refetch} error={collectionPatternsError} />
            <Table rowClassName={record => record.deactivated ? "deactivated-row" : ""} columns={columns} loading={collectionPatternsLoading} dataSource={collectionPatterns} rowKey="id" />
        </PatternTableStyle>
    );
};
