import styled from "styled-components";
import { COLORS } from "@style/colors";

export const TruckScheduleTableStyle = styled.div`
    padding-top: 40px;

    .loader {
        margin: 100px auto;
        display: block;
        font-size: 40px;
    }

    section {
        margin-bottom: 40px;

        h1 {
            color: ${COLORS.primary};
            text-transform: capitalize;
            margin-bottom: 30px;
        }

        h2 {
            margin-bottom: 20px;
            margin-top: 30px;
            font-size: 12px;
        }

        i {
            opacity: 0.3;
        }

        .ant-table-row {
            cursor: pointer;
        }
    }
`;
