/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";
import { useEffect, useRef } from "react";

import {
    GetInquiryDetailQuery,
    GetInquiryDetailQueryVariables
} from "@models/graphql";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";

export const useGetInquiryDetail = (options?: QueryHookOptions<
        GetInquiryDetailQuery,
        GetInquiryDetailQueryVariables
    >) => {
    const prevDataRef = useRef();

    const { data, error, loading } = useQuery<
        GetInquiryDetailQuery,
        GetInquiryDetailQueryVariables
    >(GraphQLDocuments.getInquiryDetail, options);

    const hasData = data && Object.keys(data).length;
    const activeData = hasData ? data : prevDataRef.current;

    useEffect(() => {
        if (hasData) {
            prevDataRef.current = data as any;
        }
    });

    return {
        inquiry: activeData && activeData.inquiry,
        inquiryLoading: loading,
        inquiryError: error,
        originalError: error
    };
};
