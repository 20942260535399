import { ErrorMessage } from "@components/errorMessage/errorMessage";
import { Permission, PERMISSIONS } from "@components/permission/permission";
import { TruckFormModal } from "@components/truckFormModal/truckFormModal";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { useDeleteTruck } from "@graphql/hocs/hooks/useDeleteTruck";
import { useGetTrucks } from "@graphql/hocs/hooks/useGetTrucks";
import { GetTrucksQuery, TruckFragment } from "@models/graphql";
import { Icon } from "@style/icon";
import { TableButtons } from "@style/tableButtons";
import { useFormatMessage } from "@utils/intlHook";
import { parseError } from "@utils/parseError";
import { Button, Icon as AntIcon, message, Popconfirm, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { TrucksTableStyle } from "./trucksTableStyle";

export interface TrucksTableProps {

}

export const TrucksTable: FunctionComponent<TrucksTableProps> = ({ }) => {
    const formatMessage = useFormatMessage();
    const [modalVisible, setModalVisible] = React.useState(false);
    const [editItem, setEditItem] = React.useState<TruckFragment | null>(null);
    const [deleteItemId, setDeleteItemId] = React.useState<string | null>(null);
    let deletedItem: string | null = null;

    const { deleteTruck } = useDeleteTruck({
        update(cache, response) {
            if (!response.data || !response.data.deleteTruck.succeeded) {
                return;
            }

            const trucksData = cache.readQuery<GetTrucksQuery>({ query: GraphQLDocuments.getTrucks });

            if (!trucksData) {
                return;
            }

            trucksData.trucks.items = trucksData.trucks.items.filter(truck => truck.id !== deletedItem);

            cache.writeQuery<GetTrucksQuery>({
                data: trucksData,
                query: GraphQLDocuments.getTrucks
            });
        }

    });
    const { trucks, trucksError, trucksLoading } = useGetTrucks();

    const handleDeleteTruck = async (truckId: string): Promise<void> => {
        setDeleteItemId(truckId);
        // This one is for cache update purposes, the state has not updated when this deleteTruck is called; So using a local variable instead.
        deletedItem = truckId;
        const { error } = await deleteTruck({ id: truckId });

        if (error) {
            message.error(parseError(error, formatMessage));
        } else {
            message.success(formatMessage({ id: "trucksTable.deleteSuccessful" }));
        }

    };

    const columns: ColumnProps<TruckFragment>[] = [
        {
            key: "name",
            dataIndex: "name",
            title: <FormattedMessage id="trucksTable.name" />
        },
        {
            key: "truckType",
            dataIndex: "type.name",
            title: <FormattedMessage id="trucksTable.type" />
        },
        {
            key: "tagCode",
            dataIndex: "tagCode",
            title: <FormattedMessage id="trucksTable.tagCode" />
        },
        {
            key: "suivoId",
            dataIndex: "suivoID",
            title: <FormattedMessage id="trucksTable.suivoId" />
        },
        {
            key: "buttons",
            width: 120,
            render(_, record) {
                return <TableButtons>
                    <Permission requiredPermissions={[PERMISSIONS.trucks.update]}>
                        <Icon type="pencil" onClick={() => {
                            setEditItem(record);
                            setModalVisible(true);
                        }}
                        />
                    </Permission>
                    <Permission requiredPermissions={[PERMISSIONS.trucks.delete]}>
                        <Popconfirm placement="left" onConfirm={() => handleDeleteTruck(record.id)} title={<FormattedMessage id="trucksTable.deleteConfirm" />}>
                            {deleteItemId === record.id ? <AntIcon type="loading" /> : <Icon type="trash" />}
                        </Popconfirm>
                    </Permission>
                </TableButtons>;
            }
        }
    ];

    const handleClose = (): void => {
        setEditItem(null);
        setModalVisible(false);
    };

    return (
        <TrucksTableStyle>
            <Permission requiredPermissions={[PERMISSIONS.trucks.create]}>
                <Button type="primary" onClick={() => setModalVisible(true)}>
                    +&nbsp;<FormattedMessage id="trucksTable.createTruck" />
                </Button>
            </Permission>
            <ErrorMessage error={trucksError} />
            <TruckFormModal
                closeCallback={handleClose}
                editItem={editItem}
                visible={modalVisible}
            />
            <Table
                loading={trucksLoading}
                dataSource={trucks}
                columns={columns}
                rowKey="id"
                pagination={false}
            />
        </TrucksTableStyle>
    );
};
