/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { CloseIncidentMutation, CloseIncidentMutationVariables } from "@models/graphql";

const defaultCloseIncidentOptions: MutationHookOptions<CloseIncidentMutation, CloseIncidentMutationVariables> = {};

export const useCloseIncident = (options?: MutationHookOptions<CloseIncidentMutation, CloseIncidentMutationVariables>) => {
    const [mutation, { data, loading, error }] = useMutation<CloseIncidentMutation, CloseIncidentMutationVariables>(
        GraphQLDocuments.closeIncident
    );

    const closeIncident = async (variables: CloseIncidentMutationVariables) => {
        try {
            const response = await mutation({
                ...defaultCloseIncidentOptions,
                ...options,
                variables
            });

            return response;
        } catch (err) {
            return { error: err };
        }
    };

    return {
        isLoading: loading,
        error,
        data,
        closeIncident
    };
};
