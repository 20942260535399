import styled from "styled-components";
import { COLORS } from "@style/colors";

export const ProductDetailModalStyle = styled.div`
    .ant-form {
    }

    em {
        color: ${COLORS.textTransparent(0.5)};
        font-size: 12px;
        display: block;
        line-height: 28px;
    }

    .ant-checkbox-group {
        margin-top: 15px;
    }

    .ant-checkbox-wrapper {
        display: block;
    }

    .ant-radio-group {
        margin-top: 15px;
    }

    .ant-radio-wrapper {
        display: block;
        padding-bottom: 15px;
    }
`;

export const SubTitle = styled.div`
    color: ${COLORS.primary};
    padding-top: 15px;
`;
