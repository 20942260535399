/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { UpdateTruckTypeMutation, UpdateTruckTypeMutationVariables } from "@models/graphql";

const defaultUpdateTruckTypeOptions: MutationHookOptions<UpdateTruckTypeMutation, UpdateTruckTypeMutationVariables> = {

};

export const useUpdateTruckType = (options?: MutationHookOptions<UpdateTruckTypeMutation, UpdateTruckTypeMutationVariables>) => {
    const [mutation, { data, loading, error }] = useMutation<UpdateTruckTypeMutation, UpdateTruckTypeMutationVariables>(GraphQLDocuments.updateTruckType);

    const updateTruckType = async (variables: UpdateTruckTypeMutationVariables) => {
        try {
            const response = await mutation({
                ...defaultUpdateTruckTypeOptions,
                ...options,
                variables
            });

            return {
                error: response.errors,
                data: response.data
            };
        } catch (err) {
            return {
                error: err
            };
        }
    };

    return {
        isLoading: loading,
        error,
        data,
        updateTruckType
    };
};
