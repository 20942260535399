/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { DeleteIncidentCommentMutation, DeleteIncidentCommentMutationVariables } from "@models/graphql";
import { useFormatMessage } from "@utils/intlHook";
import { parseError } from "@utils/parseError";

const defaultDeleteIncidentCommentOptions: MutationHookOptions<DeleteIncidentCommentMutation, DeleteIncidentCommentMutationVariables> = {};

export const useDeleteIncidentComment = (
    options?: MutationHookOptions<DeleteIncidentCommentMutation, DeleteIncidentCommentMutationVariables>
) => {
    const formatMessage = useFormatMessage();

    const [mutation, { data, loading, error }] = useMutation<DeleteIncidentCommentMutation, DeleteIncidentCommentMutationVariables>(
        GraphQLDocuments.deleteIncidentComment
    );

    const deleteIncidentComment = async (variables: DeleteIncidentCommentMutationVariables) => {
        try {
            const response = await mutation({
                ...defaultDeleteIncidentCommentOptions,
                ...options,
                variables
            });

            return {
                error: undefined,
                response
            };
        } catch (err) {
            return {
                error: err,
                response: undefined
            };
        }
    };

    return {
        isLoading: loading,
        error: parseError(error, formatMessage),
        data,
        deleteIncidentComment: deleteIncidentComment
    };
};
