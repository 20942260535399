import { Tooltip } from "antd";
import { ApolloError } from "apollo-client";
import * as React from "react";
import { FunctionComponent } from "react";

import { parseError, getDownstreamError } from "@utils/parseError";
import { useFormatMessage } from "@utils/intlHook";
import { Icon } from "@style/icon";

import { ErrorMessageStyle } from "./errorMessageStyle";

export interface ErrorMessageProps {
    error?: ApolloError | null;
    style?: React.CSSProperties;
    refetch?(): void;
}

export const ErrorMessage: FunctionComponent<ErrorMessageProps> = ({ error, style, refetch }) => {
    const formatMessage = useFormatMessage();

    if (!error) {
        return null;
    }

    let errMessage = error.message;

    if (error.graphQLErrors && error.graphQLErrors[0]) {
        const err = getDownstreamError(error.graphQLErrors[0]);
        console.log(err);
        errMessage = err.message;
    }

    return (
        <ErrorMessageStyle style={style}>
            {parseError(error, formatMessage)}
            {refetch && <Icon onClick={() => refetch} type="reset" />}
            <Tooltip overlayStyle={{ fontFamily: "monospace" }} placement="left" title={errMessage}>
                <Icon type="info" />
            </Tooltip>
        </ErrorMessageStyle>
    );
};
