/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { UpdateCollectionPatternMutation, UpdateCollectionPatternMutationVariables } from "@models/graphql";

const defaultUpdateCollectionPatternOptions: MutationHookOptions<
    UpdateCollectionPatternMutation,
    UpdateCollectionPatternMutationVariables
> = {};

export const useUpdateCollectionPattern = (
    options?: MutationHookOptions<UpdateCollectionPatternMutation, UpdateCollectionPatternMutationVariables>
) => {
    const [mutation, { data, loading, error }] = useMutation<UpdateCollectionPatternMutation, UpdateCollectionPatternMutationVariables>(
        GraphQLDocuments.updateCollectionPattern
    );

    const updateCollectionPattern = async (variables: UpdateCollectionPatternMutationVariables) => {
        try {
            const response = await mutation({
                ...defaultUpdateCollectionPatternOptions,
                ...options,
                variables
            });

            return response;
        } catch (err) {
            return { error: err };
        }
    };

    return {
        isLoading: loading,
        error,
        data,
        updateCollectionPattern
    };
};
