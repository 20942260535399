import { LogisticReportsPageStyle } from "./logisticReportsPageStyle";

import { Button, DatePicker, message } from "antd";
import Table, { ColumnProps } from "antd/lib/table";
import * as moment from "moment";
import { Moment } from "moment";
import { FunctionComponent } from "react";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { useFormatMessage } from "@utils/intlHook";
import { DATE_FORMAT } from "@utils/consts";
import { InnerContainer } from "@style/innerContainer";
import { CsvDownloadModal } from "@components/planningPageHeader/csvDownloadModal/csvDownloadModal";
import { ErrorMessage } from "@components/errorMessage/errorMessage";

import { useGetLogisticReports } from "@graphql/hocs/hooks/useGetLogisticReports";
import { GetLogisticReportsQuery } from "@models/graphql";
import { useGetLogisticReportsCsv } from "@graphql/hocs/hooks/useGetLogisticReportsCsv";
import { parseError } from "@utils/parseError";
import * as uuid from "uuid";

export interface LogisticReportsPageProps {}

export const LogisticReportsPage: FunctionComponent<LogisticReportsPageProps> = (props: LogisticReportsPageProps) => {
    const [csvUrl, setCsvUrl] = React.useState<string>("");
    const [csvModalVisible, setcCvModalVisible] = React.useState(false);
    const [[from, to], setDateRange] = React.useState<[Moment, Moment]>([moment().startOf("month"), moment().subtract(1, "d")]);
    const formatMessage = useFormatMessage();

    const { logisticReports, logisticReportsError, logisticReportsLoading } = useGetLogisticReports({
        variables: {
            from: from.format(DATE_FORMAT),
            to: to.format(DATE_FORMAT)
        }
    });

    const { getLogisticReportsCsv, isLoading } = useGetLogisticReportsCsv();

    const columns: ColumnProps<GetLogisticReportsQuery["logisticReports"][0]>[] = [
        {
            key: "date",
            title: formatMessage({ id: "logisticsReportsPage.date" }),
            render(_, record) {
                return moment(record.date, DATE_FORMAT).format("DD/MM/YYYY");
            }
        },
        {
            key: "numberPlate",
            title: formatMessage({ id: "logisticsReportsPage.numberPlate" }),
            render(_, record) {
                return record.truck.name;
            }
        },
        {
            key: "collector",
            title: formatMessage({ id: "logisticsReportsPage.collector" }),
            render(_, record) {
                return record.collector && `${record.collector.firstname} ${record.collector.lastname}`;
            }
        },
        {
            key: "addressCount",
            title: formatMessage({ id: "logisticsReportsPage.addressCount" }),
            render(_, record) {
                return record.addressCount;
            }
        },
        {
            key: "incidents",
            title: formatMessage({ id: "logisticsReportsPage.incidents" }),
            render(_, record) {
                return record.incidentCount;
            }
        },
        {
            key: "totalDistance",
            title: formatMessage({ id: "logisticsReportsPage.totalDistance" }),
            render(_, record) {
                return record.totalDistance;
            }
        },
        {
            key: "collectionDistance",
            title: formatMessage({ id: "logisticsReportsPage.collectionDistance" }),
            render(_, record) {
                return record.collectionDistance;
            }
        },
        {
            key: "weight",
            title: formatMessage({ id: "logisticsReportsPage.weight" }),
            render(_, record) {
                return record.weight;
            }
        },
        {
            key: "processor",
            title: formatMessage({ id: "logisticsReportsPage.processor" }),
            render(_, record) {
                return record.processor.name;
            }
        },
        {
            key: "municipalities",
            title: formatMessage({ id: "logisticsReportsPage.municipalities" }),
            render(_, record) {
                return record.municipalities.join(", ");
            }
        },
        {
            key: "product",
            title: formatMessage({ id: "orderReportsPage.product" }),
            render(_, record) {
                const amountPerProduct: Record<string, number> = {};

                for (const inquiry of record.inquiries) {
                    for (const product of inquiry.products) {
                        amountPerProduct[product.product.name] = (amountPerProduct[product.product.name] || 0) + product.amount;
                    }
                }

                return Object.keys(amountPerProduct).map((productName) => (
                    <div className="product-item" key={productName}>
                        {amountPerProduct[productName]} <em> x {productName}</em>
                    </div>
                ));
            }
        }
    ];

    const handleDateRangeChange = (val: [Moment, Moment]): void => {
        setDateRange(val);
    };

    const handleExport = async (): Promise<void> => {
        const { data, error } = await getLogisticReportsCsv({
            from: from.format(DATE_FORMAT),
            to: to.format(DATE_FORMAT)
        });

        if (data) {
            setcCvModalVisible(true);
            setCsvUrl(data.logisticReportsCSV.url);
        }

        if (error) {
            message.error(parseError(error, formatMessage));
        }
    };
    return (
        <LogisticReportsPageStyle>
            <CsvDownloadModal
                fileName={`Logistics Report ${from.format("DD-MM-YYYY")} - ${to.format("DD-MM-YYYY")}`}
                url={csvUrl}
                visible={csvModalVisible}
                closeCallback={() => setcCvModalVisible(false)}
            />
            <InnerContainer>
                <FormattedMessage id="logisticsReportsPage.title" tagName="h1" />
                <div className="filterBar">
                    <span>
                        <DatePicker.RangePicker
                            allowClear={false}
                            disabledDate={(cur) => !cur || cur.isSameOrAfter(moment(), "d")}
                            onChange={handleDateRangeChange}
                            value={[from, to]}
                        />
                    </span>
                    <Button onClick={handleExport} loading={isLoading}>
                        <FormattedMessage id="logisticsReportsPage.export" />
                    </Button>
                </div>
                <ErrorMessage error={logisticReportsError} />
                <Table
                    rowKey={(record) => `${record.truck.id}-${record.date}-${uuid()}`}
                    loading={logisticReportsLoading}
                    columns={columns}
                    dataSource={logisticReports}
                />
            </InnerContainer>
        </LogisticReportsPageStyle>
    );
};
