import styled from "styled-components";

export const TasksMapViewStyle = styled.div`
    height: 100%;
    flex: 1;
    
    #map {
        height: 100%;
        width: 100%;
    }
`;
