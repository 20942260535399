/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { DeleteTruckMutation, DeleteTruckMutationVariables } from "@models/graphql";

const defaultDeleteTruckOptions: MutationHookOptions<DeleteTruckMutation, DeleteTruckMutationVariables> = {};

export const useDeleteTruck = (options?: MutationHookOptions<DeleteTruckMutation, DeleteTruckMutationVariables>) => {
    const [mutation, { data, loading, error }] = useMutation<DeleteTruckMutation, DeleteTruckMutationVariables>(
        GraphQLDocuments.deleteTruck
    );

    const deleteTruck = async (variables: DeleteTruckMutationVariables) => {
        try {
            const response = await mutation({
                ...defaultDeleteTruckOptions,
                ...options,
                variables
            });

            return {
                error: response.errors,
                data: response.data
            };
        } catch (err) {
            return {
                error: err
            };
        }
    };

    return {
        isLoading: loading,
        error,
        data,
        deleteTruck
    };
};
