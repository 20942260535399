import styled from "styled-components";
import { WhitePage } from "@style/whitePage";
import { PageHeaderStyle } from "@components/pageHeader/pageHeaderStyle";
import { ErrorMessageStyle } from "@components/errorMessage/errorMessageStyle";
import { COLORS } from "@style/colors";

export const ProductsPageStyle = styled(WhitePage)`

    ${PageHeaderStyle} {
        .ant-btn {
            width: 200px !important;
        }
    }

    .name {
        display: inline-flex;
        align-items: center;
    }

    .color {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        display: inline-block;
        margin-right: 25px;
    }

    .buttons {
        .icon {
            color: ${COLORS.text};
            margin-left: 20px;
            opacity: 0.5;
            cursor: pointer;

            &:hover {
                opacity: 1;
            }
        }
    }

    ${ErrorMessageStyle} {
        margin-bottom: 25px;
    }
`;
