import styled from "styled-components";

import { WhitePage } from "@style/whitePage";

export const PatternDetailPageStyle = styled(WhitePage)`
    .ant-tabs-bar {
        border: none;
        margin-bottom: 35px;
    }

    .ant-tabs-tab {
        padding: 0;
    }

    .ant-tabs-ink-bar {
        display: none !important;
    }

    .hidden {
        display: none;
    }

    header.pageHeader {
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;

        & > span {
            font-size: 16px;
            font-weight: 700;
        }
    }
`;
