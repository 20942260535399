/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { CreateStandAloneCollectionRoundMutation, CreateStandAloneCollectionRoundMutationVariables } from "@models/graphql";

const defaultcreateStandAloneCollectionRoundOptions: MutationHookOptions<
    CreateStandAloneCollectionRoundMutation,
    CreateStandAloneCollectionRoundMutationVariables
> = {};

export const usecreateStandAloneCollectionRound = (
    options?: MutationHookOptions<CreateStandAloneCollectionRoundMutation, CreateStandAloneCollectionRoundMutationVariables>
) => {
    // tslint:disable-next-line: react-hooks-nesting
    const [mutation, { data, loading, error }] = useMutation<
        CreateStandAloneCollectionRoundMutation,
        CreateStandAloneCollectionRoundMutationVariables
    >(GraphQLDocuments.createStandAloneCollectionRound);

    const createStandAloneCollectionRound = async (variables: CreateStandAloneCollectionRoundMutationVariables) => {
        try {
            const response = await mutation({
                ...defaultcreateStandAloneCollectionRoundOptions,
                ...options,
                variables
            });

            return {
                data: response.data,
                error: response.errors
            };
        } catch (err) {
            return {
                error
            };
        }
    };

    return {
        isLoading: loading,
        error,
        data,
        createStandAloneCollectionRound
    };
};
