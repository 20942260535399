/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { GetAssetsQuery, GetAssetsQueryVariables } from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

export const useGetAssets = (options?: QueryHookOptions<GetAssetsQuery, GetAssetsQueryVariables>) => {
    const { data, error, loading } = useQuery<GetAssetsQuery, GetAssetsQueryVariables>(GraphQLDocuments.getAssets, options);

    return {
        assets: data && data.assets ? data.assets.items : [],
        assetsCount: data && data.assets ? data.assets.count : 0,
        assetsLoading: loading,
        assetsError: error
    };
};
