/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { UpdateProductMutation, UpdateProductMutationVariables } from "@models/graphql";

const defaultUpdateProductOptions: MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables> = {};

export const useUpdateProduct = (options?: MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) => {
    const [mutation, { data, loading, error }] = useMutation<UpdateProductMutation, UpdateProductMutationVariables>(
        GraphQLDocuments.updateProduct
    );

    const updateProduct = async (variables: UpdateProductMutationVariables) => {
        try {
            const response = await mutation({
                ...defaultUpdateProductOptions,
                ...options,
                variables
            });

            return {
                data: response.data,
                error: response.errors
            };
        } catch (err) {
            return {
                error
            };
        }
    };

    return {
        isLoading: loading,
        error,
        data,
        updateProduct
    };
};
