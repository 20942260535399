/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { GraphQLDocuments } from "@graphql/graphQLDocuments";
import { GetCollectionRoundsQuery, GetCollectionRoundsQueryVariables } from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

export const useGetCollectionRounds = (options?: QueryHookOptions<GetCollectionRoundsQuery, GetCollectionRoundsQueryVariables>) => {
    const { data, error, loading } = useQuery<GetCollectionRoundsQuery, GetCollectionRoundsQueryVariables>(GraphQLDocuments.getCollectionRounds, options);

    return {
        collectionRounds: data && data.collectionRounds ? data.collectionRounds : [],
        collectionRoundsLoading: loading,
        collectionRoundsError: error
    };
};
