import { Spin } from "antd";
import * as React from "react";
import { FunctionComponent } from "react";
import { Droppable } from "react-beautiful-dnd";
import { FormattedMessage } from "react-intl";

import { parseError } from "@utils/parseError";
import { useFormatMessage } from "@utils/intlHook";
import { Icon } from "@style/icon";
import { planningParams } from "@pages/planningPage/planningPage";
import { useGetInquiriesOverview } from "@graphql/hocs/hooks/useGetInquiriesOverview";
import { EmptyList } from "@components/planningSideBar/toBeRescheduledList/toBeRescheduledListStyle";
import { PlanningInquiryItem } from "@components/planningInquiryItem/planningInquiryItem";

import { InquirySearchResultsStyle } from "./inquirySearchResultsStyle";
import { CollectionRoundTaskType } from "@models/graphql";

export interface InquirySearchResultsProps {
    hideInquiries: string[];
}

export const InquirySearchResults: FunctionComponent<InquirySearchResultsProps> = ({ hideInquiries }) => {
    const formatMessage = useFormatMessage();
    const { parameters } = planningParams();

    const { inquiries, inquiriesLoading, inquiriesError } = useGetInquiriesOverview({
        variables: {
            filter: {
                search: parameters.search
            }
        },
        context: {
            debounceKey: "inquirySearch"
        },
        skip: parameters.search === ""
    });

    if (inquiriesError) {
        return (
            <EmptyList className="error">
                <Icon type="error" />
                <span>{parseError(inquiriesError, formatMessage)}</span>

                <div className="errorMessage">{inquiriesError.message}</div>
            </EmptyList>
        );
    }

    const showableInquiries = inquiries.filter(inq => !hideInquiries.some(i => i === inq.id));

    if (!showableInquiries.length && !inquiriesLoading) {
        return (
            <EmptyList>
                <Icon type="empty-box" />
                <FormattedMessage id="inquirySearch.noResults" />
            </EmptyList>
        );
    }

    return (
        <Droppable droppableId="SEARCH" isDropDisabled={true}>
            {provided => 
                <InquirySearchResultsStyle {...provided.droppableProps} ref={provided.innerRef}>
                    <Spin spinning={inquiriesLoading}>
                        {showableInquiries.map((inq, index) => 
                            <PlanningInquiryItem key={inq.id} index={index} inquiry={inq} type={CollectionRoundTaskType.Pickup} sidebarItem />)}
                    </Spin>
                    {provided.placeholder}
                </InquirySearchResultsStyle>
            }
        </Droppable>
    );
};
