import { NotificationTriggerType, ReminderTriggerType, IncidentTriggerType } from "@models/graphql";

export const DATE_FORMAT = "YYYY-MM-DD";

export const TRIGGER_TYPES: { [index: string]: string[] } = {
    notification: Object.values(NotificationTriggerType),
    reminder: Object.values(ReminderTriggerType),
    incident: Object.values(IncidentTriggerType)
};

export const NOTIFICATION_VARIABLES: { [index: string]: string[] } = {
    default: ["products", "address", "applicantName", "plannedDeliveryDate", "plannedPickupDate", "orderNumber"],
    [NotificationTriggerType.DeliveryMoved]: ["newDeliveryDate"],
    [NotificationTriggerType.PickupMoved]: ["newPickupDate"],
    [NotificationTriggerType.InquiryCanceled]: ["reason"],
    [NotificationTriggerType.InquiryDelivered]: ["dateDelivered"],
    [NotificationTriggerType.InquiryPickedUp]: ["datePickedUp"],
    [NotificationTriggerType.InquiryProcessed]: ["dateProcessed"],
    [IncidentTriggerType.IncidentCreated]: ["reason"]
};
